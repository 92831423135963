import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    flex: 1;
    width: 770px; // TODO: 
    padding: 40px;
    transition: all 1s ease;
    border-radius: 36px;
    background: #fff;
    box-shadow: 20px 20px 60px #d2d2d2, 
                -20px -20px 60px #fff;
`;

const Title = styled.h1`
    margin-bottom: 10px;
    margin-right: 30px;
    font-size: 24px;
    font-weight: 300;
    color: #5a5a5a;
    cursor: default;
`;

const PreviewArea = styled.div`
    background-color: #fbfbef;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 17px;
    line-height: 140%;
    max-height: 300px;
    max-width: 800px;
    overflow-y: auto;

    > p, > h1, > h2, > h3 {
        margin-bottom: 20px;
    }
    > h1 {
        font-size: 20px;
    }
    > h1 {
        display: none;
    }
    > h2 {
        font-size: 18px;
    }
    > h3 {
        font-size: 16px;
    }
`;

const CurrentBgSound = styled.div`
    display: block;
    background-color: #f7f7f7;
    padding: 5px 10px;
    font-family: Menlo, Monaco, Consolas, Courier New;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
`;

const SubHeader = styled.div`
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    cursor: default;
`;

const SliderValue = styled.div`
    margin-left: 20px;
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: default;
`;

const AudioDownloadLink = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    color: #898989;

    &:hover {
        color: #c0c0c0;
    }

    img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 6px;
        margin-top: -1px;
    }
`;

export { Wrapper, Title, PreviewArea, CurrentBgSound, SubHeader, 
    SliderValue, AudioDownloadLink };