import React, { useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import Dropzone from "react-dropzone";
import { DropHere } from "../BgSoundSelector/styled";

import AddFromSounds from "../AddFromSounds";
import addItemsData from "../../assets/data/add-items.json";
import { ListItem, Clone, Gripper, CloseIcon } from "../styled";
import { Wrapper, ItemGroup, Title, SubTitle, TrashCan, ItemSubtitle } from "./styled";
import { Kbd } from "@chakra-ui/react";

type ItemGroupContainer = {
    groupName?: string,
    items: AddableBlockDto[],
}

type AddableBlockDto = {
    group: string,
    type: string,
    id: string,
    title: string,
    subtitle: string,
    storyAlias?: string,
    image?: string,
    icon: string,
    shared?: boolean,
    duration?: number;
}

type Props = {
    close: any,
}

const AddFeaturePanel = (props: Props) => {
    const isMac = typeof window !== 'undefined' ? navigator.platform.toUpperCase().indexOf("MAC") >= 0 : false;
    const [availableItems] = useState<AddableBlockDto[]>(addItemsData as any);
    const [itemGroups, setItemGroups] = useState<ItemGroupContainer[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
       const itemsGrouped: ItemGroupContainer[] = [];
       const specialGroup: ItemGroupContainer = {
           items: availableItems.filter(x => x.group === 'main')
       };
       itemsGrouped.push(specialGroup);
       setItemGroups(itemsGrouped);
    }, [availableItems]);

    return (
        <Wrapper>
            <Dropzone
                // onDropAccepted={(files: any) => { console.log(files); }}
                // onDropRejected={(files: any) => { console.warn('rejected files', files); }}
                accept="*/*"
                noClick={true}
                maxFiles={1}
                minSize={0}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => {
                    return (<div {...getRootProps({ className: 'dropzone-transparent' })}>

                        {isDragActive && <DropHere>
                            <div style={{ flex: 1 }}>{t('dropFileHere')}</div>
                        </DropHere>}

                        <CloseIcon onClick={() => props.close()}>X</CloseIcon>

                        <Title>{t('addPanelHeader')}</Title>
                        <SubTitle>{t('addPanelDescription')}</SubTitle>

                        <Droppable droppableId="sidebar-droppable">
                            {(provided, snapshot) => (
                                <>
                                    {snapshot.isDraggingOver && !snapshot.draggingFromThisWith && (
                                        <TrashCan>
                                            <i className="fas fa-trash" />
                                        </TrashCan>
                                    )}
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        hidden={snapshot.isDraggingOver && !snapshot.draggingFromThisWith}
                                    >
                                        {itemGroups.map((group: ItemGroupContainer) => (
                                            <ItemGroup key={`group-` + group.groupName}>
                                                {group.groupName && <div key={`group-header-` + group.groupName} style={{ fontSize: 18, marginBottom: 20, fontWeight: 700 }}>{group.groupName}</div>}
                                                {group.items.map((item: AddableBlockDto, index) => (
                                                    <Draggable key={item.id  + `-addmode`} draggableId={item.id + `__addmode`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <>
                                                                <ListItem
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    isDragging={snapshot.isDragging}
                                                                    sidebar
                                                                    animate
                                                                    key={item.id}
                                                                >
                                                                    {!item.image && <div style={{ width: 36, height: 36, backgroundColor: '#808080', WebkitMask: `url(${item.icon}) center / contain no-repeat` }}></div>}
                                                                    {item.image && <div style={{ width: 40, height: 40 }}></div>}
                                                                    <div style={{ marginLeft: 15, marginRight: 20, flexBasis: '100%' }}>
                                                                        {!t(`addItem_${item.id}_subtitle`) &&
                                                                            (<div style={{ fontSize: 18 }}>{t(`addItem_${item.id}_title`)}</div>)
                                                                        }
                                                                        {t(`addItem_${item.id}_subtitle`)&&
                                                                            (<div style={{ fontSize: 16 }}>
                                                                                {t(`addItem_${item.id}_title`)}<br/>
                                                                                {t(`addItem_${item.id}_subtitle`) && <ItemSubtitle>{t(`addItem_${item.id}_subtitle`)}</ItemSubtitle>}
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                    <Gripper sidebar isDragging={snapshot.isDragging}></Gripper>
                                                                </ListItem>
                                                                {snapshot.isDragging && (
                                                                    <Clone />
                                                                )}
                                                            </>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </ItemGroup>
                                        ))}
                                        
                                        {provided.placeholder}
                                    </div>
                                </>
                            )}
                        </Droppable>

                        <div style={{ marginTop: 25, marginLeft: 10, fontSize: 13, color: '#808080' }}>
                            <Kbd>{isMac ? 'Cmd' : 'Ctrl'}</Kbd> + <Kbd>V</Kbd> 
                            {t('keyboardPasteInstruction')}
                        </div>

                        <Droppable droppableId="sounds-droppable">
                            {(provided, snapshot) => (
                                <>
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        hidden={snapshot.isDraggingOver && !snapshot.draggingFromThisWith}
                                    >
                                        <AddFromSounds />
                                    </div>
                                    {provided.placeholder}
                                </>
                            )}
                        </Droppable>
                    </div>
                )}
              }
            </Dropzone>
        </Wrapper>
    );
}

export default AddFeaturePanel;