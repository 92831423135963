import styled from "styled-components";

const VoiceEditorContainer = styled.div`
    background-color: #fbfbef;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size: 18px;
    min-height: 250px;
    max-height: 320px;
    overflow-y: auto;

    .ProseMirror {
        &:focus {
            outline: 0;
        }
    }
   
    /* Placeholder (at the top) */
    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #c7c7b6;
        pointer-events: none;
        height: 0;
    }

    .inactive {
        background-color: #eee;
        color: #c0c0c0;
    }

    .small {
        font-size: 14px;
    }

    p {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    voicemark {
        color: #b61fb2;
        border: dotted 1px #b61fb2;
        padding: 3px 1px;
    }

    voicemark::before {
        position: absolute;
        margin-top: -12px;
        font-size: 15px;
    }

    voicemark[data-emoji]::before {
        content: attr(data-emoji);
        position: absolute;
        margin-top: -13px;
        font-size: 15px;
        letter-spacing: 4px;
    }

    geomark {
        white-space: nowrap;

        animation-name: geomarkAni;
        animation-duration: 2s;
        animation-delay: .4s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }

    geomark::before {
        content: url('data:image/svg+xml,<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.657 2.30399C10.533 -0.769013 5.468 -0.769013 2.344 2.30399C-0.780001 5.37799 -0.780001 10.361 2.344 13.434L8 18.999L13.657 13.434C16.781 10.361 16.781 5.37799 13.657 2.30399ZM8 10.499C7.332 10.499 6.705 10.239 6.232 9.76699C5.257 8.79199 5.257 7.20599 6.232 6.23099C6.704 5.75899 7.332 5.49899 8 5.49899C8.668 5.49899 9.296 5.75899 9.768 6.23099C10.743 7.20599 10.743 8.79299 9.768 9.76699C9.296 10.239 8.668 10.499 8 10.499Z" fill="%232DB83D"/></svg>');
        width: 19px;
        height: 16px;
        margin-right: 4px;
        vertical-align: -3px;
        color: #2db83d;
        font-weight: 400;
    }

    @keyframes geomarkAni {
        from {
            color: #000;
            border-bottom: dotted 1px transparent;
        }
        to {
            color: #2db83d;
            border-bottom: dotted 1px #2db83d;
        }
      }

    * {
        [contenteditable] ~ grammarly-extension,
        input ~ grammarly-extension,
        textarea ~ grammarly-extension {
        display: none;
        }
    }
`;

const InputField = styled.input`
    padding: 8px 14px;
    font-size: 22px;
    border: solid 1px #e2e2e2;
    border-radius: 4px;
    :focus {
        outline: 0;
    }
`;

const MoreSettingsLink = styled.div`
    display: inline-block;
    font-size: 13px;
    color: #808080;
    margin-top: 5px;
    margin-right: 15px;
    cursor: pointer;
    border-bottom: dotted 1px #808080;
`;

const ListenButton = styled.button`
    margin-left: 10px;
    border: unset;
    cursor: pointer;
    flex: 1;

    background-color: #fff;
    color: #898989;
	border: solid 1px #e2e2e2;
    padding: 10px 20px;
    border-radius: 11px;
    font-size: 18px;
    color: #565656;

    :active, &.active {
        color: var(--color-persistent-brand);
        border-color: var(--color-persistent-brand);
        border-style: dotted;
    }
`;

const ExistingInputSuggestion = styled.div`
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        color: red;    
    }
`;

export {
    VoiceEditorContainer, InputField, ListenButton,
    MoreSettingsLink, ExistingInputSuggestion
};