import React, { useEffect, useState } from "react";
// import cronstrue from "cronstrue";
import parser from "cron-parser";
import { formatRelative } from "date-fns";
import { sv } from 'date-fns/locale';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type Props = {
  expression: string | null
}

const CronDisplay = (props: Props) => {
  const [nextOccurrenceString, setNextOccurrenceString] = useState<string>();
  const { t } = useTranslation();
  
  useEffect(() => {
    if (props.expression) {
      const lang =  i18next.language;
      const localeDateFns = i18next.language === 'sv' ? sv : undefined;

      // get next occurrence
      const options = {
        currentDate: new Date(),
        utc: true,
        la: lang
      };
      const parserInterval = parser.parseExpression(props.expression, options);
      const nextDateTime = formatRelative(parserInterval.next().toDate(), 
        new Date(), { locale: localeDateFns });
      setNextOccurrenceString(nextDateTime);

      // get friendly description
      /*
      let val = cronstrue.toString(props.expression!, {
        locale: lang,
        use24HourTimeFormat: true
      });
      setCronDesc(val);
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expression]);

  return (
    <>
      {nextOccurrenceString && <div style={{ paddingLeft: 5, paddingTop: 10, fontSize: 14, color: '#999' }}>
        {t('nextVersionWillBeCreated')} {nextOccurrenceString}.
      </div>}
    </>
  );
};

export default CronDisplay;