import React, { useEffect, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BlobsApi, SettingPublicDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { Modal, ModalFooter, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, 
    AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, 
    AlertDialogBody, AlertDialogCloseButton, AlertDialogFooter } from "@chakra-ui/react";
import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, 
    PopoverFooter, PopoverHeader, PopoverTrigger, Portal } from "@chakra-ui/react";
import { Button } from "../styled";

import Resizer from "react-image-file-resizer";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Wrapper = styled.div`
    float: right;
    margin-left: 20px;
`;

const Placeholder = styled.div`
    width: 70px;
    height: 70px;
    border: dotted 1px #e8e8d1;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    padding-top: 10px;
    border-radius: 6px;
    color: transparent;

    &:hover {
        color: #cdcda6;
    }
`;

type CropperModalProps = {
    img: any,
    storyAlias: string,
    onUploaded: any,
    onClose: any,
    minSize: number,
    maxSize: number
}

const CropperModal = (props: CropperModalProps) => {
    const { t } = useTranslation();
    const blobsApi = new BlobsApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [crop, setCrop] = useState({ unit: '%', width: 90, aspect: 1 / 1 });
    const [completedCrop, setCompletedCrop] = useState<any>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: any = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = 2; // window.devicePixelRatio;
        // console.log(`x: ${scaleX} y: ${scaleY} ratio: ${pixelRatio}`);

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const onLoad = useCallback((img: any) => {
        imgRef.current = img;
    }, []);

    const resizeFile = (file: Blob, size: number) => new Promise(resolve => {
        const jpegQuality: number = 90;
        const compressFormat = file.type.match('image/jp.*') ? "JPEG" : "PNG";

        Resizer.imageFileResizer(file, size, size, compressFormat, jpegQuality, 0,
            b => { resolve(b); }, 'blob', size, size
        );
    })

    const uploadImage = async (file: any) => {
        setIsUploading(true);

        const resizedFile = await resizeFile(file, props.maxSize);
        const fileExtension = file.type.match('image/jp.*') ? "jpg" : "png";
        const timeStamp = new Date().getMilliseconds();
        const fileName = `${props.storyAlias}-${timeStamp}-pic.${fileExtension}`;
        const uploadResult = await blobsApi.uploadImageAsync(fileName, resizedFile);

        if (uploadResult.data.success) {
            const newUri = uploadResult.data.uri!;
            setIsUploading(false);
            props.onUploaded(newUri);
        } else {
            console.error(uploadResult.data.message);
            props.onClose();
        }

        const smallerResizedFile = await resizeFile(file, 1142);
        const smallerFileName = `${props.storyAlias}-${timeStamp}-pic_m.${fileExtension}`;
        const uploadResult2 = await blobsApi
            .uploadImageAsync(smallerFileName, smallerResizedFile);
        if (!uploadResult2.data.success && uploadResult2.data.message) {
            throw new Error(uploadResult2.data.message);
        }

        const thumbResizedFile = await resizeFile(file, 60);
        const thumbFileName = `${props.storyAlias}-${timeStamp}-pic_t.${fileExtension}`;
        const uploadResult3 = await blobsApi
            .uploadImageAsync(thumbFileName, thumbResizedFile);
        if (!uploadResult3.data.success && uploadResult3.data.message) {
            throw new Error(uploadResult3.data.message);
        }
    }

    const generateAndUpload = (canvas: any, crop: any) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            (blob: any) => { uploadImage(blob); },
            'image/jpeg', // TODO: support PNG format
            1
        );
    }

    return (
        <Modal size={'3xl'} isOpen={true} onClose={() => { props.onClose(); }} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <div style={{ maxHeight: 650, overflow: 'scroll', textAlign: 'center' }}>
                        <ReactCrop
                            src={props.img}
                            onImageLoaded={(i: any) => onLoad(i)}
                            crop={crop}
                            onChange={(c: any) => setCrop(c)}
                            onComplete={(c: any) => setCompletedCrop(c)} />
                    </div>
                    <div style={{ display: 'none' }}>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height 
                            // matches/is a multiple for sharpness.
                            style={{
                                width: Math.round(1000), // completedCrop?.width ?? 0
                                height: Math.round(1000) // completedCrop?.height ?? 0
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        primary
                        disabled={!completedCrop?.width || !completedCrop?.height}
                        progress={isUploading}
                        onClick={() =>
                            generateAndUpload(previewCanvasRef.current, completedCrop)
                        }
                    >
                        {isUploading ? t('uploading') + '...' : t('upload')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

type ImageItemProps = {
    storyAlias: string,
    url: string | null
    onReplaced: any
}

const ImageItem = (props: ImageItemProps) => {
    const { t } = useTranslation();

    const [isCropperModalOpen, setIsCropperModalOpen] = useState<boolean>(false);
    const [showTooSmallWarning, setShowTooSmallWarning] = useState<boolean>(false);
    const [tooSmallWidth, setTooSmallWidth] = useState<number>(0);
    const [tooSmallHeight, setTooSmallHeight] = useState<number>(0);

    const [uploadedBlob, setUploadedBlob] = useState<any>();
    const cancelRef = useRef<HTMLButtonElement>(null);
    const IMAGE_SIZE = 3000;

    const selectedFromDisk = (e: any) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const fr = new FileReader();
            fr.addEventListener('load', () => {
                const image = new Image();
                if (fr !== null && typeof fr.result === 'string') {
                    image.src = fr.result;
                    setUploadedBlob(fr.result);
                }
                image.onload = async () => {
                    const width = image.width;
                    const height = image.height;
                    
                    if (width < IMAGE_SIZE || height < IMAGE_SIZE) {
                        setShowTooSmallWarning(true);
                        setTooSmallWidth(width);
                        setTooSmallHeight(height);
                    } else {
                        setIsCropperModalOpen(true);
                    }
                };
            });
            fr.readAsDataURL(file);
        } else {
            console.error(`no file selected.`);
        }
    }

    const editImage = (imageUrl: string) => {
        // TODO: setIsEditModelOpen(true);
    }

    return (
        <div>
            {props.url &&
                <>
                    <div onClick={() => { editImage(props.url!) }} style={{
                        width: 100,
                        height: 100,
                        borderRadius: 5,
                        backgroundColor: '#050505',
                        backgroundImage: `url('${props.url}')`,
                        backgroundSize: 'cover'
                    }}></div>
                </>
            }
            {!props.url && <>
                <label htmlFor={`${props.storyAlias}-fileupload`}>
                    <Placeholder>{t('insertImage')}</Placeholder>
                </label>
                <input
                    type="file"
                    accept="image/*"
                    id={`${props.storyAlias}-fileupload`}
                    onChange={(e: any) => selectedFromDisk(e)} hidden />
            </>}
            {isCropperModalOpen && uploadedBlob &&
                <CropperModal
                    storyAlias={props.storyAlias}
                    img={uploadedBlob}
                    onUploaded={(uri: string) => {
                        props.onReplaced(uri);
                        setIsCropperModalOpen(false);
                     }}
                    onClose={() => { setIsCropperModalOpen(false); }}
                    minSize={IMAGE_SIZE}
                    maxSize={IMAGE_SIZE} />
            }
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => { setShowTooSmallWarning(false); }}
                isOpen={showTooSmallWarning}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>För liten bildstorlek</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <p style={{ marginBottom: 10 }}>
                            Bilden behöver vara <strong>minst {IMAGE_SIZE} * {IMAGE_SIZE} pixlar</strong>.<br/>
                            Den uppladdade bilden är {tooSmallWidth} * {tooSmallHeight} pixlar.
                        </p>
                        <p>
                            Om du ändå använder den här bilden kommer den förstoras och
                            kvaliteten bli dålig och suddig.
                        </p>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={() => {
                            setShowTooSmallWarning(false);
                            setIsCropperModalOpen(true);
                        }} small>Använd ändå</Button>
                        <Button onClick={() => {
                            setShowTooSmallWarning(false);
                        }} primary>{t('cancel')}</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
}

type Props = {
    storyAlias: string,
    onChanged: any
}

const ImagesManager = (props: Props) => {
    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const tenantId = sessionStorage.getItem('TENANT_ID')!;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [images, setImages] = useState<string[] | null>(null);
    const [bylines, setBylines] = useState<string[] | null>([]);
    const settingKey = 'images';

    const loadImagesFromStorySetting = async () => {
        const apiResult = await storyApi
            .getStorySetting(props.storyAlias, settingKey, tenantId);
        if (apiResult.data && apiResult.data.value) {
            // Found "images" as setting on story
            return apiResult.data.value;
        }

        return null;
    };

    useEffect(() => {
        setIsLoading(true);
        async function load() {
            const imagesSettingValue = await loadImagesFromStorySetting();
            if (imagesSettingValue) {
                const imgArray = imagesSettingValue.split(',');
                console.log(imagesSettingValue);
                if (imgArray.length > 0) {
                    setImages(imgArray);
                    // TODO: set byline values
                    // setBylines(imagesSettingValue.split(',').map(x => x.split('|')[1]));
                } else {
                    setImages(null);
                }
            } else {
                setImages(null);
            }
            setIsLoading(false);
        }
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Save to backend
        if (images && images !== null) {
            console.log(images);
            const imagesWithBylines = images.map((val, index) => {
                if (bylines && bylines[index]) {
                    return `${val}|${bylines[index]}`;
                }
                return val;
            });

            let imagesSettingValue: string | null = imagesWithBylines.join(',');
            if (images.length === 0) {
                console.log(`last image has been removed`);
                imagesSettingValue = null;
            }

            const settingValue: SettingPublicDto = {
                value: imagesSettingValue
            };
            storyApi.updateStorySetting(props.storyAlias, settingKey, settingValue).then((res) => {
                if (res.data.success) {
                    console.log(`${images.length} images has ` +
                        `been saved as story setting`);
                } else {
                    throw new Error(`failed to update images setting: ${res.data.message}`);
                }
            })
            props.onChanged(images);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    const replaceImage = (oldUrl: string, newUrl: string) => {
        let array = Object.assign([], images) ?? new Array<string>();

        const index = array.findIndex(x => x === oldUrl);
        if (index < 0) {
            console.warn(`old url could not be found ` +
                `in images array: ${oldUrl}`);
        }

        array.splice(index, 1, newUrl);

        setImages(array);
    }

    const addImage = (newUrl: string) => {
        let array = Object.assign([], images) ?? new Array<string>();
        array.push(newUrl);

        setImages(array);
    }

    const deleteImage = (url: string) => {
        let array = Object.assign([], images!);
        const index = array.findIndex(x => x === url);
        array.splice(index, 1);

        setImages(array);
    }

    return (
        <Wrapper>
            {!isLoading && images && images.map((url: string) => (
                <Popover key={`${url}-popover`}>
                    <PopoverTrigger>
                        <button title="Change image">
                            <ImageItem key={`${url}-img`}
                                storyAlias={props.storyAlias}
                                onReplaced={(newUrl: string) => {
                                    replaceImage(url, newUrl);
                                }}
                                url={url} />
                        </button>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader></PopoverHeader>
                            <PopoverBody>
                                <img src={url} style={{ width: 290, height: 290 }} alt="" />
                            </PopoverBody>
                            <PopoverFooter>
                                <Button small onClick={() => deleteImage(url)}>{t('remove')}</Button>
                            </PopoverFooter>
                        </PopoverContent>
                    </Portal>
                </Popover>
            ))}
            {(!images || images.length === 0) &&
                <ImageItem
                    key="placeholder"
                    storyAlias={props.storyAlias}
                    onReplaced={(newUrl: string) => {
                        addImage(newUrl);
                    }}
                    url={null} />
            }
        </Wrapper>
    );
}

export default ImagesManager;