import { Config } from "../config";

export class FeatureFlags {
    public static podcast: boolean = true;
    public static email: boolean = Config.isBetaMode();
    public static emoji: boolean = Config.isEmojiFeatureEnabled();
    public static fbPost: boolean = true;
    public static tweet: boolean = true;
    public static video: boolean = false;
    public static webPosts: boolean = false;
    public static geoNames: boolean = Config.isBetaMode();

    public static images: boolean = true;
    public static ctaLinks: boolean = Config.isDevMode();
    public static downloadLinks: boolean = Config.isBetaMode() || Config.isDownloadLinksEnabled();
    public static localix: boolean = Config.isDevMode();
    public static developer: boolean = Config.isDevMode();
    public static personSwitcher: boolean = Config.isDevMode();
    public static radio: boolean = Config.isDevMode();
}