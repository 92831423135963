import React, { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { TenantSummaryDto, UserApi } from "./generated-sources/openapi/api";
import { Config } from "./config";

import { Route, Switch } from "react-router-dom";
import { ChakraProvider, ThemeProvider, extendTheme, Link } from "@chakra-ui/react";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";
import enTranslations from "./assets/translations/en.json";
import svTranslations from "./assets/translations/sv.json";

import { Loader } from "./components/styled";

import StationView from "./components/StationView";
import Login from "./components/Login";
import StationList from './components/StationList/StationList';
import StationMetadataModal from "./components/StationMetadataModal";
import AppTopbar from "./components/Header/AppTopbar";
import TaskList from "./components/TaskList/TaskList";

i18next
	.use(initReactI18next)
	.init({
		resources: {
			en: enTranslations,
			sv: svTranslations
		},
		lng: 'sv',
		fallbackLng: 'en',
		supportedLngs: ['en', 'sv'],
		ns: ['global'],
		defaultNS: 'global',
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react!
		},
		react: {
			useSuspense: true,
		},
	});

// Setup Bugsnag as error reporting tool
Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_APIKEY!,
	plugins: [new BugsnagPluginReact()],
	onError: (event) => {
		const tenantAlias = sessionStorage.getItem('TENANT_ALIAS')!;
		event.addMetadata('company', {
			name: tenantAlias,
			lang: i18next.language
		})
		return true;
	}
});

// Axios instance
const UNAUTHORIZED = 401;
Config.AxiosInstance.interceptors.response.use(
  response => response,
  error => {
    const status = error.response;
    if (status === UNAUTHORIZED) {
      console.warn(`no access to the api. ` +
	  	`please fix valid access token.`);
    }
    return Promise.reject(error);
 }
);

const theme = extendTheme({
	initialColorMode: "dark",
	useSystemColorMode: false,
	colors: {
		brand: {
			500: "var(--color-persistent-brand)"
		},
	},
});

const App = () => {
	const { t } = useTranslation();
	const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);
	const loadingImageUrl = `${process.env.REACT_APP_BACKEND_URL}/images/loading.gif`;

	const [tenantId, setTenantId] = useState<string | null | undefined>(undefined); // note: null = no selected at the moment, undefined = no found on user
    const [isAppLoading, setIsAppLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const { isLoading, isAuthenticated, error, user, getAccessTokenSilently, logout } = useAuth0();

	useEffect(() => {
        if (isAuthenticated) {
			setIsLoggedIn(true);
			prepareUserData();
			if (user) {
				Bugsnag.setUser(user.email, user.email, user.user_id);
			}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);
	
	const setToken = async () => {
		const token = await getAccessTokenSilently({
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		});
		sessionStorage.setItem('AUTH0_BEARER', token);
		return token;
	}

	const prepareUserData = async () => {
		setIsAppLoading(isAuthenticated);

		const token = await setToken();

		const userApi = new UserApi(Config.getApiConfig(token), undefined, Config.AxiosInstance);
		const userInfoResult = await userApi.getUserInfo();

		if (userInfoResult.data.tenants && userInfoResult.data.tenants.length > 0) {
			const tenantIdInLocalStorage = localStorage.getItem('TENANT_ID');
			const tenants = userInfoResult.data.tenants;
			
			let tenant: TenantSummaryDto;
			if (tenants.some(x => x.id === tenantIdInLocalStorage)) {
				tenant = tenants.find(x => x.id === tenantIdInLocalStorage)!;
			} else {
				tenant = tenants![0];
			}

			setTenantId(tenant.id);

			sessionStorage.setItem('TENANT_ID', tenant.id!);
			sessionStorage.setItem('TENANT_ALIAS', tenant.alias);
			sessionStorage.setItem('TENANT_NAME', tenant.name);

			localStorage.setItem('TENANT_ID', tenant.id!);
			localStorage.setItem('TENANT_ALIAS', tenant.alias);
			localStorage.setItem('TENANT_NAME', tenant.name);
		} else {
			// new user, remove the tenant-ID from session storage
			setTenantId(undefined);

			sessionStorage.removeItem('TENANT_ID');
			sessionStorage.removeItem('TENANT_ALIAS');
			sessionStorage.removeItem('TENANT_NAME');

			localStorage.removeItem('TENANT_ID');
			localStorage.removeItem('TENANT_ALIAS');
			localStorage.removeItem('TENANT_NAME');
		}

		setIsAppLoading(false);
	}

	if (isLoading) {
        return <Loader><div>{t('loading')}...</div></Loader>;
    }

	if (error) {
        return <div>Oops... {error.message}</div>;
	}   

	return (
		<React.Suspense fallback={<Loader>
				<img src={loadingImageUrl} alt="" />
				<span>{t('loading')}...</span>
			</Loader>}>
			<ErrorBoundary>
				<I18nextProvider i18n={i18next}>
					<AppTopbar appName="" />
					<div className="app">
						{isLoggedIn && tenantId &&
							<>
								<ChakraProvider>
									<ThemeProvider theme={theme}>
										<Switch>
											<Route path="/" render={() => <StationList />} exact />
											<Route path="/tasks" render={() => <TaskList />} exact />
											<Route path="/:storyAlias/edit" render={(storyAlias: string) => 
												<StationMetadataModal storyAlias={storyAlias.match['params'].storyAlias} /> } />
											<Route path="/:storyAlias" render={(storyAlias: string) => 
												<StationView storyAlias={storyAlias.match['params'].storyAlias} loading={isAppLoading} /> } />
										</Switch>
									</ThemeProvider>
								</ChakraProvider>
							</>
						}
						{!isLoggedIn &&
							<Login onLoggedIn={() => {
								setIsLoggedIn(true);
								prepareUserData();
							}}></Login>
						}
						{!isAppLoading && !tenantId && isLoggedIn && 
							<>
								<h2 style={{ fontSize: 24, fontWeight: 'bold' }}>
									{t('userCreatedHeader')}
								</h2>
								<p style={{ fontSize: 18, maxWidth: 700 }}>
									{t('userCreatedText1')}
									<a href="mailto:support@radiox.se">support@radiox.se</a> 
									{t('userCreatedText2')}
								</p>
								<p>
									<Link onClick={() => {
										logout({ returnTo: window.location.origin });
									}}>Logga ut</Link>
								</p>
							</>
							// <TenantOnboarding onFinished={(tenantId: string) => {
							// 	setTenantId(tenantId);
							// }}></TenantOnboarding>
						}
					</div>
				</I18nextProvider>
			</ErrorBoundary>
		</React.Suspense>
	)
}

export default App;
