import { Mark, mergeAttributes } from '@tiptap/core'

export interface PersonMarkOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    personmark: {
      setPersonMark: () => ReturnType,
      togglePersonMark: () => ReturnType,
      unsetPersonMark: () => ReturnType,
    }
  }
}

export const PersonMarkExtension = Mark.create<PersonMarkOptions>({
  name: 'personmark',

  group: 'inline',
  inline: false,
  selectable: true,
  atom: true,

  addOptions(): PersonMarkOptions {
    return {
      HTMLAttributes: { },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'personmark',
      },
      {
        consuming: false,
        getAttrs: style => ((style as string).includes('personmark') ? {} : false),
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['personmark', mergeAttributes(
      this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setPersonMark: () => ({ commands }) => {
        return commands.setMark('personmark')
      },
      togglePersonMark: () => ({ commands }) => {
        return commands.toggleMark('personmark')
      },
      unsetVoiceMark: () => ({ chain }) => {
        return chain()
          // .extendMarkRange('personmark')
          .unsetMark('personmark')
          .run()
      }
    }
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addAttributes() {
    return {
      type: {
        default: 'id',
        parseHTML: element => {
          return element.getAttribute('id') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }
          return {
            'id': attributes.id
          }
        },
      },
      lang: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('lang') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.lang) {
            return {}
          }
          return {
            'lang': attributes.lang
          }
        },
      },
      token: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('token') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.token) {
            return {}
          }
          return {
            'token': attributes.token
          }
        },
      },
    }
  }
})