import styled from "styled-components";

const ActionListContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ActionListItem = styled.div`
    flex: 1;
    width: 100%;
    padding: 6px 15px;
    margin-bottom: 5px;
    display: flex;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;

    opacity: 0.35;
    animation: fade 2s ease;
    animation-fill-mode: forwards;
    @keyframes fade {
        0% {
            opacity: 0.35;
        }
        50% {
        }
        100% {
            opacity: 1;
            color: black;
        }
    }

    &:nth-child(1) { animation-delay: 0.5s; }
    &:nth-child(2) { animation-delay: 2.0s; }
    &:nth-child(3) { animation-delay: 6.5s; }
    &:nth-child(4) { animation-delay: 9.0s; }
    &:nth-child(5) { animation-delay: 10.0s; }
    &:nth-child(6) { animation-delay: 11.0s; }
    &:nth-child(7) { animation-delay: 12.0s; }
    &:nth-child(8) { animation-delay: 14.0s; }
    &:nth-child(9) { animation-delay: 16.0s; }
    &:nth-child(10) { animation-delay: 17.0s; }
    &:nth-child(11) { animation-delay: 19.0s; }
`;

const EmojiAsImage = styled.div`
    width: 30px;
    display: inline-block;
    margin-right: 12px;
    font-size: 24px;
    text-align: center;
    img {
        width: 22px;
    }
`;

const EmojiContainer = styled.div`
    width: 30px;
    height: 20px;
    display: inline-block;
    margin-right: 12px;
    text-align: center;
`;

const Description = styled.div`
    
`;

const Time = styled.div`
    margin-left: auto;
`;

export { ActionListContainer, ActionListItem, 
    EmojiAsImage, EmojiContainer, Description, Time };
