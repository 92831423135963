import React, { useEffect, useRef, useState } from "react";
import {
    BlockDto, StoryApi, Destination, SnapshotDto,
    SnapshotPatchInputDto, SnapshotSummaryDto, SettingPublicDto
} from "../../generated-sources/openapi";
import { Config } from "../../config";
import { useDebouncedCallback } from "use-debounce/lib";
import { useTranslation } from "react-i18next";
import { Skeleton, Stack, useToast } from "@chakra-ui/react";

import Slider from "react-input-slider";
import ContentEditable from "react-contenteditable";
import ReactMarkdown from "react-markdown";

import {
    Wrapper, Title, PreviewArea, CurrentBgSound,
    SubHeader, SliderValue, AudioDownloadLink
} from "./styled";
import { Button, CloseIcon } from "../styled";

import UploadModal from "../UploadModal";
import VoicePersonSelector from "../VoicePersonSelector";
import CustomAudioPlayer from "../AudioPlayer";
import BgSoundSelector from "../BgSoundSelector";
import Record from "../Record/Record";
import SnapshotHistory from "../SnapshotHistory";
import CopyToModal from "../CopyToModal";
import RunkitEditor from "../RunkitEditor";
import UrlTypeForm from "../UrlTypeForm";
import ImagesManager from "../ImagesManager";
import LinksManager from "../LinksManager/LinksManager";
import AudioUploader from "../AudioUploader/AudioUploader";
import VoiceEditor from "../VoiceEditor";
import EmojiModal from "../EmojiModal";

import * as BlockFunctions from "../../utils/blockFunctions";
import * as MarkdownFunctions from "../../utils/markdownFunctions";
import { FeatureFlags } from "../../utils/featureFlags";

type Props = {
    item: BlockDto,
    langCode: string,
    updateSelected: any,
    onRemove: any,
    onClose: any
}

const BlockManager = (props: Props) => {
    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const { t } = useTranslation();
    const toast = useToast();

    const [init, setInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [adjustMode, setAdjustMode] = useState(false);
    const [configMode, setConfigMode] = useState(false);
    const [micRecordMode, setMicRecordMode] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [bgSoundModalOpen, setBgSoundModalOpen] = useState(false);
    const [copyModalOpen, setCopyModalOpen] = useState(false);
    const [emojiModalOpen, setEmojiModalOpen] = useState(false);
    const timer = useRef<any>(null);

    const [storyAlias, setStoryAlias] = useState<string | null>(null);
    const [blockItem, setBlockItem] = useState<BlockDto | null>(null);
    const [snapshot, setSnapshot] = useState<SnapshotDto | null>(null);
    const [audioUri, setAudioUri] = useState<string | null>(null);
    const [title, setTitle] = useState('');
    const [titleHasEmoji, setTitleHasEmoji] = useState<boolean>(false);
    const [markupContent, setMarkupContent] = useState<string | null>(null);
    const [markdownContent, setMarkdownContent] = useState<string | null>(null);
    const [snapshotList, setSnapshotList] = useState<SnapshotDto[] | null>(null);
    const [cronExpression, setCronExpression] = useState<string | null>(null);

    const [voicePersonId, setVoicePersonId] = useState<string | null>(null);
    const [fadeIn, setFadeIn] = useState(0);
    const [fadeOut, setFadeOut] = useState(0);
    const [falseStart, setFalseStart] = useState(0);
    const [volume, setVolume] = useState(100);
    const [bgSound, setBgSound] = useState<string | null>(null);
    const [maxFadeSecs, setMaxFadeSecs] = useState<number>(20); // TODO: adjust to duration

    const titleMaxLength = 100;
    const titleEditableRef = useRef<any>(null);

    useEffect(() => {
        // cancel waiting for recording result
        if (timer && timer.current) {
            console.debug(`remove wait for recording timer`);
            clearInterval(timer.current!);
        }
        if (props.item!.storyAlias) {
            // remove focus on the title input field
            if (titleEditableRef && titleEditableRef.current) {
                titleEditableRef!.current.blur();
            }

            // save the settings on the current block before 
            // switch to the clicked new one
            debouncedSavingTitle.cancel();
            debouncedSendToBlockList.cancel();

            // set the new story alias as active
            setStoryAlias(props.item!.storyAlias!);

            // lets load all data
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.item.storyAlias]);

    // If the title is being edited by the user,
    // wait 1 sec and save it to backend.
    useEffect(() => {
        if (!init && snapshot) {
            debouncedSavingTitle.callback(title);
            updateSelectedInList(snapshot);
        }
        const hasEmoji = /\p{Extended_Pictographic}/u.test(title);
        setTitleHasEmoji(hasEmoji);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    // When any audio setting is being updated, wait 1 secs to
    // see if it will be any more changes, before sending it
    // to the block list and updating the Markdown in the parent story.
    // Settings such as Fade in, out and volume will be updated 
    // as attributes on the tag in the block list.
    useEffect(() => {
        if (!init && props.item.storyAlias! === storyAlias) {
            console.debug(`audio settings has been changed`);
            const settingsCombined = voicePersonId + '-' +
                fadeIn + '-' + fadeOut + '-' + falseStart + '-' +
                volume + '-' + (bgSound ?? '');
            debouncedSendToBlockList.callback(settingsCombined);
        } else if (props.item.storyAlias! === storyAlias) {
            console.debug(`attributes changed, ` +
                `but we're switching block - will not save`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fadeIn, fadeOut, falseStart, volume, bgSound, voicePersonId]);

    const debouncedSendToBlockList = useDebouncedCallback(
        (value: any) => {
            updateSelectedInList(snapshot);
        }, 1000
    );

    const load = async () => {
        const storyAlias = props.item.storyAlias!;
        if (!storyAlias || storyAlias === null) {
            throw new Error(`story alias can not be null.`);
        }

        setInit(true); // TODO: not working
        setIsLoading(true);
        setSnapshot(null);
        setMarkdownContent(null);
        setVoicePersonId(null);
        setEditMode(false);
        setIsRecording(false);

        setBlockItem(props.item);
        setTitle(props.item.title);

        loadSnapshots();

        // TODO: replace with GetStory and receive inputs and content in same request
        const inputs = await storyApi
            .getInputs(storyAlias, undefined);
        if (inputs) {
            if (props.item.type !== 'sound') {
                // Voice Person setting
                const voicePersonIdSetting = inputs.data['_voice-person'];
                if (voicePersonIdSetting) {
                    setVoicePersonId(voicePersonIdSetting.toString());
                }
                else {
                    const defaultVoicePerson = BlockFunctions
                        .getDefaultVoicePerson(props.langCode);
                    setVoicePersonId(defaultVoicePerson.name);
                }

                // Scheduler (CRON expression) setting 
                //  - used on Node / external URI type
                const cronSetting = inputs.data['_audio-cron'];
                if (cronSetting) {
                    setCronExpression(cronSetting.toString());
                }
                else {
                    setCronExpression(null);
                }
            }
        }

        if (props.item.audioFadeIn) {
            setFadeIn(props.item.audioFadeIn);
        } else {
            setFadeIn(0);
        }

        if (props.item.audioFadeOut) {
            setFadeOut(props.item.audioFadeOut);
        } else {
            setFadeOut(0);
        }

        if (props.item.audioFalseStart) {
            setFalseStart(props.item.audioFalseStart);
        } else {
            setFalseStart(0);
        }

        if (props.item.audioVolume) {
            setVolume(props.item.audioVolume);
        } else {
            setVolume(100);
        }

        if (props.item.audioBgSound) {
            setBgSound(props.item.audioBgSound);
        } else {
            setBgSound(null);
        }

        setInit(false);
        setIsLoading(false);
    }

    const loadSnapshots = async () => {
        // TODO: move to SnapshotHistory component

        const snapshotCount = props.item.type === 'text' ? 1 : 5;
        const snapshots = await storyApi
            .getStorySnapshots(props.item.storyAlias!, snapshotCount);
        setSnapshotList(snapshots.data);

        if (snapshots.data.length > 0) {
            const singleSnapshot = await storyApi
                .getSnapshot(props.item.storyAlias!, snapshots.data[0].snapshotKey);
            loadCurrentSnapshot(singleSnapshot.data);
        } else {
            console.log('no snapshot found.');
            setSnapshot(null);
        }
    }

    const loadCurrentSnapshot = async (snapshot: SnapshotDto) => {
        if (!snapshot) throw new Error(`snapshot is null!`);
        setSnapshot(snapshot);
        setAudioUri(snapshot.audioUri ?? null);

        // set max fade seconds to the duration of the snapshot
        if (snapshot.audioDuration) {
            setMaxFadeSecs(snapshot.audioDuration);
        }

        if (props.item.type !== 'sound') {
            if (!snapshot.markdownContent ||
                snapshot.markdownContent === undefined) {
                setMarkupContent('');
                setMarkdownContent('');
            } else {
                setMarkupContent(snapshot.markup ?? '');
                setMarkdownContent(snapshot.markdownContent ?? '');
            }
        }
    }

    const editContent = () => {
        setEditMode(true);
    }

    const openConfigMode = () => {
        setConfigMode(true);
        setEditMode(false);
    }

    const saveContinuously = async (content: string) => {
        // called from the editor 750 ms after every change.
        setMarkupContent(content);

        // save to snapshot
        if (snapshot) {
            const titleFromMarkdownHeader =
                MarkdownFunctions.getHeaderFromMarkdown(content);
            if (titleFromMarkdownHeader) {
                const modifiedSnapshotWithMdTitle = {...snapshot,
                    snapshotKey: snapshot!.snapshotKey!,
                    title: titleFromMarkdownHeader!
                };

                setTitle(titleFromMarkdownHeader);
                setSnapshot(modifiedSnapshotWithMdTitle);
            }

            // TODO: Find the first link in Markdown and set as CTA link

            const inputDto: SnapshotPatchInputDto = {
                markup: content
            }
            const patchSnapshotResult = await storyApi
                .patchSnapshot(storyAlias!, snapshot!.snapshotKey!, inputDto);
            if (patchSnapshotResult.data && patchSnapshotResult.status === 200) {
                console.log(`update markup content on snapshot`);
                updateSelectedInList(snapshot);

                // markup has been transformed to markdown format on backend
                setMarkdownContent(patchSnapshotResult.data.markdownContent ?? '');
            } else {
                console.error(`failed to update snapshot markup on backend`);
            }
        }
    }

    const exitEditModeAndRecord = async (recordAsWell: boolean) => {
        // Note: will wait one sec so the editor has
        // saved the markdown to backend.
        setTimeout(async () => {
            setEditMode(false);
            if (recordAsWell && voicePersonId !== 'mic') {
                await recordWithAiVoice();
            }
        }, 1000);
    }

    const recordWithAiVoice = async () => {
        clearInterval(timer.current);
        setIsRecording(true);

        try {
            const runAsBgJob =
                voicePersonId === 'Mats' ||
                voicePersonId === 'Lovisa' ||
                voicePersonId === 'Katarina';

            const triggerResult = await storyApi.triggerSnapshotAsPost(
                storyAlias!, snapshot!.snapshotKey!, runAsBgJob, [Destination.Audio]);

            if (runAsBgJob) {
                console.debug(`will trigger ${snapshot!.snapshotKey} ` +
                    `in hangfire as job-id ${triggerResult.data.bgJobId!}`);

                const executeToken = triggerResult.data.executeToken!;
                const intervalId = setInterval(() => waitForRecording(executeToken), 2500);
                timer.current = intervalId;
            }
            else if (triggerResult.data.success) {
                console.debug(`recording has been completed.`);
                handleRecordingResult();
            }
            else {
                toast({
                    title: `Failed to record`,
                    description: `Error message: ${triggerResult.data.message}`,
                    status: 'error',
                    isClosable: true
                })
                console.error(triggerResult.data.message);
                setIsRecording(false);
            }
        } catch (err) {
            const error: any = err;
            toast({
                title: `Failed to record`,
                description: `Error message: ${error.message}`,
                status: 'error',
                isClosable: true
            })
            console.error(`failed to record new version ` +
                `of '${snapshot!.snapshotKey}'`);
            console.error(err);
            setIsRecording(false);
        }
    }

    const waitForRecording = async (executeToken: string) => {
        // TODO: handle if block has been changed while waiting

        const result = await storyApi.isVoiceAvailable(storyAlias!,
            snapshot!.snapshotKey!, executeToken);

        const recordingReady = result.data.success;
        if (recordingReady) {
            console.debug(`yay! found fresh recorded audio.`);
            clearInterval(timer.current);
            handleRecordingResult();
        } else {
            console.debug(`waiting for recording '${executeToken}', ` +
                `not found. sleep a few secs.`);
        }
    }

    const handleRecordingResult = async () => {
        // hide the recording info in audio player
        setIsRecording(false);

        // grab the result
        const snapshotInBackend = await storyApi
            .getSnapshot(storyAlias!, snapshot!.snapshotKey!);

        const timeStamp = Date.now() / 1000;
        const audioUri = snapshotInBackend.data.audioUri;
        setAudioUri(`${audioUri}?ts=${timeStamp}`);

        const snapshotWithUpdatedAudio = ({...snapshot,
            snapshotKey: snapshot!.snapshotKey!,
            title: snapshot!.title,
            audioUri: audioUri,
            audioDuration: snapshotInBackend.data.audioDuration,
            destination_AudioDateTime: snapshotInBackend.data.destination_AudioDateTime,

        });
        setSnapshot(snapshotWithUpdatedAudio);

        updateSelectedInList(snapshotWithUpdatedAudio);
    }

    const changeVoice = async (voicePersonId: string, voiceIcon: string) => {
        const previousVoicePersonId = props.item.voicePerson?.name ?? '(none)';
        setVoicePersonId(voicePersonId);
        setIsLoading(true);

        // save icon to story setting
        const voiceIconSettingValue: SettingPublicDto = {
            value: voiceIcon
        };
        await storyApi.updateStorySetting(
            storyAlias!, '_voice-icon', voiceIconSettingValue);

        // save the id of the voice (same as given name)
        const voicePersonIdSettingValue: SettingPublicDto = {
            value: voicePersonId
        };
        const updateSettingResult =
            await storyApi.updateStorySetting(
                storyAlias!, '_voice-person', voicePersonIdSettingValue);

        if (markupContent && markupContent !== '') {
            // Note: When calling PatchSnapshot() on the backend API
            // it will also re-generate the SSML, 
            // which is required when we're changing voice.
            const inputDto: SnapshotPatchInputDto = {
                markup: markupContent!
            }
            const patchSnapshotResult = await storyApi
                .patchSnapshot(storyAlias!, snapshot!.snapshotKey!, inputDto);
            if (patchSnapshotResult.data && patchSnapshotResult.status === 200) {
                console.log(`update markup and SSML on snapshot`);
                updateSelectedInList(snapshot);
            }
        }

        if (updateSettingResult.status !== 200) {
            console.warn(`failed to update '_voice-person' to '${voicePersonId}'`);
            // revert to the old person
            setVoicePersonId(previousVoicePersonId);
        } else {
            console.log(`update '_voice-person' to '${voicePersonId}'` +
                ` (from ${previousVoicePersonId})`);

            // re-generate story with the new voice
            if (voicePersonId !== 'mic' && markupContent && markupContent !== '') {
                recordWithAiVoice();
            } else if (voicePersonId === 'mic') {
                recordWithMic();
            }

            // update the block
            const blockItem = props.item!;
            blockItem.voicePerson = {
                name: voicePersonId,
                icon: voiceIcon,
                enabled: true
            };
            setBlockItem(blockItem);
            // will trigger change in block list
            setVoicePersonId(voicePersonId);
        }

        setIsLoading(false);
    }

    const recordWithMic = () => {
        setAdjustMode(false);
        setMicRecordMode(true);
    }

    const upload = () => {
        setUploadModalOpen(true);
    }

    const remove = () => {
        props.onRemove();
    }

    const pickBgSound = () => {
        setBgSoundModalOpen(true);
    }

    const handleTitleChange = (ev: any) => {
        const newTitle: string = ev.target.value;
        setTitle(newTitle);
        setSnapshot(snapshot => ({...snapshot,
            title: newTitle,
            snapshotKey: snapshot!.snapshotKey!
        }));
    }

    const handleTitlePaste = (e: any) => {
        e.preventDefault();

        // grab clipboard content as plain-text
        const win: any = window;
        if (win.clipboardData) {
            const clipboardContent = win.clipboardData.getData('Text');
            if (win.getSelection) {
                const selObj = win.getSelection();
                const selRange = selObj.getRangeAt(0);
                selRange.deleteContents();
                selRange.insertNode(document.createTextNode(clipboardContent));
            }
        } else if (e.clipboardData) {
            const text = e.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
        }
    }

    const handleTitleBlur = () => {
        // remove whitespace HTML char in the title field
        if (titleEditableRef && titleEditableRef!.current) {
            const el: HTMLElement = titleEditableRef.current;
            const currentTitle = el.innerText.replace('&nbsp;', '');

            setTitle(currentTitle);
            if (!snapshot) {
                console.warn('snapshot is null - handle title blur');
            } else {
                setSnapshot(snapshot => ({...snapshot,
                    title: currentTitle,
                    snapshotKey: snapshot!.snapshotKey!
                }));
            }
        } else {
            throw new Error(`could not find titleEditableRef`);
        }
    }

    // TODO: refactor this...
    const debouncedSavingTitle = useDebouncedCallback(
        (value: any) => {
            if (!isLoading) {
                saveTitle();
            } else {
                console.log(`do not save title because we're in loading mode`);
            }
        }, 500
    );

    const saveTitle = async () => {
        if (!snapshot) {
            throw new Error('snapshot is null');
        }

        // update snapshot in backend
        const snapshotPatch: SnapshotPatchInputDto = {
            title: title.length < titleMaxLength ? title : title.substring(0, titleMaxLength)
        }
        const titleChangeResult = await storyApi
            .patchSnapshot(storyAlias!, snapshot!.snapshotKey!, snapshotPatch);
        if (titleChangeResult.status !== 200) {
            throw new Error(`failed to save title: ${title}`);
        }
    }

    const replaceEmojiInTitle = (char: string) => {
        if (char.length === 0) return;
        if (char.length > 1) {
            console.error(`emoji must be one (1) char: '${char}' (length is ${char.length})`);
        }

        let newTitle: string;

        if (/\p{Extended_Pictographic}/u.test(title)) {
            // the title already contains an emoji
            const cleanedTitle = title.replace(/\p{Extended_Pictographic}/gu, '').trim();
            newTitle = char + cleanedTitle;
        } else {
            // insert emoji as prefix
            newTitle = char + ' ' + title;
        }

        setTitle(newTitle);
        if (!snapshot) { console.warn('snapshot is null - in replaceEmojiInTitle'); }
        setSnapshot(snapshot => ({...snapshot,
            title: newTitle,
            snapshotKey: snapshot!.snapshotKey!
        }));
    }

    const updateSelectedInList = (snapshot: SnapshotDto | null) => {
        if (snapshot) {
            console.debug(`update selected in list`, snapshot);
            const item = blockItem!;

            item.audioFadeIn = fadeIn;
            item.audioFadeOut = fadeOut;
            item.audioFalseStart = falseStart;
            item.audioVolume = volume;
            item.audioBgSound = bgSound;

            if (item.type !== 'sound') {
                // clean the title from space char as html
                item.title = snapshot.title.replace(/&nbsp;/g, '').trim();
            }

            props.updateSelected(item, getSummaryFromSnapshot(snapshot));
        } else {
            console.warn(`could not update in list ` +
                `because snapshot is not loaded.`);
        }
    }

    const getSummaryFromSnapshot = (snapshot: SnapshotDto) => {
        const snapshotSummary: SnapshotSummaryDto = { // TODO: refactor this - do it in backend
            snapshotKey: snapshot.snapshotKey,
            enabled: snapshot.enabled,
            title: snapshot.title,
            audioUri: snapshot.audioUri,
            audioDuration: snapshot.audioDuration,
            isHumanVoiceRecorded: snapshot.isHumanVoiceRecorded,
            destinationAudioSent:
                snapshot.destination_AudioDateTime !== undefined &&
                snapshot.destination_AudioDateTime !== null,
        };

        return snapshotSummary;
    }

    const close = () => {
        props.onClose();
    }

    return (
        <>
            <Wrapper>
                <CloseIcon onClick={close}>X</CloseIcon>

                {(!editMode || adjustMode) && <div style={{ display: 'flex', minHeight: 50 }}>
                    {!editMode &&
                        <>
                            <Title>
                                <div>
                                    <ContentEditable
                                        innerRef={titleEditableRef}
                                        html={title}
                                        disabled={props.item.type === 'sound'}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 13) {
                                                e.target.blur();
                                            }
                                        }}
                                        onPaste={handleTitlePaste}
                                        onBlur={handleTitleBlur}
                                        onChange={handleTitleChange}
                                        style={{ padding: 5 }} />
                                    {title.length > 79 &&
                                        <div style={{ fontSize: 11, color: 'red', fontWeight: 'bold' }}>
                                            {t('titleMaxChars')}
                                        </div>}
                                </div>
                                {!adjustMode && Config.isEmojiFeatureEnabled() &&
                                    props.item.type !== "sound" && !title.startsWith('(') && <div
                                        onClick={() => { setEmojiModalOpen(true) }}
                                        style={{ fontSize: 11, cursor: 'pointer', marginLeft: titleHasEmoji ? 0 : 5 }}>
                                        {titleHasEmoji ? t('changeEmoji') : t('addEmoji')}
                                    </div>}
                            </Title>
                        </>
                    }
                    {adjustMode && <div style={{ marginLeft: 30 }}>
                        <Button small onClick={() => setAdjustMode(false)}>{t('exitAdjustMode')}</Button>
                    </div>}
                </div>}

                {props.item.type === 'sound' && props.item.shared === true && <div>
                    This sound effect is from an external provider
                    and therefore you're not able to edit it.<br />
                    You can still adjust some audio settings.
                </div>}

                {props.item.type !== 'sound' && props.item.shared === true && <div>
                    This feature has another owner and you're not able to edit it.<br />
                    Please contact support@radiox.se.<br />
                    You can still adjust some audio settings.
                </div>}

                {isLoading && <Stack style={{ marginTop: 10, marginBottom: 40 }}>
                    <Skeleton height="60px" startColor={'#f7f7f7'} endColor={'#f7f7f7'} />
                    <Skeleton height="20px" startColor={'#f7f7f7'} endColor={'#f7f7f7'} />
                    <Skeleton height="20px" startColor={'#f7f7f7'} endColor={'#f7f7f7'} />
                </Stack>}

                {!editMode && !micRecordMode && snapshot && (audioUri || isRecording) && (
                    <div style={{ marginBottom: 20 }}>
                        <CustomAudioPlayer
                            variant={(blockItem?.type !== 'audio' && blockItem!.voicePerson &&
                                blockItem!.voicePerson!.name === 'mic' ? 'old' : 'default')}
                            audioUri={audioUri!}
                            duration={snapshot?.audioDuration!}
                            recordingNewVersion={isRecording}
                            recordingEstimatedTime={null} />
                    </div>
                )}

                {!editMode && !adjustMode && !configMode && snapshot &&
                    markdownContent !== null && props.item.type !== 'audio' && (
                        <>
                            {markdownContent && markdownContent !== '' && <PreviewArea>
                                <div onClick={() => setEditMode(true)}>
                                    <ReactMarkdown skipHtml={true} linkTarget="_blank">{markdownContent}</ReactMarkdown>
                                </div>
                                {FeatureFlags.images && <ImagesManager
                                    storyAlias={props.item.storyAlias!}
                                    onChanged={() => { }} />}
                                {FeatureFlags.ctaLinks && <LinksManager
                                    storyAlias={props.item.storyAlias!}
                                    onChanged={() => { }} />}
                            </PreviewArea>}
                            {(!markdownContent || markdownContent === '') &&
                                <PreviewArea onClick={() => setEditMode(true)} style={{ cursor: 'pointer' }}></PreviewArea>}
                        </>
                    )}

                {!editMode && !adjustMode && !configMode && (props.item.type === 'runkit' || props.item.type === 'uri') && <div>
                    <SnapshotHistory
                        storyAlias={props.item.storyAlias!}
                        cronExpression={cronExpression}
                        snapshots={snapshotList}
                        onNewSnapshotExecuted={() => { loadSnapshots(); }} />
                </div>}

                {/* {!editMode && !adjustMode && props.item.type === "audio" && <div>
                    <Recordings 
                        storyAlias={props.item.storyAlias!}
                        snapshotKey={snapshot?.snapshotKey!} />
                </div>} */}

                {editMode && snapshot && markupContent !== null && (
                    <div style={{ marginBottom: 10, maxWidth: 700 }}>
                        {(blockItem?.type === 'uri' || blockItem?.type === 'runkit') &&
                            <>
                                <div style={{ fontSize: 20, paddingTop: 20 }}>
                                    {t('editLatestVersionButtonLabel')}
                                </div>
                                <div style={{
                                    fontSize: 15, paddingTop: 20,
                                    paddingBottom: 20, maxWidth: 600, color: '#999'
                                }}>
                                    {t('editLatestVersionNote')}
                                </div>
                            </>
                        }

                        {voicePersonId && <VoiceEditor
                            key="voiceEditor"
                            content={markupContent}
                            voicePerson={props.item.voicePerson}
                            lang={props.langCode}
                            saveContinuously={(newContent: string) => saveContinuously(newContent)} />}

                        <div style={{ marginTop: 10 }}>
                            <Button primary disabled={isRecording} onClick={() => exitEditModeAndRecord(true)}>
                                {isRecording ? t('recording') + `...` : voicePersonId === 'mic' ?
                                    t('save') : (t('saveAndRecord') + (voicePersonId ? ` ${t('as')} ${voicePersonId}` : ''))}
                            </Button>
                            <Button onClick={() => setEditMode(false)}>{t('cancel')}</Button>
                        </div>

                    </div>
                )}

                {configMode && storyAlias && (blockItem?.type === 'runkit' || blockItem?.type === 'uri') && (
                    <div style={{ marginBottom: 10, maxWidth: 700 }}>

                        {blockItem?.type === 'runkit' && <RunkitEditor storyAlias={storyAlias}
                            onExecuted={() => {
                                console.log(`update current snapshot with newly created`);
                            }} />}

                        {blockItem?.type === 'uri' && <UrlTypeForm storyAlias={storyAlias}
                            onExecuted={() => {
                                console.log(`update current snapshot with newly created`);
                            }} />}

                        <Button onClick={() => setConfigMode(false)}>{t('cancel')}</Button>

                    </div>
                )}

                {micRecordMode && <Record
                    selectedItem={blockItem}
                    onClose={() => { setMicRecordMode(false) }}
                    onUploadedAndSelected={(newAudioUri: string, duration: number) => {
                        if (!snapshot) {
                            throw new Error(`snapshot is null`);
                        }
                        const newSnapshot = {...snapshot,
                            snapshotKey: snapshot!.snapshotKey!,
                            title: snapshot!.title,
                            audioDuration: duration,
                            audioUri: newAudioUri,
                            destination_AudioDateTime: new Date().toISOString(),
                            destinationAudioSent: true,
                            isHumanVoiceRecorded: true
                        };
                        setSnapshot(newSnapshot);
                        setAudioUri(newAudioUri);
                        updateSelectedInList(newSnapshot);
                    }}
                />}

                {!editMode && !micRecordMode && !adjustMode && snapshot &&
                    props.item.type === 'audio' && snapshot && !snapshot?.audioUri &&
                    <div style={{ marginBottom: 30, maxWidth: 700 }}>
                        <AudioUploader
                            storyAlias={storyAlias!}
                            snapshotKey={snapshot.snapshotKey}
                            currentFileName={''}
                            currentFileSize={0}
                            onUploaded={(result: any) => {
                                if (!snapshot) {
                                    throw new Error(`snapshot is null`);
                                }
                                const newSnapshot = {...snapshot,
                                    snapshotKey: snapshot!.snapshotKey!,
                                    title: snapshot!.title,
                                    audioUri: result.uri,
                                    audioDuration: result.duration,
                                    destination_AudioDateTime: new Date().toISOString(),
                                    destinationAudioSent: true,
                                    isHumanVoiceRecorded: true
                                };
                                setSnapshot(newSnapshot);
                                setAudioUri(result.uri);
                                updateSelectedInList(newSnapshot);
                            }} />
                    </div>}

                {!editMode && !configMode && !micRecordMode && !adjustMode && <div>
                    {props.item.type === "uri" && <Button onClick={openConfigMode}>
                        {t('uriTypeSettings')}
                    </Button>}
                    {props.item.type === "runkit" && <Button onClick={openConfigMode}>
                        {t('openCodeEditor')}
                    </Button>}
                    {props.item.shared !== true && props.item.type !== "sound" && snapshot &&
                        snapshot.destination_AudioDateTime === undefined && voicePersonId !== "mic" &&
                        markupContent !== null && markupContent !== "" && (
                            <Button onClick={recordWithAiVoice}>{t('record')}</Button>)}
                    {voicePersonId === "mic" && props.item.shared !== true &&
                        props.item.type !== "sound" && props.item.type !== "audio" && (
                            <Button primary onClick={() => { setMicRecordMode(true); }}>
                                {!audioUri ? t('recordWithMic') : t('recordWithMicAgain')}</Button>)}
                    {props.item.shared !== true && props.item.type === "text" &&
                        voicePersonId !== "mic" && (
                            <Button onClick={editContent}>{t('edit')}</Button>)}
                    {props.item.shared !== true && (props.item.type === "sound") && (
                        <Button onClick={upload}>{t('uploadNewFile')}</Button>)}
                    <Button onClick={() => setAdjustMode(true)}>
                        {props.item.type === "sound" || props.item.type === "audio" ?
                            t('mixSettings') : t('adjustAudio')}
                    </Button>
                    {props.item.shared !== true && props.item.type !== "sound" && (
                        <Button onClick={() => { setCopyModalOpen(true); }}>{t('copy')}</Button>)}
                    {!editMode && <Button onClick={remove}>{t('remove')}</Button>}
                </div>}

                {(props.item.type === 'audio' || FeatureFlags.downloadLinks) &&
                    snapshot && snapshot.audioUri && !editMode && !adjustMode &&
                    <AudioDownloadLink>
                        <a href={snapshot.audioUri} download={`${snapshot.title}.mp3`} target="_blank" rel="noreferrer">
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCA0OCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM4LjcxIDEyLjA3QzM3LjM1IDUuMTkgMzEuMjggMCAyNCAwQzE4LjIyIDAgMTMuMjEgMy4yOCAxMC43IDguMDdDNC42OSA4LjcyIDAgMTMuODEgMCAyMEMwIDI2LjYzIDUuMzcgMzIgMTIgMzJIMzhDNDMuNTIgMzIgNDggMjcuNTIgNDggMjJDNDggMTYuNzIgNDMuODkgMTIuNDQgMzguNzEgMTIuMDdaTTM0IDE4TDI0IDI4TDE0IDE4SDIwVjEwSDI4VjE4SDM0WiIgZmlsbD0iIzg5ODk4OSIvPgo8L3N2Zz4K" alt="Download" />
                            {t('downloadAsFile')}
                        </a>
                    </AudioDownloadLink>}

                {adjustMode && (
                    <div style={{ marginTop: 20, marginLeft: 5 }}>

                        {voicePersonId && snapshot &&
                            props.item.type !== 'sound' && props.item.type !== 'audio' && <div>
                                <SubHeader>{t('voice')}</SubHeader>
                                <VoicePersonSelector
                                    lang={props.langCode}
                                    currentVoiceId={voicePersonId}
                                    change={async (id: string, icon: string) => {
                                        await changeVoice(id, icon)
                                    }} />
                                <br /><br />
                            </div>}

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 40, width: 350 }}>
                                <SubHeader>{t('audioMixing')}</SubHeader>
                                <div style={{ marginBottom: 10, display: 'flex' }}>
                                    <label style={{ display: 'block', width: 90 }}>{t('fadeIn')}:</label>
                                    <div>
                                        <Slider axis="x" x={fadeIn} xmin={0} xmax={maxFadeSecs} xstep={0.5}
                                            onChange={({ x }) => setFadeIn(x)} />
                                    </div>
                                    {fadeIn > 0 && <SliderValue>{fadeIn} s</SliderValue>}
                                </div>
                                <div style={{ marginBottom: 10, display: 'flex' }}>
                                    <label style={{ display: 'block', width: 90 }}>{t('fadeOut')}:</label>
                                    <div>
                                        <Slider axis="x" x={fadeOut} xmin={0} xmax={maxFadeSecs} xstep={0.5}
                                            onChange={({ x }) => setFadeOut(x)} />
                                    </div>
                                    {fadeOut > 0 && <SliderValue>{fadeOut} s</SliderValue>}
                                </div>
                                <div style={{ marginBottom: 10, display: 'flex' }}>
                                    <label style={{ display: 'block', width: 90 }}>{t('falseStart')}:</label>
                                    <div>
                                        <Slider axis="x" x={falseStart} xmin={0} xmax={20} xstep={0.5}
                                            onChange={({ x }) => setFalseStart(x)} />
                                    </div>
                                    {falseStart > 0 && <SliderValue>{falseStart} s</SliderValue>}
                                </div>
                                <div style={{ marginBottom: 10, display: 'flex' }}>
                                    <label style={{ display: 'block', width: 90 }}>{t('volume')}:</label>
                                    <div>
                                        <Slider axis="x" x={volume} xmin={50} xmax={150} xstep={2}
                                            onChange={({ x }) => {
                                                setVolume(x);
                                                props.item!.audioVolume = volume;
                                            }} />
                                    </div>
                                    <SliderValue>{volume} %</SliderValue>
                                </div>
                            </div>
                            {props.item.type !== 'sound' && props.item.type !== 'audio' && <div>

                                <SubHeader>{t('bgSound')}</SubHeader>

                                {bgSound !== null &&
                                    <div onClick={pickBgSound}><CurrentBgSound>{bgSound}</CurrentBgSound></div>}
                                <Button
                                    style={{ marginLeft: 0 }} small
                                    onClick={pickBgSound}>{bgSound !== null ? t('change') : t('pick')}</Button>
                                <br /><br />

                            </div>}
                        </div>

                    </div>
                )}

            </Wrapper>

            <UploadModal
                isOpen={uploadModalOpen}
                selectedItem={props.item}
                onUploaded={() => {
                    setUploadModalOpen(false);
                    load();
                }}
                onClose={() => setUploadModalOpen(false)} />

            {bgSoundModalOpen && <BgSoundSelector
                current={bgSound}
                onChange={(bgSoundId: string | null) => {
                    setBgSoundModalOpen(false);
                    if (bgSoundId) {
                        setBgSound(bgSoundId);
                        setBgSoundModalOpen(false);
                    }
                }}
                onRemove={() => {
                    setBgSoundModalOpen(false);
                    setBgSound(null);
                }} />}

            {copyModalOpen && storyAlias &&
                <CopyToModal
                    storyAlias={storyAlias}
                    onChange={() => { setCopyModalOpen(false); }} />}

            {emojiModalOpen && storyAlias &&
                <EmojiModal onSelect={(emoji: string, colons: string) => {
                    if (emoji) {
                        replaceEmojiInTitle(emoji);
                    }
                }} onClose={() => { setEmojiModalOpen(false) }} />}

        </>
    );
}

export default BlockManager;