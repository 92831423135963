import styled from "styled-components";

const Container = styled.div`
    max-width: 700px;
    background-color: #f7f7f7;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
`;

export { Container };