import React, { useState } from "react";
import { BlockDto, InputPropertyDto } from "../../generated-sources/openapi";
import { useTranslation } from "react-i18next";

import * as BlockFunctions from "../../utils/blockFunctions";
import { CloseIcon } from "../styled";
import { Wrapper, Title } from "./styled";
import { Button } from "@chakra-ui/react";

type Props = {
    item: BlockDto,
    langCode: string,
    onReplaceWithBlock: any,
    onRemove: any,
    onClose: any,
}

const BlockPlaceholder = (props: Props) => {
    const { t } = useTranslation();

    const micVoice = BlockFunctions.getMicVoicePerson();
    const micIcon = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNTIgNTEyIj48cGF0aCBkPSJNMTc2IDM1MmM1My4wMiAwIDk2LTQyLjk4IDk2LTk2Vjk2YzAtNTMuMDItNDIuOTgtOTYtOTYtOTZTODAgNDIuOTggODAgOTZ2MTYwYzAgNTMuMDIgNDIuOTggOTYgOTYgOTZ6bTE2MC0xNjBoLTE2Yy04Ljg0IDAtMTYgNy4xNi0xNiAxNnY0OGMwIDc0LjgtNjQuNDkgMTM0LjgyLTE0MC43OSAxMjcuMzhDOTYuNzEgMzc2Ljg5IDQ4IDMxNy4xMSA0OCAyNTAuM1YyMDhjMC04Ljg0LTcuMTYtMTYtMTYtMTZIMTZjLTguODQgMC0xNiA3LjE2LTE2IDE2djQwLjE2YzAgODkuNjQgNjMuOTcgMTY5LjU1IDE1MiAxODEuNjlWNDY0SDk2Yy04Ljg0IDAtMTYgNy4xNi0xNiAxNnYxNmMwIDguODQgNy4xNiAxNiAxNiAxNmgxNjBjOC44NCAwIDE2LTcuMTYgMTYtMTZ2LTE2YzAtOC44NC03LjE2LTE2LTE2LTE2aC01NnYtMzMuNzdDMjg1LjcxIDQxOC40NyAzNTIgMzQ0LjkgMzUyIDI1NnYtNDhjMC04Ljg0LTcuMTYtMTYtMTYtMTZ6Ii8+PC9zdmc+`;
    const textIcon = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjkwLjc0IDkzLjI0bDEyOC4wMiAxMjguMDItMjc3Ljk5IDI3Ny45OS0xMTQuMTQgMTIuNkMxMS4zNSA1MTMuNTQtMS41NiA1MDAuNjIuMTQgNDg1LjM0bDEyLjctMTE0LjIyIDI3Ny45LTI3Ny44OHptMjA3LjItMTkuMDZsLTYwLjExLTYwLjExYy0xOC43NS0xOC43NS00OS4xNi0xOC43NS02Ny45MSAwbC01Ni41NSA1Ni41NSAxMjguMDIgMTI4LjAyIDU2LjU1LTU2LjU1YzE4Ljc1LTE4Ljc2IDE4Ljc1LTQ5LjE2IDAtNjcuOTF6Ii8+PC9zdmc+`;

    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [currentCreatingMode, setCurrentCreatingMode] = useState<string>('');

    const createBlock = (withMicVoice: boolean) => {
        setIsCreating(true);

        const inputs: InputPropertyDto[] = [];
        
        const placeholderId = props.item.id;
        const title = props.item.title;
        console.debug(`create placeholder ${placeholderId}`);
  
        let block: BlockDto = {
            type: 'placeholder', // note: will change to text after being created
            id: placeholderId,
            storyAlias: placeholderId,
            title: title,
            audioFalseStart: props.item.audioFalseStart,
            audioFadeIn: props.item.audioFadeIn,
            audioFadeOut: props.item.audioFadeOut,
            audioBgSound: props.item.audioBgSound,
            audioSpeed: props.item.audioSpeed,
            audioVolume: props.item.audioVolume
        };

        if (withMicVoice) {
            block.voicePerson = micVoice;
            setCurrentCreatingMode('mic');
        } else {
            block.voicePerson = props.item.voicePerson;
            setCurrentCreatingMode('text');
        }

        props.onReplaceWithBlock(block, inputs).then(() => {
            setIsCreating(false);
        });
    }

    const remove = () => {
        props.onRemove();
    }

    const close = () => {
        props.onClose();
    }

    return (
        <>
            <Wrapper>
                <CloseIcon onClick={close}>X</CloseIcon>

                {props.item.title && <Title>{props.item.title}</Title>}

                <Button variant="outline" onClick={() => createBlock(false)}
                    style={{ marginRight: 20 }}
                    isLoading={isCreating && currentCreatingMode === 'text'}>
                        <img style={{ float: 'left', width: 20, height: 20, marginRight: 15 }} 
                            src={textIcon} alt="" />
                        {t('createFromText')}
                </Button>

                <Button variant="outline" onClick={() => createBlock(true)}
                    isLoading={isCreating && currentCreatingMode === 'mic'}>
                    <img style={{ float: 'left', width: 20, height: 20, marginRight: 15 }} 
                        src={micIcon} alt="" />
                    {t('recordWithMic')}
                </Button>

                <Button size="sm" variant="outline" onClick={remove}
                    style={{ position: 'absolute', right: 40, bottom: 30 }}>{t('remove')}</Button>

            </Wrapper>
        </>
    );
}

export default BlockPlaceholder;