import { v4 as uuid } from "uuid";

export const getFileNameWithoutExt = (rawFilePath: string) => {
    const name = rawFilePath
        // Note: removes the file extension
        .replace(/\.[^/.]+$/, '')
        // Note: Remove double because it's being used 
        // as pattern to separate suffix with a unique ID
        .replace('--', '-');
    return name;
}

export const getFileFormat = (rawFilePath: string) => {
    // Find everything after . (dot) in file name/path
    const exts = rawFilePath.match(/\.([^.]*)$/);

    if (exts && exts.length > 0) {
        const fileFormat = exts[0]
            .toString()
            .substring(1);

        return fileFormat;
    }

    throw new Error(`failed to get file format from ${rawFilePath}`);
}


export const generateBlobName = (name: string) => {
    const fileName = getFileNameWithoutExt(name);
    const uniqueId = uuid().substring(0, 6);
    return `${fileName}--${uniqueId}`;
}