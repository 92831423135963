import React, { useState, useEffect } from 'react';
import { UserApi, TenantSummaryDto } from '../../generated-sources/openapi/api';
import { Config } from '../../config';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import LoginDialog from '../../components/Header/LoginDialog';
import TenantManagerDialog from '../../components/Header/TenantManagerDialog';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonGroup, ClickAwayListener, CssBaseline, Divider, 
  Drawer, Grow, List, ListItem, ListItemIcon, ListItemText, 
  MenuList, MuiThemeProvider, Paper, Popper } from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import SchoolIcon from '@material-ui/icons/School';
import CloseIcon from '@material-ui/icons/Close';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloudIcon from '@material-ui/icons/Cloud';
import AdjustIcon from '@material-ui/icons/Adjust';
import TimelineIcon from '@material-ui/icons/Timeline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import HeadsetIcon from '@material-ui/icons/Headset';
import ListIcon from '@material-ui/icons/List';

interface Props {
  appName: string
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  serviceList: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  serviceListShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 64
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  paper: {
    paddingBottom: 50,
  },
  appBar: {
    top: 'auto',
    zIndex: 100000
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  customHeight: {
    minHeight: 200
  },
  offset: theme.mixins.toolbar
})); 

const AppTopbar = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const [anchorEl, setAnchorEl] = useState();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [showMainMenu] = useState<boolean>(false);
  const [serviceListOpen, setServiceListOpen] = useState<boolean>(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [currentUsername, setCurrentUsername] = useState<string>();

  const [tenantManagerDialogOpen, setTenantManagerDialogOpen] = useState(false);
  const [currentTenant, setCurrentTenant] = useState<TenantSummaryDto>();

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  const switchTenant = () => {
    setTenantManagerDialogOpen(true);
    closeMenu();
  }

  const manageUserInfo = async () => {
    const userApi = new UserApi(Config.getApiConfig(), 
      process.env.REACT_APP_BACKEND_API_URL, Config.AxiosInstance);
    const userInfoResp = await userApi.getUserInfo();
    const userInfo = userInfoResp.data;

    const tenants = userInfo.tenants;
    const currentTenantId = Config.getTenantId();

    if (currentTenantId && tenants && tenants.some(x => x.id === currentTenantId)) {
        const ten = tenants.filter(x => x.id === currentTenantId)[0];

        sessionStorage.setItem(Config.LS_TenantId, ten.id);
        localStorage.setItem(Config.LS_TenantId, ten.id);

        return ten;
    } else if (tenants && tenants.length > 0 && tenants[0]) {
        // pick the tenant from the user
        console.debug(`set tenant from the user info in backend`);

        sessionStorage.setItem(Config.LS_TenantId, tenants[0].id);
        localStorage.setItem(Config.LS_TenantId, tenants[0].id);

        return tenants[0];
    } else {
        // set the temporary tenant from the browser to the user in Auth0
        if (!currentTenantId) {
          // TODO: throw new Error(`no tenant in local storage`);
        }
        if (currentTenantId) {
          userApi.addToTenant(currentTenantId).then(() => {
              console.debug(`user connected to the tenant.`);
          });
        }
        return null;
    }
  }

  useEffect(() => {
    let isCancelled = false;

    setLoggedIn(isAuthenticated);
    setLoginDialogOpen(!isAuthenticated);

    if (isAuthenticated && user) {
      manageUserInfo().then((tenant) => {
        if (tenant && !isCancelled) {
          setCurrentTenant(tenant);
        }
      });
      setCurrentUsername(user!.name);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = async (): Promise<void> => {
    await loginWithRedirect();
  }

  const logOut = (): void => {
    logout({ returnTo: window.location.origin });
    toast.info('You are now logged out.', {
        autoClose: 2000,
    });
  }

  const onTenantChange = (tenant: TenantSummaryDto): void => {
      if (tenant) {
          localStorage.setItem(Config.LS_TenantId, tenant.id);

          sessionStorage.setItem(Config.LS_TenantId, tenant.id);
          sessionStorage.setItem('TENANT_NAME', tenant.name);
          sessionStorage.setItem('TENANT_ALIAS', tenant.alias);

          localStorage.setItem(Config.LS_TenantId, tenant.id);
          localStorage.setItem('TENANT_NAME', tenant.name);
          localStorage.setItem('TENANT_ALIAS', tenant.alias);

          setCurrentTenant(tenant);

          history.push('/');
          window.location.reload();
      } else {
        throw new Error(`tenant is null`);
      }
      setTenantManagerDialogOpen(false);
  }

  const closeDrawer = () => {
    setServiceListOpen(false);
  }

  const closeLoginDialog = () => {
    setLoginDialogOpen(false);
  }

  return (
    <MuiThemeProvider theme={Config.getTheme()}>

      <CssBaseline />

      <LoginDialog 
        open={loginDialogOpen} 
        onClose={() => closeLoginDialog} 
        login={login} />

      <TenantManagerDialog
          open={tenantManagerDialogOpen}
          onTenantChange={onTenantChange}
          onClose={() => setTenantManagerDialogOpen(false)}
      />

      {showMainMenu && <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left" 
        open={serviceListOpen} 
        classes={{ paper: classes.drawerPaper }}>
        
        <div className={classes.drawerHeader}>
          <div style={{ flex: 1, paddingLeft: 10, fontSize: 18, fontWeight: 800 }}>
            {process.env.REACT_APP_BRAND_NAME}
          </div>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>

        <Divider />

        <List>
          <ListItem button selected>
            <ListItemIcon><TimelineIcon /></ListItemIcon>
            <ListItemText primary={'Timeline'} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><AdjustIcon /></ListItemIcon>
            <ListItemText primary={'Goals'} />
          </ListItem>
          <ListItem button={true} href={process.env.REACT_APP_EDITOR_URL!} onClick={() => {
              window.open(process.env.REACT_APP_EDITOR_URL, '_blank');
            }}>
            <ListItemIcon><MailOutlineIcon /></ListItemIcon>
            <ListItemText primary={'Briefings'} />
          </ListItem>
          <ListItem button>
            <ListItemIcon><CloudIcon /></ListItemIcon>
            <ListItemText primary={'Connectors'} />
          </ListItem>
        </List>

        <Divider />

      </Drawer>}

      {isAuthenticated && <AppBar position="fixed" color="secondary" className={classes.appBar} elevation={2}>
        <Toolbar>
          {showMainMenu &&
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => { setServiceListOpen(true) }}
            >
              <ViewComfyIcon style={{ fontSize: 36 }} />
            </IconButton>}

          {isAuthenticated && currentTenant && <Button variant="contained" color="secondary" 
            startIcon={<HeadsetIcon />} style={{ marginRight: 20 }}
            onClick={() => { history.push('/'); }}>
            {t('stations')}
          </Button>}

          {Config.isBetaMode() && isAuthenticated && currentTenant && <Button variant="contained" color="secondary" 
            startIcon={<ListIcon />} style={{ marginRight: 20 }}
            onClick={() => { history.push('/tasks'); }}>
            {t('tasks')}
          </Button>}

          <Typography variant="h6" className={classes.title}>
            {props.appName}
          </Typography>

          {currentTenant && <ButtonGroup 
            variant="contained" 
            color="secondary"
            style={{ marginRight: 20 }}>
            <Button size="small">{currentTenant.name}</Button>
            <Button size="small" color="secondary"
              aria-controls={tenantManagerDialogOpen ? 'split-button-menu' : undefined}
              aria-expanded={tenantManagerDialogOpen ? 'true' : undefined}
              aria-haspopup="menu"
              onClick={switchTenant}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>}

          {loggedIn && (
              <div>
                <IconButton
                  aria-owns={menuOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={openMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Popper open={menuOpen} anchorEl={anchorEl} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom" ? 
                            "center top" : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={closeMenu}>
                          <div>
                            <div style={{ padding: 15 }}>
                              {currentUsername}
                            </div>
                            <MenuList autoFocusItem={menuOpen}>
                              <MenuItem onClick={() => {
                                i18next.changeLanguage(i18next.language === 'sv' ? 'en' : 'sv');
                              }}>
                              Change to {t('lang') === "sv" ? 'English' : 'Swedish'}
                              </MenuItem>
                              <MenuItem onClick={logOut}>Logout</MenuItem>
                            </MenuList>
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}

          <Button
              color="inherit" 
              style={{ marginLeft: 20 }}
              size="small"
              variant="outlined"
              startIcon={<SchoolIcon />}
              onClick={(): void => {
                  window.open(`${process.env.REACT_APP_DOCS_URL}?s=docs`, '_blank');
              }}
          >
              Docs
          </Button>

        </Toolbar>
      </AppBar>}
    </MuiThemeProvider>
  );
};

export default AppTopbar;