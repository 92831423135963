import { Modal, ModalBody, ModalCloseButton, ModalContent, 
    ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Config } from '../../config';
import { VoicePreviewApi, VoicePreviewInputDto, WordRuleInputDto, 
    WordRuleOutputDto, WordRulesApi } from '../../generated-sources/openapi';
import { Button } from '../styled';
import { ExistingInputSuggestion, InputField, 
    ListenButton, MoreSettingsLink } from './styled';

type Props = {
    isOpen: boolean,
    editor: any,
    voicePerson: any,
    onClose: any,
    lang: string
}

const AdjustModal = (props: Props) => {
    const voicePreviewApi = new VoicePreviewApi(
        Config.getApiConfig(), undefined, Config.AxiosInstance);
    const wordRulesApi = new WordRulesApi(
        Config.getApiConfig(), undefined, Config.AxiosInstance);
    const { t } = useTranslation();

    const [adjustModelOpen, setAdjustModelOpen] = useState(false);
    const [listenButtonClickCount, setListenButtonClickCount] = useState<number>(0);
    const [audioIsProcessing, setAudioIsProcessing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [selectedText, setSelectedText] = useState("");
    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

    const [usedBeforePronunciationText, setUsedBeforePronunciationText] = useState<string | null>(null);
    const [pronunciationText, setPronunciationText] = useState("");
    const [suggestions, setSuggestions] = useState<any[] | null>([]);

    const [audioBase64, setAudioBase64] = useState<string | null>(null);
    const audioRef = useRef() as React.MutableRefObject<HTMLAudioElement>;
    const voiceMarkKey = 'voicemark';
    const wordRulesLsKey = `voiceeditor:wordrules`;

    const labMode = false;

    useEffect(() => {
        if (props.editor) {
            openAdjustModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    useEffect(() => {
        if (adjustModelOpen) {
            props.onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adjustModelOpen]);

    /*
    useEffect(() => {
        if (props.isOpen) {
            loadUsedBeforePronunciationText();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedText]);
    */

    if (!props.editor) {
        return null;
    }

    const { from, to } = props.editor.state.selection;

    // Docs: https://prosemirror.net/docs/ref/#model.Node.textBetween
    const text: string = props.editor.state.doc.textBetween(from, to, ' ');

    const openAdjustModal = () => {
        if (text.length < 1) {
            console.warn(`too short text selected to adjust`);
            return;
        }

        let trimmedText = text;

        // Note: When selecting text by double-click, 
        // it will include the space next to the word.
        // This removes the space from the selection.
        if (text.endsWith(' ')) {
            trimmedText = text.trimEnd();
            props.editor.commands
                .setTextSelection({
                    from: from,
                    to: from! + trimmedText.length
                });
        }

        // If an existing Voice Mark Tag is active on
        // the selected text, grab the pronounce text from it.
        if (props.editor.isActive(voiceMarkKey)) {
            props.editor.commands.extendMarkRange(voiceMarkKey);
            let attr = props.editor.getAttributes(voiceMarkKey);
            if (attr.pronounce) {
                setPronunciationText(attr.pronounce);
            } else {
                setPronunciationText(trimmedText);
            }
        } else {
            setPronunciationText(trimmedText);
        }

        // Save the selection.
        setSelectedText(trimmedText);
        setSelectionStart(from);
        setSelectionEnd(from + trimmedText.length);

        // Reset counter and open the modal.
        setListenButtonClickCount(0);
        setAdjustModelOpen(true);
        setUsedBeforePronunciationText(null);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            listen();
            setListenButtonClickCount(0);
        }
        /*else if (event.key.length === 1 && /[a-zA-ZåäöÅÄÖ]/.test(event.key)) {
            // console.debug(`text char has been pressed: ${event.key}`);
            if (event.key === "a") {
                setSuggestions([{
                    "pattern": "a",
                    "ipa": "[ɑ:]",
                    "desc": "kort vokal"
                }]);
            } else {
                setSuggestions(null);
            }
        } else if (/.,_\?!]/.test(event.key)) {
            // setSuggestions(null);
        }*/
    }

    const listen = async () => {
        if (!props.voicePerson) {
            throw new Error(`voice person must be set`);
        }
        setAudioIsProcessing(true);
        const inputData: VoicePreviewInputDto = {
            markup: pronunciationText
        };
        const apiResult = await voicePreviewApi.record(
            props.voicePerson.name, props.lang, 
            undefined, undefined, inputData);
        if (apiResult.data.audioBase64) {
            setAudioBase64(apiResult.data.audioBase64!);
            if (audioRef.current) {
                audioRef.current.play();
            }
        } else {
            throw new Error(`failed to record: ` +
                `${apiResult.data.executeToken}`);
        }
        setAudioIsProcessing(false);
    }

    const increasePitch = () => {
        props.editor
            .chain()
            .setVoiceMark()
            .updateAttributes(voiceMarkKey, {
                pitch: 'high'
            })
            .run();
    }

    const decreasePitch = () => {
        props.editor
            .chain()
            .setVoiceMark()
            .updateAttributes(voiceMarkKey, {
                pitch: 'low'
            })
            .run();
    }

    const changePronunciation = (text: string) => {
        setPronunciationText(text);
    }

    const savePronunciation = async () => {
        // TODO: ignore voice mark if there is no settings and the text is almost the same

        if (pronunciationText === selectedText) {
            return;
        }

        props.editor.chain()
            .focus()
            .setTextSelection({
                from: selectionStart,
                to: selectionEnd
            })
            .setVoiceMark()
            .updateAttributes(voiceMarkKey, {
                type: 'text',
                pronounce: pronunciationText !== selectedText ? 
                    pronunciationText : null
            })
            .run();

        // If this is in the end of the content, insert a space
        const endOfDoc = props.editor.state.doc.content.size - 1;
        if (selectionEnd === endOfDoc) {
            console.debug(`insert space on the end of document`);
            props.editor.chain()
                .focus(endOfDoc)
                .insertContent(' ')
                .run();
        }

        if (pronunciationText !== selectedText) {
            const wordRuleInput: WordRuleInputDto = {
                inputPattern: selectedText,
                outputPattern: pronunciationText,
                voicePersonId: props.voicePerson.name,
                tenantId: Config.getTenantId()!,
            };
            const addedResult = await wordRulesApi
                .addOrUpdate(wordRuleInput);
            if (addedResult.status !== 200) {
                throw new Error(addedResult.statusText);
            } else {
                insertWordRuleToLocalStorage(wordRuleInput);
            }
        }

        // reset the modal
        setSelectedText('');
        setSelectionStart(null);
        setSelectionEnd(null);
    }

    const insertWordRuleToLocalStorage = (wordRuleInput: WordRuleInputDto) => {
        console.debug(`saved to backend`);
        const lsArray = localStorage.getItem(wordRulesLsKey);

        if (!lsArray) {
            console.debug(`word rules array has been added to local storage`);
        }

        const wordRules: WordRuleOutputDto[] = lsArray ? JSON.parse(lsArray) : [];
        wordRules.push({
            inputPattern: wordRuleInput.inputPattern,
            outputPattern: wordRuleInput.outputPattern,
            voicePersonName: props.voicePerson.name,
            shared: false
        });
        localStorage.setItem(wordRulesLsKey, JSON.stringify(wordRules));
    }

    const switchToEnglish = () => {
        props.editor.chain()
            .focus()
            .setTextSelection({
                from: selectionStart,
                to: selectionEnd
            })
            .setVoiceMark()
            .updateAttributes(voiceMarkKey, {
                lang: 'en-us'
            })
            .run();
    }

    const clear = () => {
        // TODO: save as state and run it on save button
        // TODO: or show prompt with "are you sure" and close modal after run
        props.editor.chain()
            .focus()
            .unsetAllMarks()
            .run();
    }

    const save = async () => {
        setIsSaving(true);
        await savePronunciation();
        setIsSaving(false);
        setAdjustModelOpen(false);
    }

    const isEnglishEnabled = () => {
        return props.editor
            .isActive(voiceMarkKey, { 'lang': 'en' });
    }

    const insertChar = (char: string) => {
        setPronunciationText(
            pronunciationText.substring(0, pronunciationText.length-1) + char);
    }

    /*
    const loadUsedBeforePronunciationText = () => {
        const lsArray = localStorage.getItem(wordRulesLsKey);
        if (lsArray) {
            const wordRules: WordRuleOutputDto[] = JSON.parse(lsArray);
            const foundRule = wordRules.filter(x => 
                x.inputPattern === selectedText); // TODO: filter on same voice person?
            if(foundRule && foundRule.length === 1) {
                setUsedBeforePronunciationText(foundRule[0].outputPattern!);
            }
        }
    }
    */

    const setInputToUsedBefore = () => {
        setPronunciationText(usedBeforePronunciationText!);
        setUsedBeforePronunciationText(null);
    }

    return (
        <div>
            <Modal size={'2xl'} isCentered isOpen={adjustModelOpen} 
                blockScrollOnMount={false} 
                closeOnOverlayClick={false}
                onClose={() => { setAdjustModelOpen(false); }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('adjustVoice')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <div style={{ color: '#808080', fontSize: 16 }}>
                            {t('writtenText')}:
                        </div>
                        <div style={{ padding: 5, fontSize: 22 }}>{selectedText}</div>

                        <div style={{ height: 1, backgroundColor: '#ccc', 
                            marginTop: 5, marginBottom: 20 }}></div>

                        <div>

                            <div style={{ display: 'flex', alignContent: 'center', 
                                color: '#808080', fontSize: 16, lineHeight: '26px' }}>
                                {!props.voicePerson && <span style={{ color: 'red', fontSize: 14 }}>
                                    Okänd (kontakta supporten)
                                </span>}
                                {props.voicePerson && <>
                                    {props.voicePerson && props.voicePerson.icon && <img 
                                        style={{ width: 26, height: 26, marginRight: 10 }} 
                                        src={props.voicePerson.icon} alt="" />}
                                    {props.voicePerson.name + ` `}
                                </>}
                                {t('pronounceAs')}:
                            </div>

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <audio ref={audioRef} src={audioBase64!} hidden />
                                <div style={{ display: 'inline-block' }}>
                                    <InputField type="text" value={pronunciationText} 
                                        onChange={(ev: any) => changePronunciation(ev.target.value)}
                                        onKeyDown={handleKeyDown} autoFocus />                                    
                                </div>
                                <ListenButton onClick={() => {
                                    listen();
                                    setListenButtonClickCount(listenButtonClickCount + 1);
                                }} className={audioIsProcessing ? 'active' : ''}>
                                    {t('listen')}
                                </ListenButton>
                            </div>

                            {Config.isDevMode() && usedBeforePronunciationText && 
                                usedBeforePronunciationText !== pronunciationText && 
                                <ExistingInputSuggestion>
                                    <span>{'existingPronunciations'} </span>
                                    <span onClick={setInputToUsedBefore}>{usedBeforePronunciationText}</span>
                                </ExistingInputSuggestion>}

                            {suggestions && <div style={{ marginTop: 5 }}>
                                {suggestions.map((suggestion) => {
                                    return (
                                        <Button key={suggestion.ipa} small 
                                            onClick={() => { insertChar(suggestion.ipa) }}>
                                            {suggestion.ipa} - {suggestion.desc}
                                        </Button>
                                    )
                                })}
                            </div>}

                            {listenButtonClickCount > 3 && <div style={{ 
                                display: 'block', marginTop: 10, marginBottom: 20, 
                                padding: 10, fontSize: 12, borderRadius: 4,
                                border: 'solid 1px var(--color-persistent-brand)',
                                color: 'var(--color-persistent-brand)' }}>
                                {t('tipEnterButtonToListen')}
                            </div>}

                            {labMode && !props.lang.startsWith('en') && 
                                <MoreSettingsLink 
                                    onClick={switchToEnglish}
                                    className={isEnglishEnabled() ? 'small enabled' : 'small'}>
                                        {t('setAsEnglish')}
                                </MoreSettingsLink>}

                            {labMode && <div style={{ marginTop: 15 }}>
                                Normal ton
                                <Button small onClick={increasePitch}>Höj</Button>
                                <Button small onClick={decreasePitch}>Sänk</Button>
                            </div>}

                            {props.editor.isActive(voiceMarkKey) &&
                                <Button small onClick={clear}>{t('resetAdjustment')}</Button>}

                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button primary progress={isSaving} onClick={save}>{t('save')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default AdjustModal;