import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { BlobsApi, BlockDto, BlocksApi } from "../../generated-sources/openapi/api";

import { useAuth0 } from '@auth0/auth0-react';
import { ReactMic } from '@cleandersonlobo/react-mic';
import ReactAudioPlayer from 'react-audio-player';
import getBlobDuration from 'get-blob-duration';
import { format } from "date-fns";

import { Container, RecordingList } from './styled';
import { Button } from '@chakra-ui/react';

type Props = {
    selectedItem: (BlockDto | null),
    hideCloseButton?: boolean,
    onClose: any,
    onUploadedAndSelected: any,
}

const Record = (props: Props) => {
    const { user } = useAuth0();
    const { t } = useTranslation();

    const [isRecording, setIsRecording] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [recordFile, setRecordFile] = useState<any | null>(null);
    const [lastRecordingTimestamp, setLastRecordingTimestamp] = useState<string | null>(null);

    const blobsApi = new BlobsApi();
    const blocksApi = new BlocksApi();

    const startRecording = () => {
        setRecordFile(null);
        setIsRecording(true);
    }

    const stopRecording = () => {
        setIsRecording(false);
    }

    const onStop = (recordedBlob: any) => {
        setRecordFile(recordedBlob);
        setLastRecordingTimestamp(format(new Date(), 'HH:MM'));
    }

    const uploadRecording = async () => {
        if (!recordFile) return;
        setIsUploading(true);

        if (!props.selectedItem || !props.selectedItem!.storyAlias) {
            throw new Error(`story alias is null.`);
        }
        const storyAlias: string = props.selectedItem!.storyAlias!;

        const fileFormat = 'mp3';
        const uploadResp = await blobsApi
            .uploadAsync(storyAlias, fileFormat, false, recordFile.blob);

        if (uploadResp.status === 200 && uploadResp.data.success) {
            const uploadedUri = uploadResp.data.uri!;
            const duration = await getBlobDuration(recordFile.blob);

            // Note: will also update Destination_Audio timestamp on the snapshot
            const resp = await blocksApi.saveRecordingUriAsync(
                storyAlias, uploadedUri, recordFile.size, duration, user!.sub);
            if (resp.status === 200 && resp.data.success) {
                console.log(`saved recording uri successfully.`);
                setRecordFile(null);
                props.onUploadedAndSelected(uploadedUri, duration);
                props.onClose();
                setIsUploading(false);
            } else {
                console.error(`failed to save recording uri: ${resp.data.message}`);
                setIsUploading(false);
            }
        } else {
            console.error(`failed to uploading recording: ${uploadResp.data.message}`);
            setIsUploading(false);
        }
    }

    return (
        <Container>

            <ReactMic
                record={isRecording}
                onStop={onStop}
                height={70}
                sampleRate={44100}
                strokeColor="#5e6ad2"
                backgroundColor="#333333"
                mimeType="audio/mp3" />

            {recordFile && <div style={{ marginTop: 0 }}>
                <RecordingList>
                    <div style={{ marginRight: 15, fontSize: 15, fontWeight: 500, color: '#fff' }}>
                        {t('recorded')} {lastRecordingTimestamp}
                    </div>
                    <ReactAudioPlayer
                        src={recordFile.blobURL} controls
                        controlsList="play timeline nodownload"
                    />
                </RecordingList>
            </div>}

            <div style={{ marginTop: 10 }}>

                {recordFile && <Button isLoading={isUploading} 
                    onClick={uploadRecording}>{t('uploadRecording')}</Button>}

                {!isRecording && <Button onClick={startRecording}>
                    {(recordFile ? t('reRecord') : t('startRecording'))}
                </Button>}

                {isRecording && <Button onClick={stopRecording}>{t('stopRecording')}</Button>}

                {props.hideCloseButton && !isRecording && 
                    <Button onClick={props.onClose}>{t('cancel')}</Button>}

            </div>

        </Container>
    );
}

export default Record;