import axios, { AxiosRequestConfig } from "axios";

export interface GeoLocation {
  title: string;
  accuracy: string;
  totalRank: number;
}

const LocalixLocFinder = async (text: string, lang: string = 'sv') => {
  const lxSource = 'radiox';

  const apiHost = 'http://lxgeolocator.azurewebsites.net';
  // process.env.REACT_APP_LOCALIX_HOSTNAME
  const apiEndpointUrl = `${apiHost}/locationfinder`;
  const apiKey = 'N@FrVde$zRgN6^';
  
  const inputText = text;

  const requestBody = JSON.stringify({
    user_name: 'radiox',
    user_email: null, // TODO: get current user email address
    referral: lxSource,
    lang: lang,
    text: inputText,
    include_geoareas: true,
    debug: true
  });

  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-append-authorization': `Basic ${apiKey}`
    },
    data: requestBody,
    url: apiEndpointUrl
  };

  const apiResult = await axios.request(options);
  const georesultData = apiResult.data;

  return georesultData;
}

export default LocalixLocFinder;