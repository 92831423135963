export const getHeaderFromMarkdown = (content: string) => {
    // eslint-disable-next-line
    const regEx = new RegExp(`^\s*#(.*)\n`, 'm');
    const matches = content.match(regEx);

    if (matches && matches?.length > 1) {
        console.warn(`more than one header...`);
    }

    if(matches && matches?.length > 0) {
        const firstHeader = matches[0].replace('#', '').trim();
        return firstHeader;
    }

    return null;
}
