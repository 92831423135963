import { Modal, ModalBody, ModalCloseButton, ModalContent, 
    ModalFooter, ModalHeader, ModalOverlay, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    editor: any,
    onClose: any
}

const PersonSwitcher = (props: Props) => {
    const { t } = useTranslation();
    const [modelOpen, setModelOpen] = useState(false);
    const [selectedPersonId, setSelectedPersonId] = useState<string>('default');

    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

    useEffect(() => {
        if (modelOpen) {
            props.onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelOpen]);

    if (!props.editor) {
        return null;
    }

    const { from, to } = props.editor.state.selection;

    // Docs: https://prosemirror.net/docs/ref/#model.Node.textBetween
    const text: string = props.editor.state.doc.textBetween(from, to, ' ');

    const openModal = () => {
        if (text.length < 1) {
            console.warn(`too short text selected to insert link`);
            return;
        }

        let trimmedText = text;

        // Note: When selecting text by double-click, 
        // it will include the space next to the word.
        // This removes the space from the selection.
        if (text.endsWith(' ')) {
            trimmedText = text.trimEnd();
            props.editor.commands
                .setTextSelection({
                    from: from,
                    to: from! + trimmedText.length
                });
        }

        /*
        // If an existing Link Tag is active on the
        // selected text, grab the pronounce text from it.
        if (props.editor.isActive(linkMarkKey)) {
            // Extend to the whole existing link text.
            props.editor.commands.extendMarkRange(linkMarkKey);

            // Parse existing target URL.
            let attr = props.editor.getAttributes(linkMarkKey);
            if (attr.href) {
                setExistingLinkUrl(attr.href);
                setNewLinkUrl(attr.href);
            } else {
                console.error(`href could not be parsed`);
            }
        } else {
            setExistingLinkUrl('');
            setNewLinkUrl('');
        }
        */

        // Save the selection.
        setSelectionStart(from);
        setSelectionEnd(from + trimmedText.length);

        // Open the modal visually.
        setModelOpen(true);
    }

    const save = async () => {
        const executeToken = '';

        // Set selection as link.
        props.editor.chain()
            .focus()
            .setTextSelection({
                from: selectionStart,
                to: selectionEnd
            })
            .setPersonMark({ 
                id: selectedPersonId,
                token: executeToken
             })
            .run();

        // If this is in the end of the content, insert a space.
        const endOfDoc = props.editor.state.doc.content.size - 1;
        if (selectionEnd === endOfDoc) {
            console.debug(`insert space on the end of document`);
            props.editor.chain()
                .focus(endOfDoc)
                .insertContent(' ')
                .run();
        }

        // Reset the modal.
        setSelectionStart(null);
        setSelectionEnd(null);

        // Close it.
        close();
    }

    const clear = () => {
        // Unset the person mark and use default person.
        props.editor.chain()
            .focus()
            .unsetPersonMark()
            .run();
        
        // Close it.
        setModelOpen(false);
    }
    
    const close = () => {
        setModelOpen(false);
    }

    return (
        <div>
            <Modal size={'2xl'} isCentered isOpen={modelOpen} 
                blockScrollOnMount={false} 
                closeOnOverlayClick={false}
                onClose={close}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('changePerson')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={save}>{t('save')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default PersonSwitcher;