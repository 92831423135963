import React, { useState } from 'react';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone'
import { BlobsApi, BlockDto, BlocksApi } from "../../generated-sources/openapi/api";
import { useTranslation } from "react-i18next";
import { Button } from '@chakra-ui/react';

ReactModal.setAppElement('#root');

type Props = {
    isOpen: boolean,
    selectedItem: (BlockDto | null),
    onClose: any,
    onUploaded: any,
}

// TODO remove - not in use
const UploadModal = (props: Props) => {
    const { isOpen, onClose, onUploaded } = props;
    const { t } = useTranslation();

    const [files, setFiles] = useState<any[]>([]);
    const blobsApi = new BlobsApi();
    const blocksApi = new BlocksApi();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)'
        }
    };

    const onDrop = (files: any) => {
        setFiles(files);
    };

    const changeFiles = files.map(file => (
        <li key={file.name}>
            {file.name} - {(file.size / 1024)} kb
        </li>
    ));

    const upload = async () => {
        setIsUploading(true);

        const storyAlias: string = props.selectedItem!.storyAlias!;
        const resp = await blobsApi.uploadAsync(storyAlias, 'mp3', files[0]);

        if (resp.status === 200) {
            const recordingUri: string = resp.data.uri!.toString();
            const recordingResult = await blocksApi
                .saveRecordingUriAsync(storyAlias, recordingUri, undefined);
            if (recordingResult.status === 200) {
                onUploaded();
            } else {
                console.error(`failed to save uri.`);
            }
            setIsUploading(false);
        }
    }

    const maxSize = 1048576;

    return (
        <ReactModal
            id="dropzone-modal"
            contentLabel="Upload modal"
            closeTimeoutMS={150}
            isOpen={isOpen}
            style={customStyles}
            ariaHideApp={false}>
            <div className="dropzone-container">
                <i className="fa fa-times-circle modal-close" aria-hidden="true" onClick={onClose}></i>
                <div className="dropzone-content">
                    <Dropzone
                        onDrop={onDrop}
                        accept="audio/*"
                        maxFiles={1}
                        minSize={0}
                        maxSize={maxSize}>
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                            return (
                                <section className="container">
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        {!isDragActive && <div>
                                            <p>Drop file here</p>
                                            <p style={{ fontSize: 12, marginTop: 15 }}>
                                                MP3 format supported. Contact support for WAV.
                                            </p>
                                            <div><Button size="sm">Choose from your computer</Button></div>
                                        </div>}
                                        <p>
                                            {isDragActive && !isDragReject && "Drop it here!"}
                                            {isDragReject && "File type not accepted, sorry!"}
                                        </p>
                                    </div>
                                    {changeFiles.length ? (<div>
                                        <ul>{changeFiles}</ul>
                                        </div>) : <div />}
                                </section>
                            )
                        }}
                    </Dropzone>
                    {changeFiles.length ? (
                        <div>
                            <Button isLoading={isUploading} onClick={upload}>{t('upload')}</Button>
                        </div>) : <div />}
                </div>
            </div>
        </ReactModal>
    );
}

export default UploadModal;