import {
  Mark,
  mergeAttributes,
} from '@tiptap/core'
// import { Plugin, PluginKey } from 'prosemirror-state'

// Copied from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts

export interface LinkOptions {
  // If enabled, links will be opened on click.
  openOnClick: boolean,
  // A list of HTML attributes to be rendered.
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    link: {
      // Set a link mark
      setLink: (attributes: { href: string, target?: string }) => ReturnType,
      // Unset a link mark
      unsetLink: () => ReturnType,
    }
  }
}

export const LinkExtension = Mark.create<LinkOptions>({
  name: 'link',
 
  priority: 1000,
  inclusive: false,

  addOptions(): LinkOptions {
    return {
      openOnClick: true,
      HTMLAttributes: {
        target: '_blank'
      },
    }
  },

  parseHTML() {
    return [
      { tag: 'a[href]' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setLink: attributes => ({ commands }) => {
        return commands.setMark('link', attributes)
      },
      toggleLink: attributes => ({ commands }) => {
        return commands.toggleMark('link', attributes, { extendEmptyMarkRange: true })
      },
      unsetLink: () => ({ commands }) => {
        return commands.unsetMark('link')
      },
    }
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
    }
  },

  /*
  addProseMirrorPlugins() {
    const plugins: Plugin[] = []

    if (this.options.openOnClick) {
      const handleOnClickPlugin = new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
          handleClick: (view, pos, event) => {
            const attrs = this.editor.getAttributes('link')
            const link = (event.target as HTMLElement)?.closest('a')

            if (link && attrs.href) {
              // window.open(attrs.href, attrs.target)

              return true
            }

            return false
          },
        },
      });
      plugins.push(handleOnClickPlugin);
    }

    return plugins
  },
  */
})