import React, { useState } from "react";
import { SnapshotSummaryDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { formatRelative } from "date-fns";
import { useTranslation } from "react-i18next";
import { Button, Skeleton, Spinner } from "@chakra-ui/react";

import { Header, SnapshotItem } from "./styled";
import CronDisplay from "../CronDisplay";

type Props = {
  storyAlias: string | null,
  cronExpression: string | null,
  snapshots: SnapshotSummaryDto[] | null,
  onNewSnapshotExecuted: any,
}

/*
 This component shows all snapshots for one story.
 Used for "brief" type, not for "text" and "audio".
*/
const SnapshotHistory = (props: Props) => {
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const { t } = useTranslation();
  const [isLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [generateResultMsg, setGenerateResultMsg] = useState<string | undefined>(undefined);

  /*
  const loadFromBackend = async () => {
    if (!props.storyAlias) throw new Error(`story alias missing.`);
    setIsLoading(true);
    const snapshotsFromBackend = await storyApi.getStorySnapshots(props.storyAlias!);
    setEpisodes(snapshotsFromBackend.data);
    setIsLoading(false);
  };
  */

  const generateNewVersion = async () => {
    setIsGenerating(true);
    const triggerResult = await storyApi.triggerStory(props.storyAlias!);
    if (triggerResult.data.success) {
      // TODO: check wait msg while triggering in backend...
      // TODO: reload the list
      setGenerateResultMsg(triggerResult.data.message!);
      props.onNewSnapshotExecuted();
    } else {
      setGenerateResultMsg(triggerResult.data.message!);
    }
    setIsGenerating(false);
  }

  return (
    <div style={{ marginBottom: 20 }}>

        {isGenerating && <div>
          <Spinner speed="0.2s" />
        </div>}

        {!isGenerating && !isLoading && <div>
          <Button size={"sm"} isLoading={isGenerating} onClick={generateNewVersion}>{t('createNewVersion')}</Button>
        </div>}

        {!isGenerating && generateResultMsg && <div style={{ fontSize: 11, padding: 5, fontStyle: 'italic' }}>
          {generateResultMsg}
        </div>}

        <CronDisplay expression={props.cronExpression} />

        <Header>{t('snapshotHistoryHeader')}</Header>

        {isLoading && <Skeleton height="50px" my="10px" />}

        {!isLoading && <div style={{ padding: 0 }}>
          {props.snapshots?.map((snapshotSummary: SnapshotSummaryDto) => (
            <SnapshotItem key={snapshotSummary.snapshotKey}>
              <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                <div style={{ marginBottom: 8, fontSize: 10, color: '#898989' }}>
                  {snapshotSummary.destinationAudioDateTime && 
                  formatRelative(new Date(snapshotSummary.destinationAudioDateTime), new Date())}
                  {!snapshotSummary.destinationAudioDateTime && 
                    <div style={{ fontStyle: 'italic' }}>(audio not available)</div>}
                </div>
                <div style={{ marginBottom: 8, fontSize: 13, fontWeight: 'bold' }}>
                  {snapshotSummary.title}
                </div>
              </div>
            </SnapshotItem>
          ))}
        </div>}

    </div>
  );
};

export default SnapshotHistory;