// tslint:disable
/**
 * Briefly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    alias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accessToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    hasLogo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    colorPrimary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    colorSecondary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    slackTeamId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    langCode: string;
    /**
     * 
     * @type {CultureInfo}
     * @memberof Account
     */
    cultureInfo?: CultureInfo;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    createdDateTime?: string;
    /**
     * 
     * @type {TenantStatus}
     * @memberof Account
     */
    status?: TenantStatus;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    pricingPlan?: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    internalComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    timeZoneId?: string | null;
    /**
     * 
     * @type {TimeZoneInfo}
     * @memberof Account
     */
    timeZone?: TimeZoneInfo;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    loginUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    logoUrl?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Account
     */
    settings?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    settingsAsJson?: string | null;
    /**
     * 
     * @type {Array<AccountSource>}
     * @memberof Account
     */
    connections?: Array<AccountSource> | null;
}
/**
 * 
 * @export
 * @interface AccountSource
 */
export interface AccountSource {
    /**
     * 
     * @type {number}
     * @memberof AccountSource
     */
    accountSourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    accountSourceGuid?: string;
    /**
     * 
     * @type {Account}
     * @memberof AccountSource
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    accountId?: string;
    /**
     * 
     * @type {Source}
     * @memberof AccountSource
     */
    source?: Source;
    /**
     * 
     * @type {number}
     * @memberof AccountSource
     */
    sourceId?: number;
    /**
     * 
     * @type {ConnectionStatus}
     * @memberof AccountSource
     */
    status?: ConnectionStatus;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    createdDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    alias?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountSource
     */
    lastJobStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    lastJobDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountSource
     */
    lastJobErrorMsg?: string | null;
    /**
     * 
     * @type {Array<AccountSourceSetting>}
     * @memberof AccountSource
     */
    accountSourceSettings?: Array<AccountSourceSetting> | null;
}
/**
 * 
 * @export
 * @interface AccountSourceSetting
 */
export interface AccountSourceSetting {
    /**
     * 
     * @type {number}
     * @memberof AccountSourceSetting
     */
    accountSourceSettingId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountSourceSetting
     */
    settingKey: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSourceSetting
     */
    settingValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountSourceSetting
     */
    streamId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountSourceSetting
     */
    stream_StreamAlias?: string | null;
    /**
     * 
     * @type {DataStream}
     * @memberof AccountSourceSetting
     */
    dataStream?: DataStream;
    /**
     * 
     * @type {number}
     * @memberof AccountSourceSetting
     */
    accountSourceId?: number;
    /**
     * 
     * @type {AccountSource}
     * @memberof AccountSourceSetting
     */
    accountSource?: AccountSource;
}
/**
 * 
 * @export
 * @interface ArticleAudioResultDto
 */
export interface ArticleAudioResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof ArticleAudioResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticleAudioResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleAudioResultDto
     */
    audioUri?: string | null;
    /**
     * 
     * @type {Snapshot}
     * @memberof ArticleAudioResultDto
     */
    snapshot?: Snapshot;
}
/**
 * 
 * @export
 * @interface AudienceDto
 */
export interface AudienceDto {
    /**
     * 
     * @type {string}
     * @memberof AudienceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AudienceDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof AudienceDto
     */
    isPublic?: boolean;
    /**
     * 
     * @type {{ [key: string]: JToken; }}
     * @memberof AudienceDto
     */
    settings?: { [key: string]: JToken; } | null;
}
/**
 * 
 * @export
 * @interface BlockDto
 */
export interface BlockDto {
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlockDto
     */
    shared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlockDto
     */
    isFixed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    audioFalseStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    audioFadeIn?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    audioFadeOut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    audioVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    audioSpeed?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    audioBgSound?: string | null;
    /**
     * 
     * @type {VoicePersonDto}
     * @memberof BlockDto
     */
    voicePerson?: VoicePersonDto;
    /**
     * 
     * @type {SnapshotSummaryDto}
     * @memberof BlockDto
     */
    snapshot?: SnapshotSummaryDto;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    ctaUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    ctaLabel?: string | null;
}
/**
 * 
 * @export
 * @interface BlockSimplifiedDto
 */
export interface BlockSimplifiedDto {
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    ctaLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockSimplifiedDto
     */
    ctaUrl?: string | null;
}
/**
 * 
 * @export
 * @interface Calendar
 */
export interface Calendar {
    /**
     * 
     * @type {string}
     * @memberof Calendar
     */
    minSupportedDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Calendar
     */
    maxSupportedDateTime?: string;
    /**
     * 
     * @type {CalendarAlgorithmType}
     * @memberof Calendar
     */
    algorithmType?: CalendarAlgorithmType;
    /**
     * 
     * @type {boolean}
     * @memberof Calendar
     */
    isReadOnly?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Calendar
     */
    eras?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof Calendar
     */
    twoDigitYearMax?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CalendarAlgorithmType {
    Unknown = 'Unknown',
    SolarCalendar = 'SolarCalendar',
    LunarCalendar = 'LunarCalendar',
    LunisolarCalendar = 'LunisolarCalendar'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CalendarWeekRule {
    FirstDay = 'FirstDay',
    FirstFullWeek = 'FirstFullWeek',
    FirstFourDayWeek = 'FirstFourDayWeek'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChunkDefinition {
    None = 'None',
    Full = 'Full',
    Yesterday = 'Yesterday',
    LastWeek = 'LastWeek',
    LastMonth = 'LastMonth',
    LastThreeMonths = 'LastThreeMonths',
    LastYear = 'LastYear',
    YesterdayComparison = 'YesterdayComparison',
    LastWeekComparison = 'LastWeekComparison',
    LastMonthComparison = 'LastMonthComparison',
    LastYearComparison = 'LastYearComparison',
    LastTwoWeekComparison = 'LastTwoWeekComparison',
    LastTwoMonthComparison = 'LastTwoMonthComparison',
    LastTwoYearComparison = 'LastTwoYearComparison',
    LastYearCompleteWeekComparison = 'LastYearCompleteWeekComparison',
    LastYearCompleteMonthComparison = 'LastYearCompleteMonthComparison',
    LastTwoCompleteWeeksComparison = 'LastTwoCompleteWeeksComparison',
    LastTwoCompleteMonthsComparison = 'LastTwoCompleteMonthsComparison',
    LastTwoCompleteYearsComparison = 'LastTwoCompleteYearsComparison',
    LastCompleteWeek = 'LastCompleteWeek',
    Today = 'Today',
    LastYearSameWeekdayComparison = 'LastYearSameWeekdayComparison',
    LastHour = 'LastHour',
    LastHourComparison = 'LastHourComparison',
    LastCompleteHour = 'LastCompleteHour',
    LastCompleteHourComparison = 'LastCompleteHourComparison',
    LastWeekComparedToLastThreeMonths = 'LastWeekComparedToLastThreeMonths',
    LastThreeMonthsCompararedToThreeMonthsLastYear = 'LastThreeMonthsCompararedToThreeMonthsLastYear',
    LastCompleteMonth = 'LastCompleteMonth',
    SinceLastSnapshot = 'SinceLastSnapshot',
    LastSnapshotToYesterdayComparison = 'LastSnapshotToYesterdayComparison',
    LastSnapshotToNowComparison = 'LastSnapshotToNowComparison'
}

/**
 * 
 * @export
 * @interface CloneResultDto
 */
export interface CloneResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof CloneResultDto
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof CloneResultDto
     */
    newAlias: string;
}
/**
 * 
 * @export
 * @interface CompareInfo
 */
export interface CompareInfo {
    /**
     * 
     * @type {string}
     * @memberof CompareInfo
     */
    name?: string | null;
    /**
     * 
     * @type {SortVersion}
     * @memberof CompareInfo
     */
    version?: SortVersion;
    /**
     * 
     * @type {number}
     * @memberof CompareInfo
     */
    lcid?: number;
}
/**
 * 
 * @export
 * @interface ConfigureResultDto
 */
export interface ConfigureResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConfigureResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {ConnectionStatus}
     * @memberof ConfigureResultDto
     */
    status?: ConnectionStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigureResultDto
     */
    errors?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectionStatus {
    NoAuth = 'NoAuth',
    NoConfig = 'NoConfig',
    Runnable = 'Runnable',
    Error = 'Error',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @interface ConnectionSummaryDto
 */
export interface ConnectionSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    cid?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    pluginRefreshToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    title: string;
    /**
     * 
     * @type {ConnectionStatus}
     * @memberof ConnectionSummaryDto
     */
    status?: ConnectionStatus;
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    activeStreamAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    sourceAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionSummaryDto
     */
    configUri?: string | null;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateStoryDto
 */
export interface CreateOrUpdateStoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStoryDto
     */
    storyAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStoryDto
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStoryDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStoryDto
     */
    content?: string | null;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof CreateOrUpdateStoryDto
     */
    inputs?: Array<InputPropertyDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateStoryDto
     */
    createSnapshot?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateStreamDto
 */
export interface CreateOrUpdateStreamDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStreamDto
     */
    streamAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStreamDto
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStreamDto
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStreamDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateStreamDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateStreamDto
     */
    isPublic?: boolean | null;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof CreateOrUpdateStreamDto
     */
    settings?: Array<InputPropertyDto> | null;
}
/**
 * 
 * @export
 * @interface CreateStreamResultDto
 */
export interface CreateStreamResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateStreamResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateStreamResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateStreamResultDto
     */
    connAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateStreamResultDto
     */
    cid?: string | null;
}
/**
 * 
 * @export
 * @interface CultureInfo
 */
export interface CultureInfo {
    /**
     * 
     * @type {CultureInfo}
     * @memberof CultureInfo
     */
    parent?: CultureInfo;
    /**
     * 
     * @type {number}
     * @memberof CultureInfo
     */
    lcid?: number;
    /**
     * 
     * @type {number}
     * @memberof CultureInfo
     */
    keyboardLayoutId?: number;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    ietfLanguageTag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    nativeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    englishName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    twoLetterISOLanguageName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    threeLetterISOLanguageName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CultureInfo
     */
    threeLetterWindowsLanguageName?: string | null;
    /**
     * 
     * @type {CompareInfo}
     * @memberof CultureInfo
     */
    compareInfo?: CompareInfo;
    /**
     * 
     * @type {TextInfo}
     * @memberof CultureInfo
     */
    textInfo?: TextInfo;
    /**
     * 
     * @type {boolean}
     * @memberof CultureInfo
     */
    isNeutralCulture?: boolean;
    /**
     * 
     * @type {CultureTypes}
     * @memberof CultureInfo
     */
    cultureTypes?: CultureTypes;
    /**
     * 
     * @type {NumberFormatInfo}
     * @memberof CultureInfo
     */
    numberFormat?: NumberFormatInfo;
    /**
     * 
     * @type {DateTimeFormatInfo}
     * @memberof CultureInfo
     */
    dateTimeFormat?: DateTimeFormatInfo;
    /**
     * 
     * @type {Calendar}
     * @memberof CultureInfo
     */
    calendar?: Calendar;
    /**
     * 
     * @type {Array<Calendar>}
     * @memberof CultureInfo
     */
    optionalCalendars?: Array<Calendar> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CultureInfo
     */
    useUserOverride?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CultureInfo
     */
    isReadOnly?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CultureTypes {
    NeutralCultures = 'NeutralCultures',
    SpecificCultures = 'SpecificCultures',
    InstalledWin32Cultures = 'InstalledWin32Cultures',
    AllCultures = 'AllCultures',
    UserCustomCulture = 'UserCustomCulture',
    ReplacementCultures = 'ReplacementCultures',
    WindowsOnlyCultures = 'WindowsOnlyCultures',
    FrameworkCultures = 'FrameworkCultures'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DataStorageType {
    None = 'None',
    AzureBlob = 'AzureBlob',
    AmazonS3 = 'AmazonS3'
}

/**
 * 
 * @export
 * @interface DataStream
 */
export interface DataStream {
    /**
     * 
     * @type {number}
     * @memberof DataStream
     */
    sourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    streamAlias: string;
    /**
     * 
     * @type {DataStreamType}
     * @memberof DataStream
     */
    streamType?: DataStreamType;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    synonyms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    loaderAssemblyName?: string | null;
    /**
     * 
     * @type {DataStorageType}
     * @memberof DataStream
     */
    storageType?: DataStorageType;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    tenantId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DataStream
     */
    _public?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataStream
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    clonedFromStreamAlias?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataStream
     */
    settings?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof DataStream
     */
    settingsAsJson?: string | null;
    /**
     * 
     * @type {Source}
     * @memberof DataStream
     */
    source?: Source;
}
/**
 * 
 * @export
 * @interface DataStreamSummaryDto
 */
export interface DataStreamSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    streamAlias: string;
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    streamTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    streamType: string;
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataStreamSummaryDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<SourceSummaryDto>}
     * @memberof DataStreamSummaryDto
     */
    sources?: Array<SourceSummaryDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DataStreamType {
    Json = 'Json',
    TimeSerie = 'TimeSerie',
    EventSerie = 'EventSerie',
    ChangeDetection = 'ChangeDetection',
    NewItems = 'NewItems',
    Push = 'Push',
    Text = 'Text',
    Number = 'Number'
}

/**
 * 
 * @export
 * @interface DateTimeFormatInfo
 */
export interface DateTimeFormatInfo {
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    amDesignator?: string | null;
    /**
     * 
     * @type {Calendar}
     * @memberof DateTimeFormatInfo
     */
    calendar?: Calendar;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    dateSeparator?: string | null;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof DateTimeFormatInfo
     */
    firstDayOfWeek?: DayOfWeek;
    /**
     * 
     * @type {CalendarWeekRule}
     * @memberof DateTimeFormatInfo
     */
    calendarWeekRule?: CalendarWeekRule;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    fullDateTimePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    longDatePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    longTimePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    monthDayPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    pmDesignator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    rfC1123Pattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    shortDatePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    shortTimePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    sortableDateTimePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    timeSeparator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    universalSortableDateTimePattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    yearMonthPattern?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    abbreviatedDayNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    shortestDayNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    dayNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    abbreviatedMonthNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    monthNames?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DateTimeFormatInfo
     */
    isReadOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DateTimeFormatInfo
     */
    nativeCalendarName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    abbreviatedMonthGenitiveNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateTimeFormatInfo
     */
    monthGenitiveNames?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Destination {
    Email = 'Email',
    Audio = 'Audio',
    Chat = 'Chat',
    SmsMessage = 'SmsMessage',
    Radio = 'Radio',
    PodcastEpisode = 'PodcastEpisode'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DigitShapes {
    Context = 'Context',
    None = 'None',
    NativeNational = 'NativeNational'
}

/**
 * 
 * @export
 * @interface EmailPreviewDto
 */
export interface EmailPreviewDto {
    /**
     * 
     * @type {string}
     * @memberof EmailPreviewDto
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailPreviewDto
     */
    htmlBody?: string | null;
}
/**
 * 
 * @export
 * @interface EpisodeInfoDto
 */
export interface EpisodeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    published?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    listenDuration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    webUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    audioUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    imageUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    thumbnailUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EpisodeInfoDto
     */
    spotifyId?: string | null;
    /**
     * 
     * @type {Array<PodcastChapter>}
     * @memberof EpisodeInfoDto
     */
    chapters?: Array<PodcastChapter> | null;
    /**
     * 
     * @type {Array<NextInQueueInfo>}
     * @memberof EpisodeInfoDto
     */
    nextInQueue?: Array<NextInQueueInfo> | null;
}
/**
 * 
 * @export
 * @interface EventSerie
 */
export interface EventSerie {
    /**
     * 
     * @type {string}
     * @memberof EventSerie
     */
    alias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSerie
     */
    defaultIcon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSerie
     */
    startDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSerie
     */
    endDateTime?: string | null;
    /**
     * 
     * @type {Array<EventUnit>}
     * @memberof EventSerie
     */
    events?: Array<EventUnit> | null;
}
/**
 * 
 * @export
 * @interface EventUnit
 */
export interface EventUnit {
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    startDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    endDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventUnit
     */
    rank?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    detailsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventUnit
     */
    detailsLabel?: string | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EventUnit
     */
    metaData?: { [key: string]: object; } | null;
}
/**
 * 
 * @export
 * @interface ExistStatusDto
 */
export interface ExistStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof ExistStatusDto
     */
    exist?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FeedType {
    Atom = 'Atom',
    Rss091 = 'Rss_0_91',
    Rss092 = 'Rss_0_92',
    Rss10 = 'Rss_1_0',
    Rss20 = 'Rss_2_0',
    MediaRss = 'MediaRss',
    Rss = 'Rss',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface GetContentDto
 */
export interface GetContentDto {
    /**
     * 
     * @type {string}
     * @memberof GetContentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetContentDto
     */
    content?: string | null;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof GetContentDto
     */
    inputs?: Array<InputPropertyDto> | null;
}
/**
 * 
 * @export
 * @interface HttpContent
 */
export interface HttpContent {
    /**
     * 
     * @type {Array<StringStringIEnumerableKeyValuePair>}
     * @memberof HttpContent
     */
    headers?: Array<StringStringIEnumerableKeyValuePair> | null;
}
/**
 * 
 * @export
 * @interface HttpMethod
 */
export interface HttpMethod {
    /**
     * 
     * @type {string}
     * @memberof HttpMethod
     */
    method?: string | null;
}
/**
 * 
 * @export
 * @interface HttpRequestMessage
 */
export interface HttpRequestMessage {
    /**
     * 
     * @type {Version}
     * @memberof HttpRequestMessage
     */
    version?: Version;
    /**
     * 
     * @type {HttpContent}
     * @memberof HttpRequestMessage
     */
    content?: HttpContent;
    /**
     * 
     * @type {HttpMethod}
     * @memberof HttpRequestMessage
     */
    method?: HttpMethod;
    /**
     * 
     * @type {string}
     * @memberof HttpRequestMessage
     */
    requestUri?: string | null;
    /**
     * 
     * @type {Array<StringStringIEnumerableKeyValuePair>}
     * @memberof HttpRequestMessage
     */
    headers?: Array<StringStringIEnumerableKeyValuePair> | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof HttpRequestMessage
     */
    properties?: { [key: string]: object; } | null;
}
/**
 * 
 * @export
 * @interface ImportBlocksResultDto
 */
export interface ImportBlocksResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof ImportBlocksResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportBlocksResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportBlocksResultDto
     */
    markdownOutput?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<StringStringValuesKeyValuePair>}
     * @memberof InlineObject
     */
    data?: Array<StringStringValuesKeyValuePair>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {Array<StringStringValuesKeyValuePair>}
     * @memberof InlineObject3
     */
    data?: Array<StringStringValuesKeyValuePair>;
}
/**
 * 
 * @export
 * @interface InputPropertyDto
 */
export interface InputPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof InputPropertyDto
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputPropertyDto
     */
    value?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InputPropertyDto
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InputPropertyDto
     */
    helpText?: string | null;
}
/**
 * 
 * @export
 * @interface Insight
 */
export interface Insight {
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    postGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    lang?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    key?: string | null;
    /**
     * 
     * @type {Account}
     * @memberof Insight
     */
    account?: Account;
    /**
     * 
     * @type {AccountSource}
     * @memberof Insight
     */
    accountSource?: AccountSource;
    /**
     * 
     * @type {Story}
     * @memberof Insight
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    createdDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof Insight
     */
    rank?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Insight
     */
    incorrect?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    formattedTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    formattedMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    detailsLinkText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    formattedDetails?: string | null;
    /**
     * 
     * @type {VisualizationType}
     * @memberof Insight
     */
    visualizationType?: VisualizationType;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    visualizationData?: string | null;
    /**
     * 
     * @type {{ [key: string]: JToken; }}
     * @memberof Insight
     */
    visualizationDataAsJson?: { [key: string]: JToken; } | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    visualizationUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    icon?: string | null;
    /**
     * 
     * @type {MoodState}
     * @memberof Insight
     */
    mood?: MoodState;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    validUntil?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    moodAsString?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Insight
     */
    debugInfo?: string | null;
    /**
     * 
     * @type {Array<InsightActionItem>}
     * @memberof Insight
     */
    actions?: Array<InsightActionItem> | null;
}
/**
 * 
 * @export
 * @interface InsightActionItem
 */
export interface InsightActionItem {
    /**
     * 
     * @type {number}
     * @memberof InsightActionItem
     */
    actionItemId?: number;
    /**
     * 
     * @type {Insight}
     * @memberof InsightActionItem
     */
    insight?: Insight;
    /**
     * 
     * @type {InsightActionType}
     * @memberof InsightActionItem
     */
    actionType?: InsightActionType;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsightActionItem
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    resultData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    resultUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsightActionItem
     */
    resultDateTime?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InsightActionType {
    Mockup = 'Mockup',
    Link = 'Link',
    AddConnector = 'AddConnector',
    Reminder = 'Reminder',
    Form = 'Form',
    CustomCode = 'CustomCode',
    UserSetting = 'UserSetting',
    AccountSourceSetting = 'AccountSourceSetting',
    AccountSetting = 'AccountSetting',
    WebHook = 'WebHook'
}

/**
 * 
 * @export
 * @interface JToken
 */
export interface JToken extends Array<JToken> {
}
/**
 * 
 * @export
 * @interface MixModeResultDto
 */
export interface MixModeResultDto {
    /**
     * 
     * @type {string}
     * @memberof MixModeResultDto
     */
    title?: string | null;
    /**
     * 
     * @type {Array<BlockSimplifiedDto>}
     * @memberof MixModeResultDto
     */
    blocks?: Array<BlockSimplifiedDto> | null;
    /**
     * 
     * @type {string}
     * @memberof MixModeResultDto
     */
    audioUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MixModeResultDto
     */
    published?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MoodState {
    None = 'None',
    Neutral = 'Neutral',
    Positive = 'Positive',
    VeryPositive = 'VeryPositive',
    VeryNegative = 'VeryNegative',
    Negative = 'Negative'
}

/**
 * 
 * @export
 * @interface MultiTimeSerie
 */
export interface MultiTimeSerie {
    /**
     * 
     * @type {string}
     * @memberof MultiTimeSerie
     */
    cacheAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MultiTimeSerie
     */
    title?: string | null;
    /**
     * 
     * @type {Array<TimeSeriePeriod>}
     * @memberof MultiTimeSerie
     */
    periods?: Array<TimeSeriePeriod> | null;
    /**
     * 
     * @type {TimeSeriePeriod}
     * @memberof MultiTimeSerie
     */
    first?: TimeSeriePeriod;
    /**
     * 
     * @type {TimeSeriePeriod}
     * @memberof MultiTimeSerie
     */
    last?: TimeSeriePeriod;
}
/**
 * 
 * @export
 * @interface NextInQueueInfo
 */
export interface NextInQueueInfo {
    /**
     * 
     * @type {string}
     * @memberof NextInQueueInfo
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NextInQueueInfo
     */
    snapshotKey?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NextInQueueInfo
     */
    falseStart?: number;
}
/**
 * 
 * @export
 * @interface NumberFormatInfo
 */
export interface NumberFormatInfo {
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    currencyDecimalDigits?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    currencyDecimalSeparator?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NumberFormatInfo
     */
    isReadOnly?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof NumberFormatInfo
     */
    currencyGroupSizes?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NumberFormatInfo
     */
    numberGroupSizes?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NumberFormatInfo
     */
    percentGroupSizes?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    currencyGroupSeparator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    currencySymbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    naNSymbol?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    currencyNegativePattern?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    numberNegativePattern?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    percentPositivePattern?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    percentNegativePattern?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    negativeInfinitySymbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    negativeSign?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    numberDecimalDigits?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    numberDecimalSeparator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    numberGroupSeparator?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    currencyPositivePattern?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    positiveInfinitySymbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    positiveSign?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NumberFormatInfo
     */
    percentDecimalDigits?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    percentDecimalSeparator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    percentGroupSeparator?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    percentSymbol?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatInfo
     */
    perMilleSymbol?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumberFormatInfo
     */
    nativeDigits?: Array<string> | null;
    /**
     * 
     * @type {DigitShapes}
     * @memberof NumberFormatInfo
     */
    digitSubstitution?: DigitShapes;
}
/**
 * 
 * @export
 * @interface PodcastChapter
 */
export interface PodcastChapter {
    /**
     * 
     * @type {TimeSpan}
     * @memberof PodcastChapter
     */
    start?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof PodcastChapter
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof PodcastChapter
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PodcastChapter
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PodcastChapter
     */
    imageSettings?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PodcastChapter
     */
    linkUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PodcastChapter
     */
    linkLabel?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterPushDto
 */
export interface RegisterPushDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterPushDto
     */
    platform?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterPushDto
     */
    handle?: string | null;
}
/**
 * 
 * @export
 * @interface RssFeedValidatorResultDto
 */
export interface RssFeedValidatorResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof RssFeedValidatorResultDto
     */
    found?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RssFeedValidatorResultDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RssFeedValidatorResultDto
     */
    title?: string | null;
    /**
     * 
     * @type {FeedType}
     * @memberof RssFeedValidatorResultDto
     */
    type?: FeedType;
}
/**
 * 
 * @export
 * @interface SaveContentDto
 */
export interface SaveContentDto {
    /**
     * 
     * @type {string}
     * @memberof SaveContentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveContentDto
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface SettingDto
 */
export interface SettingDto {
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    alias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    value?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingDto
     */
    required?: boolean;
}
/**
 * 
 * @export
 * @interface SettingPublicDto
 */
export interface SettingPublicDto {
    /**
     * 
     * @type {string}
     * @memberof SettingPublicDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface SettingRowDto
 */
export interface SettingRowDto {
    /**
     * 
     * @type {string}
     * @memberof SettingRowDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SettingRowDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface Snapshot
 */
export interface Snapshot {
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    snapshotId?: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof Snapshot
     */
    storyId?: number;
    /**
     * 
     * @type {Story}
     * @memberof Snapshot
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    storyAlias: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    markup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    markdownContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    ssmlContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    publishedMarkup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    publishedAudioUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    automaticAudioUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    masteredAudioUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    humanRecordedAudioUri?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Snapshot
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof Snapshot
     */
    audioFileSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    hlsOutput?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    podcastDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    webSlug?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Snapshot
     */
    metaData?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    metadataJson?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isManualTriggered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isHumanVoiceRecorded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isMarkdownEdited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isSsmlEdited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    originalMarkdownContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    originalSsmlContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    destination_ChatDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    destination_EmailDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    destination_VoiceDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    publishDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    debugInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    audioUri?: string | null;
}
/**
 * 
 * @export
 * @interface SnapshotDto
 */
export interface SnapshotDto {
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    snapshotKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotDto
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    audioUri?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SnapshotDto
     */
    audioDuration?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotDto
     */
    isMarkdownEdited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotDto
     */
    isSsmlEdited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotDto
     */
    isHumanVoiceRecorded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotDto
     */
    isAudioOptimized?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    markup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    markdownContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    ssmlContent?: string | null;
    /**
     * 
     * @type {Array<StringUriTuple>}
     * @memberof SnapshotDto
     */
    links?: Array<StringUriTuple> | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    publishDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    destination_ChatDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    destination_EmailDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotDto
     */
    destination_AudioDateTime?: string | null;
}
/**
 * 
 * @export
 * @interface SnapshotPatchInputDto
 */
export interface SnapshotPatchInputDto {
    /**
     * 
     * @type {string}
     * @memberof SnapshotPatchInputDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotPatchInputDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotPatchInputDto
     */
    markup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotPatchInputDto
     */
    markdownContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotPatchInputDto
     */
    ssmlContent?: string | null;
}
/**
 * 
 * @export
 * @interface SnapshotSummaryDto
 */
export interface SnapshotSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    snapshotKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    audioUri?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SnapshotSummaryDto
     */
    audioDuration?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    isEdited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    isHumanVoiceRecorded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    publishDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    destinationChatDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    destinationEmailDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SnapshotSummaryDto
     */
    destinationAudioDateTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    destinationChatSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    destinationEmailSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SnapshotSummaryDto
     */
    destinationAudioSent?: boolean;
}
/**
 * 
 * @export
 * @interface SortVersion
 */
export interface SortVersion {
    /**
     * 
     * @type {number}
     * @memberof SortVersion
     */
    fullVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof SortVersion
     */
    sortId?: string;
}
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    sourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    sourceTitle: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    sourceAlias: string;
    /**
     * 
     * @type {SourceStatus}
     * @memberof Source
     */
    status?: SourceStatus;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    groupAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    queryTypes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    connectionTitleTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    defaultAssemblyName?: string | null;
    /**
     * 
     * @type {Array<AccountSource>}
     * @memberof Source
     */
    accountSources?: Array<AccountSource> | null;
    /**
     * 
     * @type {Array<SourceSetting>}
     * @memberof Source
     */
    sourceSettings?: Array<SourceSetting> | null;
    /**
     * 
     * @type {Array<DataStream>}
     * @memberof Source
     */
    streams?: Array<DataStream> | null;
}
/**
 * 
 * @export
 * @interface SourceGroupDto
 */
export interface SourceGroupDto {
    /**
     * 
     * @type {string}
     * @memberof SourceGroupDto
     */
    groupHeader?: string | null;
    /**
     * 
     * @type {Array<SourceInGroupDto>}
     * @memberof SourceGroupDto
     */
    sources?: Array<SourceInGroupDto> | null;
}
/**
 * 
 * @export
 * @interface SourceInGroupDto
 */
export interface SourceInGroupDto {
    /**
     * 
     * @type {SourceSummaryDto}
     * @memberof SourceInGroupDto
     */
    source?: SourceSummaryDto;
    /**
     * 
     * @type {boolean}
     * @memberof SourceInGroupDto
     */
    needAuth?: boolean;
    /**
     * 
     * @type {Array<ConnectionSummaryDto>}
     * @memberof SourceInGroupDto
     */
    connections?: Array<ConnectionSummaryDto> | null;
    /**
     * 
     * @type {Array<DataStreamSummaryDto>}
     * @memberof SourceInGroupDto
     */
    streams?: Array<DataStreamSummaryDto> | null;
}
/**
 * 
 * @export
 * @interface SourceSetting
 */
export interface SourceSetting {
    /**
     * 
     * @type {number}
     * @memberof SourceSetting
     */
    sourceSettingId?: number;
    /**
     * 
     * @type {number}
     * @memberof SourceSetting
     */
    sourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof SourceSetting
     */
    settingKey: string;
    /**
     * 
     * @type {string}
     * @memberof SourceSetting
     */
    settingValue?: string | null;
    /**
     * 
     * @type {Source}
     * @memberof SourceSetting
     */
    source?: Source;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SourceStatus {
    NotReady = 'NotReady',
    InTesting = 'InTesting',
    Released = 'Released',
    Disabled = 'Disabled'
}

/**
 * 
 * @export
 * @interface SourceSummaryDto
 */
export interface SourceSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof SourceSummaryDto
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof SourceSummaryDto
     */
    group?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceSummaryDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceSummaryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceSummaryDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<ConnectionSummaryDto>}
     * @memberof SourceSummaryDto
     */
    connections?: Array<ConnectionSummaryDto> | null;
}
/**
 * 
 * @export
 * @interface StatusResultDto
 */
export interface StatusResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof StatusResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatusResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatusResultDto
     */
    createdId?: string | null;
}
/**
 * 
 * @export
 * @interface Story
 */
export interface Story {
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    storyId?: number;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {StoryType}
     * @memberof Story
     */
    storyType?: StoryType;
    /**
     * 
     * @type {StoryStatus}
     * @memberof Story
     */
    storyStatus?: StoryStatus;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    storyGroup?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Story
     */
    isMulti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    scriptType: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    scheduleExpression?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    tenantId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Story
     */
    lastJobStatus?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    lastJobDateTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    lastJobErrorMsg?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    lastModified?: string;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    content?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Story
     */
    settings?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof Story
     */
    settingsAsJson?: string | null;
    /**
     * 
     * @type {Array<StoryStream>}
     * @memberof Story
     */
    streamRelations?: Array<StoryStream> | null;
    /**
     * 
     * @type {Array<StoryVersion>}
     * @memberof Story
     */
    versions?: Array<StoryVersion> | null;
    /**
     * 
     * @type {Array<Snapshot>}
     * @memberof Story
     */
    snapshots?: Array<Snapshot> | null;
    /**
     * 
     * @type {Array<Subscriber>}
     * @memberof Story
     */
    subscribers?: Array<Subscriber> | null;
    /**
     * 
     * @type {Array<VoiceRecording>}
     * @memberof Story
     */
    voiceRecordings?: Array<VoiceRecording> | null;
}
/**
 * 
 * @export
 * @interface StoryDto
 */
export interface StoryDto {
    /**
     * 
     * @type {string}
     * @memberof StoryDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StoryDto
     */
    content: string;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof StoryDto
     */
    inputs?: Array<InputPropertyDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StoryStatus {
    Removed = 'Removed',
    InTest = 'InTest',
    Running = 'Running'
}

/**
 * 
 * @export
 * @interface StoryStream
 */
export interface StoryStream {
    /**
     * 
     * @type {number}
     * @memberof StoryStream
     */
    storyStreamId?: number;
    /**
     * 
     * @type {number}
     * @memberof StoryStream
     */
    storyId?: number;
    /**
     * 
     * @type {string}
     * @memberof StoryStream
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {Story}
     * @memberof StoryStream
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof StoryStream
     */
    streamAlias?: string | null;
    /**
     * 
     * @type {DataStream}
     * @memberof StoryStream
     */
    dataStream?: DataStream;
    /**
     * 
     * @type {ChunkDefinition}
     * @memberof StoryStream
     */
    chunkDefinition?: ChunkDefinition;
}
/**
 * 
 * @export
 * @interface StoryStreamSummaryDto
 */
export interface StoryStreamSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    connectionGuid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StoryStreamSummaryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    streamAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StoryStreamSummaryDto
     */
    primary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoryStreamSummaryDto
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoryStreamSummaryDto
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    chunkDef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryStreamSummaryDto
     */
    loadingUri?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoryStreamSummaryDto
     */
    queryTypes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoryStreamSummaryDto
     */
    attributes?: Array<string> | null;
    /**
     * 
     * @type {Array<SettingRowDto>}
     * @memberof StoryStreamSummaryDto
     */
    settings?: Array<SettingRowDto> | null;
}
/**
 * 
 * @export
 * @interface StorySummaryDto
 */
export interface StorySummaryDto {
    /**
     * 
     * @type {string}
     * @memberof StorySummaryDto
     */
    alias: string;
    /**
     * 
     * @type {StoryType}
     * @memberof StorySummaryDto
     */
    type: StoryType;
    /**
     * 
     * @type {StoryStatus}
     * @memberof StorySummaryDto
     */
    status: StoryStatus;
    /**
     * 
     * @type {string}
     * @memberof StorySummaryDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StorySummaryDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StorySummaryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StorySummaryDto
     */
    lastModified?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StorySummaryDto
     */
    isPublic?: boolean;
    /**
     * 
     * @type {SnapshotSummaryDto}
     * @memberof StorySummaryDto
     */
    latestSnapshot?: SnapshotSummaryDto;
}
/**
 * 
 * @export
 * @interface StoryTemplateDto
 */
export interface StoryTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof StoryTemplateDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoryTemplateDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StoryTemplateDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryTemplateDto
     */
    price?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StoryType {
    Unknown = 'Unknown',
    OneTime = 'OneTime',
    Scheduled = 'Scheduled',
    NewItemsInList = 'NewItemsInList',
    Push = 'Push'
}

/**
 * 
 * @export
 * @interface StoryVersion
 */
export interface StoryVersion {
    /**
     * 
     * @type {number}
     * @memberof StoryVersion
     */
    storyVersionId?: number;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof StoryVersion
     */
    storyId?: number;
    /**
     * 
     * @type {Story}
     * @memberof StoryVersion
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    storyAlias: string;
    /**
     * 
     * @type {StoryType}
     * @memberof StoryVersion
     */
    storyType?: StoryType;
    /**
     * 
     * @type {boolean}
     * @memberof StoryVersion
     */
    isMulti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    content?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoryVersion
     */
    settings?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof StoryVersion
     */
    settingsAsJson?: string | null;
}
/**
 * 
 * @export
 * @interface StoryVersionDto
 */
export interface StoryVersionDto {
    /**
     * 
     * @type {number}
     * @memberof StoryVersionDto
     */
    storyVersionId?: number;
    /**
     * 
     * @type {string}
     * @memberof StoryVersionDto
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface StreamSummariesDto
 */
export interface StreamSummariesDto {
    /**
     * 
     * @type {boolean}
     * @memberof StreamSummariesDto
     */
    success?: boolean;
    /**
     * 
     * @type {Array<StoryStreamSummaryDto>}
     * @memberof StreamSummariesDto
     */
    list?: Array<StoryStreamSummaryDto> | null;
}
/**
 * 
 * @export
 * @interface StringObjectKeyValuePair
 */
export interface StringObjectKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringObjectKeyValuePair
     */
    key?: string | null;
    /**
     * 
     * @type {object}
     * @memberof StringObjectKeyValuePair
     */
    value?: object | null;
}
/**
 * 
 * @export
 * @interface StringStringIEnumerableKeyValuePair
 */
export interface StringStringIEnumerableKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringStringIEnumerableKeyValuePair
     */
    key?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringStringIEnumerableKeyValuePair
     */
    value?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface StringStringValuesKeyValuePair
 */
export interface StringStringValuesKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringStringValuesKeyValuePair
     */
    key?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringStringValuesKeyValuePair
     */
    value?: Array<string>;
}
/**
 * 
 * @export
 * @interface StringUriTuple
 */
export interface StringUriTuple {
    /**
     * 
     * @type {string}
     * @memberof StringUriTuple
     */
    item1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringUriTuple
     */
    item2?: string | null;
}
/**
 * 
 * @export
 * @interface Subscriber
 */
export interface Subscriber {
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    tenantId?: string;
    /**
     * 
     * @type {number}
     * @memberof Subscriber
     */
    storyId?: number;
    /**
     * 
     * @type {Story}
     * @memberof Subscriber
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    storyAlias: string;
    /**
     * 
     * @type {number}
     * @memberof Subscriber
     */
    subscriberId?: number;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Subscriber
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    confirmCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    geoInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    sourceString?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    userAgentString?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    referralUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    notes?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Subscriber
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    deletedTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subscriber
     */
    deletedMethod?: string | null;
}
/**
 * 
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    snapshotKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    requestedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    deadline?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TenantStatus {
    Disabled = 'Disabled',
    Active = 'Active',
    Test = 'Test',
    ProPlan = 'ProPlan'
}

/**
 * 
 * @export
 * @interface TenantSummaryDto
 */
export interface TenantSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof TenantSummaryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSummaryDto
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSummaryDto
     */
    logoUri?: string | null;
}
/**
 * 
 * @export
 * @interface TextInfo
 */
export interface TextInfo {
    /**
     * 
     * @type {number}
     * @memberof TextInfo
     */
    ansiCodePage?: number;
    /**
     * 
     * @type {number}
     * @memberof TextInfo
     */
    oemCodePage?: number;
    /**
     * 
     * @type {number}
     * @memberof TextInfo
     */
    macCodePage?: number;
    /**
     * 
     * @type {number}
     * @memberof TextInfo
     */
    ebcdicCodePage?: number;
    /**
     * 
     * @type {number}
     * @memberof TextInfo
     */
    lcid?: number;
    /**
     * 
     * @type {string}
     * @memberof TextInfo
     */
    cultureName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TextInfo
     */
    isReadOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TextInfo
     */
    listSeparator?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TextInfo
     */
    isRightToLeft?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeFrequency {
    Year = 'Year',
    Month = 'Month',
    Week = 'Week',
    Day = 'Day',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface TimeSeriePeriod
 */
export interface TimeSeriePeriod {
    /**
     * 
     * @type {string}
     * @memberof TimeSeriePeriod
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriePeriod
     */
    alias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriePeriod
     */
    startDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriePeriod
     */
    endDateTime?: string;
    /**
     * 
     * @type {TimeFrequency}
     * @memberof TimeSeriePeriod
     */
    frequency?: TimeFrequency;
    /**
     * 
     * @type {Array<TimeUnit>}
     * @memberof TimeSeriePeriod
     */
    values?: Array<TimeUnit> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TimeSeriePeriod
     */
    isNewData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimeSeriePeriod
     */
    isAccumulative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimeSeriePeriod
     */
    hasComparison?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TimeSeriePeriod
     */
    comparisonList?: { [key: string]: object; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TimeSeriePeriod
     */
    comparisonListWithNumbers?: { [key: string]: number; } | null;
    /**
     * 
     * @type {object}
     * @memberof TimeSeriePeriod
     */
    metaData?: object | null;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriePeriod
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriePeriod
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriePeriod
     */
    average?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriePeriod
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    ticks?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    milliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMilliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalSeconds?: number;
}
/**
 * 
 * @export
 * @interface TimeUnit
 */
export interface TimeUnit {
    /**
     * 
     * @type {string}
     * @memberof TimeUnit
     */
    identifier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeUnit
     */
    value?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TimeUnit
     */
    extras?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof TimeUnit
     */
    debugInfo?: string | null;
}
/**
 * 
 * @export
 * @interface TimeZoneInfo
 */
export interface TimeZoneInfo {
    /**
     * 
     * @type {string}
     * @memberof TimeZoneInfo
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneInfo
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneInfo
     */
    standardName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneInfo
     */
    daylightName?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TimeZoneInfo
     */
    baseUtcOffset?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof TimeZoneInfo
     */
    supportsDaylightSavingTime?: boolean;
}
/**
 * 
 * @export
 * @interface TimelineStreamDto
 */
export interface TimelineStreamDto {
    /**
     * 
     * @type {string}
     * @memberof TimelineStreamDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineStreamDto
     */
    streamAlias: string;
    /**
     * 
     * @type {DataStreamType}
     * @memberof TimelineStreamDto
     */
    streamType: DataStreamType;
    /**
     * 
     * @type {ConnectionSummaryDto}
     * @memberof TimelineStreamDto
     */
    connection?: ConnectionSummaryDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TimelineStreamDto
     */
    settings?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: JToken; }}
     * @memberof TimelineStreamDto
     */
    calculatedSettings?: { [key: string]: JToken; } | null;
}
/**
 * 
 * @export
 * @interface TriggerResultDto
 */
export interface TriggerResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof TriggerResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TriggerResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TriggerResultDto
     */
    executeToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TriggerResultDto
     */
    bgJobId?: string | null;
}
/**
 * 
 * @export
 * @interface UploadResultDto
 */
export interface UploadResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof UploadResultDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UploadResultDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadResultDto
     */
    message?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof UploadResultDto
     */
    calculatedDuration?: TimeSpan;
}
/**
 * 
 * @export
 * @interface UserInfoDto
 */
export interface UserInfoDto {
    /**
     * 
     * @type {Array<TenantSummaryDto>}
     * @memberof UserInfoDto
     */
    tenants: Array<TenantSummaryDto>;
}
/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    major?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    minor?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    build?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    revision?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    majorRevision?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    minorRevision?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VisualizationType {
    None = 'None',
    Picture = 'Picture',
    Number = 'Number',
    HighchartsJson = 'HighchartsJson',
    SVG = 'SVG',
    Screenshot = 'Screenshot'
}

/**
 * 
 * @export
 * @interface VoicePersonDto
 */
export interface VoicePersonDto {
    /**
     * 
     * @type {string}
     * @memberof VoicePersonDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoicePersonDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VoicePersonDto
     */
    icon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoicePersonDto
     */
    lang?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoicePersonDto
     */
    isLocked?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoicePersonDto
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoicePersonDto
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface VoicePreviewInputDto
 */
export interface VoicePreviewInputDto {
    /**
     * 
     * @type {string}
     * @memberof VoicePreviewInputDto
     */
    markup: string;
    /**
     * 
     * @type {string}
     * @memberof VoicePreviewInputDto
     */
    requestedBy?: string | null;
}
/**
 * 
 * @export
 * @interface VoicePreviewOutputDto
 */
export interface VoicePreviewOutputDto {
    /**
     * 
     * @type {boolean}
     * @memberof VoicePreviewOutputDto
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoicePreviewOutputDto
     */
    audioBase64?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoicePreviewOutputDto
     */
    executeToken?: string | null;
}
/**
 * 
 * @export
 * @interface VoiceRecording
 */
export interface VoiceRecording {
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    voiceRecordingId?: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecording
     */
    storyId?: number | null;
    /**
     * 
     * @type {Story}
     * @memberof VoiceRecording
     */
    story?: Story;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {Snapshot}
     * @memberof VoiceRecording
     */
    snapshot?: Snapshot;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    snapshotId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    snapshotKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    hangfireJobId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    type?: string | null;
    /**
     * 
     * @type {VoiceRecordingStatus}
     * @memberof VoiceRecording
     */
    status?: VoiceRecordingStatus;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    lang?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    uri?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecording
     */
    duration?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceRecording
     */
    isRecorded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceRecording
     */
    isOptimized?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    optimizationResultText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    optimizationImageUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    requestedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    requestedByName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    humanVoiceRequest_ToEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    humanVoiceRequest_ToPhoneNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    humanVoiceRequest_Deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    providerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    ssml?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    voicePersonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    voicePersonFriendlyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecording
     */
    processingTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoiceRecording
     */
    processingCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    server?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoiceRecording
     */
    notes?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VoiceRecordingStatus {
    Requested = 'Requested',
    Recording = 'Recording',
    Recorded = 'Recorded',
    Failed = 'Failed',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @interface WebPostDto
 */
export interface WebPostDto {
    /**
     * 
     * @type {string}
     * @memberof WebPostDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WebPostDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebPostDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof WebPostDto
     */
    created: string;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof WebPostDto
     */
    metaData?: Array<InputPropertyDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WebPostDto
     */
    imageUri?: string | null;
}
/**
 * 
 * @export
 * @interface WordRuleInputDto
 */
export interface WordRuleInputDto {
    /**
     * 
     * @type {string}
     * @memberof WordRuleInputDto
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof WordRuleInputDto
     */
    voicePersonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WordRuleInputDto
     */
    inputPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WordRuleInputDto
     */
    outputPattern?: string | null;
}
/**
 * 
 * @export
 * @interface WordRuleOutputDto
 */
export interface WordRuleOutputDto {
    /**
     * 
     * @type {string}
     * @memberof WordRuleOutputDto
     */
    voicePersonName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WordRuleOutputDto
     */
    shared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WordRuleOutputDto
     */
    inputPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WordRuleOutputDto
     */
    outputPattern?: string | null;
}
/**
 * 
 * @export
 * @interface WriterInputDto
 */
export interface WriterInputDto {
    /**
     * 
     * @type {string}
     * @memberof WriterInputDto
     */
    storyAlias?: string | null;
    /**
     * 
     * @type {Array<InputPropertyDto>}
     * @memberof WriterInputDto
     */
    inputs?: Array<InputPropertyDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WriterInputDto
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriterInputDto
     */
    tenantId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriterInputDto
     */
    inputsYAML?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriterInputDto
     */
    htmlOutput?: boolean;
}

/**
 * ActionExecutionApi - axios parameter creator
 * @export
 */
export const ActionExecutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAction(id?: number, options: any = {}): RequestArgs {
            const localVarPath = `/action`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionExecutionApi - functional programming interface
 * @export
 */
export const ActionExecutionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAction(id?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ActionExecutionApiAxiosParamCreator(configuration).executeAction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActionExecutionApi - factory interface
 * @export
 */
export const ActionExecutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAction(id?: number, options?: any): AxiosPromise<string> {
            return ActionExecutionApiFp(configuration).executeAction(id, options)(axios, basePath);
        },
    };
};

/**
 * ActionExecutionApi - object-oriented interface
 * @export
 * @class ActionExecutionApi
 * @extends {BaseAPI}
 */
export class ActionExecutionApi extends BaseAPI {
    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionExecutionApi
     */
    public executeAction(id?: number, options?: any) {
        return ActionExecutionApiFp(this.configuration).executeAction(id, options)(this.axios, this.basePath);
    }

}


/**
 * ArticleAudioApi - axios parameter creator
 * @export
 */
export const ArticleAudioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute(token?: string, url?: string, force?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/articleaudio`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEpisodeInfo(token?: string, url?: string, options: any = {}): RequestArgs {
            const localVarPath = `/articleaudio/episode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleAudioApi - functional programming interface
 * @export
 */
export const ArticleAudioApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute(token?: string, url?: string, force?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleAudioResultDto> {
            const localVarAxiosArgs = ArticleAudioApiAxiosParamCreator(configuration).execute(token, url, force, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEpisodeInfo(token?: string, url?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleAudioResultDto> {
            const localVarAxiosArgs = ArticleAudioApiAxiosParamCreator(configuration).loadEpisodeInfo(token, url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArticleAudioApi - factory interface
 * @export
 */
export const ArticleAudioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute(token?: string, url?: string, force?: boolean, options?: any): AxiosPromise<ArticleAudioResultDto> {
            return ArticleAudioApiFp(configuration).execute(token, url, force, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEpisodeInfo(token?: string, url?: string, options?: any): AxiosPromise<ArticleAudioResultDto> {
            return ArticleAudioApiFp(configuration).loadEpisodeInfo(token, url, options)(axios, basePath);
        },
    };
};

/**
 * ArticleAudioApi - object-oriented interface
 * @export
 * @class ArticleAudioApi
 * @extends {BaseAPI}
 */
export class ArticleAudioApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {string} [url] 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAudioApi
     */
    public execute(token?: string, url?: string, force?: boolean, options?: any) {
        return ArticleAudioApiFp(this.configuration).execute(token, url, force, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAudioApi
     */
    public loadEpisodeInfo(token?: string, url?: string, options?: any) {
        return ArticleAudioApiFp(this.configuration).loadEpisodeInfo(token, url, options)(this.axios, this.basePath);
    }

}


/**
 * AudioOptimizerApi - axios parameter creator
 * @export
 */
export const AudioOptimizerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCallback(key?: string, data?: Array<StringStringValuesKeyValuePair>, options: any = {}): RequestArgs {
            const localVarPath = `/audiooptimizer/callback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (data) {
            
                localVarFormParams.set('data', data.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudioOptimizerApi - functional programming interface
 * @export
 */
export const AudioOptimizerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCallback(key?: string, data?: Array<StringStringValuesKeyValuePair>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = AudioOptimizerApiAxiosParamCreator(configuration).handleCallback(key, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AudioOptimizerApi - factory interface
 * @export
 */
export const AudioOptimizerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [key] 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCallback(key?: string, data?: Array<StringStringValuesKeyValuePair>, options?: any): AxiosPromise<StatusResultDto> {
            return AudioOptimizerApiFp(configuration).handleCallback(key, data, options)(axios, basePath);
        },
    };
};

/**
 * AudioOptimizerApi - object-oriented interface
 * @export
 * @class AudioOptimizerApi
 * @extends {BaseAPI}
 */
export class AudioOptimizerApi extends BaseAPI {
    /**
     * 
     * @param {string} [key] 
     * @param {Array<StringStringValuesKeyValuePair>} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioOptimizerApi
     */
    public handleCallback(key?: string, data?: Array<StringStringValuesKeyValuePair>, options?: any) {
        return AudioOptimizerApiFp(this.configuration).handleCallback(key, data, options)(this.axios, this.basePath);
    }

}


/**
 * AudioPlayerApi - axios parameter creator
 * @export
 */
export const AudioPlayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} episodeKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(episodeKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'episodeKey' is not null or undefined
            if (episodeKey === null || episodeKey === undefined) {
                throw new RequiredError('episodeKey','Required parameter episodeKey was null or undefined when calling getEpisode.');
            }
            const localVarPath = `/audioplayer/track/{episodeKey}`
                .replace(`{${"episodeKey"}}`, encodeURIComponent(String(episodeKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudioPlayerApi - functional programming interface
 * @export
 */
export const AudioPlayerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} episodeKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(episodeKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeInfoDto> {
            const localVarAxiosArgs = AudioPlayerApiAxiosParamCreator(configuration).getEpisode(episodeKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AudioPlayerApi - factory interface
 * @export
 */
export const AudioPlayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} episodeKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(episodeKey: string, options?: any): AxiosPromise<EpisodeInfoDto> {
            return AudioPlayerApiFp(configuration).getEpisode(episodeKey, options)(axios, basePath);
        },
    };
};

/**
 * AudioPlayerApi - object-oriented interface
 * @export
 * @class AudioPlayerApi
 * @extends {BaseAPI}
 */
export class AudioPlayerApi extends BaseAPI {
    /**
     * 
     * @param {string} episodeKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioPlayerApi
     */
    public getEpisode(episodeKey: string, options?: any) {
        return AudioPlayerApiFp(this.configuration).getEpisode(episodeKey, options)(this.axios, this.basePath);
    }

}


/**
 * BlobsApi - axios parameter creator
 * @export
 */
export const BlobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [format] 
         * @param {boolean} [calculateDuration] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAsync(alias?: string, format?: string, calculateDuration?: boolean, file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/blobs/upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (calculateDuration !== undefined) {
                localVarQueryParameter['calculateDuration'] = calculateDuration;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageAsync(fileName?: string, file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/blobs/upload-image`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlobsApi - functional programming interface
 * @export
 */
export const BlobsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [format] 
         * @param {boolean} [calculateDuration] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAsync(alias?: string, format?: string, calculateDuration?: boolean, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResultDto> {
            const localVarAxiosArgs = BlobsApiAxiosParamCreator(configuration).uploadAsync(alias, format, calculateDuration, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageAsync(fileName?: string, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResultDto> {
            const localVarAxiosArgs = BlobsApiAxiosParamCreator(configuration).uploadImageAsync(fileName, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BlobsApi - factory interface
 * @export
 */
export const BlobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [format] 
         * @param {boolean} [calculateDuration] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAsync(alias?: string, format?: string, calculateDuration?: boolean, file?: any, options?: any): AxiosPromise<UploadResultDto> {
            return BlobsApiFp(configuration).uploadAsync(alias, format, calculateDuration, file, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageAsync(fileName?: string, file?: any, options?: any): AxiosPromise<UploadResultDto> {
            return BlobsApiFp(configuration).uploadImageAsync(fileName, file, options)(axios, basePath);
        },
    };
};

/**
 * BlobsApi - object-oriented interface
 * @export
 * @class BlobsApi
 * @extends {BaseAPI}
 */
export class BlobsApi extends BaseAPI {
    /**
     * 
     * @param {string} [alias] 
     * @param {string} [format] 
     * @param {boolean} [calculateDuration] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlobsApi
     */
    public uploadAsync(alias?: string, format?: string, calculateDuration?: boolean, file?: any, options?: any) {
        return BlobsApiFp(this.configuration).uploadAsync(alias, format, calculateDuration, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlobsApi
     */
    public uploadImageAsync(fileName?: string, file?: any, options?: any) {
        return BlobsApiFp(this.configuration).uploadImageAsync(fileName, file, options)(this.axios, this.basePath);
    }

}


/**
 * BlocksApi - axios parameter creator
 * @export
 */
export const BlocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToOther(blockId: string, tenantId?: string, requestBody?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'blockId' is not null or undefined
            if (blockId === null || blockId === undefined) {
                throw new RequiredError('blockId','Required parameter blockId was null or undefined when calling copyToOther.');
            }
            const localVarPath = `/blocks/{blockId}`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [userId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBlocksAsync(tenantId?: string, userId?: string, mode?: string, options: any = {}): RequestArgs {
            const localVarPath = `/blocks/$available`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling getBlock.');
            }
            const localVarPath = `/blocks/{snapshotKey}`
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {string} [securityToken] 
         * @param {string} [userId] 
         * @param {boolean} [draft] 
         * @param {boolean} [includeEmptyTitles] 
         * @param {boolean} [contentAsMarkdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMixMode(storyAlias: string, tenantId?: string, securityToken?: string, userId?: string, draft?: boolean, includeEmptyTitles?: boolean, contentAsMarkdown?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getLatestMixMode.');
            }
            const localVarPath = `/blocks/mixmode/{storyAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (securityToken !== undefined) {
                localVarQueryParameter['securityToken'] = securityToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (draft !== undefined) {
                localVarQueryParameter['draft'] = draft;
            }

            if (includeEmptyTitles !== undefined) {
                localVarQueryParameter['includeEmptyTitles'] = includeEmptyTitles;
            }

            if (contentAsMarkdown !== undefined) {
                localVarQueryParameter['contentAsMarkdown'] = contentAsMarkdown;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options: any = {}): RequestArgs {
            const localVarPath = `/blocks/$web-posts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBlocks(tenantId?: string, blockDto?: Array<BlockDto>, options: any = {}): RequestArgs {
            const localVarPath = `/blocks/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof blockDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(blockDto !== undefined ? blockDto : {}) : (blockDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFromUrl(url?: string, tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/blocks/import-url`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlocksAsync(blockId: string, tenantId?: string, blockDto?: Array<BlockDto>, options: any = {}): RequestArgs {
            // verify required parameter 'blockId' is not null or undefined
            if (blockId === null || blockId === undefined) {
                throw new RequiredError('blockId','Required parameter blockId was null or undefined when calling saveBlocksAsync.');
            }
            const localVarPath = `/blocks/{blockId}`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof blockDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(blockDto !== undefined ? blockDto : {}) : (blockDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [uri] 
         * @param {number} [fileSize] 
         * @param {number} [duration] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRecordingUriAsync(blockId: string, uri?: string, fileSize?: number, duration?: number, userId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'blockId' is not null or undefined
            if (blockId === null || blockId === undefined) {
                throw new RequiredError('blockId','Required parameter blockId was null or undefined when calling saveRecordingUriAsync.');
            }
            const localVarPath = `/blocks/{blockId}/recording-uri`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (uri !== undefined) {
                localVarQueryParameter['uri'] = uri;
            }

            if (fileSize !== undefined) {
                localVarQueryParameter['fileSize'] = fileSize;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlocksApi - functional programming interface
 * @export
 */
export const BlocksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToOther(blockId: string, tenantId?: string, requestBody?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).copyToOther(blockId, tenantId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [userId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBlocksAsync(tenantId?: string, userId?: string, mode?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockDto>> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).getAvailableBlocksAsync(tenantId, userId, mode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).getBlock(snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {string} [securityToken] 
         * @param {string} [userId] 
         * @param {boolean} [draft] 
         * @param {boolean} [includeEmptyTitles] 
         * @param {boolean} [contentAsMarkdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMixMode(storyAlias: string, tenantId?: string, securityToken?: string, userId?: string, draft?: boolean, includeEmptyTitles?: boolean, contentAsMarkdown?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MixModeResultDto>> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).getLatestMixMode(storyAlias, tenantId, securityToken, userId, draft, includeEmptyTitles, contentAsMarkdown, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebPostDto>> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).getWebPosts(tenantId, tag, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBlocks(tenantId?: string, blockDto?: Array<BlockDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportBlocksResultDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).importBlocks(tenantId, blockDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFromUrl(url?: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportBlocksResultDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).importFromUrl(url, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlocksAsync(blockId: string, tenantId?: string, blockDto?: Array<BlockDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportBlocksResultDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).saveBlocksAsync(blockId, tenantId, blockDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [uri] 
         * @param {number} [fileSize] 
         * @param {number} [duration] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRecordingUriAsync(blockId: string, uri?: string, fileSize?: number, duration?: number, userId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = BlocksApiAxiosParamCreator(configuration).saveRecordingUriAsync(blockId, uri, fileSize, duration, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BlocksApi - factory interface
 * @export
 */
export const BlocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToOther(blockId: string, tenantId?: string, requestBody?: Array<string>, options?: any): AxiosPromise<StatusResultDto> {
            return BlocksApiFp(configuration).copyToOther(blockId, tenantId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [userId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBlocksAsync(tenantId?: string, userId?: string, mode?: string, options?: any): AxiosPromise<Array<BlockDto>> {
            return BlocksApiFp(configuration).getAvailableBlocksAsync(tenantId, userId, mode, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlock(snapshotKey: string, options?: any): AxiosPromise<BlockDto> {
            return BlocksApiFp(configuration).getBlock(snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {string} [securityToken] 
         * @param {string} [userId] 
         * @param {boolean} [draft] 
         * @param {boolean} [includeEmptyTitles] 
         * @param {boolean} [contentAsMarkdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMixMode(storyAlias: string, tenantId?: string, securityToken?: string, userId?: string, draft?: boolean, includeEmptyTitles?: boolean, contentAsMarkdown?: boolean, options?: any): AxiosPromise<Array<MixModeResultDto>> {
            return BlocksApiFp(configuration).getLatestMixMode(storyAlias, tenantId, securityToken, userId, draft, includeEmptyTitles, contentAsMarkdown, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options?: any): AxiosPromise<Array<WebPostDto>> {
            return BlocksApiFp(configuration).getWebPosts(tenantId, tag, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBlocks(tenantId?: string, blockDto?: Array<BlockDto>, options?: any): AxiosPromise<ImportBlocksResultDto> {
            return BlocksApiFp(configuration).importBlocks(tenantId, blockDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [url] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFromUrl(url?: string, tenantId?: string, options?: any): AxiosPromise<ImportBlocksResultDto> {
            return BlocksApiFp(configuration).importFromUrl(url, tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [tenantId] 
         * @param {Array<BlockDto>} [blockDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlocksAsync(blockId: string, tenantId?: string, blockDto?: Array<BlockDto>, options?: any): AxiosPromise<ImportBlocksResultDto> {
            return BlocksApiFp(configuration).saveBlocksAsync(blockId, tenantId, blockDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} blockId 
         * @param {string} [uri] 
         * @param {number} [fileSize] 
         * @param {number} [duration] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRecordingUriAsync(blockId: string, uri?: string, fileSize?: number, duration?: number, userId?: string, options?: any): AxiosPromise<StatusResultDto> {
            return BlocksApiFp(configuration).saveRecordingUriAsync(blockId, uri, fileSize, duration, userId, options)(axios, basePath);
        },
    };
};

/**
 * BlocksApi - object-oriented interface
 * @export
 * @class BlocksApi
 * @extends {BaseAPI}
 */
export class BlocksApi extends BaseAPI {
    /**
     * 
     * @param {string} blockId 
     * @param {string} [tenantId] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public copyToOther(blockId: string, tenantId?: string, requestBody?: Array<string>, options?: any) {
        return BlocksApiFp(this.configuration).copyToOther(blockId, tenantId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [userId] 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public getAvailableBlocksAsync(tenantId?: string, userId?: string, mode?: string, options?: any) {
        return BlocksApiFp(this.configuration).getAvailableBlocksAsync(tenantId, userId, mode, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public getBlock(snapshotKey: string, options?: any) {
        return BlocksApiFp(this.configuration).getBlock(snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [tenantId] 
     * @param {string} [securityToken] 
     * @param {string} [userId] 
     * @param {boolean} [draft] 
     * @param {boolean} [includeEmptyTitles] 
     * @param {boolean} [contentAsMarkdown] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public getLatestMixMode(storyAlias: string, tenantId?: string, securityToken?: string, userId?: string, draft?: boolean, includeEmptyTitles?: boolean, contentAsMarkdown?: boolean, options?: any) {
        return BlocksApiFp(this.configuration).getLatestMixMode(storyAlias, tenantId, securityToken, userId, draft, includeEmptyTitles, contentAsMarkdown, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [tag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public getWebPosts(tenantId?: string, tag?: string, options?: any) {
        return BlocksApiFp(this.configuration).getWebPosts(tenantId, tag, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {Array<BlockDto>} [blockDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public importBlocks(tenantId?: string, blockDto?: Array<BlockDto>, options?: any) {
        return BlocksApiFp(this.configuration).importBlocks(tenantId, blockDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [url] 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public importFromUrl(url?: string, tenantId?: string, options?: any) {
        return BlocksApiFp(this.configuration).importFromUrl(url, tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} blockId 
     * @param {string} [tenantId] 
     * @param {Array<BlockDto>} [blockDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public saveBlocksAsync(blockId: string, tenantId?: string, blockDto?: Array<BlockDto>, options?: any) {
        return BlocksApiFp(this.configuration).saveBlocksAsync(blockId, tenantId, blockDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} blockId 
     * @param {string} [uri] 
     * @param {number} [fileSize] 
     * @param {number} [duration] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlocksApi
     */
    public saveRecordingUriAsync(blockId: string, uri?: string, fileSize?: number, duration?: number, userId?: string, options?: any) {
        return BlocksApiFp(this.configuration).saveRecordingUriAsync(blockId, uri, fileSize, duration, userId, options)(this.axios, this.basePath);
    }

}


/**
 * CalendarEventsApi - axios parameter creator
 * @export
 */
export const CalendarEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [current] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarEvents(start?: string, end?: string, current?: string, options: any = {}): RequestArgs {
            const localVarPath = `/calendarevents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (current !== undefined) {
                localVarQueryParameter['current'] = current;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEventsApi - functional programming interface
 * @export
 */
export const CalendarEventsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [current] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarEvents(start?: string, end?: string, current?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
            const localVarAxiosArgs = CalendarEventsApiAxiosParamCreator(configuration).getAllCalendarEvents(start, end, current, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CalendarEventsApi - factory interface
 * @export
 */
export const CalendarEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [current] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarEvents(start?: string, end?: string, current?: string, options?: any): AxiosPromise<Array<object>> {
            return CalendarEventsApiFp(configuration).getAllCalendarEvents(start, end, current, options)(axios, basePath);
        },
    };
};

/**
 * CalendarEventsApi - object-oriented interface
 * @export
 * @class CalendarEventsApi
 * @extends {BaseAPI}
 */
export class CalendarEventsApi extends BaseAPI {
    /**
     * 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [current] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public getAllCalendarEvents(start?: string, end?: string, current?: string, options?: any) {
        return CalendarEventsApiFp(this.configuration).getAllCalendarEvents(start, end, current, options)(this.axios, this.basePath);
    }

}


/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConnection(sourceAlias?: string, tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/connections/$add`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (sourceAlias !== undefined) {
                localVarQueryParameter['sourceAlias'] = sourceAlias;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(cid: string, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling deleteConnection.');
            }
            const localVarPath = `/connections/{cid}`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings(cid: string, token?: string, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling getConnectionSettings.');
            }
            const localVarPath = `/connections/{cid}/settings-fromplugin`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings2(cid: string, key?: string, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling getConnectionSettings2.');
            }
            const localVarPath = `/connections/{cid}/settings`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {boolean} [createNewIfEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections(sourceAlias?: string, tenantId?: string, createNewIfEmpty?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/connections`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (sourceAlias !== undefined) {
                localVarQueryParameter['sourceAlias'] = sourceAlias;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (createNewIfEmpty !== undefined) {
                localVarQueryParameter['createNewIfEmpty'] = createNewIfEmpty;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToConfigurePlugin(cid: string, stream?: string, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling redirectToConfigurePlugin.');
            }
            const localVarPath = `/connections/{cid}/$goto-configure-plugin`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (stream !== undefined) {
                localVarQueryParameter['stream'] = stream;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict(cid: string, token?: string, requestBody?: { [key: string]: object; }, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling updateConnectionSettingsAsDict.');
            }
            const localVarPath = `/connections/{cid}/settings-fromplugin`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict2(cid: string, requestBody?: { [key: string]: object; }, options: any = {}): RequestArgs {
            // verify required parameter 'cid' is not null or undefined
            if (cid === null || cid === undefined) {
                throw new RequiredError('cid','Required parameter cid was null or undefined when calling updateConnectionSettingsAsDict2.');
            }
            const localVarPath = `/connections/{cid}/settings`
                .replace(`{${"cid"}}`, encodeURIComponent(String(cid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConnection(sourceAlias?: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionSummaryDto> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).addConnection(sourceAlias, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(cid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).deleteConnection(cid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings(cid: string, token?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingDto>> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).getConnectionSettings(cid, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings2(cid: string, key?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingDto>> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).getConnectionSettings2(cid, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {boolean} [createNewIfEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections(sourceAlias?: string, tenantId?: string, createNewIfEmpty?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectionSummaryDto>> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).getConnections(sourceAlias, tenantId, createNewIfEmpty, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToConfigurePlugin(cid: string, stream?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).redirectToConfigurePlugin(cid, stream, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict(cid: string, token?: string, requestBody?: { [key: string]: object; }, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigureResultDto> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).updateConnectionSettingsAsDict(cid, token, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cid 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict2(cid: string, requestBody?: { [key: string]: object; }, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigureResultDto> {
            const localVarAxiosArgs = ConnectionsApiAxiosParamCreator(configuration).updateConnectionSettingsAsDict2(cid, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConnection(sourceAlias?: string, tenantId?: string, options?: any): AxiosPromise<ConnectionSummaryDto> {
            return ConnectionsApiFp(configuration).addConnection(sourceAlias, tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(cid: string, options?: any): AxiosPromise<boolean> {
            return ConnectionsApiFp(configuration).deleteConnection(cid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings(cid: string, token?: string, options?: any): AxiosPromise<Array<SettingDto>> {
            return ConnectionsApiFp(configuration).getConnectionSettings(cid, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionSettings2(cid: string, key?: string, options?: any): AxiosPromise<Array<SettingDto>> {
            return ConnectionsApiFp(configuration).getConnectionSettings2(cid, key, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [sourceAlias] 
         * @param {string} [tenantId] 
         * @param {boolean} [createNewIfEmpty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections(sourceAlias?: string, tenantId?: string, createNewIfEmpty?: boolean, options?: any): AxiosPromise<Array<ConnectionSummaryDto>> {
            return ConnectionsApiFp(configuration).getConnections(sourceAlias, tenantId, createNewIfEmpty, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToConfigurePlugin(cid: string, stream?: string, options?: any): AxiosPromise<void> {
            return ConnectionsApiFp(configuration).redirectToConfigurePlugin(cid, stream, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {string} [token] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict(cid: string, token?: string, requestBody?: { [key: string]: object; }, options?: any): AxiosPromise<ConfigureResultDto> {
            return ConnectionsApiFp(configuration).updateConnectionSettingsAsDict(cid, token, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cid 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionSettingsAsDict2(cid: string, requestBody?: { [key: string]: object; }, options?: any): AxiosPromise<ConfigureResultDto> {
            return ConnectionsApiFp(configuration).updateConnectionSettingsAsDict2(cid, requestBody, options)(axios, basePath);
        },
    };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [sourceAlias] 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public addConnection(sourceAlias?: string, tenantId?: string, options?: any) {
        return ConnectionsApiFp(this.configuration).addConnection(sourceAlias, tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public deleteConnection(cid: string, options?: any) {
        return ConnectionsApiFp(this.configuration).deleteConnection(cid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public getConnectionSettings(cid: string, token?: string, options?: any) {
        return ConnectionsApiFp(this.configuration).getConnectionSettings(cid, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public getConnectionSettings2(cid: string, key?: string, options?: any) {
        return ConnectionsApiFp(this.configuration).getConnectionSettings2(cid, key, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [sourceAlias] 
     * @param {string} [tenantId] 
     * @param {boolean} [createNewIfEmpty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public getConnections(sourceAlias?: string, tenantId?: string, createNewIfEmpty?: boolean, options?: any) {
        return ConnectionsApiFp(this.configuration).getConnections(sourceAlias, tenantId, createNewIfEmpty, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {string} [stream] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public redirectToConfigurePlugin(cid: string, stream?: string, options?: any) {
        return ConnectionsApiFp(this.configuration).redirectToConfigurePlugin(cid, stream, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {string} [token] 
     * @param {{ [key: string]: object; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public updateConnectionSettingsAsDict(cid: string, token?: string, requestBody?: { [key: string]: object; }, options?: any) {
        return ConnectionsApiFp(this.configuration).updateConnectionSettingsAsDict(cid, token, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cid 
     * @param {{ [key: string]: object; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public updateConnectionSettingsAsDict2(cid: string, requestBody?: { [key: string]: object; }, options?: any) {
        return ConnectionsApiFp(this.configuration).updateConnectionSettingsAsDict2(cid, requestBody, options)(this.axios, this.basePath);
    }

}


/**
 * DestinationsApi - axios parameter creator
 * @export
 */
export const DestinationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlack(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/destinations/slack`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [returnHtmlPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlackCallback(returnHtmlPage?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/destinations/slack-callback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (returnHtmlPage !== undefined) {
                localVarQueryParameter['returnHtmlPage'] = returnHtmlPage;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DestinationsApi - functional programming interface
 * @export
 */
export const DestinationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlack(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DestinationsApiAxiosParamCreator(configuration).installSlack(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [returnHtmlPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlackCallback(returnHtmlPage?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DestinationsApiAxiosParamCreator(configuration).installSlackCallback(returnHtmlPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DestinationsApi - factory interface
 * @export
 */
export const DestinationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlack(tenantId?: string, options?: any): AxiosPromise<void> {
            return DestinationsApiFp(configuration).installSlack(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [returnHtmlPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        installSlackCallback(returnHtmlPage?: boolean, options?: any): AxiosPromise<void> {
            return DestinationsApiFp(configuration).installSlackCallback(returnHtmlPage, options)(axios, basePath);
        },
    };
};

/**
 * DestinationsApi - object-oriented interface
 * @export
 * @class DestinationsApi
 * @extends {BaseAPI}
 */
export class DestinationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public installSlack(tenantId?: string, options?: any) {
        return DestinationsApiFp(this.configuration).installSlack(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [returnHtmlPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public installSlackCallback(returnHtmlPage?: boolean, options?: any) {
        return DestinationsApiFp(this.configuration).installSlackCallback(returnHtmlPage, options)(this.axios, this.basePath);
    }

}


/**
 * EmailPreviewApi - axios parameter creator
 * @export
 */
export const EmailPreviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailPreview(snapshotKey: string, storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling getEmailPreview.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getEmailPreview.');
            }
            const localVarPath = `/emailpreview/{storyAlias}/snapshots/{snapshotKey}/preview`
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewAsHtml(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getPreviewAsHtml.');
            }
            const localVarPath = `/emailpreview/{storyAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailPreviewApi - functional programming interface
 * @export
 */
export const EmailPreviewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailPreview(snapshotKey: string, storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailPreviewDto> {
            const localVarAxiosArgs = EmailPreviewApiAxiosParamCreator(configuration).getEmailPreview(snapshotKey, storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewAsHtml(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = EmailPreviewApiAxiosParamCreator(configuration).getPreviewAsHtml(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EmailPreviewApi - factory interface
 * @export
 */
export const EmailPreviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailPreview(snapshotKey: string, storyAlias: string, options?: any): AxiosPromise<EmailPreviewDto> {
            return EmailPreviewApiFp(configuration).getEmailPreview(snapshotKey, storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewAsHtml(storyAlias: string, options?: any): AxiosPromise<void> {
            return EmailPreviewApiFp(configuration).getPreviewAsHtml(storyAlias, options)(axios, basePath);
        },
    };
};

/**
 * EmailPreviewApi - object-oriented interface
 * @export
 * @class EmailPreviewApi
 * @extends {BaseAPI}
 */
export class EmailPreviewApi extends BaseAPI {
    /**
     * 
     * @param {string} snapshotKey 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailPreviewApi
     */
    public getEmailPreview(snapshotKey: string, storyAlias: string, options?: any) {
        return EmailPreviewApiFp(this.configuration).getEmailPreview(snapshotKey, storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailPreviewApi
     */
    public getPreviewAsHtml(storyAlias: string, options?: any) {
        return EmailPreviewApiFp(this.configuration).getPreviewAsHtml(storyAlias, options)(this.axios, this.basePath);
    }

}


/**
 * EpisodesApi - axios parameter creator
 * @export
 */
export const EpisodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} station 
         * @param {string} episodeKey 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(station: string, episodeKey: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'station' is not null or undefined
            if (station === null || station === undefined) {
                throw new RequiredError('station','Required parameter station was null or undefined when calling getEpisode.');
            }
            // verify required parameter 'episodeKey' is not null or undefined
            if (episodeKey === null || episodeKey === undefined) {
                throw new RequiredError('episodeKey','Required parameter episodeKey was null or undefined when calling getEpisode.');
            }
            const localVarPath = `/episodes/{station}/{episodeKey}`
                .replace(`{${"station"}}`, encodeURIComponent(String(station)))
                .replace(`{${"episodeKey"}}`, encodeURIComponent(String(episodeKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} episodeSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeBySlug(storyAlias: string, episodeSlug: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getEpisodeBySlug.');
            }
            // verify required parameter 'episodeSlug' is not null or undefined
            if (episodeSlug === null || episodeSlug === undefined) {
                throw new RequiredError('episodeSlug','Required parameter episodeSlug was null or undefined when calling getEpisodeBySlug.');
            }
            const localVarPath = `/episodes/{storyAlias}/$by-slug/{episodeSlug}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"episodeSlug"}}`, encodeURIComponent(String(episodeSlug)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatest(station: string, options: any = {}): RequestArgs {
            // verify required parameter 'station' is not null or undefined
            if (station === null || station === undefined) {
                throw new RequiredError('station','Required parameter station was null or undefined when calling getLatest.');
            }
            const localVarPath = `/episodes/{station}/$latest`
                .replace(`{${"station"}}`, encodeURIComponent(String(station)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(station: string, options: any = {}): RequestArgs {
            // verify required parameter 'station' is not null or undefined
            if (station === null || station === undefined) {
                throw new RequiredError('station','Required parameter station was null or undefined when calling list.');
            }
            const localVarPath = `/episodes/{station}`
                .replace(`{${"station"}}`, encodeURIComponent(String(station)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EpisodesApi - functional programming interface
 * @export
 */
export const EpisodesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} station 
         * @param {string} episodeKey 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(station: string, episodeKey: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeInfoDto> {
            const localVarAxiosArgs = EpisodesApiAxiosParamCreator(configuration).getEpisode(station, episodeKey, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} episodeSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeBySlug(storyAlias: string, episodeSlug: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeInfoDto> {
            const localVarAxiosArgs = EpisodesApiAxiosParamCreator(configuration).getEpisodeBySlug(storyAlias, episodeSlug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatest(station: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeInfoDto> {
            const localVarAxiosArgs = EpisodesApiAxiosParamCreator(configuration).getLatest(station, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(station: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EpisodeInfoDto>> {
            const localVarAxiosArgs = EpisodesApiAxiosParamCreator(configuration).list(station, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EpisodesApi - factory interface
 * @export
 */
export const EpisodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} station 
         * @param {string} episodeKey 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisode(station: string, episodeKey: string, t?: string, options?: any): AxiosPromise<EpisodeInfoDto> {
            return EpisodesApiFp(configuration).getEpisode(station, episodeKey, t, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} episodeSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodeBySlug(storyAlias: string, episodeSlug: string, options?: any): AxiosPromise<EpisodeInfoDto> {
            return EpisodesApiFp(configuration).getEpisodeBySlug(storyAlias, episodeSlug, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatest(station: string, options?: any): AxiosPromise<EpisodeInfoDto> {
            return EpisodesApiFp(configuration).getLatest(station, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} station 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(station: string, options?: any): AxiosPromise<Array<EpisodeInfoDto>> {
            return EpisodesApiFp(configuration).list(station, options)(axios, basePath);
        },
    };
};

/**
 * EpisodesApi - object-oriented interface
 * @export
 * @class EpisodesApi
 * @extends {BaseAPI}
 */
export class EpisodesApi extends BaseAPI {
    /**
     * 
     * @param {string} station 
     * @param {string} episodeKey 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodesApi
     */
    public getEpisode(station: string, episodeKey: string, t?: string, options?: any) {
        return EpisodesApiFp(this.configuration).getEpisode(station, episodeKey, t, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} episodeSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodesApi
     */
    public getEpisodeBySlug(storyAlias: string, episodeSlug: string, options?: any) {
        return EpisodesApiFp(this.configuration).getEpisodeBySlug(storyAlias, episodeSlug, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} station 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodesApi
     */
    public getLatest(station: string, options?: any) {
        return EpisodesApiFp(this.configuration).getLatest(station, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} station 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpisodesApi
     */
    public list(station: string, options?: any) {
        return EpisodesApiFp(this.configuration).list(station, options)(this.axios, this.basePath);
    }

}


/**
 * GoalsApi - axios parameter creator
 * @export
 */
export const GoalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoals(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/goals`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoalsApi - functional programming interface
 * @export
 */
export const GoalsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoals(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = GoalsApiAxiosParamCreator(configuration).getGoals(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GoalsApi - factory interface
 * @export
 */
export const GoalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoals(tenantId?: string, options?: any): AxiosPromise<void> {
            return GoalsApiFp(configuration).getGoals(tenantId, options)(axios, basePath);
        },
    };
};

/**
 * GoalsApi - object-oriented interface
 * @export
 * @class GoalsApi
 * @extends {BaseAPI}
 */
export class GoalsApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalsApi
     */
    public getGoals(tenantId?: string, options?: any) {
        return GoalsApiFp(this.configuration).getGoals(tenantId, options)(this.axios, this.basePath);
    }

}


/**
 * HangfireMonitorApi - axios parameter creator
 * @export
 */
export const HangfireMonitorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options: any = {}): RequestArgs {
            const localVarPath = `/hangfiremonitor`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HangfireMonitorApi - functional programming interface
 * @export
 */
export const HangfireMonitorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HangfireMonitorApiAxiosParamCreator(configuration).getWorkers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HangfireMonitorApi - factory interface
 * @export
 */
export const HangfireMonitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkers(options?: any): AxiosPromise<void> {
            return HangfireMonitorApiFp(configuration).getWorkers(options)(axios, basePath);
        },
    };
};

/**
 * HangfireMonitorApi - object-oriented interface
 * @export
 * @class HangfireMonitorApi
 * @extends {BaseAPI}
 */
export class HangfireMonitorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HangfireMonitorApi
     */
    public getWorkers(options?: any) {
        return HangfireMonitorApiFp(this.configuration).getWorkers(options)(this.axios, this.basePath);
    }

}


/**
 * InsightsApi - axios parameter creator
 * @export
 */
export const InsightsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsight(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInsight.');
            }
            const localVarPath = `/insights/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsights(key?: string, top?: number, options: any = {}): RequestArgs {
            const localVarPath = `/insights`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [tags] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsightsByTag(key?: string, tags?: string, top?: number, options: any = {}): RequestArgs {
            const localVarPath = `/insights/by-tags`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInsights(options: any = {}): RequestArgs {
            const localVarPath = `/insights/my-insights`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsightsApi - functional programming interface
 * @export
 */
export const InsightsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsight(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Insight> {
            const localVarAxiosArgs = InsightsApiAxiosParamCreator(configuration).getInsight(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsights(key?: string, top?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Insight>> {
            const localVarAxiosArgs = InsightsApiAxiosParamCreator(configuration).getInsights(key, top, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [tags] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsightsByTag(key?: string, tags?: string, top?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Insight>> {
            const localVarAxiosArgs = InsightsApiAxiosParamCreator(configuration).getInsightsByTag(key, tags, top, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInsights(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Insight>> {
            const localVarAxiosArgs = InsightsApiAxiosParamCreator(configuration).getMyInsights(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InsightsApi - factory interface
 * @export
 */
export const InsightsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsight(id: string, options?: any): AxiosPromise<Insight> {
            return InsightsApiFp(configuration).getInsight(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsights(key?: string, top?: number, options?: any): AxiosPromise<Array<Insight>> {
            return InsightsApiFp(configuration).getInsights(key, top, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [key] 
         * @param {string} [tags] 
         * @param {number} [top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsightsByTag(key?: string, tags?: string, top?: number, options?: any): AxiosPromise<Array<Insight>> {
            return InsightsApiFp(configuration).getInsightsByTag(key, tags, top, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInsights(options?: any): AxiosPromise<Array<Insight>> {
            return InsightsApiFp(configuration).getMyInsights(options)(axios, basePath);
        },
    };
};

/**
 * InsightsApi - object-oriented interface
 * @export
 * @class InsightsApi
 * @extends {BaseAPI}
 */
export class InsightsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsApi
     */
    public getInsight(id: string, options?: any) {
        return InsightsApiFp(this.configuration).getInsight(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [key] 
     * @param {number} [top] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsApi
     */
    public getInsights(key?: string, top?: number, options?: any) {
        return InsightsApiFp(this.configuration).getInsights(key, top, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [key] 
     * @param {string} [tags] 
     * @param {number} [top] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsApi
     */
    public getInsightsByTag(key?: string, tags?: string, top?: number, options?: any) {
        return InsightsApiFp(this.configuration).getInsightsByTag(key, tags, top, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsightsApi
     */
    public getMyInsights(options?: any) {
        return InsightsApiFp(this.configuration).getMyInsights(options)(this.axios, this.basePath);
    }

}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RegisterPushDto} [registerPushDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNotification(registerPushDto?: RegisterPushDto, options: any = {}): RequestArgs {
            const localVarPath = `/notification/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registerPushDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registerPushDto !== undefined ? registerPushDto : {}) : (registerPushDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [handle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unRegisterNotification(handle?: string, options: any = {}): RequestArgs {
            const localVarPath = `/notification/unregister`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (handle !== undefined) {
                localVarQueryParameter['handle'] = handle;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RegisterPushDto} [registerPushDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNotification(registerPushDto?: RegisterPushDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NotificationApiAxiosParamCreator(configuration).registerNotification(registerPushDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [handle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unRegisterNotification(handle?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = NotificationApiAxiosParamCreator(configuration).unRegisterNotification(handle, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {RegisterPushDto} [registerPushDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNotification(registerPushDto?: RegisterPushDto, options?: any): AxiosPromise<void> {
            return NotificationApiFp(configuration).registerNotification(registerPushDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [handle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unRegisterNotification(handle?: string, options?: any): AxiosPromise<void> {
            return NotificationApiFp(configuration).unRegisterNotification(handle, options)(axios, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {RegisterPushDto} [registerPushDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public registerNotification(registerPushDto?: RegisterPushDto, options?: any) {
        return NotificationApiFp(this.configuration).registerNotification(registerPushDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [handle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public unRegisterNotification(handle?: string, options?: any) {
        return NotificationApiFp(this.configuration).unRegisterNotification(handle, options)(this.axios, this.basePath);
    }

}


/**
 * OEmbedApi - axios parameter creator
 * @export
 */
export const OEmbedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {string} [format] 
         * @param {number} [maxWidth] 
         * @param {number} [maxHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOEmbedSingleEpisodeAsync(url?: string, format?: string, maxWidth?: number, maxHeight?: number, options: any = {}): RequestArgs {
            const localVarPath = `/oembed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OEmbedApi - functional programming interface
 * @export
 */
export const OEmbedApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {string} [format] 
         * @param {number} [maxWidth] 
         * @param {number} [maxHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOEmbedSingleEpisodeAsync(url?: string, format?: string, maxWidth?: number, maxHeight?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OEmbedApiAxiosParamCreator(configuration).getOEmbedSingleEpisodeAsync(url, format, maxWidth, maxHeight, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OEmbedApi - factory interface
 * @export
 */
export const OEmbedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {string} [format] 
         * @param {number} [maxWidth] 
         * @param {number} [maxHeight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOEmbedSingleEpisodeAsync(url?: string, format?: string, maxWidth?: number, maxHeight?: number, options?: any): AxiosPromise<void> {
            return OEmbedApiFp(configuration).getOEmbedSingleEpisodeAsync(url, format, maxWidth, maxHeight, options)(axios, basePath);
        },
    };
};

/**
 * OEmbedApi - object-oriented interface
 * @export
 * @class OEmbedApi
 * @extends {BaseAPI}
 */
export class OEmbedApi extends BaseAPI {
    /**
     * 
     * @param {string} [url] 
     * @param {string} [format] 
     * @param {number} [maxWidth] 
     * @param {number} [maxHeight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OEmbedApi
     */
    public getOEmbedSingleEpisodeAsync(url?: string, format?: string, maxWidth?: number, maxHeight?: number, options?: any) {
        return OEmbedApiFp(this.configuration).getOEmbedSingleEpisodeAsync(url, format, maxWidth, maxHeight, options)(this.axios, this.basePath);
    }

}


/**
 * PodcastFeedApi - axios parameter creator
 * @export
 */
export const PodcastFeedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed(token: string, storyAlias?: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling generateRssFeed.');
            }
            const localVarPath = `/podcastfeed/{token}.xml`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (storyAlias !== undefined) {
                localVarQueryParameter['storyAlias'] = storyAlias;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory(token: string, storyAlias: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling generateRssFeedForStory.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling generateRssFeedForStory.');
            }
            const localVarPath = `/podcastfeed/{token}/{storyAlias}.xml`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory_1(token: string, storyAlias: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling generateRssFeedForStory_1.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling generateRssFeedForStory_1.');
            }
            const localVarPath = `/podcastfeed/{token}/{storyAlias}.xml`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed_2(token: string, storyAlias?: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling generateRssFeed_2.');
            }
            const localVarPath = `/podcastfeed/{token}.xml`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (storyAlias !== undefined) {
                localVarQueryParameter['storyAlias'] = storyAlias;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEpisodeAsync(token: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getSingleEpisodeAsync.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling getSingleEpisodeAsync.');
            }
            const localVarPath = `/podcastfeed/{token}/directlink/{snapshotKey}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePodcastStationAsync(token: string, storyAlias: string, t?: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getSinglePodcastStationAsync.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getSinglePodcastStationAsync.');
            }
            const localVarPath = `/podcastfeed/{token}/{storyAlias}/info`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (t !== undefined) {
                localVarQueryParameter['t'] = t;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PodcastFeedApi - functional programming interface
 * @export
 */
export const PodcastFeedApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed(token: string, storyAlias?: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).generateRssFeed(token, storyAlias, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory(token: string, storyAlias: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).generateRssFeedForStory(token, storyAlias, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory_1(token: string, storyAlias: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).generateRssFeedForStory_1(token, storyAlias, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed_2(token: string, storyAlias?: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).generateRssFeed_2(token, storyAlias, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEpisodeAsync(token: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).getSingleEpisodeAsync(token, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePodcastStationAsync(token: string, storyAlias: string, t?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PodcastFeedApiAxiosParamCreator(configuration).getSinglePodcastStationAsync(token, storyAlias, t, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PodcastFeedApi - factory interface
 * @export
 */
export const PodcastFeedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed(token: string, storyAlias?: string, t?: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).generateRssFeed(token, storyAlias, t, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory(token: string, storyAlias: string, t?: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).generateRssFeedForStory(token, storyAlias, t, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeedForStory_1(token: string, storyAlias: string, t?: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).generateRssFeedForStory_1(token, storyAlias, t, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [storyAlias] 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRssFeed_2(token: string, storyAlias?: string, t?: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).generateRssFeed_2(token, storyAlias, t, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleEpisodeAsync(token: string, snapshotKey: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).getSingleEpisodeAsync(token, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} storyAlias 
         * @param {string} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePodcastStationAsync(token: string, storyAlias: string, t?: string, options?: any): AxiosPromise<void> {
            return PodcastFeedApiFp(configuration).getSinglePodcastStationAsync(token, storyAlias, t, options)(axios, basePath);
        },
    };
};

/**
 * PodcastFeedApi - object-oriented interface
 * @export
 * @class PodcastFeedApi
 * @extends {BaseAPI}
 */
export class PodcastFeedApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {string} [storyAlias] 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public generateRssFeed(token: string, storyAlias?: string, t?: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).generateRssFeed(token, storyAlias, t, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} storyAlias 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public generateRssFeedForStory(token: string, storyAlias: string, t?: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).generateRssFeedForStory(token, storyAlias, t, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} storyAlias 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public generateRssFeedForStory_1(token: string, storyAlias: string, t?: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).generateRssFeedForStory_1(token, storyAlias, t, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [storyAlias] 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public generateRssFeed_2(token: string, storyAlias?: string, t?: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).generateRssFeed_2(token, storyAlias, t, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public getSingleEpisodeAsync(token: string, snapshotKey: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).getSingleEpisodeAsync(token, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {string} storyAlias 
     * @param {string} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodcastFeedApi
     */
    public getSinglePodcastStationAsync(token: string, storyAlias: string, t?: string, options?: any) {
        return PodcastFeedApiFp(this.configuration).getSinglePodcastStationAsync(token, storyAlias, t, options)(this.axios, this.basePath);
    }

}


/**
 * PushApi - axios parameter creator
 * @export
 */
export const PushApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {string} [rawjson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPushRequestAsync(token?: string, stream?: string, rawjson?: string, options: any = {}): RequestArgs {
            const localVarPath = `/push`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (stream !== undefined) {
                localVarQueryParameter['stream'] = stream;
            }

            if (rawjson !== undefined) {
                localVarQueryParameter['rawjson'] = rawjson;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushByGetRequest(token?: string, stream?: string, options: any = {}): RequestArgs {
            const localVarPath = `/push`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (stream !== undefined) {
                localVarQueryParameter['stream'] = stream;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushApi - functional programming interface
 * @export
 */
export const PushApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {string} [rawjson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPushRequestAsync(token?: string, stream?: string, rawjson?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = PushApiAxiosParamCreator(configuration).postPushRequestAsync(token, stream, rawjson, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushByGetRequest(token?: string, stream?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = PushApiAxiosParamCreator(configuration).pushByGetRequest(token, stream, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PushApi - factory interface
 * @export
 */
export const PushApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {string} [rawjson] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPushRequestAsync(token?: string, stream?: string, rawjson?: string, options?: any): AxiosPromise<StatusResultDto> {
            return PushApiFp(configuration).postPushRequestAsync(token, stream, rawjson, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushByGetRequest(token?: string, stream?: string, options?: any): AxiosPromise<StatusResultDto> {
            return PushApiFp(configuration).pushByGetRequest(token, stream, options)(axios, basePath);
        },
    };
};

/**
 * PushApi - object-oriented interface
 * @export
 * @class PushApi
 * @extends {BaseAPI}
 */
export class PushApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {string} [stream] 
     * @param {string} [rawjson] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApi
     */
    public postPushRequestAsync(token?: string, stream?: string, rawjson?: string, options?: any) {
        return PushApiFp(this.configuration).postPushRequestAsync(token, stream, rawjson, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [stream] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApi
     */
    public pushByGetRequest(token?: string, stream?: string, options?: any) {
        return PushApiFp(this.configuration).pushByGetRequest(token, stream, options)(this.axios, this.basePath);
    }

}


/**
 * RssFeedValidatorApi - axios parameter creator
 * @export
 */
export const RssFeedValidatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRssFeed(url?: string, options: any = {}): RequestArgs {
            const localVarPath = `/rssfeedvalidator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RssFeedValidatorApi - functional programming interface
 * @export
 */
export const RssFeedValidatorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRssFeed(url?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RssFeedValidatorResultDto> {
            const localVarAxiosArgs = RssFeedValidatorApiAxiosParamCreator(configuration).getRssFeed(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RssFeedValidatorApi - factory interface
 * @export
 */
export const RssFeedValidatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRssFeed(url?: string, options?: any): AxiosPromise<RssFeedValidatorResultDto> {
            return RssFeedValidatorApiFp(configuration).getRssFeed(url, options)(axios, basePath);
        },
    };
};

/**
 * RssFeedValidatorApi - object-oriented interface
 * @export
 * @class RssFeedValidatorApi
 * @extends {BaseAPI}
 */
export class RssFeedValidatorApi extends BaseAPI {
    /**
     * 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RssFeedValidatorApi
     */
    public getRssFeed(url?: string, options?: any) {
        return RssFeedValidatorApiFp(this.configuration).getRssFeed(url, options)(this.axios, this.basePath);
    }

}


/**
 * SerieApi - axios parameter creator
 * @export
 */
export const SerieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsGet(options: any = {}): RequestArgs {
            const localVarPath = `/serie`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [serie] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsPost(token?: string, serie?: string, httpRequestMessage?: HttpRequestMessage, options: any = {}): RequestArgs {
            const localVarPath = `/serie`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (serie !== undefined) {
                localVarQueryParameter['serie'] = serie;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof httpRequestMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(httpRequestMessage !== undefined ? httpRequestMessage : {}) : (httpRequestMessage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SerieApi - functional programming interface
 * @export
 */
export const SerieApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = SerieApiAxiosParamCreator(configuration).addSerieAsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [serie] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsPost(token?: string, serie?: string, httpRequestMessage?: HttpRequestMessage, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = SerieApiAxiosParamCreator(configuration).addSerieAsPost(token, serie, httpRequestMessage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SerieApi - factory interface
 * @export
 */
export const SerieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsGet(options?: any): AxiosPromise<boolean> {
            return SerieApiFp(configuration).addSerieAsGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [serie] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSerieAsPost(token?: string, serie?: string, httpRequestMessage?: HttpRequestMessage, options?: any): AxiosPromise<boolean> {
            return SerieApiFp(configuration).addSerieAsPost(token, serie, httpRequestMessage, options)(axios, basePath);
        },
    };
};

/**
 * SerieApi - object-oriented interface
 * @export
 * @class SerieApi
 * @extends {BaseAPI}
 */
export class SerieApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SerieApi
     */
    public addSerieAsGet(options?: any) {
        return SerieApiFp(this.configuration).addSerieAsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [serie] 
     * @param {HttpRequestMessage} [httpRequestMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SerieApi
     */
    public addSerieAsPost(token?: string, serie?: string, httpRequestMessage?: HttpRequestMessage, options?: any) {
        return SerieApiFp(this.configuration).addSerieAsPost(token, serie, httpRequestMessage, options)(this.axios, this.basePath);
    }

}


/**
 * SiteTrackerApi - axios parameter creator
 * @export
 */
export const SiteTrackerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(token?: string, stream?: string, httpRequestMessage?: HttpRequestMessage, options: any = {}): RequestArgs {
            const localVarPath = `/sitetracker`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (stream !== undefined) {
                localVarQueryParameter['stream'] = stream;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof httpRequestMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(httpRequestMessage !== undefined ? httpRequestMessage : {}) : (httpRequestMessage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteTrackerApi - functional programming interface
 * @export
 */
export const SiteTrackerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(token?: string, stream?: string, httpRequestMessage?: HttpRequestMessage, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = SiteTrackerApiAxiosParamCreator(configuration).post(token, stream, httpRequestMessage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SiteTrackerApi - factory interface
 * @export
 */
export const SiteTrackerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [stream] 
         * @param {HttpRequestMessage} [httpRequestMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(token?: string, stream?: string, httpRequestMessage?: HttpRequestMessage, options?: any): AxiosPromise<boolean> {
            return SiteTrackerApiFp(configuration).post(token, stream, httpRequestMessage, options)(axios, basePath);
        },
    };
};

/**
 * SiteTrackerApi - object-oriented interface
 * @export
 * @class SiteTrackerApi
 * @extends {BaseAPI}
 */
export class SiteTrackerApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {string} [stream] 
     * @param {HttpRequestMessage} [httpRequestMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTrackerApi
     */
    public post(token?: string, stream?: string, httpRequestMessage?: HttpRequestMessage, options?: any) {
        return SiteTrackerApiFp(this.configuration).post(token, stream, httpRequestMessage, options)(this.axios, this.basePath);
    }

}


/**
 * SourcesApi - axios parameter creator
 * @export
 */
export const SourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSources(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/sources/$available`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSourcesGrouped(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/sources/$available-grouped`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesByAccount(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/sources`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamsBySource(sourceAlias: string, tenantId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sourceAlias' is not null or undefined
            if (sourceAlias === null || sourceAlias === undefined) {
                throw new RequiredError('sourceAlias','Required parameter sourceAlias was null or undefined when calling getStreamsBySource.');
            }
            const localVarPath = `/sources/{sourceAlias}/streams`
                .replace(`{${"sourceAlias"}}`, encodeURIComponent(String(sourceAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourcesApi - functional programming interface
 * @export
 */
export const SourcesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSources(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceInGroupDto>> {
            const localVarAxiosArgs = SourcesApiAxiosParamCreator(configuration).getAvailableSources(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSourcesGrouped(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceGroupDto>> {
            const localVarAxiosArgs = SourcesApiAxiosParamCreator(configuration).getAvailableSourcesGrouped(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesByAccount(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceSummaryDto>> {
            const localVarAxiosArgs = SourcesApiAxiosParamCreator(configuration).getSourcesByAccount(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sourceAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamsBySource(sourceAlias: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataStreamSummaryDto>> {
            const localVarAxiosArgs = SourcesApiAxiosParamCreator(configuration).getStreamsBySource(sourceAlias, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SourcesApi - factory interface
 * @export
 */
export const SourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSources(tenantId?: string, options?: any): AxiosPromise<Array<SourceInGroupDto>> {
            return SourcesApiFp(configuration).getAvailableSources(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSourcesGrouped(tenantId?: string, options?: any): AxiosPromise<Array<SourceGroupDto>> {
            return SourcesApiFp(configuration).getAvailableSourcesGrouped(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesByAccount(tenantId?: string, options?: any): AxiosPromise<Array<SourceSummaryDto>> {
            return SourcesApiFp(configuration).getSourcesByAccount(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sourceAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamsBySource(sourceAlias: string, tenantId?: string, options?: any): AxiosPromise<Array<DataStreamSummaryDto>> {
            return SourcesApiFp(configuration).getStreamsBySource(sourceAlias, tenantId, options)(axios, basePath);
        },
    };
};

/**
 * SourcesApi - object-oriented interface
 * @export
 * @class SourcesApi
 * @extends {BaseAPI}
 */
export class SourcesApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getAvailableSources(tenantId?: string, options?: any) {
        return SourcesApiFp(this.configuration).getAvailableSources(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getAvailableSourcesGrouped(tenantId?: string, options?: any) {
        return SourcesApiFp(this.configuration).getAvailableSourcesGrouped(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourcesByAccount(tenantId?: string, options?: any) {
        return SourcesApiFp(this.configuration).getSourcesByAccount(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sourceAlias 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getStreamsBySource(sourceAlias: string, tenantId?: string, options?: any) {
        return SourcesApiFp(this.configuration).getStreamsBySource(sourceAlias, tenantId, options)(this.axios, this.basePath);
    }

}


/**
 * StoryApi - axios parameter creator
 * @export
 */
export const StoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStreamToStory(storyAlias: string, streamAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling addStreamToStory.');
            }
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling addStreamToStory.');
            }
            const localVarPath = `/story/{storyAlias}/$add-stream/{streamAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBgJob(jobId: string, storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling cancelBgJob.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling cancelBgJob.');
            }
            const localVarPath = `/story/{storyAlias}/$cancel-job/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oldStoryAlias 
         * @param {string} [newSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSlug(oldStoryAlias: string, newSlug?: string, options: any = {}): RequestArgs {
            // verify required parameter 'oldStoryAlias' is not null or undefined
            if (oldStoryAlias === null || oldStoryAlias === undefined) {
                throw new RequiredError('oldStoryAlias','Required parameter oldStoryAlias was null or undefined when calling changeSlug.');
            }
            const localVarPath = `/story/{oldStoryAlias}/$change-slug`
                .replace(`{${"oldStoryAlias"}}`, encodeURIComponent(String(oldStoryAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (newSlug !== undefined) {
                localVarQueryParameter['newSlug'] = newSlug;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromStoryAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [cloneSubStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStory(fromStoryAlias: string, tenantId?: string, cloneSubStories?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'fromStoryAlias' is not null or undefined
            if (fromStoryAlias === null || fromStoryAlias === undefined) {
                throw new RequiredError('fromStoryAlias','Required parameter fromStoryAlias was null or undefined when calling cloneStory.');
            }
            const localVarPath = `/story/{fromStoryAlias}/$clone`
                .replace(`{${"fromStoryAlias"}}`, encodeURIComponent(String(fromStoryAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (cloneSubStories !== undefined) {
                localVarQueryParameter['cloneSubStories'] = cloneSubStories;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStoryDto} [createOrUpdateStoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateStory(createOrUpdateStoryDto?: CreateOrUpdateStoryDto, options: any = {}): RequestArgs {
            const localVarPath = `/story`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateStoryDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateStoryDto !== undefined ? createOrUpdateStoryDto : {}) : (createOrUpdateStoryDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [alias] 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStory(tenantId?: string, alias?: string, title?: string, description?: string, content?: string, options: any = {}): RequestArgs {
            const localVarPath = `/story/create2`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling deleteSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling deleteSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling deleteStory.');
            }
            const localVarPath = `/story/{storyAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesStoryExist(storyAlias: string, tenantId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling doesStoryExist.');
            }
            const localVarPath = `/story/{storyAlias}/$exist`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSnapshotVoice(snapshotKey: string, storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling downloadSnapshotVoice.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling downloadSnapshotVoice.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/$download-voice`
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVoice(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling downloadVoice.');
            }
            const localVarPath = `/story/{storyAlias}/$download-voice`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVoice(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling generateVoice.');
            }
            const localVarPath = `/story/{storyAlias}/$generate-voice`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputs(storyAlias: string, cid?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getInputs.');
            }
            const localVarPath = `/story/{storyAlias}/inputs`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {boolean} [onlyPublished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshot(storyAlias: string, onlyPublished?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getLatestSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/latest-snapshot`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (onlyPublished !== undefined) {
                localVarQueryParameter['onlyPublished'] = onlyPublished;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshotSummary(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getLatestSnapshotSummary.');
            }
            const localVarPath = `/story/{storyAlias}/latest-snapshot-summary`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling getSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotsWhichUseThisSnapshot(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getSnapshotsWhichUseThisSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling getSnapshotsWhichUseThisSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/used-by`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [mode] 
         * @param {string} [query] 
         * @param {number} [top] 
         * @param {boolean} [includeDndStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoriesByTenant(tenantId: string, mode?: string, query?: string, top?: number, includeDndStories?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getStoriesByTenant.');
            }
            const localVarPath = `/story/$by-tenant/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (top !== undefined) {
                localVarQueryParameter['top'] = top;
            }

            if (includeDndStories !== undefined) {
                localVarQueryParameter['includeDndStories'] = includeDndStories;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStory(storyAlias: string, tenantId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStory.');
            }
            const localVarPath = `/story/{storyAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [inputsYaml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryContent(storyAlias: string, tenantId?: string, inputsYaml?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStoryContent.');
            }
            const localVarPath = `/story/{storyAlias}/content`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (inputsYaml !== undefined) {
                localVarQueryParameter['inputsYaml'] = inputsYaml;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySetting(storyAlias: string, settingAlias: string, tenantId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStorySetting.');
            }
            // verify required parameter 'settingAlias' is not null or undefined
            if (settingAlias === null || settingAlias === undefined) {
                throw new RequiredError('settingAlias','Required parameter settingAlias was null or undefined when calling getStorySetting.');
            }
            const localVarPath = `/story/{storyAlias}/settings/{settingAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"settingAlias"}}`, encodeURIComponent(String(settingAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySnapshots(storyAlias: string, maxCount?: number, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStorySnapshots.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (maxCount !== undefined) {
                localVarQueryParameter['maxCount'] = maxCount;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamSummaries(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStreamSummaries.');
            }
            const localVarPath = `/story/{storyAlias}/streamsummaries`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreams(storyAlias: string, cid?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getStreams.');
            }
            const localVarPath = `/story/{storyAlias}/streams`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options: any = {}): RequestArgs {
            const localVarPath = `/story/templates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling getVersions.');
            }
            const localVarPath = `/story/{storyAlias}/versions`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [snapshotKey] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVoiceAvailable(storyAlias: string, snapshotKey?: string, token?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling isVoiceAvailable.');
            }
            const localVarPath = `/story/{storyAlias}/$is-voice-available`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (snapshotKey !== undefined) {
                localVarQueryParameter['snapshotKey'] = snapshotKey;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {SnapshotPatchInputDto} [snapshotPatchInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSnapshot(storyAlias: string, snapshotKey: string, snapshotPatchInputDto?: SnapshotPatchInputDto, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling patchSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling patchSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof snapshotPatchInputDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(snapshotPatchInputDto !== undefined ? snapshotPatchInputDto : {}) : (snapshotPatchInputDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {SaveContentDto} [saveContentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStoryContent(storyAlias: string, saveContentDto?: SaveContentDto, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling patchStoryContent.');
            }
            const localVarPath = `/story/{storyAlias}/content`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof saveContentDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(saveContentDto !== undefined ? saveContentDto : {}) : (saveContentDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInputs(storyAlias: string, cid?: string, requestBody?: { [key: string]: object; }, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling postInputs.');
            }
            const localVarPath = `/story/{storyAlias}/inputs/post`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [publishTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishAsPodcastEpisode(storyAlias: string, snapshotKey: string, publishTimestamp?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling publishAsPodcastEpisode.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling publishAsPodcastEpisode.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/$publish`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (publishTimestamp !== undefined) {
                localVarQueryParameter['publishTimestamp'] = (publishTimestamp as any instanceof Date) ?
                    (publishTimestamp as any).toISOString() :
                    publishTimestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamToStory(storyAlias: string, streamAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling removeStreamToStory.');
            }
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling removeStreamToStory.');
            }
            const localVarPath = `/story/{storyAlias}/streams/{streamAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} storyVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVersion(storyAlias: string, storyVersionId: number, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling restoreVersion.');
            }
            // verify required parameter 'storyVersionId' is not null or undefined
            if (storyVersionId === null || storyVersionId === undefined) {
                throw new RequiredError('storyVersionId','Required parameter storyVersionId was null or undefined when calling restoreVersion.');
            }
            const localVarPath = `/story/{storyAlias}/versions/{storyVersionId}/restore`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"storyVersionId"}}`, encodeURIComponent(String(storyVersionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsChatMessage(storyAlias: string, channel?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling sendAsChatMessage.');
            }
            const localVarPath = `/story/{storyAlias}/$send-chatmessage`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsEmail(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling sendAsEmail.');
            }
            const localVarPath = `/story/{storyAlias}/$send-email`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {string} [toEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSnapshotAsEmail(snapshotKey: string, storyAlias: string, toEmail?: string, options: any = {}): RequestArgs {
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling sendSnapshotAsEmail.');
            }
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling sendSnapshotAsEmail.');
            }
            const localVarPath = `/story/{storyAlias}/{snapshotKey}/$send-email`
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)))
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (toEmail !== undefined) {
                localVarQueryParameter['toEmail'] = toEmail;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDisabled(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling setAsDisabled.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling setAsDisabled.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/$disabled`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsEnabled(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling setAsEnabled.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling setAsEnabled.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/$enabled`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {Destination} [destination] 
         * @param {boolean} [bgJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshot(storyAlias: string, snapshotKey: string, destination?: Destination, bgJob?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling triggerSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling triggerSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/trigger`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (destination !== undefined) {
                localVarQueryParameter['destination'] = destination;
            }

            if (bgJob !== undefined) {
                localVarQueryParameter['bgJob'] = bgJob;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {boolean} [bgJob] 
         * @param {Array<Destination>} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshotAsPost(storyAlias: string, snapshotKey: string, bgJob?: boolean, destination?: Array<Destination>, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling triggerSnapshotAsPost.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling triggerSnapshotAsPost.');
            }
            const localVarPath = `/story/{storyAlias}/snapshots/{snapshotKey}/trigger`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (bgJob !== undefined) {
                localVarQueryParameter['bgJob'] = bgJob;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof destination !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(destination !== undefined ? destination : {}) : (destination || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStory(storyAlias: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling triggerStory.');
            }
            const localVarPath = `/story/{storyAlias}/$trigger`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [audioUri] 
         * @param {number} [fileSize] 
         * @param {number} [durationSecs] 
         * @param {boolean} [optimize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudioOnSnapshot(storyAlias: string, snapshotKey: string, audioUri?: string, fileSize?: number, durationSecs?: number, optimize?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling updateAudioOnSnapshot.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling updateAudioOnSnapshot.');
            }
            const localVarPath = `/story/{storyAlias}/{snapshotKey}/$set-audio`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (audioUri !== undefined) {
                localVarQueryParameter['audioUri'] = audioUri;
            }

            if (fileSize !== undefined) {
                localVarQueryParameter['fileSize'] = fileSize;
            }

            if (durationSecs !== undefined) {
                localVarQueryParameter['durationSecs'] = durationSecs;
            }

            if (optimize !== undefined) {
                localVarQueryParameter['optimize'] = optimize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {SettingPublicDto} [settingPublicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorySetting(storyAlias: string, settingAlias: string, settingPublicDto?: SettingPublicDto, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling updateStorySetting.');
            }
            // verify required parameter 'settingAlias' is not null or undefined
            if (settingAlias === null || settingAlias === undefined) {
                throw new RequiredError('settingAlias','Required parameter settingAlias was null or undefined when calling updateStorySetting.');
            }
            const localVarPath = `/story/{storyAlias}/settings/{settingAlias}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"settingAlias"}}`, encodeURIComponent(String(settingAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof settingPublicDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(settingPublicDto !== undefined ? settingPublicDto : {}) : (settingPublicDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoryApi - functional programming interface
 * @export
 */
export const StoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStreamToStory(storyAlias: string, streamAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).addStreamToStory(storyAlias, streamAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBgJob(jobId: string, storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).cancelBgJob(jobId, storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oldStoryAlias 
         * @param {string} [newSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSlug(oldStoryAlias: string, newSlug?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).changeSlug(oldStoryAlias, newSlug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fromStoryAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [cloneSubStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStory(fromStoryAlias: string, tenantId?: string, cloneSubStories?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).cloneStory(fromStoryAlias, tenantId, cloneSubStories, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStoryDto} [createOrUpdateStoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateStory(createOrUpdateStoryDto?: CreateOrUpdateStoryDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).createOrUpdateStory(createOrUpdateStoryDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [alias] 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStory(tenantId?: string, alias?: string, title?: string, description?: string, content?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).createStory(tenantId, alias, title, description, content, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).deleteSnapshot(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).deleteStory(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesStoryExist(storyAlias: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).doesStoryExist(storyAlias, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSnapshotVoice(snapshotKey: string, storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).downloadSnapshotVoice(snapshotKey, storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVoice(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).downloadVoice(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVoice(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).generateVoice(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputs(storyAlias: string, cid?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getInputs(storyAlias, cid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {boolean} [onlyPublished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshot(storyAlias: string, onlyPublished?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getLatestSnapshot(storyAlias, onlyPublished, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshotSummary(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotSummaryDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getLatestSnapshotSummary(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getSnapshot(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotsWhichUseThisSnapshot(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SnapshotSummaryDto>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getSnapshotsWhichUseThisSnapshot(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [mode] 
         * @param {string} [query] 
         * @param {number} [top] 
         * @param {boolean} [includeDndStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoriesByTenant(tenantId: string, mode?: string, query?: string, top?: number, includeDndStories?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorySummaryDto>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStoriesByTenant(tenantId, mode, query, top, includeDndStories, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStory(storyAlias: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStory(storyAlias, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [inputsYaml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryContent(storyAlias: string, tenantId?: string, inputsYaml?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContentDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStoryContent(storyAlias, tenantId, inputsYaml, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySetting(storyAlias: string, settingAlias: string, tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingPublicDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStorySetting(storyAlias, settingAlias, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySnapshots(storyAlias: string, maxCount?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SnapshotSummaryDto>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStorySnapshots(storyAlias, maxCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamSummaries(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamSummariesDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStreamSummaries(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreams(storyAlias: string, cid?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getStreams(storyAlias, cid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoryTemplateDto>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getTemplates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoryVersionDto>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).getVersions(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [snapshotKey] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVoiceAvailable(storyAlias: string, snapshotKey?: string, token?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).isVoiceAvailable(storyAlias, snapshotKey, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {SnapshotPatchInputDto} [snapshotPatchInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSnapshot(storyAlias: string, snapshotKey: string, snapshotPatchInputDto?: SnapshotPatchInputDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SnapshotDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).patchSnapshot(storyAlias, snapshotKey, snapshotPatchInputDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {SaveContentDto} [saveContentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStoryContent(storyAlias: string, saveContentDto?: SaveContentDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).patchStoryContent(storyAlias, saveContentDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInputs(storyAlias: string, cid?: string, requestBody?: { [key: string]: object; }, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StringObjectKeyValuePair>> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).postInputs(storyAlias, cid, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [publishTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishAsPodcastEpisode(storyAlias: string, snapshotKey: string, publishTimestamp?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).publishAsPodcastEpisode(storyAlias, snapshotKey, publishTimestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamToStory(storyAlias: string, streamAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).removeStreamToStory(storyAlias, streamAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} storyVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVersion(storyAlias: string, storyVersionId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).restoreVersion(storyAlias, storyVersionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsChatMessage(storyAlias: string, channel?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).sendAsChatMessage(storyAlias, channel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsEmail(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).sendAsEmail(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {string} [toEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSnapshotAsEmail(snapshotKey: string, storyAlias: string, toEmail?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).sendSnapshotAsEmail(snapshotKey, storyAlias, toEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDisabled(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).setAsDisabled(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsEnabled(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).setAsEnabled(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {Destination} [destination] 
         * @param {boolean} [bgJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshot(storyAlias: string, snapshotKey: string, destination?: Destination, bgJob?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).triggerSnapshot(storyAlias, snapshotKey, destination, bgJob, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {boolean} [bgJob] 
         * @param {Array<Destination>} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshotAsPost(storyAlias: string, snapshotKey: string, bgJob?: boolean, destination?: Array<Destination>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).triggerSnapshotAsPost(storyAlias, snapshotKey, bgJob, destination, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStory(storyAlias: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).triggerStory(storyAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [audioUri] 
         * @param {number} [fileSize] 
         * @param {number} [durationSecs] 
         * @param {boolean} [optimize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudioOnSnapshot(storyAlias: string, snapshotKey: string, audioUri?: string, fileSize?: number, durationSecs?: number, optimize?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).updateAudioOnSnapshot(storyAlias, snapshotKey, audioUri, fileSize, durationSecs, optimize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {SettingPublicDto} [settingPublicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorySetting(storyAlias: string, settingAlias: string, settingPublicDto?: SettingPublicDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StoryApiAxiosParamCreator(configuration).updateStorySetting(storyAlias, settingAlias, settingPublicDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StoryApi - factory interface
 * @export
 */
export const StoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStreamToStory(storyAlias: string, streamAlias: string, options?: any): AxiosPromise<boolean> {
            return StoryApiFp(configuration).addStreamToStory(storyAlias, streamAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} jobId 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBgJob(jobId: string, storyAlias: string, options?: any): AxiosPromise<object> {
            return StoryApiFp(configuration).cancelBgJob(jobId, storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oldStoryAlias 
         * @param {string} [newSlug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSlug(oldStoryAlias: string, newSlug?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).changeSlug(oldStoryAlias, newSlug, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} fromStoryAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [cloneSubStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStory(fromStoryAlias: string, tenantId?: string, cloneSubStories?: boolean, options?: any): AxiosPromise<CloneResultDto> {
            return StoryApiFp(configuration).cloneStory(fromStoryAlias, tenantId, cloneSubStories, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateOrUpdateStoryDto} [createOrUpdateStoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateStory(createOrUpdateStoryDto?: CreateOrUpdateStoryDto, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).createOrUpdateStory(createOrUpdateStoryDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [alias] 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStory(tenantId?: string, alias?: string, title?: string, description?: string, content?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).createStory(tenantId, alias, title, description, content, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnapshot(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<boolean> {
            return StoryApiFp(configuration).deleteSnapshot(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStory(storyAlias: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).deleteStory(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesStoryExist(storyAlias: string, tenantId?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).doesStoryExist(storyAlias, tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSnapshotVoice(snapshotKey: string, storyAlias: string, options?: any): AxiosPromise<string> {
            return StoryApiFp(configuration).downloadSnapshotVoice(snapshotKey, storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVoice(storyAlias: string, options?: any): AxiosPromise<string> {
            return StoryApiFp(configuration).downloadVoice(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateVoice(storyAlias: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).generateVoice(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputs(storyAlias: string, cid?: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return StoryApiFp(configuration).getInputs(storyAlias, cid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {boolean} [onlyPublished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshot(storyAlias: string, onlyPublished?: boolean, options?: any): AxiosPromise<SnapshotDto> {
            return StoryApiFp(configuration).getLatestSnapshot(storyAlias, onlyPublished, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestSnapshotSummary(storyAlias: string, options?: any): AxiosPromise<SnapshotSummaryDto> {
            return StoryApiFp(configuration).getLatestSnapshotSummary(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshot(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<SnapshotDto> {
            return StoryApiFp(configuration).getSnapshot(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotsWhichUseThisSnapshot(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<Array<SnapshotSummaryDto>> {
            return StoryApiFp(configuration).getSnapshotsWhichUseThisSnapshot(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [mode] 
         * @param {string} [query] 
         * @param {number} [top] 
         * @param {boolean} [includeDndStories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoriesByTenant(tenantId: string, mode?: string, query?: string, top?: number, includeDndStories?: boolean, options?: any): AxiosPromise<Array<StorySummaryDto>> {
            return StoryApiFp(configuration).getStoriesByTenant(tenantId, mode, query, top, includeDndStories, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStory(storyAlias: string, tenantId?: string, options?: any): AxiosPromise<StoryDto> {
            return StoryApiFp(configuration).getStory(storyAlias, tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [tenantId] 
         * @param {boolean} [inputsYaml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryContent(storyAlias: string, tenantId?: string, inputsYaml?: boolean, options?: any): AxiosPromise<GetContentDto> {
            return StoryApiFp(configuration).getStoryContent(storyAlias, tenantId, inputsYaml, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySetting(storyAlias: string, settingAlias: string, tenantId?: string, options?: any): AxiosPromise<SettingPublicDto> {
            return StoryApiFp(configuration).getStorySetting(storyAlias, settingAlias, tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySnapshots(storyAlias: string, maxCount?: number, options?: any): AxiosPromise<Array<SnapshotSummaryDto>> {
            return StoryApiFp(configuration).getStorySnapshots(storyAlias, maxCount, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreamSummaries(storyAlias: string, options?: any): AxiosPromise<StreamSummariesDto> {
            return StoryApiFp(configuration).getStreamSummaries(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStreams(storyAlias: string, cid?: string, options?: any): AxiosPromise<{ [key: string]: object; }> {
            return StoryApiFp(configuration).getStreams(storyAlias, cid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any): AxiosPromise<Array<StoryTemplateDto>> {
            return StoryApiFp(configuration).getTemplates(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(storyAlias: string, options?: any): AxiosPromise<Array<StoryVersionDto>> {
            return StoryApiFp(configuration).getVersions(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [snapshotKey] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVoiceAvailable(storyAlias: string, snapshotKey?: string, token?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).isVoiceAvailable(storyAlias, snapshotKey, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {SnapshotPatchInputDto} [snapshotPatchInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSnapshot(storyAlias: string, snapshotKey: string, snapshotPatchInputDto?: SnapshotPatchInputDto, options?: any): AxiosPromise<SnapshotDto> {
            return StoryApiFp(configuration).patchSnapshot(storyAlias, snapshotKey, snapshotPatchInputDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {SaveContentDto} [saveContentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStoryContent(storyAlias: string, saveContentDto?: SaveContentDto, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).patchStoryContent(storyAlias, saveContentDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [cid] 
         * @param {{ [key: string]: object; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInputs(storyAlias: string, cid?: string, requestBody?: { [key: string]: object; }, options?: any): AxiosPromise<Array<StringObjectKeyValuePair>> {
            return StoryApiFp(configuration).postInputs(storyAlias, cid, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [publishTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishAsPodcastEpisode(storyAlias: string, snapshotKey: string, publishTimestamp?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).publishAsPodcastEpisode(storyAlias, snapshotKey, publishTimestamp, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} streamAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamToStory(storyAlias: string, streamAlias: string, options?: any): AxiosPromise<boolean> {
            return StoryApiFp(configuration).removeStreamToStory(storyAlias, streamAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {number} storyVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreVersion(storyAlias: string, storyVersionId: number, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).restoreVersion(storyAlias, storyVersionId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsChatMessage(storyAlias: string, channel?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).sendAsChatMessage(storyAlias, channel, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAsEmail(storyAlias: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).sendAsEmail(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} snapshotKey 
         * @param {string} storyAlias 
         * @param {string} [toEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSnapshotAsEmail(snapshotKey: string, storyAlias: string, toEmail?: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).sendSnapshotAsEmail(snapshotKey, storyAlias, toEmail, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsDisabled(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).setAsDisabled(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsEnabled(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).setAsEnabled(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {Destination} [destination] 
         * @param {boolean} [bgJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshot(storyAlias: string, snapshotKey: string, destination?: Destination, bgJob?: boolean, options?: any): AxiosPromise<TriggerResultDto> {
            return StoryApiFp(configuration).triggerSnapshot(storyAlias, snapshotKey, destination, bgJob, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {boolean} [bgJob] 
         * @param {Array<Destination>} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSnapshotAsPost(storyAlias: string, snapshotKey: string, bgJob?: boolean, destination?: Array<Destination>, options?: any): AxiosPromise<TriggerResultDto> {
            return StoryApiFp(configuration).triggerSnapshotAsPost(storyAlias, snapshotKey, bgJob, destination, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerStory(storyAlias: string, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).triggerStory(storyAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {string} [audioUri] 
         * @param {number} [fileSize] 
         * @param {number} [durationSecs] 
         * @param {boolean} [optimize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAudioOnSnapshot(storyAlias: string, snapshotKey: string, audioUri?: string, fileSize?: number, durationSecs?: number, optimize?: boolean, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).updateAudioOnSnapshot(storyAlias, snapshotKey, audioUri, fileSize, durationSecs, optimize, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} settingAlias 
         * @param {SettingPublicDto} [settingPublicDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorySetting(storyAlias: string, settingAlias: string, settingPublicDto?: SettingPublicDto, options?: any): AxiosPromise<StatusResultDto> {
            return StoryApiFp(configuration).updateStorySetting(storyAlias, settingAlias, settingPublicDto, options)(axios, basePath);
        },
    };
};

/**
 * StoryApi - object-oriented interface
 * @export
 * @class StoryApi
 * @extends {BaseAPI}
 */
export class StoryApi extends BaseAPI {
    /**
     * 
     * @param {string} storyAlias 
     * @param {string} streamAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public addStreamToStory(storyAlias: string, streamAlias: string, options?: any) {
        return StoryApiFp(this.configuration).addStreamToStory(storyAlias, streamAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} jobId 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public cancelBgJob(jobId: string, storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).cancelBgJob(jobId, storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oldStoryAlias 
     * @param {string} [newSlug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public changeSlug(oldStoryAlias: string, newSlug?: string, options?: any) {
        return StoryApiFp(this.configuration).changeSlug(oldStoryAlias, newSlug, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} fromStoryAlias 
     * @param {string} [tenantId] 
     * @param {boolean} [cloneSubStories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public cloneStory(fromStoryAlias: string, tenantId?: string, cloneSubStories?: boolean, options?: any) {
        return StoryApiFp(this.configuration).cloneStory(fromStoryAlias, tenantId, cloneSubStories, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateOrUpdateStoryDto} [createOrUpdateStoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public createOrUpdateStory(createOrUpdateStoryDto?: CreateOrUpdateStoryDto, options?: any) {
        return StoryApiFp(this.configuration).createOrUpdateStory(createOrUpdateStoryDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [alias] 
     * @param {string} [title] 
     * @param {string} [description] 
     * @param {string} [content] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public createStory(tenantId?: string, alias?: string, title?: string, description?: string, content?: string, options?: any) {
        return StoryApiFp(this.configuration).createStory(tenantId, alias, title, description, content, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public deleteSnapshot(storyAlias: string, snapshotKey: string, options?: any) {
        return StoryApiFp(this.configuration).deleteSnapshot(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public deleteStory(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).deleteStory(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public doesStoryExist(storyAlias: string, tenantId?: string, options?: any) {
        return StoryApiFp(this.configuration).doesStoryExist(storyAlias, tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} snapshotKey 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public downloadSnapshotVoice(snapshotKey: string, storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).downloadSnapshotVoice(snapshotKey, storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public downloadVoice(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).downloadVoice(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public generateVoice(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).generateVoice(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [cid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getInputs(storyAlias: string, cid?: string, options?: any) {
        return StoryApiFp(this.configuration).getInputs(storyAlias, cid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {boolean} [onlyPublished] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getLatestSnapshot(storyAlias: string, onlyPublished?: boolean, options?: any) {
        return StoryApiFp(this.configuration).getLatestSnapshot(storyAlias, onlyPublished, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getLatestSnapshotSummary(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).getLatestSnapshotSummary(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getSnapshot(storyAlias: string, snapshotKey: string, options?: any) {
        return StoryApiFp(this.configuration).getSnapshot(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getSnapshotsWhichUseThisSnapshot(storyAlias: string, snapshotKey: string, options?: any) {
        return StoryApiFp(this.configuration).getSnapshotsWhichUseThisSnapshot(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [mode] 
     * @param {string} [query] 
     * @param {number} [top] 
     * @param {boolean} [includeDndStories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStoriesByTenant(tenantId: string, mode?: string, query?: string, top?: number, includeDndStories?: boolean, options?: any) {
        return StoryApiFp(this.configuration).getStoriesByTenant(tenantId, mode, query, top, includeDndStories, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStory(storyAlias: string, tenantId?: string, options?: any) {
        return StoryApiFp(this.configuration).getStory(storyAlias, tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [tenantId] 
     * @param {boolean} [inputsYaml] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStoryContent(storyAlias: string, tenantId?: string, inputsYaml?: boolean, options?: any) {
        return StoryApiFp(this.configuration).getStoryContent(storyAlias, tenantId, inputsYaml, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} settingAlias 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStorySetting(storyAlias: string, settingAlias: string, tenantId?: string, options?: any) {
        return StoryApiFp(this.configuration).getStorySetting(storyAlias, settingAlias, tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {number} [maxCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStorySnapshots(storyAlias: string, maxCount?: number, options?: any) {
        return StoryApiFp(this.configuration).getStorySnapshots(storyAlias, maxCount, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStreamSummaries(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).getStreamSummaries(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [cid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getStreams(storyAlias: string, cid?: string, options?: any) {
        return StoryApiFp(this.configuration).getStreams(storyAlias, cid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getTemplates(options?: any) {
        return StoryApiFp(this.configuration).getTemplates(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public getVersions(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).getVersions(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [snapshotKey] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public isVoiceAvailable(storyAlias: string, snapshotKey?: string, token?: string, options?: any) {
        return StoryApiFp(this.configuration).isVoiceAvailable(storyAlias, snapshotKey, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {SnapshotPatchInputDto} [snapshotPatchInputDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public patchSnapshot(storyAlias: string, snapshotKey: string, snapshotPatchInputDto?: SnapshotPatchInputDto, options?: any) {
        return StoryApiFp(this.configuration).patchSnapshot(storyAlias, snapshotKey, snapshotPatchInputDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {SaveContentDto} [saveContentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public patchStoryContent(storyAlias: string, saveContentDto?: SaveContentDto, options?: any) {
        return StoryApiFp(this.configuration).patchStoryContent(storyAlias, saveContentDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [cid] 
     * @param {{ [key: string]: object; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public postInputs(storyAlias: string, cid?: string, requestBody?: { [key: string]: object; }, options?: any) {
        return StoryApiFp(this.configuration).postInputs(storyAlias, cid, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {string} [publishTimestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public publishAsPodcastEpisode(storyAlias: string, snapshotKey: string, publishTimestamp?: string, options?: any) {
        return StoryApiFp(this.configuration).publishAsPodcastEpisode(storyAlias, snapshotKey, publishTimestamp, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} streamAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public removeStreamToStory(storyAlias: string, streamAlias: string, options?: any) {
        return StoryApiFp(this.configuration).removeStreamToStory(storyAlias, streamAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {number} storyVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public restoreVersion(storyAlias: string, storyVersionId: number, options?: any) {
        return StoryApiFp(this.configuration).restoreVersion(storyAlias, storyVersionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} [channel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public sendAsChatMessage(storyAlias: string, channel?: string, options?: any) {
        return StoryApiFp(this.configuration).sendAsChatMessage(storyAlias, channel, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public sendAsEmail(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).sendAsEmail(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} snapshotKey 
     * @param {string} storyAlias 
     * @param {string} [toEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public sendSnapshotAsEmail(snapshotKey: string, storyAlias: string, toEmail?: string, options?: any) {
        return StoryApiFp(this.configuration).sendSnapshotAsEmail(snapshotKey, storyAlias, toEmail, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public setAsDisabled(storyAlias: string, snapshotKey: string, options?: any) {
        return StoryApiFp(this.configuration).setAsDisabled(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public setAsEnabled(storyAlias: string, snapshotKey: string, options?: any) {
        return StoryApiFp(this.configuration).setAsEnabled(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {Destination} [destination] 
     * @param {boolean} [bgJob] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public triggerSnapshot(storyAlias: string, snapshotKey: string, destination?: Destination, bgJob?: boolean, options?: any) {
        return StoryApiFp(this.configuration).triggerSnapshot(storyAlias, snapshotKey, destination, bgJob, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {boolean} [bgJob] 
     * @param {Array<Destination>} [destination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public triggerSnapshotAsPost(storyAlias: string, snapshotKey: string, bgJob?: boolean, destination?: Array<Destination>, options?: any) {
        return StoryApiFp(this.configuration).triggerSnapshotAsPost(storyAlias, snapshotKey, bgJob, destination, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public triggerStory(storyAlias: string, options?: any) {
        return StoryApiFp(this.configuration).triggerStory(storyAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {string} [audioUri] 
     * @param {number} [fileSize] 
     * @param {number} [durationSecs] 
     * @param {boolean} [optimize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public updateAudioOnSnapshot(storyAlias: string, snapshotKey: string, audioUri?: string, fileSize?: number, durationSecs?: number, optimize?: boolean, options?: any) {
        return StoryApiFp(this.configuration).updateAudioOnSnapshot(storyAlias, snapshotKey, audioUri, fileSize, durationSecs, optimize, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} settingAlias 
     * @param {SettingPublicDto} [settingPublicDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public updateStorySetting(storyAlias: string, settingAlias: string, settingPublicDto?: SettingPublicDto, options?: any) {
        return StoryApiFp(this.configuration).updateStorySetting(storyAlias, settingAlias, settingPublicDto, options)(this.axios, this.basePath);
    }

}


/**
 * StreamsApi - axios parameter creator
 * @export
 */
export const StreamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [newStreamAlias] 
         * @param {string} [toTenantId] 
         * @param {string} [toStoryAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStreamAsync(streamAlias: string, newStreamAlias?: string, toTenantId?: string, toStoryAlias?: string, options: any = {}): RequestArgs {
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling cloneStreamAsync.');
            }
            const localVarPath = `/streams/{streamAlias}/$clone`
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (newStreamAlias !== undefined) {
                localVarQueryParameter['newStreamAlias'] = newStreamAlias;
            }

            if (toTenantId !== undefined) {
                localVarQueryParameter['toTenantId'] = toTenantId;
            }

            if (toStoryAlias !== undefined) {
                localVarQueryParameter['toStoryAlias'] = toStoryAlias;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options: any = {}): RequestArgs {
            const localVarPath = `/streams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateStreamDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateStreamDto !== undefined ? createOrUpdateStreamDto : {}) : (createOrUpdateStreamDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [streamAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsCheckAsync(streamAlias?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/$exist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (streamAlias !== undefined) {
                localVarQueryParameter['streamAlias'] = streamAlias;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStreamsAvailableForTenant(tenantId?: string, mode?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/$available`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [tenantId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsMultiTimeSerieAsync(alias?: string, tenantId?: string, from?: string, to?: string, expanded?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/multi-ts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (expanded !== undefined) {
                localVarQueryParameter['expanded'] = expanded;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStreamsForTenant(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/$available-timeline`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [audienceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTimelineAudience(audienceId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/$by-audience`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (audienceId !== undefined) {
                localVarQueryParameter['audienceId'] = audienceId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewDataWithQuery(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, body?: string, options: any = {}): RequestArgs {
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling getPreviewDataWithQuery.');
            }
            const localVarPath = `/streams/{streamAlias}`
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (chunk !== undefined) {
                localVarQueryParameter['chunk'] = chunk;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleStreamDataAsync(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, mode?: string, options: any = {}): RequestArgs {
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling getSingleStreamDataAsync.');
            }
            const localVarPath = `/streams/{streamAlias}`
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (chunk !== undefined) {
                localVarQueryParameter['chunk'] = chunk;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSerieAnalysisAsEventSerieAsync(alias?: string, cid?: string, from?: string, to?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/timeserie-events`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (cid !== undefined) {
                localVarQueryParameter['cid'] = cid;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestStreamAliasFromUrl(url?: string, options: any = {}): RequestArgs {
            const localVarPath = `/streams/$suggestalias`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options: any = {}): RequestArgs {
            const localVarPath = `/streams`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateStreamDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateStreamDto !== undefined ? createOrUpdateStreamDto : {}) : (createOrUpdateStreamDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StreamsApi - functional programming interface
 * @export
 */
export const StreamsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [newStreamAlias] 
         * @param {string} [toTenantId] 
         * @param {string} [toStoryAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStreamAsync(streamAlias: string, newStreamAlias?: string, toTenantId?: string, toStoryAlias?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryStreamSummaryDto> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).cloneStreamAsync(streamAlias, newStreamAlias, toTenantId, toStoryAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateStreamResultDto> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).createStreamAsync(createOrUpdateStreamDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [streamAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsCheckAsync(streamAlias?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExistStatusDto> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).existsCheckAsync(streamAlias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStreamsAvailableForTenant(tenantId?: string, mode?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataStreamSummaryDto>> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getAllStreamsAvailableForTenant(tenantId, mode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [tenantId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsMultiTimeSerieAsync(alias?: string, tenantId?: string, from?: string, to?: string, expanded?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiTimeSerie> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getAsMultiTimeSerieAsync(alias, tenantId, from, to, expanded, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStreamsForTenant(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimelineStreamDto>> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getAvailableStreamsForTenant(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [audienceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTimelineAudience(audienceId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimelineStreamDto>> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getByTimelineAudience(audienceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewDataWithQuery(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, body?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getPreviewDataWithQuery(streamAlias, cid, from, to, chunk, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleStreamDataAsync(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, mode?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getSingleStreamDataAsync(streamAlias, cid, from, to, chunk, mode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSerieAnalysisAsEventSerieAsync(alias?: string, cid?: string, from?: string, to?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSerie> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).getTimeSerieAnalysisAsEventSerieAsync(alias, cid, from, to, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestStreamAliasFromUrl(url?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).suggestStreamAliasFromUrl(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = StreamsApiAxiosParamCreator(configuration).updateStreamAsync(createOrUpdateStreamDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StreamsApi - factory interface
 * @export
 */
export const StreamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [newStreamAlias] 
         * @param {string} [toTenantId] 
         * @param {string} [toStoryAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStreamAsync(streamAlias: string, newStreamAlias?: string, toTenantId?: string, toStoryAlias?: string, options?: any): AxiosPromise<StoryStreamSummaryDto> {
            return StreamsApiFp(configuration).cloneStreamAsync(streamAlias, newStreamAlias, toTenantId, toStoryAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any): AxiosPromise<CreateStreamResultDto> {
            return StreamsApiFp(configuration).createStreamAsync(createOrUpdateStreamDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [streamAlias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsCheckAsync(streamAlias?: string, options?: any): AxiosPromise<ExistStatusDto> {
            return StreamsApiFp(configuration).existsCheckAsync(streamAlias, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStreamsAvailableForTenant(tenantId?: string, mode?: string, options?: any): AxiosPromise<Array<DataStreamSummaryDto>> {
            return StreamsApiFp(configuration).getAllStreamsAvailableForTenant(tenantId, mode, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [tenantId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsMultiTimeSerieAsync(alias?: string, tenantId?: string, from?: string, to?: string, expanded?: string, options?: any): AxiosPromise<MultiTimeSerie> {
            return StreamsApiFp(configuration).getAsMultiTimeSerieAsync(alias, tenantId, from, to, expanded, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableStreamsForTenant(tenantId?: string, options?: any): AxiosPromise<Array<TimelineStreamDto>> {
            return StreamsApiFp(configuration).getAvailableStreamsForTenant(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [audienceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByTimelineAudience(audienceId?: string, options?: any): AxiosPromise<Array<TimelineStreamDto>> {
            return StreamsApiFp(configuration).getByTimelineAudience(audienceId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewDataWithQuery(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, body?: string, options?: any): AxiosPromise<void> {
            return StreamsApiFp(configuration).getPreviewDataWithQuery(streamAlias, cid, from, to, chunk, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} streamAlias 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [chunk] 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleStreamDataAsync(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, mode?: string, options?: any): AxiosPromise<void> {
            return StreamsApiFp(configuration).getSingleStreamDataAsync(streamAlias, cid, from, to, chunk, mode, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {string} [cid] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSerieAnalysisAsEventSerieAsync(alias?: string, cid?: string, from?: string, to?: string, options?: any): AxiosPromise<EventSerie> {
            return StreamsApiFp(configuration).getTimeSerieAnalysisAsEventSerieAsync(alias, cid, from, to, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestStreamAliasFromUrl(url?: string, options?: any): AxiosPromise<string> {
            return StreamsApiFp(configuration).suggestStreamAliasFromUrl(url, options)(axios, basePath);
        },
        /**
         * 
         * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any): AxiosPromise<StatusResultDto> {
            return StreamsApiFp(configuration).updateStreamAsync(createOrUpdateStreamDto, options)(axios, basePath);
        },
    };
};

/**
 * StreamsApi - object-oriented interface
 * @export
 * @class StreamsApi
 * @extends {BaseAPI}
 */
export class StreamsApi extends BaseAPI {
    /**
     * 
     * @param {string} streamAlias 
     * @param {string} [newStreamAlias] 
     * @param {string} [toTenantId] 
     * @param {string} [toStoryAlias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public cloneStreamAsync(streamAlias: string, newStreamAlias?: string, toTenantId?: string, toStoryAlias?: string, options?: any) {
        return StreamsApiFp(this.configuration).cloneStreamAsync(streamAlias, newStreamAlias, toTenantId, toStoryAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public createStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any) {
        return StreamsApiFp(this.configuration).createStreamAsync(createOrUpdateStreamDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [streamAlias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public existsCheckAsync(streamAlias?: string, options?: any) {
        return StreamsApiFp(this.configuration).existsCheckAsync(streamAlias, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getAllStreamsAvailableForTenant(tenantId?: string, mode?: string, options?: any) {
        return StreamsApiFp(this.configuration).getAllStreamsAvailableForTenant(tenantId, mode, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [alias] 
     * @param {string} [tenantId] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [expanded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getAsMultiTimeSerieAsync(alias?: string, tenantId?: string, from?: string, to?: string, expanded?: string, options?: any) {
        return StreamsApiFp(this.configuration).getAsMultiTimeSerieAsync(alias, tenantId, from, to, expanded, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getAvailableStreamsForTenant(tenantId?: string, options?: any) {
        return StreamsApiFp(this.configuration).getAvailableStreamsForTenant(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [audienceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getByTimelineAudience(audienceId?: string, options?: any) {
        return StreamsApiFp(this.configuration).getByTimelineAudience(audienceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} streamAlias 
     * @param {string} [cid] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [chunk] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getPreviewDataWithQuery(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, body?: string, options?: any) {
        return StreamsApiFp(this.configuration).getPreviewDataWithQuery(streamAlias, cid, from, to, chunk, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} streamAlias 
     * @param {string} [cid] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [chunk] 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getSingleStreamDataAsync(streamAlias: string, cid?: string, from?: string, to?: string, chunk?: string, mode?: string, options?: any) {
        return StreamsApiFp(this.configuration).getSingleStreamDataAsync(streamAlias, cid, from, to, chunk, mode, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [alias] 
     * @param {string} [cid] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public getTimeSerieAnalysisAsEventSerieAsync(alias?: string, cid?: string, from?: string, to?: string, options?: any) {
        return StreamsApiFp(this.configuration).getTimeSerieAnalysisAsEventSerieAsync(alias, cid, from, to, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public suggestStreamAliasFromUrl(url?: string, options?: any) {
        return StreamsApiFp(this.configuration).suggestStreamAliasFromUrl(url, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {CreateOrUpdateStreamDto} [createOrUpdateStreamDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreamsApi
     */
    public updateStreamAsync(createOrUpdateStreamDto?: CreateOrUpdateStreamDto, options?: any) {
        return StreamsApiFp(this.configuration).updateStreamAsync(createOrUpdateStreamDto, options)(this.axios, this.basePath);
    }

}


/**
 * SubscribersApi - axios parameter creator
 * @export
 */
export const SubscribersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(data?: Array<StringStringValuesKeyValuePair>, options: any = {}): RequestArgs {
            const localVarPath = `/subscribers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (data) {
            
                localVarFormParams.set('data', data.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [story] 
         * @param {string} [emailAddress] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSubscriber(story?: string, emailAddress?: string, token?: string, options: any = {}): RequestArgs {
            const localVarPath = `/subscribers/confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (story !== undefined) {
                localVarQueryParameter['story'] = story;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} emailAddress 
         * @param {string} [story] 
         * @param {string} [email] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(storyAlias: string, emailAddress: string, story?: string, email?: string, token?: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling removeSubscriber.');
            }
            // verify required parameter 'emailAddress' is not null or undefined
            if (emailAddress === null || emailAddress === undefined) {
                throw new RequiredError('emailAddress','Required parameter emailAddress was null or undefined when calling removeSubscriber.');
            }
            const localVarPath = `/subscribers/{storyAlias}/subscribers/{emailAddress}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (story !== undefined) {
                localVarQueryParameter['story'] = story;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [storyAlias] 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeWebhookCallback(storyAlias?: string, requestBody?: Array<object>, options: any = {}): RequestArgs {
            const localVarPath = `/subscribers/sendgrid-webhook`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (storyAlias !== undefined) {
                localVarQueryParameter['storyAlias'] = storyAlias;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscribersApi - functional programming interface
 * @export
 */
export const SubscribersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(data?: Array<StringStringValuesKeyValuePair>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = SubscribersApiAxiosParamCreator(configuration).addSubscriber(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [story] 
         * @param {string} [emailAddress] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSubscriber(story?: string, emailAddress?: string, token?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = SubscribersApiAxiosParamCreator(configuration).confirmSubscriber(story, emailAddress, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} emailAddress 
         * @param {string} [story] 
         * @param {string} [email] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(storyAlias: string, emailAddress: string, story?: string, email?: string, token?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = SubscribersApiAxiosParamCreator(configuration).removeSubscriber(storyAlias, emailAddress, story, email, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [storyAlias] 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeWebhookCallback(storyAlias?: string, requestBody?: Array<object>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = SubscribersApiAxiosParamCreator(configuration).unsubscribeWebhookCallback(storyAlias, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscribersApi - factory interface
 * @export
 */
export const SubscribersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<StringStringValuesKeyValuePair>} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscriber(data?: Array<StringStringValuesKeyValuePair>, options?: any): AxiosPromise<StatusResultDto> {
            return SubscribersApiFp(configuration).addSubscriber(data, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [story] 
         * @param {string} [emailAddress] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmSubscriber(story?: string, emailAddress?: string, token?: string, options?: any): AxiosPromise<StatusResultDto> {
            return SubscribersApiFp(configuration).confirmSubscriber(story, emailAddress, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} emailAddress 
         * @param {string} [story] 
         * @param {string} [email] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscriber(storyAlias: string, emailAddress: string, story?: string, email?: string, token?: string, options?: any): AxiosPromise<StatusResultDto> {
            return SubscribersApiFp(configuration).removeSubscriber(storyAlias, emailAddress, story, email, token, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [storyAlias] 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeWebhookCallback(storyAlias?: string, requestBody?: Array<object>, options?: any): AxiosPromise<StatusResultDto> {
            return SubscribersApiFp(configuration).unsubscribeWebhookCallback(storyAlias, requestBody, options)(axios, basePath);
        },
    };
};

/**
 * SubscribersApi - object-oriented interface
 * @export
 * @class SubscribersApi
 * @extends {BaseAPI}
 */
export class SubscribersApi extends BaseAPI {
    /**
     * 
     * @param {Array<StringStringValuesKeyValuePair>} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public addSubscriber(data?: Array<StringStringValuesKeyValuePair>, options?: any) {
        return SubscribersApiFp(this.configuration).addSubscriber(data, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [story] 
     * @param {string} [emailAddress] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public confirmSubscriber(story?: string, emailAddress?: string, token?: string, options?: any) {
        return SubscribersApiFp(this.configuration).confirmSubscriber(story, emailAddress, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} storyAlias 
     * @param {string} emailAddress 
     * @param {string} [story] 
     * @param {string} [email] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public removeSubscriber(storyAlias: string, emailAddress: string, story?: string, email?: string, token?: string, options?: any) {
        return SubscribersApiFp(this.configuration).removeSubscriber(storyAlias, emailAddress, story, email, token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [storyAlias] 
     * @param {Array<object>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public unsubscribeWebhookCallback(storyAlias?: string, requestBody?: Array<object>, options?: any) {
        return SubscribersApiFp(this.configuration).unsubscribeWebhookCallback(storyAlias, requestBody, options)(this.axios, this.basePath);
    }

}


/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardTask(storyAlias: string, snapshotKey: string, options: any = {}): RequestArgs {
            // verify required parameter 'storyAlias' is not null or undefined
            if (storyAlias === null || storyAlias === undefined) {
                throw new RequiredError('storyAlias','Required parameter storyAlias was null or undefined when calling discardTask.');
            }
            // verify required parameter 'snapshotKey' is not null or undefined
            if (snapshotKey === null || snapshotKey === undefined) {
                throw new RequiredError('snapshotKey','Required parameter snapshotKey was null or undefined when calling discardTask.');
            }
            const localVarPath = `/tasks/{storyAlias}/{snapshotKey}`
                .replace(`{${"storyAlias"}}`, encodeURIComponent(String(storyAlias)))
                .replace(`{${"snapshotKey"}}`, encodeURIComponent(String(snapshotKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/tasks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardTask(storyAlias: string, snapshotKey: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = TasksApiAxiosParamCreator(configuration).discardTask(storyAlias, snapshotKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>> {
            const localVarAxiosArgs = TasksApiAxiosParamCreator(configuration).getTasks(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} storyAlias 
         * @param {string} snapshotKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardTask(storyAlias: string, snapshotKey: string, options?: any): AxiosPromise<StatusResultDto> {
            return TasksApiFp(configuration).discardTask(storyAlias, snapshotKey, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(tenantId?: string, options?: any): AxiosPromise<Array<TaskDto>> {
            return TasksApiFp(configuration).getTasks(tenantId, options)(axios, basePath);
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @param {string} storyAlias 
     * @param {string} snapshotKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public discardTask(storyAlias: string, snapshotKey: string, options?: any) {
        return TasksApiFp(this.configuration).discardTask(storyAlias, snapshotKey, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTasks(tenantId?: string, options?: any) {
        return TasksApiFp(this.configuration).getTasks(tenantId, options)(this.axios, this.basePath);
    }

}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(tenantId?: string, prefix?: string, options: any = {}): RequestArgs {
            const localVarPath = `/tenant/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [name] 
         * @param {string} [langCode] 
         * @param {string} [slug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTenant(tenantId?: string, name?: string, langCode?: string, slug?: string, options: any = {}): RequestArgs {
            const localVarPath = `/tenant/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenantsByAlias(alias?: string, options: any = {}): RequestArgs {
            const localVarPath = `/tenant/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(tenantId?: string, prefix?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }> {
            const localVarAxiosArgs = TenantApiAxiosParamCreator(configuration).getSettings(tenantId, prefix, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [name] 
         * @param {string} [langCode] 
         * @param {string} [slug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTenant(tenantId?: string, name?: string, langCode?: string, slug?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = TenantApiAxiosParamCreator(configuration).registerTenant(tenantId, name, langCode, slug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenantsByAlias(alias?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantSummaryDto>> {
            const localVarAxiosArgs = TenantApiAxiosParamCreator(configuration).searchTenantsByAlias(alias, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [prefix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(tenantId?: string, prefix?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return TenantApiFp(configuration).getSettings(tenantId, prefix, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [name] 
         * @param {string} [langCode] 
         * @param {string} [slug] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTenant(tenantId?: string, name?: string, langCode?: string, slug?: string, options?: any): AxiosPromise<StatusResultDto> {
            return TenantApiFp(configuration).registerTenant(tenantId, name, langCode, slug, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [alias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenantsByAlias(alias?: string, options?: any): AxiosPromise<Array<TenantSummaryDto>> {
            return TenantApiFp(configuration).searchTenantsByAlias(alias, options)(axios, basePath);
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [prefix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getSettings(tenantId?: string, prefix?: string, options?: any) {
        return TenantApiFp(this.configuration).getSettings(tenantId, prefix, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [name] 
     * @param {string} [langCode] 
     * @param {string} [slug] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public registerTenant(tenantId?: string, name?: string, langCode?: string, slug?: string, options?: any) {
        return TenantApiFp(this.configuration).registerTenant(tenantId, name, langCode, slug, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [alias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchTenantsByAlias(alias?: string, options?: any) {
        return TenantApiFp(this.configuration).searchTenantsByAlias(alias, options)(this.axios, this.basePath);
    }

}


/**
 * TimelineApi - axios parameter creator
 * @export
 */
export const TimelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {string} [streamAlias] 
         * @param {string} [connId] 
         * @param {string} [settings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateStreamOnAudience(audienceId: string, tenantId?: string, streamAlias?: string, connId?: string, settings?: string, options: any = {}): RequestArgs {
            // verify required parameter 'audienceId' is not null or undefined
            if (audienceId === null || audienceId === undefined) {
                throw new RequiredError('audienceId','Required parameter audienceId was null or undefined when calling addOrUpdateStreamOnAudience.');
            }
            const localVarPath = `/timeline/{audienceId}`
                .replace(`{${"audienceId"}}`, encodeURIComponent(String(audienceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (streamAlias !== undefined) {
                localVarQueryParameter['streamAlias'] = streamAlias;
            }

            if (connId !== undefined) {
                localVarQueryParameter['connId'] = connId;
            }

            if (settings !== undefined) {
                localVarQueryParameter['settings'] = settings;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiencesAsync(tenantId: string, options: any = {}): RequestArgs {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getAudiencesAsync.');
            }
            const localVarPath = `/timeline/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {AudienceDto} [audienceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAudienceAsync(audienceId: string, tenantId?: string, audienceDto?: AudienceDto, options: any = {}): RequestArgs {
            // verify required parameter 'audienceId' is not null or undefined
            if (audienceId === null || audienceId === undefined) {
                throw new RequiredError('audienceId','Required parameter audienceId was null or undefined when calling patchAudienceAsync.');
            }
            const localVarPath = `/timeline/{audienceId}`
                .replace(`{${"audienceId"}}`, encodeURIComponent(String(audienceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof audienceDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(audienceDto !== undefined ? audienceDto : {}) : (audienceDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} streamAlias 
         * @param {string} [tenantId] 
         * @param {string} [connId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamRelationToAudience(audienceId: string, streamAlias: string, tenantId?: string, connId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'audienceId' is not null or undefined
            if (audienceId === null || audienceId === undefined) {
                throw new RequiredError('audienceId','Required parameter audienceId was null or undefined when calling removeStreamRelationToAudience.');
            }
            // verify required parameter 'streamAlias' is not null or undefined
            if (streamAlias === null || streamAlias === undefined) {
                throw new RequiredError('streamAlias','Required parameter streamAlias was null or undefined when calling removeStreamRelationToAudience.');
            }
            const localVarPath = `/timeline/{audienceId}/{streamAlias}`
                .replace(`{${"audienceId"}}`, encodeURIComponent(String(audienceId)))
                .replace(`{${"streamAlias"}}`, encodeURIComponent(String(streamAlias)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (connId !== undefined) {
                localVarQueryParameter['connId'] = connId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimelineApi - functional programming interface
 * @export
 */
export const TimelineApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {string} [streamAlias] 
         * @param {string} [connId] 
         * @param {string} [settings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateStreamOnAudience(audienceId: string, tenantId?: string, streamAlias?: string, connId?: string, settings?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineStreamDto> {
            const localVarAxiosArgs = TimelineApiAxiosParamCreator(configuration).addOrUpdateStreamOnAudience(audienceId, tenantId, streamAlias, connId, settings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiencesAsync(tenantId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudienceDto>> {
            const localVarAxiosArgs = TimelineApiAxiosParamCreator(configuration).getAudiencesAsync(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {AudienceDto} [audienceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAudienceAsync(audienceId: string, tenantId?: string, audienceDto?: AudienceDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = TimelineApiAxiosParamCreator(configuration).patchAudienceAsync(audienceId, tenantId, audienceDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} streamAlias 
         * @param {string} [tenantId] 
         * @param {string} [connId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamRelationToAudience(audienceId: string, streamAlias: string, tenantId?: string, connId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = TimelineApiAxiosParamCreator(configuration).removeStreamRelationToAudience(audienceId, streamAlias, tenantId, connId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TimelineApi - factory interface
 * @export
 */
export const TimelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {string} [streamAlias] 
         * @param {string} [connId] 
         * @param {string} [settings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateStreamOnAudience(audienceId: string, tenantId?: string, streamAlias?: string, connId?: string, settings?: string, options?: any): AxiosPromise<TimelineStreamDto> {
            return TimelineApiFp(configuration).addOrUpdateStreamOnAudience(audienceId, tenantId, streamAlias, connId, settings, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudiencesAsync(tenantId: string, options?: any): AxiosPromise<Array<AudienceDto>> {
            return TimelineApiFp(configuration).getAudiencesAsync(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} [tenantId] 
         * @param {AudienceDto} [audienceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAudienceAsync(audienceId: string, tenantId?: string, audienceDto?: AudienceDto, options?: any): AxiosPromise<StatusResultDto> {
            return TimelineApiFp(configuration).patchAudienceAsync(audienceId, tenantId, audienceDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} audienceId 
         * @param {string} streamAlias 
         * @param {string} [tenantId] 
         * @param {string} [connId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStreamRelationToAudience(audienceId: string, streamAlias: string, tenantId?: string, connId?: string, options?: any): AxiosPromise<StatusResultDto> {
            return TimelineApiFp(configuration).removeStreamRelationToAudience(audienceId, streamAlias, tenantId, connId, options)(axios, basePath);
        },
    };
};

/**
 * TimelineApi - object-oriented interface
 * @export
 * @class TimelineApi
 * @extends {BaseAPI}
 */
export class TimelineApi extends BaseAPI {
    /**
     * 
     * @param {string} audienceId 
     * @param {string} [tenantId] 
     * @param {string} [streamAlias] 
     * @param {string} [connId] 
     * @param {string} [settings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelineApi
     */
    public addOrUpdateStreamOnAudience(audienceId: string, tenantId?: string, streamAlias?: string, connId?: string, settings?: string, options?: any) {
        return TimelineApiFp(this.configuration).addOrUpdateStreamOnAudience(audienceId, tenantId, streamAlias, connId, settings, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelineApi
     */
    public getAudiencesAsync(tenantId: string, options?: any) {
        return TimelineApiFp(this.configuration).getAudiencesAsync(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} audienceId 
     * @param {string} [tenantId] 
     * @param {AudienceDto} [audienceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelineApi
     */
    public patchAudienceAsync(audienceId: string, tenantId?: string, audienceDto?: AudienceDto, options?: any) {
        return TimelineApiFp(this.configuration).patchAudienceAsync(audienceId, tenantId, audienceDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} audienceId 
     * @param {string} streamAlias 
     * @param {string} [tenantId] 
     * @param {string} [connId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelineApi
     */
    public removeStreamRelationToAudience(audienceId: string, streamAlias: string, tenantId?: string, connId?: string, options?: any) {
        return TimelineApiFp(this.configuration).removeStreamRelationToAudience(audienceId, streamAlias, tenantId, connId, options)(this.axios, this.basePath);
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToTenant(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/user/add-to-tenant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options: any = {}): RequestArgs {
            const localVarPath = `/user/userinfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToTenant(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).addToTenant(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoDto> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).getUserInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToTenant(tenantId?: string, options?: any): AxiosPromise<StatusResultDto> {
            return UserApiFp(configuration).addToTenant(tenantId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfoDto> {
            return UserApiFp(configuration).getUserInfo(options)(axios, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addToTenant(tenantId?: string, options?: any) {
        return UserApiFp(this.configuration).addToTenant(tenantId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserInfo(options?: any) {
        return UserApiFp(this.configuration).getUserInfo(options)(this.axios, this.basePath);
    }

}


/**
 * VoicePreviewApi - axios parameter creator
 * @export
 */
export const VoicePreviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [voicePersonId] 
         * @param {string} [lang] 
         * @param {string} [voiceSpeed] 
         * @param {string} [voicePitch] 
         * @param {VoicePreviewInputDto} [voicePreviewInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record(voicePersonId?: string, lang?: string, voiceSpeed?: string, voicePitch?: string, voicePreviewInputDto?: VoicePreviewInputDto, options: any = {}): RequestArgs {
            const localVarPath = `/voicepreview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (voicePersonId !== undefined) {
                localVarQueryParameter['voicePersonId'] = voicePersonId;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (voiceSpeed !== undefined) {
                localVarQueryParameter['voiceSpeed'] = voiceSpeed;
            }

            if (voicePitch !== undefined) {
                localVarQueryParameter['voicePitch'] = voicePitch;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof voicePreviewInputDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(voicePreviewInputDto !== undefined ? voicePreviewInputDto : {}) : (voicePreviewInputDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoicePreviewApi - functional programming interface
 * @export
 */
export const VoicePreviewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [voicePersonId] 
         * @param {string} [lang] 
         * @param {string} [voiceSpeed] 
         * @param {string} [voicePitch] 
         * @param {VoicePreviewInputDto} [voicePreviewInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record(voicePersonId?: string, lang?: string, voiceSpeed?: string, voicePitch?: string, voicePreviewInputDto?: VoicePreviewInputDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoicePreviewOutputDto> {
            const localVarAxiosArgs = VoicePreviewApiAxiosParamCreator(configuration).record(voicePersonId, lang, voiceSpeed, voicePitch, voicePreviewInputDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VoicePreviewApi - factory interface
 * @export
 */
export const VoicePreviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [voicePersonId] 
         * @param {string} [lang] 
         * @param {string} [voiceSpeed] 
         * @param {string} [voicePitch] 
         * @param {VoicePreviewInputDto} [voicePreviewInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record(voicePersonId?: string, lang?: string, voiceSpeed?: string, voicePitch?: string, voicePreviewInputDto?: VoicePreviewInputDto, options?: any): AxiosPromise<VoicePreviewOutputDto> {
            return VoicePreviewApiFp(configuration).record(voicePersonId, lang, voiceSpeed, voicePitch, voicePreviewInputDto, options)(axios, basePath);
        },
    };
};

/**
 * VoicePreviewApi - object-oriented interface
 * @export
 * @class VoicePreviewApi
 * @extends {BaseAPI}
 */
export class VoicePreviewApi extends BaseAPI {
    /**
     * 
     * @param {string} [voicePersonId] 
     * @param {string} [lang] 
     * @param {string} [voiceSpeed] 
     * @param {string} [voicePitch] 
     * @param {VoicePreviewInputDto} [voicePreviewInputDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoicePreviewApi
     */
    public record(voicePersonId?: string, lang?: string, voiceSpeed?: string, voicePitch?: string, voicePreviewInputDto?: VoicePreviewInputDto, options?: any) {
        return VoicePreviewApiFp(this.configuration).record(voicePersonId, lang, voiceSpeed, voicePitch, voicePreviewInputDto, options)(this.axios, this.basePath);
    }

}


/**
 * VoicesApi - axios parameter creator
 * @export
 */
export const VoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfAvailableVoices(langCode?: string, options: any = {}): RequestArgs {
            const localVarPath = `/voices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (langCode !== undefined) {
                localVarQueryParameter['langCode'] = langCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoicesApi - functional programming interface
 * @export
 */
export const VoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfAvailableVoices(langCode?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoicePersonDto>> {
            const localVarAxiosArgs = VoicesApiAxiosParamCreator(configuration).getListOfAvailableVoices(langCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VoicesApi - factory interface
 * @export
 */
export const VoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [langCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfAvailableVoices(langCode?: string, options?: any): AxiosPromise<Array<VoicePersonDto>> {
            return VoicesApiFp(configuration).getListOfAvailableVoices(langCode, options)(axios, basePath);
        },
    };
};

/**
 * VoicesApi - object-oriented interface
 * @export
 * @class VoicesApi
 * @extends {BaseAPI}
 */
export class VoicesApi extends BaseAPI {
    /**
     * 
     * @param {string} [langCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoicesApi
     */
    public getListOfAvailableVoices(langCode?: string, options?: any) {
        return VoicesApiFp(this.configuration).getListOfAvailableVoices(langCode, options)(this.axios, this.basePath);
    }

}


/**
 * WebPostsApi - axios parameter creator
 * @export
 */
export const WebPostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options: any = {}): RequestArgs {
            const localVarPath = `/webposts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebPostsApi - functional programming interface
 * @export
 */
export const WebPostsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebPostDto>> {
            const localVarAxiosArgs = WebPostsApiAxiosParamCreator(configuration).getWebPosts(tenantId, tag, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebPostsApi - factory interface
 * @export
 */
export const WebPostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [tenantId] 
         * @param {string} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebPosts(tenantId?: string, tag?: string, options?: any): AxiosPromise<Array<WebPostDto>> {
            return WebPostsApiFp(configuration).getWebPosts(tenantId, tag, options)(axios, basePath);
        },
    };
};

/**
 * WebPostsApi - object-oriented interface
 * @export
 * @class WebPostsApi
 * @extends {BaseAPI}
 */
export class WebPostsApi extends BaseAPI {
    /**
     * 
     * @param {string} [tenantId] 
     * @param {string} [tag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebPostsApi
     */
    public getWebPosts(tenantId?: string, tag?: string, options?: any) {
        return WebPostsApiFp(this.configuration).getWebPosts(tenantId, tag, options)(this.axios, this.basePath);
    }

}


/**
 * WordRulesApi - axios parameter creator
 * @export
 */
export const WordRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WordRuleInputDto} [wordRuleInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdate(wordRuleInputDto?: WordRuleInputDto, options: any = {}): RequestArgs {
            const localVarPath = `/wordrules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof wordRuleInputDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(wordRuleInputDto !== undefined ? wordRuleInputDto : {}) : (wordRuleInputDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(tenantId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/wordrules`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WordRulesApi - functional programming interface
 * @export
 */
export const WordRulesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WordRuleInputDto} [wordRuleInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdate(wordRuleInputDto?: WordRuleInputDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResultDto> {
            const localVarAxiosArgs = WordRulesApiAxiosParamCreator(configuration).addOrUpdate(wordRuleInputDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(tenantId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WordRuleOutputDto>> {
            const localVarAxiosArgs = WordRulesApiAxiosParamCreator(configuration).getList(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WordRulesApi - factory interface
 * @export
 */
export const WordRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {WordRuleInputDto} [wordRuleInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdate(wordRuleInputDto?: WordRuleInputDto, options?: any): AxiosPromise<StatusResultDto> {
            return WordRulesApiFp(configuration).addOrUpdate(wordRuleInputDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getList(tenantId?: string, options?: any): AxiosPromise<Array<WordRuleOutputDto>> {
            return WordRulesApiFp(configuration).getList(tenantId, options)(axios, basePath);
        },
    };
};

/**
 * WordRulesApi - object-oriented interface
 * @export
 * @class WordRulesApi
 * @extends {BaseAPI}
 */
export class WordRulesApi extends BaseAPI {
    /**
     * 
     * @param {WordRuleInputDto} [wordRuleInputDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WordRulesApi
     */
    public addOrUpdate(wordRuleInputDto?: WordRuleInputDto, options?: any) {
        return WordRulesApiFp(this.configuration).addOrUpdate(wordRuleInputDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WordRulesApi
     */
    public getList(tenantId?: string, options?: any) {
        return WordRulesApiFp(this.configuration).getList(tenantId, options)(this.axios, this.basePath);
    }

}


/**
 * WriterApi - axios parameter creator
 * @export
 */
export const WriterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [format] 
         * @param {string} [showScriban] 
         * @param {boolean} [addStreamsFromContent] 
         * @param {WriterInputDto} [writerInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFromWriter(format?: string, showScriban?: string, addStreamsFromContent?: boolean, writerInputDto?: WriterInputDto, options: any = {}): RequestArgs {
            const localVarPath = `/writer/generate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (showScriban !== undefined) {
                localVarQueryParameter['showScriban'] = showScriban;
            }

            if (addStreamsFromContent !== undefined) {
                localVarQueryParameter['addStreamsFromContent'] = addStreamsFromContent;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof writerInputDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(writerInputDto !== undefined ? writerInputDto : {}) : (writerInputDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WriterApi - functional programming interface
 * @export
 */
export const WriterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [format] 
         * @param {string} [showScriban] 
         * @param {boolean} [addStreamsFromContent] 
         * @param {WriterInputDto} [writerInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFromWriter(format?: string, showScriban?: string, addStreamsFromContent?: boolean, writerInputDto?: WriterInputDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WriterApiAxiosParamCreator(configuration).generateFromWriter(format, showScriban, addStreamsFromContent, writerInputDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WriterApi - factory interface
 * @export
 */
export const WriterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [format] 
         * @param {string} [showScriban] 
         * @param {boolean} [addStreamsFromContent] 
         * @param {WriterInputDto} [writerInputDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFromWriter(format?: string, showScriban?: string, addStreamsFromContent?: boolean, writerInputDto?: WriterInputDto, options?: any): AxiosPromise<void> {
            return WriterApiFp(configuration).generateFromWriter(format, showScriban, addStreamsFromContent, writerInputDto, options)(axios, basePath);
        },
    };
};

/**
 * WriterApi - object-oriented interface
 * @export
 * @class WriterApi
 * @extends {BaseAPI}
 */
export class WriterApi extends BaseAPI {
    /**
     * 
     * @param {string} [format] 
     * @param {string} [showScriban] 
     * @param {boolean} [addStreamsFromContent] 
     * @param {WriterInputDto} [writerInputDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriterApi
     */
    public generateFromWriter(format?: string, showScriban?: string, addStreamsFromContent?: boolean, writerInputDto?: WriterInputDto, options?: any) {
        return WriterApiFp(this.configuration).generateFromWriter(format, showScriban, addStreamsFromContent, writerInputDto, options)(this.axios, this.basePath);
    }

}


