import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import isUrl from "is-url";

import { SettingPublicDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, 
    AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, FormControl, 
    FormHelperText, FormLabel, HStack, Input, Popover, PopoverArrow, PopoverBody, 
    PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, 
    PopoverTrigger, Portal, Stack, Image, Checkbox, Spinner, VStack } from "@chakra-ui/react";
import styled from "styled-components";

const Wrapper = styled.div`
    float: right;
    margin-left: 20px;
`;

type LinkItemProps = {
    storyAlias: string,
    url: string | null,
    label: string | null,
    onReplaced: any
}

const LinkItem = (props: LinkItemProps) => {
    const { t } = useTranslation();
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    const labelMaxLength = 45;
    
    const [url, setUrl] = useState<string>('');
    const [isLoadingUrlInfo, setIsLoadingUrlInfo] = useState<boolean>(false);
    const [label, setLabel] = useState<string>();

    const [urlInfoLoaded, setUrlInfoLoaded] = useState<boolean>(false);
    const [screenshotUri, setScreenshotUri] = useState<string>();
    const [screenshotCanBeUsed, setScreenshotCanBeUsed] = useState<boolean>(true);

    const edit = () => {
        setIsEditModalOpen(true);
    }

    const paste = (e: any) => {
        const text = e.clipboardData.getData('text/plain').trim();
        if (isUrl) {
            setUrl(text);
            // TODO: fetch (but the url has not been set yet - refactor...)
        } else {
            console.error(`invalid url: ${text}`);
            setUrl(text);
        }
    }

    const fetchUrl = async () => {
        setUrlInfoLoaded(false);
        setIsLoadingUrlInfo(true);

        if (askForScreenshot()) {
            await captureScreenshot();
        } else {
            setUrlInfoLoaded(true);
        }
    }

    const askForScreenshot = () => {
        if (url.includes('facebook.com')) {
            return false;
        }
        return true;
    }

    const captureScreenshot = async () => {
        if (!url) throw new Error('url missing');

        const apiKey = '7116092a6cf69af35c4ed0ad5b82a805';
        const uri = `http://api.screenshotlayer.com/api/capture?access_key=${apiKey}&url=${encodeURI(url)}&delay=2`;

        // wait 3.5 secs for the screenshot to be captured
        setTimeout(() => {
            setScreenshotUri(uri);
            setUrlInfoLoaded(true);
            setIsLoadingUrlInfo(false);
        }, (3500));
        
        // Note: https://screenshotlayer.com/documentation
    }

    const save = () => {
        props.onReplaced(url, label);
        setIsEditModalOpen(false);
    }

    return (
        <div>
            {props.url && <Button onClick={edit}>{props.label ?? 'Länk'}</Button>}
            {!props.url && <>
                <Button size={"sm"} onClick={edit}>Ange länk</Button>
            </>}
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => { setIsEditModalOpen(false); }}
                isOpen={isEditModalOpen}
                size="xl"
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>{t('addLink')}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody style={{ marginBottom: 10 }}>

                        <VStack spacing={5}>

                            <HStack w="full">
                                <Input 
                                    autoFocus
                                    variant="outline" 
                                    value={url}
                                    size="md"
                                    isInvalid={url !== '' && !isUrl(url)}
                                    isDisabled={isLoadingUrlInfo}
                                    placeholder={t('insertUrlPlaceholder')}
                                    onChange={(e) => { setUrl(e.target.value); }} 
                                    onPaste={paste} />
                                <Button onClick={fetchUrl}>{t('fetch')}</Button>
                            </HStack>

                            <FormControl id="linkLabel" isRequired>
                                <FormLabel htmlFor="linkLabelField">{t('linkLabel')}</FormLabel>
                                <Input id="linkLabelField" variant="outline" size="lg" value={label} 
                                    maxLength={labelMaxLength} 
                                    onChange={(ev: any) => { setLabel(ev.target.value) }} />
                                <FormHelperText>
                                    {t('linkLabelHelpText')}
                                </FormHelperText>
                            </FormControl>

                            {isLoadingUrlInfo && <Spinner />}

                            {urlInfoLoaded && <Stack spacing={3}>

                                {screenshotUri && screenshotCanBeUsed && <Image
                                    boxSize="300px"
                                    objectFit="cover"
                                    src={screenshotUri} />}

                                <Checkbox 
                                    colorScheme="brand"
                                    isChecked={screenshotCanBeUsed}
                                    onChange={(e) => setScreenshotCanBeUsed(e.target.checked)}>
                                    {t('screenshotCanBeUsed')}
                                </Checkbox>

                            </Stack>}
                        </VStack>

                    </AlertDialogBody>
                    {urlInfoLoaded && <AlertDialogFooter>
                        <Button onClick={save} colorScheme="brand" mr={3}>{t('save')}</Button>
                    </AlertDialogFooter>}
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
}

type Props = {
    storyAlias: string,
    onChanged: any
}

const LinksManager = (props: Props) => {
    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const tenantId = sessionStorage.getItem('TENANT_ID')!;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [links, setLinks] = useState<[string, string][] | null>([]);
    const settingKey = 'links';

    useEffect(() => {
        setIsLoading(true);
        async function load() {
            const linksSettingValue = await loadLinksFromStorySetting();
            if (linksSettingValue) {
                setLinks(linksSettingValue.split('|').map((val) => {
                    const t = val.split('='); // TODO: 
                    return [t[0], t[1]];
                }));
            } else {
                setLinks(null);
            }
            setIsLoading(false);
        }
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Save to backend
        if (links && links.length > 0) {
            updateLinksSetting(links);
            props.onChanged(links);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links]);

    const replaceLink = (oldUrl: string, newUrl: string, newText: string) => {
        console.log(`replace link: ${newUrl}`);
        let array = Object.assign([], links) ?? new Array<[string, string]>();

        const index = array.findIndex(x => x === oldUrl);
        if (index < 0) {
            console.warn(`old url could not be found ` +
                `in links array: ${oldUrl}`);
        }

        array.splice(index, 1, [newText, newUrl]);

        setLinks(array);
    }

    const addLink = (newUrl: string, newText: string) => {
        console.log(`add link: ${newUrl}`);

        let array = Object.assign([], links) ?? new Array<[string, string]>();
        array.push([newText, newUrl]);

        setLinks(array);
    }

    const deleteLink = (url: string) => {
        let array = Object.assign([], links!);
        const index = array.findIndex(x => x === url);
        array.splice(index, 1);

        setLinks(array);
    }

    const loadLinksFromStorySetting = async () => {
        const apiResult = await storyApi
            .getStorySetting(props.storyAlias, settingKey, tenantId);
        if (apiResult.data && apiResult.data.value) {
            return apiResult.data.value;
        }

        return null;
    };

    const updateLinksSetting = async (links: [string, string][]) => {
        const linkString = links.map((l) => {
            return `${l[0]}=${l[1]}`;
        }).join('|');
        const settingValue: SettingPublicDto = {
            value: linkString
        };
        const result = await storyApi.updateStorySetting(
            props.storyAlias, settingKey, settingValue);
        if (result.data.success) {
            console.log(`${links!.length} links has ` +
                `been saved as story setting`);
        } else {
            throw new Error(
                `failed to update links setting: ${result.data.message}`);
        }
    }

    return (
        <Wrapper>
            {!isLoading && links && links.map((link: [string, string]) => {
                return {
                    url: link[1],
                    label: link[0]
                }
            }).map((link) => (
                <Popover key={`${link.url}-popover`}>
                    <PopoverTrigger>
                        <a>
                            <LinkItem key={`${link.url}-img`}
                                storyAlias={props.storyAlias}
                                onReplaced={(newUrl: string, newText: string) => {
                                    replaceLink(link.url, newUrl, newText);
                                }}
                                url={link.url}
                                label={link.label} />
                        </a>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader></PopoverHeader>
                            <PopoverBody>
                                <a href={link.url} target="_blank" rel="noreferrer">{link.url}</a>
                            </PopoverBody>
                            <PopoverFooter>
                                <Button size={"sm"} onClick={() => {
                                    deleteLink(link.url);
                                }}>{t('remove')}</Button>
                            </PopoverFooter>
                        </PopoverContent>
                    </Portal>
                </Popover>
            ))}
            {(!links || links.length === 0) &&
                <>
                    <LinkItem
                        key="placeholder"
                        storyAlias={props.storyAlias}
                        onReplaced={(newUrl: string, newText: string) => {
                            addLink(newUrl, newText);
                        }}
                        url={null}
                        label={null} />
                </>
            }
        </Wrapper>
    );
}

export default LinksManager;