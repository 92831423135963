import styled from "styled-components";

type EpisodeItemProps = {
    current?: boolean,
    published?: boolean,
}

const EpisodeItem = styled.div`
    padding: 6px 15px;
    margin-bottom: 10px;
    font-size: 18px;
    border: solid 1px #e2e2e2;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    ${(props: EpisodeItemProps) => props.current && `
        border: solid 2px #5e72e4;
        padding: 5px 14px;
    `}
`;

export { EpisodeItem };
