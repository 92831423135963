import React from 'react';
// import { useAuth0 } from "@auth0/auth0-react";

type Props = {
    onLoggedIn: any
}

const Login = (props: Props) => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
            
        </div>
    );
}

export default Login;