import { BlockDto,  CreateOrUpdateStoryDto, 
    InputPropertyDto, StoryApi } from '../generated-sources/openapi';
import axios from "axios";
import { default as slugify } from "slugify";
import getBlobDuration from "get-blob-duration";
import { Config } from '../config';

/**
 * Create story on backend
 */
export const createSubStory = async (item: BlockDto, 
    extraInputs: InputPropertyDto[] | null = null,
    langCode: string) => {

    // story doesn't exist, create a new one
    const tenantId = Config.getTenantId();

    const scriptType = item.type === 'placeholder' ?
        'text' : item.type // options: text, audio, uri or runkit

    let inputs : InputPropertyDto[] = [];
    inputs.push({
        key: '__scripttype',
        value: scriptType
    });
    inputs.push({ 
        key: '_description',
        value: `Created from ${process.env.REACT_APP_BRAND_NAME}`
    });
    inputs.push({ 
        key: '_creator',
        value: `${process.env.REACT_APP_BRAND_NAME}`
    });
    inputs.push({ 
        key: '_lang',
        value: langCode
    });
    inputs.push({ 
        key: 'dnd-type',
        value: scriptType
    });
    inputs.push({ 
        key: 'dnd-block',
        value: '1'
    });

    if (item.icon && item.icon !== '') {
        inputs.push({ 
            key: 'dnd-icon',
            value: item.icon
        });
    }

    // Set the rule for the snapshot title.
    
    // On Runtkit and external scripts (uri), the timestamp is 
    // preferred because they often are scheduled.

    // By default, choose the same as the story title.
    // With this rule, the placeholder title will also be inherit.
    inputs.push({ 
        key: '_snapshot-title-mode',
        value: item.type === 'runkit' || 
            item.type === 'uri' ? 'datetime' : 'storytitle'
    });

    inputs.push({ 
        key: '_audio-enabled',
        value: '1'
    });

    if (item.type === 'text') {
        inputs.push({ 
            key: '_webpost-enabled',
            value: '1'
        });
    }
    
    if (item.voicePerson) {
        inputs.push({ 
            key: '_voice-person',
            value: item.voicePerson?.name
        });
        inputs.push({ 
            key: '_voice-icon',
            value: item.voicePerson?.icon
        });
    }

    if (extraInputs) {
        inputs = inputs.concat(extraInputs);
    }

    const story: CreateOrUpdateStoryDto = {
        tenantId: tenantId,
        storyAlias: item.storyAlias ?? item.id,
        title: item.title,
        content: item.text,
        inputs: inputs,
        createSnapshot: true
    };

    return story;
}

/**
 * Create sound story on backend
 */
export const createSoundStory = async (file: File, alias: string, uploadUri: string) => {
    let inputs : InputPropertyDto[] = [];
    inputs.push({ 
        key: '__scripttype',
        value: 'audio'
    });
    inputs.push({ 
      key: '_description',
      value: `Created from ${process.env.REACT_APP_BRAND_NAME}`
    });
    inputs.push({ 
      key: '_creator',
      value: `${process.env.REACT_APP_BRAND_NAME}`
    });
    inputs.push({ 
        key: '_moderation',
        value: '0'
    });
    inputs.push({ 
      key: 'dnd-type',
      value: 'sound'
    });
    inputs.push({ 
      key: 'dnd-block',
      value: '1'
    });
    inputs.push({ 
      key: 'file-size',
      value: file.size.toString()
    });
    inputs.push({ 
      key: 'file-type',
      value: file.type
    });

    inputs.push({ 
      key: '_audio-enabled',
      value: '1'
    });
    inputs.push({ 
      key: '_audio-uri',
      value: uploadUri
    });

    const duration = await getBlobDuration(file);
    inputs.push({ 
        key: '_audio-duration',
        value: duration.toString()
    });

    const slug = slugify(alias);
    const tenantAlias = Config.getTenantAlias();
    const storyAlias = `${tenantAlias}-${slug}`;

    const story: CreateOrUpdateStoryDto = {
      tenantId: Config.getTenantId(),
      storyAlias: storyAlias,
      title: `${alias}`,
      content: uploadUri,
      inputs: inputs,
      createSnapshot: true
    };

    return story;
}

export const createLocalixHighlightAsStory = async (storyApi: StoryApi, highlightId: string) => {
    // get highlight from Localix API
    const lxSource = 'app'; // TODO: should be changed to RadioX
    const lxAccountId = process.env.REACT_APP_LOCALIX_ACCOUNTID;
    const apiEndpoint = `${process.env.REACT_APP_LOCALIX_HOSTNAME}/highlight/` + 
        `${highlightId}?source=${lxSource}&accountId=${lxAccountId}`;

    const apiResult = await axios.get(apiEndpoint);
    const highlight = apiResult.data;

    // TODO: get points from the highlight

    const storyAlias = `lx-${highlightId}`;
    const block: BlockDto = {
        type: 'text',
        id: highlightId,
        title: highlight.title,
        text: highlight.description,
        storyAlias: storyAlias
    };

    let extraInputs: InputPropertyDto[] = [];
    extraInputs.push({ 
        key: 'localix-geoarea',
        value: highlight.geoAreaLabel
    });

    // create the story in the backend
    const storyCreateData = await createSubStory(block, extraInputs, 'sv-se');
    storyApi.createOrUpdateStory(storyCreateData).then(async (result: any) => {
        if (result.status !== 200) {
            console.error(result.statusText);
        } else {
            console.debug(`story '${block.storyAlias}' created`);
        }
    });

    return block;
}


export const removeFileExtension = (fileName: string) => {
    return fileName.replace(/\.[^/.]+$/, '');
}