import React from 'react';
import { PreviewCloseIcon, Preview } from "./styled";

type Props = {
    content?: string,
    onClose: any
}

const MarkupPreview = (props: Props) => {
    return (
        <Preview onDoubleClick={props.onClose}>
            <PreviewCloseIcon onClick={props.onClose}>x</PreviewCloseIcon>
            <div dangerouslySetInnerHTML={{ __html: props.content?.replaceAll('\n', '<br/>') ?? '' }}></div>
        </Preview>
    );
}

export default MarkupPreview;