import styled from "styled-components";

const Container = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    @media only screen and (min-width: 600px) {
        overflow: auto;
    }

    @media only screen and (min-width: 600px) {
        padding: 40px;
    }
`;

const StoryItem = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border: solid 1px #e2e2e2;
    border-radius: 8px;
    min-height: 70px;
    cursor: pointer;
    width: calc(100% - 20px);

    @media only screen and (min-width: 600px) {
        width: 600px;
    }
`;

const StatusIcon = styled.div`
    margin-left: 0;
    margin-right: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;

    @media only screen and (min-width: 600px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

const Header = styled.div`
    font-size: 22px;
    margin-left: 12px;
    margin-bottom: 30px;
`;

const Loading = styled.div`
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 20px;
`;

const PodcastIcon = styled.div`
    width: 60px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 15px;
    font-style: italic;

    img {
        width: 60px;
        height: 60px;
    }
`;

const EpisodeInfo = styled.div`
    margin-left: auto;
    font-size: 11px;
    width: 130px;
    display: none;

    @media only screen and (min-width: 600px) {
        display: block;
    }
`;

const LiveBox = styled.div`
    width: 50px;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: red;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-style: italic;
    margin-right: 15px;

    /*
    .live-icon {
        display: inline-block;
        top: calc(50% - 5px);
        background-color: red;
        width: 10px;
        height: 10px;
        margin-left: 20px;
        border: 1px solid rgba(black, .1);
        border-radius: 50%;
        z-index: 1;

        &:before{
            content: '';
            display: block;
            position: absolute;
            background-color: rgba(red,.6);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            animation: live 2s ease-in-out -1s infinite;
            z-index: -1;
        }
    }
    @keyframes live {
        0% {
            transform: scale(1,1);
        }
        100% {
            transform: scale(3.5,3.5);
            background-color: rgba(red,0);
        }
    }
    */
`;

export { Container, StoryItem, Header, Loading, 
    StatusIcon, LiveBox, PodcastIcon, EpisodeInfo };