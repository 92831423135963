import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    max-width: 700px;
`;

type BgSoundItemProps = {
    current?: boolean,
}

const BgSoundItem = styled.div`
    display: flex;
    align-items: center;
    width: 170px;
    padding: 6px 15px;
    background-color: #303030;
    border-radius: 5px;
    cursor: pointer;

    .title {
        flex: 1;
        flex-basis: 100%;
        font-size: 13px;
        font-weight: 500;
        color: #f7f7f7;
    }

    .icon {
        flex: 1;
        width: 32px;
        margin: auto;
    }

    ${(props: BgSoundItemProps) => props.current && `
        border: solid 2px #5e72e4;
        padding: 5px 14px;
    `}
`;

const ReplacePrompt = styled.div`
    margin-bottom: 30px;
    padding: 20px;
    border: solid 1px #898989;
    border-radius: 10px;
`;

const DropHere = styled.div`
    position: absolute;
    top: 0px;
    bottom: 10px;
    left: 0px;
    right: 0px;
    font-size: 28px;
    display: flex;
    align-items: center;
    color: red;
    text-align: center;
`;

const Uploading = styled.div`
    position: absolute;
    top: 0px;
    bottom: 10px;
    left: 0px;
    right: 0px;
    font-size: 28px;
    display: flex;
    align-items: center;
    text-align: center;
`;

type AddFileInstructionProps = {
    small?: boolean,
}

const AddFileInstruction = styled.div`
    flex: 1;
    width: 100%;
    margin-top: 30px;
    color: #898989;
    cursor: pointer;
    text-align: center;
    font-size: 15px;

    ${(props: AddFileInstructionProps) => props.small && `
        font-size: 12px;
    `}
`;

export { Grid, BgSoundItem, DropHere, Uploading, 
    ReplacePrompt, AddFileInstruction };