import styled from "styled-components";

const Container = styled.div`
    background-color: #454545;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 40px;
    min-height: 100px;
`;

const RecordingList = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export { Container, RecordingList };