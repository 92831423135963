import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    max-width: 700px;
    max-height: 500px;
    overflow: scroll;
`;

type CopyToStoryItemProps = {
    selected?: boolean,
}

const CopyToStoryItem = styled.div`
    cursor: pointer;
    user-select: none;
    border: solid 2px transparent;

    font-size: 17px;
    background-color: #f7f7f7;
    color: #808080;
    border-radius: 5px;
    padding: 10px;

    ${(props: CopyToStoryItemProps) => props.selected && `
        border: solid 2px red;
    `}
`;

const ItemTitle = styled.div`
    font-size: 15px;
    margin-bottom: 6px;
    margin-top: 6px;
`;

const ItemStatus = styled.div`
    font-size: 11px;
`;

export { Grid, CopyToStoryItem, ItemTitle, ItemStatus };