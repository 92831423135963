import React, { useEffect, useState } from "react";
import { StoryApi, SnapshotDto, SnapshotPatchInputDto } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { useTranslation } from "react-i18next";
import { Button, Code, FormControl, FormHelperText, FormLabel, Input, Modal, ModalBody, 
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea,
  useClipboard } from "@chakra-ui/react";
import { FeatureFlags } from "../../utils/featureFlags";

type Props = {
  storyAlias: string,
  currentSnapshotKey: string | undefined,
  snapshot: SnapshotDto | null,
  onChange: any
}

const EpisodeMetadataModal = (props: Props) => {
  const { t } = useTranslation();
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

  const [newTitle, setNewTitle] = useState<string>();
  const [newDescription, setNewDescription] = useState<string>();
  const [snapshotKey, setSnapshotKey] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { onCopy } = useClipboard(snapshotKey ?? '');

  useEffect(() => {
    setNewTitle(props.snapshot?.title);
    setNewDescription(props.snapshot?.description ?? '');
    setSnapshotKey(props.snapshot?.snapshotKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentSnapshotKey]);

  const save = async () => {
    setIsSaving(true);
    if (!hasChange()) throw new Error(`can't save with no changes`);

    // update snapshot in backend
    const snapshotPatch: SnapshotPatchInputDto = {
      title: newTitle,
      description: newDescription
    }
    const titleChangeResult = await storyApi
      .patchSnapshot(props.storyAlias, props.currentSnapshotKey!, snapshotPatch);
    if (titleChangeResult.status !== 200) {
      throw new Error(`failed to save title: ${newTitle}`);
    }

    props.onChange({
      saved: true,
      title: newTitle
    });
  };

  const cancel = () => {
    if (!isSaving) { 
      props.onChange({
        saved: false
      });
    }
  };

  const hasChange = () => {
    if (newTitle !== props.snapshot?.title) {
      return true;
    }
    if (props.snapshot?.publishDateTime && 
      newDescription !== props.snapshot?.description) {
      return true;
    }
    return false;
  };

  return (
    <Modal size={'3xl'} isOpen={true} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('episodeMetadataHeader')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>

          <FormControl id="title" isRequired style={{ marginBottom: 20 }}>
            <FormLabel>Episode title</FormLabel>
            <Input type="text" value={newTitle} 
              onChange={(ev) => { setNewTitle(ev.target.value) }} />
            <FormHelperText>
              Will be shown in all podcast players. Max 150 chars.
              Has been generated from a template, which can be edited in <a href="https://editor.briefly.so" target="_blank" rel="noreferrer">Briefly.Dev</a> by administrators.
            </FormHelperText>
          </FormControl>

          {<FormControl id="description" isDisabled={!props.snapshot?.publishDateTime}>
            <FormLabel>Episode description</FormLabel>
            <Textarea rows={6} 
              value={newDescription}
              onChange={(ev) => { setNewDescription(ev.target.value) }}
               />
            <FormHelperText>
              {!props.snapshot?.publishDateTime ? 
                `Episode description will be generated from template when publishing. ` +
                `You'll be able to edit it afterward.` : 
                `Markdown supported. It'll be shown in all podcast players.`}
            </FormHelperText>
          </FormControl>}

          {FeatureFlags.developer && 
            <Code onClick={onCopy} style={{ marginTop: 20 }}>{snapshotKey}</Code>}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brand" mr={3} 
            isLoading={isSaving}
            disabled={isSaving || !hasChange()} 
            onClick={save}>{t('save')}</Button>
          <Button variant="ghost" onClick={cancel}>{t('cancel')}</Button>
        </ModalFooter>

      </ModalContent>
    </Modal>
  );
};

export default EpisodeMetadataModal;
