import styled from "styled-components";

const Container = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    @media only screen and (min-width: 600px) {
        overflow: auto;
    }

    @media only screen and (min-width: 600px) {
        padding: 40px;
    }
`;

type ItemProps = {
    current?: boolean;
}

const Item = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    margin: 10px;
    border: solid 2px #e2e2e2;
    border-radius: 8px;
    min-height: 70px;
    cursor: pointer;
    width: calc(100% - 20px);

    ${(props: ItemProps) => props.current && 
        "border-color: var(--color-persistent-brand);"}

    @media only screen and (min-width: 600px) {
        width: 380px;
    }
`;

const Header = styled.div`
    font-size: 22px;
    margin-left: 12px;
    margin-bottom: 30px;
`;

const Title = styled.div`
    font-size: 15px;
`;

const StoryIcon = styled.div`
    width: 60px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 15px;
    font-style: italic;

    img {
        width: 60px;
        height: 60px;
    }
`;

export { Container, Header,  Item, Title, StoryIcon };