import styled from "styled-components";

const ScrollableList = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    padding-bottom: 20px;
    max-height: 600px;

    @media only screen and (max-width: 600px) {
        max-height: 490px; // TODO: max height
    }
    
    ::-webkit-scrollbar {
        height: 8px;
        width: 0px;
    }
    ::-webkit-scrollbar-track-piece {
        background: rgba(9,30,66,.08);
    }
`;

const PlusIcon = styled.div`
    font-size: 14px;
    color: #898989;
    padding: 0 20px 0 20px;
    border: solid 1px transparent;
    margin-bottom: 10px;
    cursor: pointer;
    :hover {
        color: #c0c0c0;
    }
    :focus { outline:0; }
`;

const TimeSuffix = styled.div`
    font-size: 11px;
`;

export { ScrollableList, PlusIcon, TimeSuffix };
