/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { BlockDto, CreateOrUpdateStoryDto, 
  InputPropertyDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { useTranslation } from "react-i18next";
import {
  Button, FormControl, FormHelperText, FormLabel, Input,
  Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, Select, Stack
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

import * as StoryFunctions from "../../utils/storyFunctions";
import * as BlockFunctions from "../../utils/blockFunctions";

type Props = {
  onClose: any
}
const CreateStationModal = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const tenantId = sessionStorage.getItem('TENANT_ID')!;

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [stationTitle, setStationTitle] = useState<string>('');
  const [langCode, setLangCode] = useState<string>('sv-se');

  useEffect(() => {
    const titleIsValid = stationTitle !== '' && stationTitle.length > 0;
    setValid(titleIsValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationTitle]);

  const cancel = () => {
    props.onClose();
  }

  const create = async () => {
    setIsCreating(true);

    const subStoryTitle = t('newStationExampleTitle');
    const subStoryText = t('newStationExampleContent');

    const newStoryAlias = getSlugFromTitle(stationTitle);

    // Create a first sub story
    const id = `${newStoryAlias}-first`;
    let blockItem: BlockDto = {
      id: id,
      type: 'text',
      title: subStoryTitle,
      text: subStoryText,
      voicePerson: BlockFunctions.getDefaultVoicePerson(langCode)
    };

    const firstBlockInputs: InputPropertyDto[] = [];

    const firstSubStory = await StoryFunctions
      .createSubStory(blockItem, firstBlockInputs, langCode);

    const firstSubStoryResult = await storyApi
      .createOrUpdateStory(firstSubStory);

    if (firstSubStoryResult.status !== 200 || !firstSubStoryResult.data.success) {
      console.error(firstSubStoryResult.data.message);
    } else {
      console.log(`sub story '${firstSubStory.storyAlias}'` +
        ` has been created created`);
    }

    // Create the story for the podcast itself
    const newStoryContent = `[${firstSubStory.storyAlias}]`;

    let inputs: InputPropertyDto[] = [];
    inputs.push({
      key: '__scripttype',
      value: 'mixmode'
    });
    inputs.push({
      key: '_description',
      value: `Created in ${process.env.REACT_APP_BRAND_NAME}`
    });
    inputs.push({
      key: '_mixmode',
      value: '1'
    });
    inputs.push({
      key: '_lang',
      value: langCode
    });
    inputs.push({
      key: '_creator',
      value: process.env.REACT_APP_BRAND_NAME
    });
    inputs.push({
      key: '_moderation',
      value: '1'
    });
    inputs.push({
      key: '_snapshot-title-mode',
      value: 'date'
    });
    inputs.push({
      key: '_snapshot-title-dateformat',
      value: 'dddd d MMMM'
    });
    inputs.push({
      key: '_podcast-enabled',
      value: '1'
    });
    inputs.push({
      key: '_radio-enabled',
      value: '0'
    });

    const tenantName = sessionStorage.getItem('TENANT_NAME')!;
    inputs.push({
      key: '_podcast-copyright',
      value: tenantName
    });
    inputs.push({
      key: '_podcast-author-email',
      value: 'support@radiox.se'
    });
    inputs.push({
      key: '_podcast-author-name',
      value: tenantName
    });
    inputs.push({
      key: '_podcast-title',
      value: stationTitle
    });
    inputs.push({
      key: '_podcast-categories',
      value: 'News'
    });

    const story: CreateOrUpdateStoryDto = {
      tenantId: tenantId,
      storyAlias: newStoryAlias,
      title: stationTitle,
      content: newStoryContent,
      inputs: inputs,
      createSnapshot: true
    };

    const createResult = await storyApi.createOrUpdateStory(story);
    if (createResult.status === 200 && createResult.data.success) {
      // Open the created story
      history.push(`/${newStoryAlias}`)
      setIsCreating(false);
    } else {
      console.error(`failed to create story: ${createResult.data.message}`);
      setIsCreating(false);
    }
  }

  const getSlugFromTitle = (title: string) => {
    return slugify(title);
  }

  const slugify = (text: string) => {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeeiiiiooooouuuunc------';

    const newText = text
        .split('')
        .map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));

    return newText
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-y-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  }

  return (
    <Modal size={'2xl'} isOpen={true} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('createPodcastHeader')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>

          <Stack spacing={3}>

            <FormControl id="title" isRequired>
              <FormLabel htmlFor="titleField">{t('podcastTitle')}</FormLabel>
              <Input id="titleField" variant="outline" size="lg" value={stationTitle}
                onChange={(ev: any) => { setStationTitle(ev.target.value) }} />
              <FormHelperText>
                {t('podcastTitleHelpText')}
              </FormHelperText>
            </FormControl>

            <FormControl id="language">
              <FormLabel htmlFor="langField">{t('changeLanguage')}</FormLabel>
              <Select id="langField" variant="outline" size="lg" value={langCode}
                onChange={(ev: any) => { setLangCode(ev.target.value) }}>
                <option value="sv-se">Swedish</option>
                <option value="en-us">English (US)</option>
                <option value="en-gb">English (UK)</option>
                <option value="nb-no">Norwegian</option>
                <option value="da-dk">Danish</option>
                <option value="ar-xa">Arabic</option>
              </Select>
              <FormHelperText>
                {t('chooseLanguageHelpText')}
              </FormHelperText>
            </FormControl>

          </Stack>

        </ModalBody>

        <ModalFooter>
          <Button size="lg" mr={3} onClick={create} disabled={!valid}
            isLoading={isCreating} spinner={<BeatLoader size={8} color="white" />}>
            {t('createPodcast')}
          </Button>
        </ModalFooter>

      </ModalContent>
    </Modal>
  );
};

export default CreateStationModal;
