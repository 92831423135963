import React, { useEffect, useRef, useState } from 'react';
import { Container } from './styled';
import { Config } from "../../config";
import { CreateOrUpdateStoryDto, InputPropertyDto, StoryApi } from '../../generated-sources/openapi';

// https://github.com/amagitechnologies/react-runkit-embed
import Embed from 'runkit-embed-react';

type Props = {
    storyAlias: string,
    onExecuted: any,
}

const RunkitEditor = (props: Props) => {
    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const runkitEditor = useRef();
    const [sourceCode, setSourceCode] = useState('');

    useEffect(() => {
        // Note: block manager manages snapshots, 
        // this is used to edit the story/brief/template
        (async function loadStoryContent() {
            const tenantId = sessionStorage.getItem('TENANT_ID')!;
            const contentResult = await storyApi.getStoryContent(props.storyAlias, tenantId);
            if (contentResult.data.content) {
                setSourceCode(contentResult.data.content!);
            } else {
                console.warn(`no story content`);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.storyAlias]);

    const save = async () => {
        const e: any = runkitEditor.current!;
        await e.getSource((code: string) => { saveToBackend(code)});
    };

    const getRunkitUrl = async () => {
        const e: any = runkitEditor.current!;
        return await e.getURL();
    };

    const saveToBackend = async (sourceCode: string) => {
        // Save the source code back to Briefly backend.
        // Does not execute it, only save the content.
        const runkitUrl = await getRunkitUrl();
        const endpointUrl = getRunkitEndpoint(runkitUrl);

        const tenantId = sessionStorage.getItem('TENANT_ID')!;

        let inputs : InputPropertyDto[] = [];
        inputs.push({ 
            key: 'runkit-endpoint',
            value: endpointUrl
        });

        const story: CreateOrUpdateStoryDto = {
            tenantId: tenantId,
            storyAlias: props.storyAlias,
            content: sourceCode,
            title: props.storyAlias,
            inputs: inputs
        };

        const createResult = await storyApi.createOrUpdateStory(story);
        if (createResult.status === 200 && createResult.data.success) {
            console.log(`saved successfully to backend.`);
            // TODO: create a new snapshot?
            props.onExecuted();
        } else {
            throw new Error(`failed to save runkit to backend: ${createResult.data.message}`);
        }
    };

    const getRunkitEndpoint = (url: string) => {
        // example: https://runkit.com/embed/zd4z0ktrpok2
        const slug = url.substr(url.lastIndexOf('/')+1);
        return `https://embed-${slug}.runkit.sh`;
    }

    // TODO: environment
    // TODO: mode

    return (
        <Container>
            <Embed ref={runkitEditor} 
                source={sourceCode} 
                preamble={`
                    const fetch = require("async-get-json");
                    const briefly = require("infeed");
                    const endpoint = require("@runkit/runkit/json-endpoint/1.0.0");
                `}
                title={`Briefly: ${props.storyAlias}`}
                onSave={(e: any) => { console.log('saving...'); }} 
                onEvaluate={(e: any) => { save() }} 
                minHeight='300px' />
        </Container>
    );
}

export default RunkitEditor;