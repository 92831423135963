const lixCalcA = (text: string) => {
    const m = text.match(/[æøå\w]+/g);
    if (m === null) {
        return 0;
    } else {
        return m.length;
    }
}

// Count the number of periods and other symbols 
// signifying a new sentence.
const lixCalcB = (text: string) => {
    const m = text.match(/[.:;!?]+/g);
    if (m === null) {
        return 0;
    } else {
        return m.length;
    }
}

// Count the number of long words, i.e. words with 6 letters or more.
const lixCalcC = (text: string) => {
    const m = text.match(/[æøå\w]{6}[æøå\w]*\b/g);
    if (m === null) {
        return 0;
    } else {
        return m.length;
    }
}

const LixCalculator = (text: string) => {
    if (text.length < 20) {
        // Ignore on texts less then 20 chars.
        return 0;
    }

    const a = lixCalcA(text);
    if (a === 0) {
        // The text must contain at least one word.
        return 0;
    }

    const b = lixCalcB(text);
    if (b === 0) {
        // The text must contain at least one period 
        // or other sentence separator.
        return 0;
    }

    const c = lixCalcC(text);

    let lix: number = a / b + c * 100 / a;
    lix = Math.floor(lix + 0.5);

    return lix;
}


export default LixCalculator;