import styled from "styled-components";

const Wrapper = styled.div`
    background-color: #f7f7f7;
`;

const DropHere = styled.div`
    position: absolute;
    top: 0px;
    bottom: 10px;
    left: 0px;
    right: 0px;
    font-size: 28px;
    display: flex;
    align-items: center;
    color: red;
    text-align: center;
`;

const Uploading = styled.div`
    position: absolute;
    top: 0px;
    bottom: 10px;
    left: 0px;
    right: 0px;
    font-size: 28px;
    display: flex;
    align-items: center;
    text-align: center;
`;

const AddFileInstruction = styled.div`
    flex: 1;
    width: 100%;
    margin-top: 30px;
    color: #898989;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
`;

export { Wrapper, DropHere, Uploading, AddFileInstruction };