import React from "react";
import { ActionListContainer, ActionListItem, 
    Description, EmojiContainer, EmojiAsImage } from "./styled";
import { Emoji } from "emoji-mart";
import { FeatureFlags } from "../../utils/featureFlags";
import { useTranslation } from "react-i18next";

type Props = {
    showTimeStamps?: boolean
};

const ActionList = (props: Props) => {
    const { t } = useTranslation();

    const openRssUrl = () => {

    }

    const openLandingPage = () => {
        
    }

    return (
        <ActionListContainer>
            <ActionListItem>
                <EmojiContainer><Emoji emoji='writing_hand' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_metaData')}</Description>
            </ActionListItem>
            {FeatureFlags.podcast && <ActionListItem>
                <EmojiContainer><Emoji emoji='headphones' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_optimizeAudio')}</Description>
                {/* {props.showTimeStamps && <Time>09:51</Time>} */}
            </ActionListItem>}
            {FeatureFlags.podcast && <ActionListItem>
                <EmojiContainer><Emoji emoji='rocket' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_sendToPlayers')}</Description>
                {/* {props.showTimeStamps && <Time>10:00</Time>} */}
            </ActionListItem>}
            {FeatureFlags.podcast && <ActionListItem>
                <EmojiContainer><Emoji emoji='frame_with_picture' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_mainImage')}</Description>
                {/* {props.showTimeStamps && <Time>10:01</Time>} */}
            </ActionListItem>}
            {FeatureFlags.podcast && <ActionListItem onClick={openRssUrl}>
                <EmojiAsImage><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyNCIgdmVyc2lvbj0iMS4xIiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTEwMjguNCkiPjxnPjxwYXRoIGQ9Im00IDEwMzEuNGMtMS4xMDQ2IDAtMiAwLjktMiAydjE2YzAgMS4xIDAuODk1NCAyIDIgMmgxNmMxLjEwNSAwIDItMC45IDItMnYtMTZjMC0xLjEtMC44OTUtMi0yLTJoLTE2eiIgZmlsbD0iI2QzNTQwMCIvPjxwYXRoIGQ9Im00IDJjLTEuMTA0NiAwLTIgMC44OTU0LTIgMnYxNmMwIDEuMTA1IDAuODk1NCAyIDIgMmgxNmMxLjEwNSAwIDItMC44OTUgMi0ydi0xNmMwLTEuMTA0Ni0wLjg5NS0yLTItMmgtMTZ6IiBmaWxsPSIjZTY3ZTIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEwMjguNCkiLz48cGF0aCBkPSJtNSAxMDM0LjR2Mi4zYzYuNDQzIDAgMTEuNjY3IDUuMiAxMS42NjcgMTEuN2gyLjMzM2MwLTcuOC02LjI2OC0xNC0xNC0xNHptMCA0LjZ2Mi40YzMuODY2IDAgNyAzLjEgNyA3aDIuMzMzYzAtNS4yLTQuMTc4LTkuNC05LjMzMy05LjR6bTIuMDQxNyA1LjNjLTEuMTI3NiAwLTIuMDQxNyAwLjktMi4wNDE3IDJzMC45MTQxIDIuMSAyLjA0MTcgMi4xYzEuMTI3NSAwIDIuMDQxNi0xIDIuMDQxNi0yLjFzLTAuOTE0MS0yLTIuMDQxNi0yeiIgZmlsbD0iI2QzNTQwMCIvPjxwYXRoIGQ9Im01IDEwMzMuNHYyLjNjNi40NDMgMCAxMS42NjcgNS4yIDExLjY2NyAxMS43aDIuMzMzYzAtNy44LTYuMjY4LTE0LTE0LTE0em0wIDQuNnYyLjRjMy44NjYgMCA3IDMuMSA3IDdoMi4zMzNjMC01LjItNC4xNzgtOS40LTkuMzMzLTkuNHptMi4wNDE3IDUuM2MtMS4xMjc2IDAtMi4wNDE3IDAuOS0yLjA0MTcgMnMwLjkxNDEgMi4xIDIuMDQxNyAyLjFjMS4xMjc1IDAgMi4wNDE2LTEgMi4wNDE2LTIuMXMtMC45MTQxLTItMi4wNDE2LTJ6IiBmaWxsPSIjZWNmMGYxIi8+PC9nPjwvZz48L3N2Zz4=" alt="RSS" /></EmojiAsImage>
                <Description>{t('actionList_rssFeed')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:00</Time>} */}
            </ActionListItem>}
            {FeatureFlags.podcast && <ActionListItem onClick={openLandingPage}>
                <EmojiContainer><Emoji emoji='house' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_landingPage')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:01</Time>} */}
            </ActionListItem>}
            {FeatureFlags.webPosts && <ActionListItem>
                <EmojiContainer><Emoji emoji='newspaper' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_webPosts')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:01</Time>} */}
            </ActionListItem>}
            {FeatureFlags.podcast && <ActionListItem>
                <EmojiContainer><Emoji emoji='incoming_envelope' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_emailSending')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:02</Time>} */}
            </ActionListItem>}
            {(FeatureFlags.fbPost || FeatureFlags.tweet) && <ActionListItem>
                <EmojiContainer><Emoji emoji='heart_eyes' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_socialMedia')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:01</Time>} */}
            </ActionListItem>}
            {FeatureFlags.tweet && <ActionListItem>
                <EmojiAsImage><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMTAwJSIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjEwMCUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGhlaWdodD0iNDAwIiBzdHlsZT0iZmlsbDpub25lOyIgd2lkdGg9IjQwMCIgeD0iNTYiIHk9IjU2Ii8+PHBhdGggZD0iTTE2MS4wMTQsNDY0LjAxM2MxOTMuMjA4LDAgMjk4Ljg4NSwtMTYwLjA3MSAyOTguODg1LC0yOTguODg1YzAsLTQuNTQ2IDAsLTkuMDcyIC0wLjMwNywtMTMuNTc4YzIwLjU1OCwtMTQuODcxIDM4LjMwNSwtMzMuMjgyIDUyLjQwOCwtNTQuMzc0Yy0xOS4xNzEsOC40OTUgLTM5LjUxLDE0LjA2NSAtNjAuMzM0LDE2LjUyN2MyMS45MjQsLTEzLjEyNCAzOC4zNDMsLTMzLjc4MiA0Ni4xODIsLTU4LjEwMmMtMjAuNjE5LDEyLjIzNSAtNDMuMTgsMjAuODU5IC02Ni43MDMsMjUuNDk4Yy0xOS44NjIsLTIxLjEyMSAtNDcuNjAyLC0zMy4xMTIgLTc2LjU5MywtMzMuMTEyYy01Ny42ODIsMCAtMTA1LjE0NSw0Ny40NjQgLTEwNS4xNDUsMTA1LjE0NGMwLDguMDAyIDAuOTE0LDE1Ljk3OSAyLjcyMiwyMy43NzNjLTg0LjQxOCwtNC4yMzEgLTE2My4xOCwtNDQuMTYxIC0yMTYuNDk0LC0xMDkuNzUyYy0yNy43MjQsNDcuNzI2IC0xMy4zNzksMTA5LjU3NiAzMi41MjIsMTQwLjIyNmMtMTYuNzE1LC0wLjQ5NSAtMzMuMDcxLC01LjAwNSAtNDcuNjc3LC0xMy4xNDhsMCwxLjMzMWMwLjAxNCw0OS44MTQgMzUuNDQ3LDkzLjExMSA4NC4yNzUsMTAyLjk3NGMtMTUuNDY0LDQuMjE3IC0zMS42OTMsNC44MzMgLTQ3LjQzMSwxLjgwMmMxMy43MjcsNDIuNjg1IDUzLjMxMSw3Mi4xMDggOTguMTQsNzIuOTVjLTM3LjE5LDI5LjIyNyAtODMuMTU3LDQ1LjEwMyAtMTMwLjQ1OCw0NS4wNTZjLTguMzU4LC0wLjAxNiAtMTYuNzA4LC0wLjUyMiAtMjUuMDA2LC0xLjUxNmM0OC4wMzQsMzAuODI1IDEwMy45NCw0Ny4xOCAxNjEuMDE0LDQ3LjEwNCIgc3R5bGU9ImZpbGw6IzFkYTFmMjtmaWxsLXJ1bGU6bm9uemVybzsiLz48L3N2Zz4=" alt="Twitter" /></EmojiAsImage>
                <Description>{t('actionList_tweet')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:10</Time>} */}
            </ActionListItem>}
            {FeatureFlags.fbPost && <ActionListItem>
                <EmojiAsImage><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMTAwJSIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjEwMCUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwYXRoIGQ9Ik01MTIsMjU2YzAsLTE0MS4zODUgLTExNC42MTUsLTI1NiAtMjU2LC0yNTZjLTE0MS4zODUsMCAtMjU2LDExNC42MTUgLTI1NiwyNTZjMCwxMjcuNzc3IDkzLjYxNiwyMzMuNjg1IDIxNiwyNTIuODlsMCwtMTc4Ljg5bC02NSwwbDAsLTc0bDY1LDBsMCwtNTYuNGMwLC02NC4xNiAzOC4yMTksLTk5LjYgOTYuNjk1LC05OS42YzI4LjAwOSwwIDU3LjMwNSw1IDU3LjMwNSw1bDAsNjNsLTMyLjI4MSwwYy0zMS44MDEsMCAtNDEuNzE5LDE5LjczMyAtNDEuNzE5LDM5Ljk3OGwwLDQ4LjAyMmw3MSwwbC0xMS4zNSw3NGwtNTkuNjUsMGwwLDE3OC44OWMxMjIuMzg1LC0xOS4yMDUgMjE2LC0xMjUuMTEzIDIxNiwtMjUyLjg5WiIgc3R5bGU9ImZpbGw6IzE4NzdmMjtmaWxsLXJ1bGU6bm9uemVybzsiLz48cGF0aCBkPSJNMzU1LjY1LDMzMGwxMS4zNSwtNzRsLTcxLDBsMCwtNDguMDIyYzAsLTIwLjI0NSA5LjkxNywtMzkuOTc4IDQxLjcxOSwtMzkuOTc4bDMyLjI4MSwwbDAsLTYzYzAsMCAtMjkuMjk3LC01IC01Ny4zMDUsLTVjLTU4LjQ3NiwwIC05Ni42OTUsMzUuNDQgLTk2LjY5NSw5OS42bDAsNTYuNGwtNjUsMGwwLDc0bDY1LDBsMCwxNzguODljMTMuMDMzLDIuMDQ1IDI2LjM5MiwzLjExIDQwLDMuMTFjMTMuNjA4LDAgMjYuOTY2LC0xLjA2NSA0MCwtMy4xMWwwLC0xNzguODlsNTkuNjUsMFoiIHN0eWxlPSJmaWxsOiNmZmY7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==" alt="Facebook" /></EmojiAsImage>
                <Description>{t('actionList_facebook')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:10</Time>} */}
            </ActionListItem>}
            <ActionListItem>
                <EmojiContainer><Emoji emoji='chipmunk' set='apple' size={20} /></EmojiContainer>
                <Description>{t('actionList_stats')}</Description>
                {/* {props.showTimeStamps === true && <Time>10:00</Time>} */}
            </ActionListItem>
        </ActionListContainer>
    );
}

export default ActionList;
