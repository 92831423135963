import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parser from "cron-parser";
import { format, formatRelative, addMinutes } from "date-fns";
import { Destination, SnapshotDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import i18next from "i18next";
import { sv } from 'date-fns/locale';
import { FeatureFlags } from "../../utils/featureFlags";

import { Button, Link2 } from "../styled";
import CustomAudioPlayer from "../AudioPlayer";
import PercentageTimer from "../PercentageTimer";
import ActionList from "./ActionList";

import Lottie from "react-lottie-player";
import lottieProcessing from "../../assets/animations/processing2.json";
import lottieSuccess from "../../assets/animations/success.json";

type Props = {
  enabled: boolean,
  storyAlias: string,
  currentSnapshot: SnapshotDto,
  publishCron: string | null,
  estimatedTime: number | null,
  blockItems: any,
  generate: any,
  restart: any
};

const Generate = (props: Props) => {
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);
  const [generatingResult, setGeneratingResult] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [audioProcessingTimer, setAudioProcessingTimer] = useState<any>();
  const [audioUri, setAudioUri] = useState<string | null>('');
  const [bgJobId, setBgJobId] = useState<string | null>('');
  const [hasBeenGenerated, setHasBeenGenerated] = useState(true);
  const [isPublished, setIsPublished] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [hasBeenPublished, setHasBeenPublished] = useState(false);
  const [hasBeenScheduled, setHasBeenScheduled] = useState(false);
  const [estimatedTimeAsString, setEstimatedTimeAsString] = useState<string | null>('');
  const [schedulePublishTimestamp, setSchedulePublishTimestamp] = useState<Date | null>(); // Note: UTC time

  useEffect(() => {
    reset();

    if (props.currentSnapshot !== null) {
      if (props.currentSnapshot!.audioUri) {
        setAudioUri(props.currentSnapshot!.audioUri!);
      } else {
        setAudioUri(null);
      }
      setIsPublished(props.currentSnapshot.enabled ?? false);
      calculatePublishTimestamp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentSnapshot]);

  useEffect(() => {
    if (props.estimatedTime) {
      const divideRule: number = 3;
      const minutes = (Math.round((props.estimatedTime / divideRule / 60) * 10) / 10);

      if (minutes < 1) {
        setEstimatedTimeAsString(`< 1 min`);
      } else {
        setEstimatedTimeAsString(`${minutes} min`);
      }
    }
  }, [props.estimatedTime]);

  const start = () => {
    generate();
    props.generate();
  };

  const calculatePublishTimestamp = () => {
    if (props.publishCron) {
      let nextOccurrence: Date;
      const publishDt = props.currentSnapshot!.publishDateTime;
      if (publishDt) {
        console.debug(`will be published ${publishDt}`);
        nextOccurrence = new Date(publishDt);
      } else {
        console.debug(`parsing ${props.publishCron}`);

        const parserInterval = parser.parseExpression(props.publishCron!, {
          currentDate: new Date(),
          utc: true
        });
        nextOccurrence = parserInterval.next().toDate();
      }

      if (nextOccurrence > new Date()) {
        /*
        If the scheduling date is less than x minutes from now,
        disable the Schedule feature and provide "Publish now" only.
        */
        const threshold = 15;
        if (nextOccurrence > addMinutes(new Date(), threshold)) {
          console.debug(`set schedule publish timestamp to ${nextOccurrence.toISOString()}`);
          setSchedulePublishTimestamp(nextOccurrence);
        } else {
          console.warn(
            `publish time is too soon (less than x minutes) ` +
            `to be used for scheduling.`);
        }
      } else {
        setSchedulePublishTimestamp(null);
      }
    }
  }

  const reset = () => {
    setHasBeenGenerated(false);
    setIsPublishing(false);
    setIsPublished(false);
    setGeneratingResult(undefined);
    setHasBeenScheduled(false);
    setHasBeenPublished(false);
    setErrorMessage(null);
  };

  /**
   * Starts generating a podcast and sets an interval for 
   * checking if it has been completed every 5 seconds
   */
  const generate = async (publishAsWell: boolean | undefined = false) => {
    if (!isProcessing) {
      setIsProcessing(true);
      setHasBeenGenerated(true);
      setAudioUri(null);

      let destinations = [Destination.Audio];
      if (publishAsWell) {
        destinations.push(Destination.PodcastEpisode);
      }

      const triggerSnapshotResult = await storyApi.triggerSnapshotAsPost(
        props.storyAlias!, props.currentSnapshot!.snapshotKey!, true, destinations);
      if (triggerSnapshotResult.status !== 200) {
          if (!triggerSnapshotResult.data.success) {
            setErrorMessage(triggerSnapshotResult.data.message!);
          }
          setIsProcessing(false);
      } else {
          const executeToken = triggerSnapshotResult.data.executeToken!;
          sessionStorage.setItem('executeToken', executeToken);

          const jobId = triggerSnapshotResult.data.bgJobId;
          if (jobId) {
            setBgJobId(jobId);
          }

          checkAudio();

          const intervalId = setInterval(() => checkAudio(), 5000);
          setAudioProcessingTimer(intervalId);
      }
    }
  };

  /**
   * Checks if audio file is ready and available
   */
  const audioAvailable = async (): Promise<boolean> => {
    try {
        const audioExecuteToken = 
          sessionStorage.getItem('executeToken')?.toString();
        const resp = await storyApi.isVoiceAvailable(props.storyAlias!, 
          props.currentSnapshot!.snapshotKey!, audioExecuteToken!);
        return resp.data.success ?? false;
    } catch (err) {
        console.warn(err);
        return false;
    }
  };

  /**
   * Fetches the URI to audio track for the story
   */
  const foundAudio = async () => {
    console.log(`found audio on the backend`);
    clearInterval(audioProcessingTimer);

    // load snapshot again with audio uri
    const snapshot = await storyApi.getSnapshot(
      props.storyAlias!, props.currentSnapshot!.snapshotKey!);

    setIsProcessing(false);
    setAudioUri(snapshot.data.audioUri!);
    setGeneratingResult(snapshot.data.audioUri!);
  };

  /**
   * Calls foundAudio if audioAvailable returns true
   */
  const checkAudio = async () => {
    (await audioAvailable()) && foundAudio();
  };

  const returnToEditing = () => {
    clearInterval(audioProcessingTimer);
    reset();
    props.restart();
  };

  const cancelBackgroundJob = async () => {
    if (bgJobId) {
      await storyApi
        .cancelBgJob(bgJobId!, props.storyAlias!);
    } else {
      console.warn(`can't cancel job because id is missing`);
    }
  };

  const publish = async () => {
    setIsPublishing(true);

    const publishResult = await storyApi
      .publishAsPodcastEpisode(props.storyAlias!,
        props.currentSnapshot!.snapshotKey!, new Date().toISOString());

    if (publishResult.status === 200 && publishResult.data.success) {
      setIsPublished(true);
      setHasBeenPublished(true);
      setIsPublishing(false);
    } else {
      setHasBeenPublished(false);
      setIsPublishing(false);
      console.error(`failed to publish: ` +
        `${publishResult.data.message}`);
    }
  };

  const schedule = async () => {
    setIsPublishing(true);

    if (!schedulePublishTimestamp) {
      throw new Error(`schedulePublishTimestamp is null or empty`);
    }

    const publishResult = await storyApi
      .publishAsPodcastEpisode(props.storyAlias!,
        props.currentSnapshot!.snapshotKey!, 
          schedulePublishTimestamp.toISOString());

    if (publishResult.status === 200 && publishResult.data.success) {
      setIsPublishing(false);
      setIsPublished(true);
      setHasBeenPublished(true);
      setHasBeenScheduled(true);
    } else {
      setHasBeenPublished(false);
      setIsPublishing(false);
      console.error(`failed to schedule: ` +
        `${publishResult.data.message}`);
    }
  };

  const updatePublishedVersion = async () => {
    setIsPublishing(true);

    const publishResult = await storyApi
      .publishAsPodcastEpisode(props.storyAlias!,
        props.currentSnapshot!.snapshotKey!, undefined);

    if (publishResult.status === 200 && publishResult.data.success) {
      setIsPublished(true);
      setHasBeenPublished(true);
      setIsPublishing(false);
    } else {
      setHasBeenPublished(false);
      setIsPublishing(false);
      console.error(`failed to re-publish: ` +
        `${publishResult.data.message}`);
    }
  }

  return (
    <div>

      {!generatingResult && !isProcessing && <div style={{ width: '100%' }}>
        <Button fullWidth 
          style={{ fontWeight: 700, border: 'solid 2px var(--color-persistent-brand)', 
          color: 'var(--color-persistent-brand)', textTransform: 'uppercase' }} 
          onClick={() => start()}>{t('generate')}</Button>
      </div>}

      {isProcessing && <div style={{ width: '100%' }}>
        <Lottie
          animationData={lottieProcessing} play loop
          style={{ width: 150, height: 150, margin: 'auto' }}
        />
        <div style={{ fontSize: 20, textAlign: 'center', cursor: 'default' }}>
          {t('recordingOngoingHeader')}
        </div>
        <div style={{ marginTop: 30, fontSize: 14, textAlign: 'center', cursor: 'default' }}>
          {props.estimatedTime ? `${estimatedTimeAsString} ` : t('recordingOngoingText')}
          <PercentageTimer estimatedTime={props.estimatedTime} />
        </div>
        
        <div 
          onClick={() => {
            cancelBackgroundJob();
            setIsProcessing(false); 
            props.restart();
          }} 
          style={{ marginTop: 30, fontSize: 11, textAlign: 'center', 
            cursor: 'pointer', color: '#ccc' }}>
          {t('cancel')}
        </div>
      </div>}

      {generatingResult && (
          <div style={{ fontSize: 17, padding: 20 }}>

            {errorMessage && <div style={{ color: 'red', fontSize: 14, fontWeight: 800 }}>
              <p>{t('anErrorOccurred')}: {errorMessage}</p>
            </div>}

            {!hasBeenPublished && hasBeenGenerated && <div>
              <p style={{ marginBottom: 10, fontSize: 18 }}>
                <strong>{t('recordingFinishedHeader')}</strong>
              </p>
              <p style={{ marginBottom: 20 }}>
                {t('recordingFinishedText')}
              </p>
            </div>}

            {audioUri && <CustomAudioPlayer
              variant={'default'}
              audioUri={audioUri} 
              duration={null} 
              recordingNewVersion={false} 
              recordingEstimatedTime={null} />}

            {!hasBeenPublished && <div style={{ marginTop: 30, textAlign: 'left' }}>
              <Link2 onClick={returnToEditing}>{t('returnToEditing')}</Link2>
            </div>}

            {!isPublished && <div style={{ marginTop: 20, textAlign: 'left' }}>
              <Button primary disabled={isPublished} 
                progress={isPublishing} onClick={schedulePublishTimestamp ? schedule : publish}>
                  {schedulePublishTimestamp ? 
                    `${t('schedulePublish')} ${format(schedulePublishTimestamp, 'HH:mm')}` : t('publish')}
              </Button>
            </div>}

            {FeatureFlags.downloadLinks && audioUri && <div style={{ marginTop: 30, textAlign: 'left' }}>
              <Link2 href={audioUri} download={`${props.currentSnapshot.title}.mp3`} target="_blank">
                {t('downloadAsFile')}
              </Link2>
            </div>}

            {!isPublished && schedulePublishTimestamp && <div style={{ marginTop: 20, textAlign: 'left' }}>
              <Link2 onClick={publish}>
                {t('publishInstantly')}
              </Link2>
            </div>}

            {isPublished && <div style={{ marginTop: 30 }}>

              {hasBeenPublished && <Lottie
                animationData={lottieSuccess}
                play
                loop={false}
                style={{ width: 100, height: 100, margin: 'auto' }}
              />}

              {hasBeenPublished && !hasBeenScheduled && <p style={{
                  marginTop: 20, marginBottom: 10, fontSize: 18, 
                  lineHeight: '140%', textAlign: 'center', width: '100%'
                }}>
                {t('hasBeenPublished')}
              </p>}

              {hasBeenPublished && hasBeenScheduled && schedulePublishTimestamp && <p style={{
                  marginTop: 20, marginBottom: 10, fontSize: 20, 
                  lineHeight: '140%', textAlign: 'center', width: '100%'
                }}>
                {t('willBePublished')} {formatRelative(schedulePublishTimestamp!, new Date(), {
                  locale: i18next.language === 'sv' ? sv : undefined
                })}
              </p>}

              {hasBeenPublished && 
                <ActionList showTimeStamps={false} />}

              {isPublished && !hasBeenPublished && 
                <div style={{ width: '100%', marginBottom: 20 }}>
                  <Button small onClick={updatePublishedVersion}>{t('updatePublishedVersion')}</Button>
                  <div style={{ display: 'none', marginTop: 10, fontSize: 12, color: '#808080' }}>
                    {t('alreadyPublishedInfo')}
                  </div>
                </div>}

              {hasBeenPublished && props.publishCron && <p style={{
                  marginTop: 20, marginBottom: 20, fontSize: 14, 
                  textAlign: 'center', color: '#c0c0c0'
                }}>
                {t('nextEpisodeWillBeGenerated')}
              </p>}

            </div>}

          </div>
      )}
    </div>
  );
};

export default Generate;
