import React, { useEffect, useState } from "react";
import { BlocksApi, SettingPublicDto, StoryApi, StorySummaryDto } from "../../generated-sources/openapi";
import { Grid, CopyToStoryItem, ItemTitle, ItemStatus } from "./styled";
import { Config } from "../../config";
import { useTranslation } from "react-i18next";
import { Alert, AlertIcon, Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, 
  ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import { PodcastIcon } from "../StationList/styled";

type Props = {
  storyAlias: string,
  onChange: any
}

const CopyToModal = (props: Props) => {
  const { t } = useTranslation();
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const blocksApi = new BlocksApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const tenantId = sessionStorage.getItem('TENANT_ID')!;
  const settingKey = 'copyto-ids';

  useEffect(() => {
    loadFromBackend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [stories, setStories] = useState<StorySummaryDto[] | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [successfullyCopied, setSuccessfullyCopied] = useState<boolean>(false);

  const loadFromBackend = async () => {
    setIsLoading(true);

    storyApi.getStoriesByTenant(tenantId, 'podcasts').then((result) => {
      setStories(result.data.filter(x => x.alias !== getCurrentStoryAlias()));
      setIsLoading(false);
    });

    getStorySetting().then((ids) => {
      if (ids) {
        setSelectedIds(ids.split(','));
      }
    });
  };

  const selectItem = (story: StorySummaryDto) => {
    if (selectedIds.some(x => x === story.alias)) {
      // remove it
      setSelectedIds(selectedIds.filter(x => x !== story.alias));
    }
    else {
      // add it
      setSelectedIds([...selectedIds, story.alias]);
    }
  };

  const saveAsStorySetting = async () => {
    const copyToIdsSettingValue: SettingPublicDto = {
      value: selectedIds.toString()
    };
    const settingSaved = await storyApi
      .updateStorySetting(getCurrentStoryAlias(), settingKey, copyToIdsSettingValue);
    // console.log(settingSaved.data.message);
  }

  const getStorySetting = async () => {
    const settingValue = await storyApi
      .getStorySetting(getCurrentStoryAlias(), settingKey, tenantId);

    return settingValue.data.value;
  };

  const run = async () => {
    setIsCopying(true);

    // let's run it
    let copyResult = await blocksApi
      .copyToOther(props.storyAlias, tenantId, selectedIds);
    if (copyResult.data.success) {
      setIsCopying(false);
      setSuccessfullyCopied(true);
    }

    // save it to the podcast as setting
    await saveAsStorySetting();
  };

  const cancel = () => {
    setIsCopying(false);
    props.onChange();
  };

  const getCurrentStoryAlias = () => {
    return sessionStorage.getItem("STORY_ALIAS")!;
  };

  return (
    <Modal size={'3xl'} isOpen={true} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('copyModalHeader')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>

          {stories?.length === 0 && <div>
            {t('noOtherStations')}
          </div>}

          {!isCopying && !successfullyCopied && <Grid>
            {isLoading && <div style={{ fontSize: 10 }}>{t('loading')}...</div>}
            {stories?.map((item) => (
              <CopyToStoryItem 
                key={item.alias} 
                onClick={() => selectItem(item)} 
                selected={selectedIds.some(x => x === item.alias)}>
                <PodcastIcon>{item.icon && <img src={item.icon} alt="" />}</PodcastIcon>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemStatus>
                  {item.latestSnapshot?.destinationAudioDateTime && 
                    <div>Har publicerats.</div>}
                </ItemStatus>
              </CopyToStoryItem>
            ))}
          </Grid>}

          {isCopying && 
            <HStack>
              <Spinner size="sm" />
              <div style={{ fontSize: 16 }}>{t('copying')}...</div>
            </HStack>
          }

          {successfullyCopied && <Alert status="success">
            <AlertIcon />
            Inslaget är nu kopierat till 
              {selectedIds.length === 1 && <span>en annan station</span>}
              {selectedIds.length > 1 && <span>{selectedIds.length} andra stationer.</span>}
          </Alert>}

        </ModalBody>

        <ModalFooter>
          {!successfullyCopied && <>
            <Button disabled={selectedIds.length===0} isLoading={isCopying} colorScheme="brand" mr={3} onClick={run}>
              {t('copyToSelected')}
            </Button>
            <Button variant="ghost" onClick={cancel}>{t('cancel')}</Button>
          </>}
          {successfullyCopied && <Button variant="ghost" onClick={cancel}>{t('close')}</Button>}
        </ModalFooter>

      </ModalContent>
    </Modal>
  );
};

export default CopyToModal;
