import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Stack, Switch, 
    NumberInput, NumberInputField, NumberInputStepper, 
    NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
    cron: string,
    onChange: any
}

const DayAndTimeScheduler = (props: Props) => {
    const { t } = useTranslation();

    const [mondayEnabled, setMondayEnabled] = useState<boolean>(false);
    const [tuesdayEnabled, setTuesdayEnabled] = useState<boolean>(false);
    const [wednesdayEnabled, setWednesdayEnabled] = useState<boolean>(false);
    const [thursdayEnabled, setThursdayEnabled] = useState<boolean>(false);
    const [fridayEnabled, setFridayEnabled] = useState<boolean>(false);
    const [saturdayEnabled, setSaturdayEnabled] = useState<boolean>(false);
    const [sundayEnabled, setSundayEnabled] = useState<boolean>(false);

    const [hours, setHours] = useState<number>(8);
    const [minutes, setMinutes] = useState<number>(0);

    useEffect(() => {
        if (props.cron) {
            // console.log(`parse cron from property: ${props.cron}`);
            parseCron(props.cron);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cron]);

    useEffect(() => {
        const newCron = calculate();
        if (newCron !== props.cron) {
            props.onChange(newCron);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hours, minutes, mondayEnabled, tuesdayEnabled, wednesdayEnabled, thursdayEnabled, 
        thursdayEnabled, fridayEnabled, saturdayEnabled, sundayEnabled]);

    const parseCron = (cron: string) => {
        if (cron.length === 0) {
            return;
        }

        // Example: 5 7 * * MON
        let pos = cron.indexOf(" ");

        const minutesStr = cron.substring(0, pos);
        setMinutes(parseInt(minutesStr));

        const hoursStr = cron.substring(pos+1, cron.indexOf(" ", pos+1));
        setHours(parseInt(hoursStr));

        let daysStr = cron.substring(cron.lastIndexOf("* ")+2);
        if (daysStr === "*") {
            daysStr = "MON,TUE,WED,THU,FRI,SAT,SUN";
        }

        const days = daysStr.split(',');
        days.forEach(day => {
            switch (day) {
                case "MON":
                    setMondayEnabled(true);
                    break;
                case "TUE":
                    setTuesdayEnabled(true);
                    break;
                case "WED":
                    setWednesdayEnabled(true);
                    break;
                case "THU":
                    setThursdayEnabled(true);
                    break;
                case "FRI":
                    setFridayEnabled(true);
                    break;
                case "SAT":
                    setSaturdayEnabled(true);
                    break;
                case "SUN":
                    setSundayEnabled(true);
                    break;
            }
        });
    }

    const calculate = (): string | null => {
        const daysExp = makeDaysExpression();

        if (daysExp.length > 0) {
            return `${minutes} ${hours} * * ${daysExp}`;
        }
        return null;
    }

    const makeDaysExpression = () => {
        let value = "";

        if (mondayEnabled && tuesdayEnabled && wednesdayEnabled && 
            thursdayEnabled && fridayEnabled && saturdayEnabled && sundayEnabled) {
            return "*";
        }

        if (mondayEnabled) {
            value += "MON,";
        }
        if (tuesdayEnabled) {
            value += "TUE,";
        }
        if (wednesdayEnabled) {
            value += "WED,";
        }
        if (thursdayEnabled) {
            value += "THU,";
        }
        if (fridayEnabled) {
            value += "FRI,";
        }
        if (saturdayEnabled) {
            value += "SAT,";
        }
        if (sundayEnabled) {
            value += "SUN,";
        }
        
        if (value.length > 0) {
            value = value.substring(0, value.length-1);
        }

        return value;
    }

    return (
        <Stack spacing={3}>
            <FormControl>
                <FormLabel htmlFor="scheduler">{t('schedulerDaysTitle')}</FormLabel>
                <Stack shouldWrapChildren isInline>
                    <label><Switch id="scheduler-day-monday" isChecked={mondayEnabled} colorScheme="brand"
                        onChange={(ev) => { setMondayEnabled(ev.target.checked) }} /> Monday</label>
                    <div><Switch id="scheduler-day-tuesday" isChecked={tuesdayEnabled}  colorScheme="brand"
                        onChange={(ev) => { setTuesdayEnabled(ev.target.checked) }} /> Tuesday</div>
                    <div><Switch id="scheduler-day-wednesday" isChecked={wednesdayEnabled} colorScheme="brand"
                        onChange={(ev) => { setWednesdayEnabled(ev.target.checked) }} /> Wednesday</div>
                    <div><Switch id="scheduler-day-thursday" isChecked={thursdayEnabled} colorScheme="brand"
                        onChange={(ev) => { setThursdayEnabled(ev.target.checked) }} /> Thursday</div>
                    <div><Switch id="scheduler-day-friday" isChecked={fridayEnabled} colorScheme="brand"
                        onChange={(ev) => { setFridayEnabled(ev.target.checked) }} /> Friday</div>
                    <div><Switch id="scheduler-day-saturday" isChecked={saturdayEnabled} colorScheme="brand"
                        onChange={(ev) => { setSaturdayEnabled(ev.target.checked) }} /> Saturday</div>
                    <div><Switch id="scheduler-day-sunday" isChecked={sundayEnabled} colorScheme="brand"
                        onChange={(ev) => { setSundayEnabled(ev.target.checked) }} /> Sunday</div>
                </Stack>
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="time">{t('schedulerTimeTitle')}</FormLabel>
                <Stack shouldWrapChildren isInline>
                    <NumberInput id="hours" size="lg" value={hours} min={0} max={24} 
                        onChange={(val) => { setHours(parseInt(val)); }}>
                        <NumberInputField width={90} />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <NumberInput id="minutes" size="lg" value={minutes} min={0} max={59} 
                        onChange={(val) => { setMinutes(parseInt(val)); }}>
                        <NumberInputField width={90} />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Stack>
            </FormControl>
        </Stack>
    );
}

export default DayAndTimeScheduler;