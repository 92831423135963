import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { BlockDto } from "../../generated-sources/openapi";
import { useTranslation } from "react-i18next";

import { List, ListItem, Clone, Gripper } from "../styled";
import { ScrollableList, PlusIcon, TimeSuffix } from "./styled";

type Props = {
    items: BlockDto[],
    selectedBlock: BlockDto | null,
    loading: boolean,
    show: boolean,
    save: any,
    onAddBlock: any,
    onOpenBlock: any,
    onImport: any,
}

const BlockList = (props: Props) => {
    const { t } = useTranslation();

    const [dragMode] = useState(true);
    const soundIconBase64 = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiBoZWlnaHQ9IjY0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2NCA2NCIgd2lkdGg9IjY0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yMy43MjcsMTYuNDAzdjQuODR2NS4wNTh2MTQuMjM2Yy0xLjI2OC0wLjc3Ny0yLjc1NC0xLjIzMy00LjM1LTEuMjMzYy00LjYxMiwwLTguMzUzLDMuNzQtOC4zNTMsOC4zNDkgIGMwLDQuNjE0LDMuNzQsOC4zNTQsOC4zNTMsOC4zNTRjNC42MSwwLDguMzQ5LTMuNzM5LDguMzUtOC4zNTJoMFYyNS4xNTFsMjEuMjUtNi4xMDlWMzMuMzNjLTEuMjY4LTAuNzc3LTIuNzU0LTEuMjMzLTQuMzUtMS4yMzMgIGMtNC42MTQsMC04LjM1MywzLjczOS04LjM1Myw4LjM0OGMwLDQuNjEzLDMuNzM5LDguMzU0LDguMzUzLDguMzU0YzQuMzQ0LDAsNy45MTQtMy4zMjUsOC4zMS03LjU3aDAuMDRWMTcuODkydi0zLjU4NlY3Ljk5MyAgTDIzLjcyNywxNi40MDN6Ii8+PC9zdmc+`;

    const getStatusText = (item: BlockDto) => {
        if (item.type === 'sound') {
            // This block is a sound effect.
            return t('soundTypeLabel');
        }
        
        if (item.type === 'mixmode') {
            // This block is another podcast which has been inherited.
            return t('mixModeLabel');
        }

        if (item.snapshot && 
            item.snapshot.destinationAudioSent &&
            item.snapshot.audioUri !== null && 
            item.snapshot.isHumanVoiceRecorded === true) {
                return t('humanRecorded');
        }

        // Has been recorded with AI voice person.
        if (item.snapshot && 
            item.snapshot.destinationAudioSent &&
            item.snapshot.audioUri !== null &&
            item.type === 'text') {
            return t('voiceGeneratorRecorded')
                .replace('%VOICE_PERSON%', item.voicePerson?.name!);
        }

        // The snapshot has been generated from the story
        // and has not been edited by an user.
        if (item.snapshot && 
            item.snapshot.destinationAudioSent &&
            item.snapshot.audioUri !== null) {
            return t('automaticallyRecorded');
        }

        if (item.type === 'audio' &&
            item.snapshot && item.snapshot.audioUri !== null) {
            return t('fileUploaded');
        }

        return t('notRecorded');
    };

    const getStatusColor = (item: BlockDto) => {
        if (item.type === 'audio') {
            if (item.snapshot && item.snapshot.audioUri !== null) {
                return `var(--color-text-success)`;
            }
            return `var(--color-text-error)`;
        }

        if (item.snapshot && item.snapshot.destinationAudioSent &&
            item.snapshot.audioUri !== null) {
            return `var(--color-text-success)`;
        }

        if (item.type === 'sound') return `#0c0c0c`;

        return `var(--color-text-error)`;
    };

    const getTimeString = (timeInSeconds: number) => {
        if (timeInSeconds <= 100) {
            return {time: timeInSeconds, suffix: ' sek'}
        } else if (timeInSeconds > 600) {
            return {time: Math.floor(timeInSeconds / 60), suffix: ' min'}
        } else if (timeInSeconds >= 100) {
            let decimal: number = 1;
            if ((timeInSeconds / 60) % Math.floor(timeInSeconds / 60) === 0) {
                decimal = 0;
            }
            return {time: (timeInSeconds / 60).toFixed(decimal), suffix: ' min'}
        }
        return {time: 'unknown', suffix: ' '};
    }

    if(props.loading) {
        return (
            <div style={{ minHeight: 500 }}>
                <p style={{ marginLeft: 15, color: '#898989' }}>
                    {t('loading') + '...'}
                </p>
            </div>
        )
    }

    return (
        <div style={{
            maxHeight: props.show ? 'initial' : 0, 
            visibility: props.show ? 'visible' : 'hidden', 
            opacity: !props.show || props.loading ? 0 : 1,
            transition: 'max-height 2s ease-in' }}>
            {props.loading && <div style={{ marginTop: 20, fontSize: 18, color: '#808080' }}>
                {t('loading')}...
            </div>}
            <ScrollableList>
                <Droppable droppableId="main-droppable" isCombineEnabled={true}>
                    {(provided, dropSnapshot) => (
                        <List {...provided.droppableProps} ref={provided.innerRef}>
                            {!props.loading && props.items.length === 0 && (
                                <Clone absolute notClickable>
                                    <p>{t('dropHere')}</p>
                                </Clone>
                            )}
                            {!props.loading && props.items.map((item, index) => (
                                <Draggable key={item!.id!} draggableId={item!.id!} index={index} 
                                        isDragDisabled={!dragMode || item.type === 'placeholder'}>
                                    {(provided, dragSnapshot) => (
                                        <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            droppable={item.type === 'placeholder' && dragSnapshot.combineTargetFor !== null}
                                            isDragging={dragSnapshot.isDragging}
                                            draggingOver={dragSnapshot.draggingOver}
                                            isDropAnimating={dragSnapshot.isDropAnimating}
                                            animate={props.loading}
                                            item={item}
                                            selected={(props.selectedBlock && item.id === props.selectedBlock.id) ?? false}
                                            dragEnabled={!item.isFixed && item.type !== 'placeholder' && dragMode}
                                            onClick={() => {
                                                props.onOpenBlock(item);
                                            }}>
                                            {item.icon && <div style={{ width: 42, height: 42, 
                                                backgroundColor: getStatusColor(item), 
                                                WebkitMask: `url(${item.icon}) center / contain no-repeat` }}></div>}

                                            {item.type === 'placeholder' && item.title.startsWith('(') &&
                                                <div className="title">{item.title}</div>}

                                            {item.type === 'placeholder' && !item.title.startsWith('(') &&
                                                <div className="title">({item.title})</div>}

                                            {item.storyAlias && <div className="content">
                                                <div className="title" title={`Type: ${item.type} ID: ${item.id}`}>
                                                    {!item.icon && (item.type === 'sound') && <img 
                                                        className="sound-icon"
                                                        src={soundIconBase64} alt="" />}
                                                    {item.title === t('untitledLabel') && <div style={{ fontStyle: 'italic', color: '#888' }}>{item.title}</div>}
                                                    {item.title !== t('untitledLabel') ? item.title : ``}
                                                </div>
                                                {item.text && <div className="text">{item.text}</div>}
                                                <div className="status" style={{ color: getStatusColor(item), 
                                                    marginTop: 9, fontSize: 11, fontWeight: 700, textTransform: 'uppercase' }}>
                                                    {getStatusText(item)}
                                                </div>
                                            </div>}
                                            {item.voicePerson && item.voicePerson.icon && <div className="voiceperson">
                                                <img src={item.voicePerson.icon!} alt={item.voicePerson.name} />
                                            </div>}
                                            {item.snapshot && 
                                            item.snapshot.audioDuration !== null && 
                                            item.snapshot!.audioDuration! > 0 && <div className="duration" title={`${item.snapshot!.audioDuration!.toFixed(2)} seconds`}>
                                                {getTimeString(Math.round(item.snapshot.audioDuration!)).time} 
                                                <TimeSuffix>{getTimeString(Math.round(item.snapshot.audioDuration!)).suffix}</TimeSuffix>
                                            </div>}
                                            <div className="gripper">
                                                <Gripper isDragging={dragSnapshot.isDragging}></Gripper>
                                            </div>
                                            {dragSnapshot.combineTargetFor !== null && item.type === 'placeholder' && 
                                                <div className="dropToReplace">{t('dropToReplace')}</div>}
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
                <div style={{ textAlign: 'center', marginTop: 25 }}>
                    <PlusIcon onClick={props.onAddBlock}>+ {t('addFeature')}</PlusIcon>
                    <PlusIcon onClick={props.onImport}>+ {t('import')}</PlusIcon>
                </div>
            </ScrollableList>
        </div>
    );
}

export default BlockList;