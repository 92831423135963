import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useFileInput from "use-file-input";
import { Config } from "../../config";

import { BlobsApi, BlocksApi, StoryApi, BlockDto } from "../../generated-sources/openapi";
import { ReplacePrompt, Uploading } from "../BgSoundSelector/styled";
import { AddableItem, SoundAddableItemClone, AddGroupHeader, Gripper, Button } from "../styled";
import { Grid, UploadLink } from "./styled";

import * as StoryFunctions from "../../utils/storyFunctions";
import * as AudioFileFunctions from "../../utils/audioFileFunctions";

type Props = {};

const AddFromSounds = (props: Props) => {
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const blobsApi = new BlobsApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const blocksApi = new BlocksApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

  const tenantId = sessionStorage.getItem('TENANT_ID')!;
  const { t } = useTranslation();

  const [items, setItems] = useState<BlockDto[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showUploadButton: boolean = true;
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [replaceFileName, setReplaceFileName] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<any | null>(null);
  const [uploadUri, setUploadUri] = useState<string | null>(null);

  const handleSoundFileUpload = useFileInput((files: FileList) => {
      Array.from(files).forEach((file: any) => {
        upload(file);
      });
    },
    { accept: ".mp3,.wav", multiple: true }
  );

  useEffect(() => {
    loadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadItems = async () => {
    setIsLoading(true);

    const result = await blocksApi
      .getAvailableBlocksAsync(tenantId, undefined, 'shared-sounds');

    setItems(result.data);
    setIsLoading(false);
  };

  const upload = async (file: any) => {
    // Note: copied form and very similar to upload() in BgSoundSelector
    if (file) {
      setIsUploading(true); // TODO: show progress bar

      const fileNameWithoutExtension = AudioFileFunctions
        .getFileNameWithoutExt(file.name);
      const fileFormat = AudioFileFunctions
        .getFileFormat(file.name);
      const blobName = AudioFileFunctions
        .generateBlobName(fileNameWithoutExtension);

      const resp = await blobsApi.uploadAsync(blobName, fileFormat, false, file);

      if (resp.status === 200) {
        const uploadUri: string = resp.data.uri!.toString();
        setUploadUri(uploadUri);
        console.log(`uploaded successfully as ${uploadUri}`);

        const existingStory = await storyApi
          .doesStoryExist(fileNameWithoutExtension, tenantId);

        if (existingStory.data.success === true) {
          setReplaceFileName(fileNameWithoutExtension);
          setUploadedFile(file);
          setIsUploading(false);
        } else {
          saveAsStory(file, fileNameWithoutExtension, uploadUri).then(() => {
            setIsUploading(false);
          }).catch((e) => {
            console.log(`failed to save as a story`);
          });
        }
      } else {
        throw new Error(`failed to upload file: ${resp.statusText}`);
      }
    }
  }

  const replaceFile = () => {
    if (!replaceFileName) throw Error(`not in replace mode`);
    saveAsStory(uploadedFile, replaceFileName, uploadUri!);
    reset();
  }

  const saveAsStory = async (file: File, alias: string, uploadedUri: string) => {
    const audioStory = await StoryFunctions.createSoundStory(file, alias, uploadedUri);
    const createResult = await storyApi.createOrUpdateStory(audioStory);
    if (createResult.status === 200 && createResult.data.success) {
        setUploadedFile(null);
        loadItems();
    } else {
      throw new Error(`failed to create sound effect as ` + 
        `backend story: ${createResult.data.message}`);
    }
  }

  const reset = () => {
    setReplaceFileName(null);
    setUploadedFile(null);
  }

  return (
    <div id="sounds-plugin">

      <AddGroupHeader>{t('soundTransitions')}</AddGroupHeader>

      {isLoading && <div style={{ marginLeft: 15, fontSize: 13, color: '#c0c0c0' }}>{t('loading')}...</div>}

      {items && items!.length === 0 && 
        <div>
          <div style={{ marginLeft: 15, fontSize: 14, color: '#888' }}>{t('noSounds')}</div>
          {showUploadButton && <Button small 
            style={{ marginTop: 15, marginLeft: 15 }}
            onClick={handleSoundFileUpload}>{t('uploadSound')}</Button>}
        </div>
      }

      {items && <Grid>
        {items.map((item, index) => (
          <Draggable key={`${item.id}-sounds-addmode`} draggableId={`${item.id}__sounds-addmode`} index={20000+index}>
            {(provided, dropSnapshot) => (
              <>
                <AddableItem
                  animate
                  key={`sounds-${item.id}`}
                  ref={provided.innerRef}
                  color={'#fafafa'}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  isDragging={dropSnapshot.isDragging}
                >
                  <img 
                    className="sound-icon"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiBoZWlnaHQ9IjY0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2NCA2NCIgd2lkdGg9IjY0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yMy43MjcsMTYuNDAzdjQuODR2NS4wNTh2MTQuMjM2Yy0xLjI2OC0wLjc3Ny0yLjc1NC0xLjIzMy00LjM1LTEuMjMzYy00LjYxMiwwLTguMzUzLDMuNzQtOC4zNTMsOC4zNDkgIGMwLDQuNjE0LDMuNzQsOC4zNTQsOC4zNTMsOC4zNTRjNC42MSwwLDguMzQ5LTMuNzM5LDguMzUtOC4zNTJoMFYyNS4xNTFsMjEuMjUtNi4xMDlWMzMuMzNjLTEuMjY4LTAuNzc3LTIuNzU0LTEuMjMzLTQuMzUtMS4yMzMgIGMtNC42MTQsMC04LjM1MywzLjczOS04LjM1Myw4LjM0OGMwLDQuNjEzLDMuNzM5LDguMzU0LDguMzUzLDguMzU0YzQuMzQ0LDAsNy45MTQtMy4zMjUsOC4zMS03LjU3aDAuMDRWMTcuODkydi0zLjU4NlY3Ljk5MyAgTDIzLjcyNywxNi40MDN6Ii8+PC9zdmc+" alt="" />
                  <div style={{ flex: 1, flexBasis: '100%', alignContent: 'center', marginTop: '-2px' }}>
                    {item.title}
                  </div>
                  <div className="gripper" style={{ flex: 1, marginLeft: 'auto' }}>
                    <Gripper isDragging={dropSnapshot.isDragging}></Gripper>
                  </div>
                </AddableItem>
                {dropSnapshot.isDragging && (
                  <SoundAddableItemClone />
                )}
              </>
            )}
          </Draggable>
        ))}
      </Grid>}

      {replaceFileName !== null && <ReplacePrompt>
        <p>{t('replaceSoundStory')} "{replaceFileName}"?</p>
        <Button onClick={replaceFile}>{t('yesReplace')}</Button>
        <Button onClick={reset}>{t('no')}</Button>
      </ReplacePrompt>}

      {isUploading && <Uploading>
        <div style={{ flex: 1 }}>{t('uploading')}...</div>
      </Uploading>}

      {showUploadButton && items && items.length > 0 && <UploadLink>
        <div onClick={handleSoundFileUpload}>{t('uploadSound')}</div>
      </UploadLink>}

    </div>
  );
};

export default AddFromSounds;