import axios, { AxiosInstance } from 'axios';
import { Configuration, ConfigurationParameters } from './generated-sources/openapi';
import { createTheme } from '@material-ui/core/styles';

export class Config {
    public static LS_TenantId = 'TENANT_ID';
    public static LS_TenantAlias = 'TENANT_ALIAS';
    public static LS_AuthAccessToken = 'AUTH0_BEARER';
    
    public static LangCode = 'sv-se';
    public static BackendUri = process.env.REACT_APP_BACKEND_API_URL;

    public static AxiosInstance: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API_URL
    });

    public static getTenantId(): string | null {
        const tenantId = sessionStorage.getItem(Config.LS_TenantId) ??
            localStorage.getItem(Config.LS_TenantId);

        if (!tenantId) {
            console.error(`tenant is null`);
            // throw new Error(`tenant is null`);
            return null;
        }

        return tenantId;
    }

    public static getTenantAlias(): string | null {
        const tenantAlias = sessionStorage.getItem(Config.LS_TenantAlias) ??
            localStorage.getItem(Config.LS_TenantAlias);

        if (!tenantAlias) {
            throw new Error(`tenant is null`);
        }

        return tenantAlias;
    }

    public static getAccessToken(): string {
        return sessionStorage.getItem(Config.LS_AuthAccessToken)!;
    }

    public static getApiConfig = (token: string | undefined = undefined) => {
        let bearer: string;
        if (token) {
            // use the parameter instead of session storage
            bearer = token;
        } else {
            bearer = Config.getAccessToken();
        }

        if (!bearer) {
            console.warn(`bearer missing for api access.`);
        }

        const configParams: ConfigurationParameters = {
            basePath: process.env.REACT_APP_BACKEND_API_URL,
            accessToken: bearer
        };
        return new Configuration(configParams);
    }

    public static getTheme = () => {
        const lightTheme = createTheme({
            palette: {
                type: 'light',
                primary: {
                    main: '#5e6ad2',
                    light: '#5e6ad2',
                },
                secondary: {
                    main: '#fff',
                }
            },
        });
        return lightTheme;
    }

    public static isBetaMode = () => {
        const tenantAlias = localStorage.getItem(Config.LS_TenantAlias)!;
        const betaMode = 
            tenantAlias === 'marca' || 
            tenantAlias === 'olada' || 
            tenantAlias === 'radiox';
        return betaMode;
    }

    public static isDownloadLinksEnabled = () => {
        const tenantAlias = localStorage.getItem(Config.LS_TenantAlias)!;
        return tenantAlias === 'bonnier' ||
            tenantAlias === 'bnl-syd';
    }

    public static isDevMode = () => {
        const tenantAlias = localStorage.getItem(Config.LS_TenantAlias)!;
        const devMode = 
            tenantAlias === 'marca' || 
            tenantAlias === 'olada' || 
            tenantAlias === 'botfactory';
        return devMode;
    }

    public static isEmojiFeatureEnabled = () => {
        const tenantAlias = localStorage.getItem(Config.LS_TenantAlias)!;
        const isEnabled = 
            tenantAlias === 'marca' || 
            tenantAlias === 'olada' || 
            tenantAlias === 'radiox' ||
            tenantAlias === 'bjarenu';
        return isEnabled;
    }

    public static isAdmin = () => {
        return Config.isDevMode();
    }
}