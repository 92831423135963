import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    width: 770px; 
    padding: 40px;
    transition: all 1s ease;
    border-radius: 36px;
    background-color: #fff;
    box-shadow: 20px 20px 60px #d2d2d2, 
                -20px -20px 60px #fff;
    text-align: center;
`;

const Title = styled.h1`
    margin-top: 50px;
    margin-bottom: 70px;
    font-size: 30px;
    font-weight: 300;
    color: #999;
    cursor: default;
`;

const ImportInstruction = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    color: #999;
    font-size: 16px;
    font-weight: normal;
    cursor: default;
`;

export { Wrapper, Title, ImportInstruction };