import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { useTranslation } from "react-i18next";

interface Props {
    open: boolean;
    onClose: any;
    login: any;
}

const LoginDialog = (props: Props) => {
    const { t } = useTranslation();
    const productName = `${process.env.REACT_APP_BRAND_NAME} ` +
        `${process.env.REACT_APP_MODULE_NAME}`;

    return (
        <Dialog disableEscapeKeyDown onClose={props.onClose} open={props.open}>

            <DialogTitle>Welcome to {productName}!</DialogTitle>

            <DialogContent>
                <DialogContentText>You will need to login to continue.</DialogContentText>
                <DialogActions>
                    <Button onClick={props.login} color="primary">{t('login')}</Button>
                </DialogActions>
            </DialogContent>

        </Dialog>
    );
};

export default LoginDialog;
