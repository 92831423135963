import React, { useEffect, useState } from "react";
import { VoicesApi, VoicePersonDto } from "../../generated-sources/openapi";
import { Grid, ShowAllLink, VoiceItem } from "./styled";
import { Config } from "../../config";
import { useTranslation } from "react-i18next";
import * as BlockFunctions from "../../utils/blockFunctions";

type Props = {
  lang: string,
  currentVoiceId: string | null,
  change: any
}

const VoicePersonSelector = (props: Props) => {
  const { t } = useTranslation();
  const voicesApi = new VoicesApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  
  const micVoice = BlockFunctions.getMicVoicePerson();
  const [showAll, setShowAll] = useState<boolean>(false);
  const maxCount: number = 15; // Note: 7 and not 8 because we will show the current one as well
  
  useEffect(() => {
    loadFromBackend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [voicePersons, setVoicePersons] = useState<VoicePersonDto[] | null>(null);

  const loadFromBackend = async () => {
    const voices = await voicesApi.getListOfAvailableVoices(props.lang);
    const enabledVoices = voices.data.filter(x => x.icon);
    setVoicePersons(enabledVoices);
  };

  return (
    <div>

      <Grid>
        <VoiceItem
          key={'mic'}
          current={'mic' === props.currentVoiceId}
          onClick={() => props.change(micVoice.name, micVoice.icon)}>
          {micVoice.icon && <img className="mic-icon" src={micVoice.icon} alt={micVoice.name} />}
          {t(`micVoiceLabel`)}
        </VoiceItem>
        {voicePersons?.map((item, index) => (
          (item.name === props.currentVoiceId || showAll || index < maxCount-1) && <VoiceItem
            key={item.name}
            current={item.name === props.currentVoiceId}
            locked={item.isLocked}
            onClick={() => {
              if (!item.isLocked) {
                props.change(item.name, item.icon);
              }
             }}>
            {item.icon && <img src={item.icon} alt={item.name} />}
            {item.name}
          </VoiceItem>
        ))}
      </Grid>
    
      {(voicePersons && voicePersons.length > (maxCount-1)) && 
        <ShowAllLink onClick={() => { setShowAll(!showAll); }}>
          {t('showAllVoices')}
        </ShowAllLink>}

    </div>
  );
};

export default VoicePersonSelector;
