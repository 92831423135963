import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
// TODO: import { useBeforeunload } from "react-beforeunload";

import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { Box, HStack, Heading, Spinner, useToast } from "@chakra-ui/react";

import BlockList from "../BlockList";
import Sidebar from "../AddFeaturePanel";
import BlockManager from "../BlockManager";
import BlockPlaceholder from "../BlockPlaceholder";
import Generate from "../Generate";
import Episodes from "../Episodes";
import MarkupPreview from "../MarkupPreview";
import ImportModal from "../Import";
import EpisodeMetadataModal from "../EpisodeMetadataModal";
import Paste from "../Paste";

import { StoryApi, VoicesApi, BlockDto, SnapshotDto, InputPropertyDto, 
    SnapshotSummaryDto, VoicePersonDto, SnapshotPatchInputDto, BlobsApi } from "../../generated-sources/openapi/api";
import { LeftPanel, StoryTitle, SnapshotTitle, Link, TotalTime } from "../styled";
import { useDebouncedCallback } from "use-debounce/lib";
import getBlobDuration from "get-blob-duration";
import { Config } from "../../config";

import * as BlockFunctions from "../../utils/blockFunctions";
import * as StoryFunctions from "../../utils/storyFunctions";
import * as AudioFileFunctions from "../../utils/audioFileFunctions";

/**
 * Reorders the computed array to match the order after a user interaction
 * @param items Array of items to be sorted
 * @param source The index of the item before it was moved
 * @param destination The index of the item after it was moved
 */
const reorder = (items: any, source: number, destination: number): any => {
    const result = Array.from(items);
    const [removed] = result.splice(source, 1);
    result.splice(destination, 0, removed);
    return result;
};

/**
 * Transforms the array of blocks to Botwriter markdown
 * @param items Array of blocks / sub stories
 */
const parse = (items: BlockDto[]): string => {
    return items.reduce((acc, item: BlockDto) => `${acc}[${item.storyAlias ? 
        item.storyAlias : item.type === 'placeholder' ? 
            '$placeholder' : '$FAILED - story alias missing'}${BlockFunctions.generateAttributes(item)}]\n`, '');
};

type Props = {
    storyAlias: string,
    loading: boolean
};

const StationView = (props: Props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const toast = useToast();
    const toastIdRef = useRef<any>();

    // Note: used for cancelling async functions
    const isCancelled = useRef(false);

    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const voicesApi = new VoicesApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const blobsApi = new BlobsApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

    // Current data
    const [storyTitle, setStoryTitle] = useState<string | null>(null);
    const [langCode, setLangCode] = useState<string>('sv-se');
    const [publishCron, setPublishCron] = useState<string | null>(null);

    const [currentSnapshot, setCurrentSnapshot] = useState<SnapshotDto | null>(null);
    const [blockItems, setBlockItems] = useState<BlockDto[]>([]);
    const [totalTime, setTotalTime] = useState<number | null>(null);
    const [totalTimeString, setTotalTimeString] = useState<string | null>(null);
    const [selectedBlock, setSelectedBlock] = useState<BlockDto | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    
    // States in UI
    const [isAppLoading, setIsAppLoading] = useState(true);
    const [saved, setSaved] = useState(true);
    const [addMode, setAddMode] = useState(false);
    const [generateMode, setGenerateMode] = useState(false);
    const [isReadyForGenerate, setIsReadyForGenerate] = useState(false);
    const [importMode, setImportMode] = useState(false);
    const [episodesModalOpen, setEpisodesModalOpen] = useState(false);
    const [markdownPreviewOpen, setMarkdownPreviewOpen] = useState(false);
    const [episodeMetadataOpen, setEpisodeMetadataOpen] = useState(false);
    const [isUploadingAudioFile, setIsUploadingAudioFile] = useState(false);

    useEffect(() => {
        if (!currentSnapshot) return;
        if (!currentSnapshot?.markdownContent) {
            console.warn(`markdown content is empty`);
        } else {
            setIsAppLoading(true);
            async function doTheUnparsing() {
                // TODO: fix this with isCancelled.current = true
                const items = await unparse(currentSnapshot?.markdownContent!);
            
                if (items.length === 0) {
                    console.warn(`block list is empty`);
                }
                else if (isCancelled.current !== true) {
                    setBlockItems(items);
                    setIsAppLoading(false);
                }
            }
            doTheUnparsing();
        }
        return () => {
            // TODO: isCancelled.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSnapshot]);

    useEffect(() => {
        if (totalTime == null) return;
        if (totalTime === 0 || totalTime < 7) {
            setTotalTimeString(null);
        } else {
            let str = t('approx') + ' ' +
                (Math.round((totalTime / 60) * 10) / 10) + ' min';
            setTotalTimeString(str);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalTime]);

    useEffect(() => {
        openStory(props.storyAlias!);
        return () => {
            // isCancelled.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const debouncedSaving = useDebouncedCallback(
        (value: BlockDto[]) => {
            // Note: Save block list to backend and 
            // calculate the total time
            saveToBackend();
            calculateTotalTime();
            setIsReadyForGenerate(isBlockItemsCompleted());
            setSaved(true);
        }, 1000);

    useEffect(() => {
        if (!isAppLoading) {
            console.debug(`block items has been changed.`);
            setSaved(false);
            debouncedSaving.callback(blockItems);
        } else {
            calculateTotalTime();
            // console.debug(`block list changed, but we're in loading mode`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockItems]);

    const saveList = (list: BlockDto[], force: boolean = false) => {
        setSaved(false);
        if (force) {
            console.debug(`force saving to backend`);
            saveToBackend();
            setSaved(true);
        } else {
            if (blockItems !== list && !props.loading) {
                debouncedSaving.callback(blockItems);
            }
        }
    };

    const importUrl = async (url: string) => {
        if (importMode) {
            // Note: ignore this if Import modal is active
            return;
        }

        // We require HTTPS links
        if (url.startsWith('http://')) {
            closeToast();
            toastIdRef.current = toast({
                title: `Failed to import.`,
                description: `You can't import http:// links (only https:// supported).`,
                status: 'error',
                duration: 5000
            });
        }

        toastIdRef.current = toast({
            title: `Importing ${url}`,
            duration: 9000
        });
        try {
            const block = await BlockFunctions.createBlockFromUrl(url, langCode);

            let inputs: InputPropertyDto[] = [];
            inputs.push({ 
                key: 'url',
                value: url
            });

            importBlock(block, inputs);
            closeToast();
            toastIdRef.current = toast({
                title: t('importingCompleted'),
                status: 'success',
                duration: 2500
            });
        }
        catch (ev) {
            closeToast();
            toastIdRef.current = toast({
                title: `Failed to import.`,
                description: `Please try again later or contact support.`,
                status: 'error',
                duration: 5000
            });
        }
    }

    const importAudioFile = async (file: File) => {
        if (!file || !file.name) return;

        const fileFormat = AudioFileFunctions
            .getFileFormat(file.name);

        if (fileFormat !== 'mp3' && fileFormat !== 'wav' &&
            fileFormat !== 'ogg' && fileFormat !== 'm4a') {
            console.error(`invalid file extension: ${fileFormat}`);
            toastIdRef.current = toast({
                title: `The file format is not supported.`,
                description: 
                    `Pasted file is ${fileFormat.toUpperCase()} format. ` + 
                    `Supported formats are MP3, WAV, OGG and M4A.`,
                status: 'error',
                duration: null,
                isClosable: true
            });
            return;
        }

        if (isUploadingAudioFile) {
            toast({
                title: 'Importing already in progress. Please wait.',
                duration: 3000,
                status: 'warning',
                position: 'top',
            });
            console.warn(`importing already in progress.`);
            return;
        }
        setIsUploadingAudioFile(true);
        toastIdRef.current = toast({
            render: () => (
                <Box color="white" p={3} bg="blue.500">
                    <HStack>
                        <Spinner />
                        <Heading size="sm">
                            {t('importingInProgress').replace('%FILE_NAME%', file.name)}
                        </Heading>
                    </HStack>
                </Box>),
            duration: null,
            position: 'top',
        });
        try {
            const fileNameWithoutExtension = AudioFileFunctions
                .getFileNameWithoutExt(file.name);
            const fileFormat = AudioFileFunctions
                .getFileFormat(file.name);
            const blobFileName = AudioFileFunctions
                .generateBlobName(fileNameWithoutExtension);

            const duration = await getBlobDuration(file);
      
            const resp = await blobsApi.uploadAsync(
                blobFileName, fileFormat, false, file);

            if (resp.status === 200 && resp.data.success) {
                const uploadUri: string = resp.data.uri!.toString();

                let inputs: InputPropertyDto[] = [];
                inputs.push({
                    key: 'url',
                    value: uploadUri
                });
                inputs.push({
                    key: 'file-name',
                    value: file.name
                });
                inputs.push({
                    key: 'file-size',
                    value: file.size.toString()
                });
                inputs.push({
                    key: '_audio-duration',
                    value: duration.toString()
                });

                const block = BlockFunctions
                    .createBlockFromAudioFile(props.storyAlias, uploadUri, 
                        fileNameWithoutExtension);

                block.snapshot = {
                    snapshotKey: block.id,
                    title: block.title,
                    audioUri: uploadUri,
                    audioDuration: duration
                }

                importBlock(block, inputs);

                closeToast();
                setTimeout(() => {
                    toastIdRef.current = toast({
                        title: t('importingCompleted'),
                        status: 'success',
                        position: 'top',
                        duration: 2500
                    });
                }, 1000);
            } else {
                closeToast();
                toastIdRef.current = toast({
                    title: `Failed to import file.`,
                    description: 
                        `The backend servers could not handle the file. ` +
                        `Please try again later or contact support. ` +
                        `Status code: ${resp.status} ` + 
                        `Exception: ${resp?.data?.message ?? '-'}`,
                    status: 'error',
                    position: 'top',
                    duration: 5000
                });
            }

            setIsUploadingAudioFile(false);
        }
        catch (err) {
            const errorMessage: string = (err as any).message;
            closeToast();
            toastIdRef.current = toast({
                title: `Failed to import file.`,
                description: `Please try again later or contact support. ` +
                    `Details: ${errorMessage}`,
                status: 'error',
                duration: 5000
            });
        }
    }

    const closeToast = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current!);
        }
    }

    /**
     * Determines if and how to reorder potential data after a user interaction
     * @param result All information concerning the user interaction
     */
    const onDragEnd = async (result: DropResult) => {
        setSelectedBlock(null);
        if (result.source.droppableId === result.destination?.droppableId) {
            // reorder blocks in the block list
            if (result.source.droppableId === "main-droppable") {
                const ordered = reorder(
                    blockItems,
                    result.source.index,
                    result.destination.index
                );
                if (blockItems.length === 0) {
                    throw Error(`block list is empty`);
                }
                setBlockItems(ordered);
            }
        } else if (
            result.source.droppableId !== "main-droppable" &&
            (result.destination?.droppableId === "main-droppable" || result.combine)
        ) {
            let scriptType = result.source
                .droppableId === "sounds-droppable" ? "sound" : "text";
            let sourceStoryAlias = result.draggableId
                .substring(0, result.draggableId.indexOf("__"));

            // Note: "type" will set the "script type" in backend.
            if (sourceStoryAlias === "runkit") {
                scriptType = "runkit";
            }
            else if (sourceStoryAlias === "uri") {
                scriptType = "uri";
            }
            else if (sourceStoryAlias === "upload") {
                scriptType = "audio";
            }

            let id = BlockFunctions.makeUniqueIdFromTag(sourceStoryAlias);

            if (result.source.droppableId !== "shared-droppable" && 
                (scriptType === "text" || scriptType === "uri" || 
                scriptType === "runkit" || scriptType === "audio")) {
                const uniqueId = uuid().substring(0, 6);
                id = `${scriptType}-${uniqueId}`;
            }

            let title = 
                scriptType === "sound" ? sourceStoryAlias : 
                scriptType === "audio" ? `${t('untitledAudioLabel')}` : 
                    `${t('untitledLabel')}`;

            let blockToBeAdded: BlockDto = {
                id: id,
                type: scriptType,
                title: title,
                storyAlias: sourceStoryAlias
            };

            if (result.combine) {
                console.debug(`replacing a block in the list...`);
                console.debug(result.combine);
                if (scriptType === 'sound') {
                    // you can't replace a sound type block
                    return;
                }
                const targetBlockId = result.combine!.draggableId;
                const dest = await move(
                    blockToBeAdded,
                    blockItems,
                    result.source,
                    null,
                    targetBlockId);
                setBlockItems(dest);
            } else {
                const dest = await move(
                    blockToBeAdded,
                    blockItems,
                    result.source,
                    result.destination,
                    undefined);
                setBlockItems(dest);
            }
        } else {
            // can't replace a block by dragging one block in the list
            // above and drop it at another block in the list.
        }
    };

    const calculateTotalTime = () => {
        if (!blockItems || blockItems.length === 0) return;
        const blocksWithDuration = blockItems
            .filter(x => x.snapshot !== undefined && 
                x.snapshot!.audioDuration && 
                x.snapshot!.audioDuration !== null)
            .map(x => x.snapshot!.audioDuration);
        if (blocksWithDuration.length > 0) {
            const totalTime = blocksWithDuration
                .reduce((a, b) => { return a! + b!; });
            setTotalTime(totalTime!);
        } else {
            setTotalTime(null);
        }
    };

    /**
     * Saves the story to backend
     */
    const saveToBackend = async () => {
        setSaved(false);

        if (!props.storyAlias) {
            throw new Error(`Story alias can't be null.`);
        }

        if (!sessionStorage.getItem('TENANT_ID')!) {
            throw new Error(`Tenant-ID can't be null.`);
        }

        const content = parse(blockItems);

        if (props.loading) {
            console.warn(`do not save to backend because ` +
                `the block list is still loading`);
            return;
        }

        if (currentSnapshot) {
            const inputDto: SnapshotPatchInputDto = {
                markup: content
            }
            const patchSnapshot = await storyApi
                .patchSnapshot(props.storyAlias, 
                    currentSnapshot!.snapshotKey, inputDto);

            if (patchSnapshot.data && patchSnapshot.status === 200) {
                console.debug(`snapshot content has been saved to parent snapshot`);
            } else {
                throw new Error(`failed to save content to backend`);
            }
        } else {
            console.error('no snapshot.'); // and saved the story only
        }

        setSaved(true);
    };

    const openBlock = (block: BlockDto) => {
        console.debug(`open block '${block.id}' (${block.title})`);
        setSelectedBlock(block);
    }

    const modifyBlock = async (block: BlockDto, snapshot: SnapshotSummaryDto) => {
        const newList = blockItems.map((item) => {
            if (item.id === block!.id) {
                console.debug(`update block ${item.id} in list`, block);
                const updatedItem: BlockDto = {
                    ...block,
                    snapshot: block.type !== "sound" ? 
                        snapshot ?? undefined : undefined
                };

                return updatedItem;
            }
            return item;
        });

        if (newList.length === 0) {
            throw Error(`block list is empty`);
        }

        if (newList !== blockItems) {
            console.debug(`update block list`);
            setBlockItems(newList);
            saveList(newList);
        }
    }

    const reset = () => {
        setSelectedBlock(null);
        setAddMode(false);
        setGenerateMode(false);
    }

    const importBlock = async (block: BlockDto, inputs: InputPropertyDto[]) => {
        // create story in backend
        const runAsync = false;
        if (runAsync) {
            createSubStory(block, inputs).then(() => {
                console.debug(`block ${block.id} has been created in backend`);
            });
        } else {
            await createSubStory(block, inputs);
        }
        
        const list = blockItems;

        // default: last in list
        let positionInList = blockItems.length;

        if (block?.type === 'placeholder') {
            // the selected block is a placeholder
            console.debug(`search after ${block.id} in the list`);
            positionInList = blockItems.findIndex(x => x.id === block.id);
            console.debug(`found it on ${positionInList}`);
        } else {
            // find placeholder (empty container)
            console.debug(`find the first available placeholder in list`);
            positionInList = blockItems.findIndex(x => x.type === 'placeholder');
        }

        // convert it to normal block - it's not a placeholder anymore
        if (block.type !== 'audio') {
            block.type = 'text';
        }

        if (positionInList > -1) {
            // remove placeholder and replace with this block
            list.splice(positionInList, 1, block);
            setBlockItems(list);
        } else {
            // add to the top of the block list
            setBlockItems(blockItems => [block, ...blockItems]);
        }

        saveList(list, true);
        setSelectedBlock(block);

        return true;
    }

    const removeBlock = () => {
        const list = blockItems.filter(x => x.id !== selectedBlock!.id);
        if (blockItems.length === 0) {
            console.warn(`block list is now empty`);
        }
        setBlockItems(list);
        saveList(list);
        reset();
    }

    /**
     * Adds the moved item to the computed array and formats it
     * @param item The added item as object
     * @param destination The computed array of selected items
     * @param droppableSource The source of the item which was moved
     * @param droppableDestination The destination of the item which was moved
     */
    const move = async (item: BlockDto, blockItems: BlockDto[], 
        droppableSource: any, droppableDestination: any, 
        blockIdToBeReplaced: string | undefined): Promise<any> => {
        const destClone = Array.from(blockItems);

        if (blockIdToBeReplaced) {
            // Note: A placeholder will be replaced, 
            // so lets check the voice person configured and 
            // set it on the block which will replace it.
            const placeholderBlock = blockItems
                .filter(x => x.id === blockIdToBeReplaced)[0];
            item.voicePerson = placeholderBlock.voicePerson;

            item.audioBgSound = placeholderBlock.audioBgSound;
            item.audioFadeIn = placeholderBlock.audioFadeIn;
            item.audioFadeOut = placeholderBlock.audioFadeOut;
            item.audioFalseStart = placeholderBlock.audioFalseStart;
            item.audioVolume = placeholderBlock.audioVolume;
        }

        // lets determine kind of item
        if (droppableSource.droppableId === 'shared-droppable') {
            // it's shared between multiple podcasts
            if (item.storyAlias) {
                const b = await getBlockFromTag(item.storyAlias!, null);
                if (b) {
                    item = b;
                } else {
                    throw Error(`failed to get block from shared story.`);    
                }
            } else {
                throw Error(`story alias missing on shared add feature block.`);
            }
        } else if (droppableSource.droppableId === 'localix-droppable') {
            // from localix - create a story first
            // TODO: strange to create the story here, when we already have a BlockDto
            const highlightId = item.storyAlias!.replace('lx-', '');
            const localixHighlightAsBlock = await StoryFunctions
                .createLocalixHighlightAsStory(storyApi, highlightId);
            item = localixHighlightAsBlock;
            item.id = BlockFunctions.makeUniqueIdFromTag(item.storyAlias!);
            if (!item.voicePerson) {
                item.voicePerson = BlockFunctions.getDefaultVoicePerson(langCode);
            }
        } else if (droppableSource.droppableId !== 'shared-droppable' && 
            (item.type === 'text' || item.type === 'uri' || 
             item.type === 'runkit' || item.type === 'audio')) {
            // make a clone of it and create it as a new story
            if (!item.storyAlias || item.storyAlias === null) {
                throw new Error(`story alias is null.`);
            }

            let extraInputs: InputPropertyDto[] = [];

            if (item.id === 'mic') {
                console.debug('add input for prefer microphone.');
                extraInputs.push({ 
                    key: 'dnd-mic',
                    value: '1'
                });
            }

            if (item.storyAlias === 'mic') {
                item.id = BlockFunctions.makeUniqueIdFromTag('mic');
                // Note: Adding the podcast alias as prefix 
                //  (storyAlias=parent podcast and item.storyAlias=current block)
                item.storyAlias = `${props.storyAlias}-${item.id}`;
                item.voicePerson = BlockFunctions.getMicVoicePerson();
            } else if (item.type === 'text') {
                item.id = BlockFunctions.makeUniqueIdFromTag(item.storyAlias!);
                // Adding the podcast alias as prefix 
                //  (storyAlias=parent podcast and item.storyAlias=current block)
                item.storyAlias = `${props.storyAlias}-${item.id}`;
            } else if (item.type === 'runkit' || item.type === 'uri') {
                item.id = BlockFunctions.makeUniqueIdFromTag(item.storyAlias!);
                // Adding the podcast alias as prefix 
                //  (storyAlias=parent podcast and item.storyAlias=current block)
                item.storyAlias = `${props.storyAlias}-${item.id}`;
            } else if (item.type === 'audio') {
                item.id = BlockFunctions.makeUniqueIdFromTag('upload');
                // Note: Adding the podcast alias as prefix 
                //  (storyAlias=parent podcast and item.storyAlias=current block)
                item.storyAlias = `${props.storyAlias}-${item.id}`;
            } 

            if (item.type === 'audio') {
                item.voicePerson = BlockFunctions.getMicVoicePerson();
            } else if (!item.voicePerson) {
                item.voicePerson = BlockFunctions.getDefaultVoicePerson(langCode);
            }

            await createSubStory(item, extraInputs);
            console.debug(`${item.storyAlias!} has been created.`);

            // Note: this will only be used in the snapshot and will 
            // not be added to the story content. Therefore it will not
            // collide with the _storytag-nonfound:empty which will
            // create story tags which is not yet created.
        }

        if (blockIdToBeReplaced) {
            const index = blockItems.findIndex(x => x.id === blockIdToBeReplaced);
            destClone.splice(index, 1, item);
        } else {
            destClone.splice(droppableDestination.index, 0, item);
        }

        return destClone;
    };

    /**
     * Adds the moved item to backend as a new story
     * @param item The added item as object
     * @param extraInputs Extra settings / variable array
     */
    const createSubStory = async (item: BlockDto, 
        extraInputs: InputPropertyDto[] | null = null) => {
        const storyCreateData = await StoryFunctions
            .createSubStory(item, extraInputs, langCode);
        
        // create the story in the backend
        const result = await storyApi.createOrUpdateStory(storyCreateData);
        
        if(result.status === 200 && !result.data.success) {
            setErrorMessage(result.data.message!);
        } else if (result.status !== 200) {
            setErrorMessage(result.statusText);
        } else {
            console.log(`story '${item.storyAlias}' created in backend`);
        }
    };

    /**
     * Fetches the title and icon for the story
     * @param tag The story alias to fetch content from
     */
    const getInputs = async (tag: string) => {
        if (!tag) {
            console.warn(`tag is undefined`);
            return null;
        }
        try {
            const resp = await storyApi.getInputs(tag, undefined);
            if (resp.status === 200) {
                return resp.data;
            }
        }
        catch { }
        return null;
    }

    /**
     * Transforms the Briefly markdown to an array of 
     * the stories used and fetches their icon and title
     */
    const unparse = async (markupContent: string) => {
        const tags = markupContent.split("\n").filter(item => item) ?? [];
        const blocks: BlockDto[] = [];

        for (const fullTag of tags) {
            const regex = new RegExp(`\\[((\\w|-)*)(\\s*|\\t)(?:(.*))\\]`, "");
            const regexResult = regex.exec(fullTag);

            if (!regexResult || regexResult?.length === 0) {
                throw Error(`regex failed from full tag: ${fullTag}`);
            }

            const tag = regexResult![1];
            const attributes = regexResult?.input!;

            if (fullTag.startsWith('[$')) { // Note: $placeholder or $import
                blocks.push(BlockFunctions.createPlaceholderBlock(attributes, langCode));
            } else {
                const block = await getBlockFromTag(tag, attributes);
                if (block) {
                    blocks.push(block);
                } else {
                    console.log(`failed to parse tag`, tag, attributes);
                }
            }
        };

        /*
        if (tags.length !== blocks.length) {
            throw new Error(`tags could not be unparsed to blocks.`);
        }
        */

        return blocks;
    }

    const getBlockFromTag = async (tag: string, attributes: string | null) => {
        // TODO: optimize this with one backend call instead of two

        if (tag === '$placeholder' || tag === '$import') {
            return null;
        }

        const inputs = await getInputs(tag);
        if (inputs) {
            let type: string = inputs["dnd-type"]?.toString() ?? `text`;
            let latestSnapshot: SnapshotSummaryDto | undefined;
            let voicePerson: VoicePersonDto | undefined;
            let icon: string = inputs["dnd-icon"]?.toString();

            let title: string;
            if (type === 'sound') {
                title = tag;
            }
            else {
                const snapshotResult = await storyApi.getLatestSnapshotSummary(tag);
                if (snapshotResult.status === 200) {
                    latestSnapshot = snapshotResult.data;
                }

                if (!latestSnapshot) {
                    console.warn(`snapshot could not be found at [${tag}]`);
                }

                if (inputs["_voice-person"]) {
                    voicePerson = {
                        name: inputs["_voice-person"]?.toString(),
                        icon: inputs["_voice-icon"]?.toString(),
                        enabled: true
                    }
                }

                title = inputs["dnd-title"]?.toString() ?? `[${tag}]`;
                if (latestSnapshot?.title) {
                    title = latestSnapshot?.title;
                }
            }

            const id = BlockFunctions.makeUniqueIdFromTag(tag);
            const block: BlockDto = {
                id: id,
                storyAlias: tag,
                type: type,
                title: title,
                icon: icon,
                voicePerson: voicePerson,
                snapshot: latestSnapshot
            };

            if (attributes) {
                block.audioFadeIn = BlockFunctions.getAttributeAsNumber(attributes, 'fadein');
                block.audioFadeOut = BlockFunctions.getAttributeAsNumber(attributes, 'fadeout');
                block.audioVolume = BlockFunctions.getAttributeAsNumber(attributes, 'volume');
                block.audioFalseStart = BlockFunctions.getAttributeAsNumber(attributes, 'falsestart');
                block.audioBgSound = BlockFunctions.getAttribute(attributes, 'bgsound');
            }

            return block;
        } else {
            console.error(`failed to get inputs for tag ${tag}`);
            return null;
        }
    }

    /**
     * Open snapshot
     * @param key The snapshot key
     */
    const openSnapshot = async (snapshotKey: string | null) => {
        if(!saved) {
            throw new Error(`you must save before switching snapshot.`);
        }
        reset();
        if (snapshotKey) {
            setIsAppLoading(true);

            const snapshotResult = await storyApi
                .getSnapshot(props.storyAlias!, snapshotKey);
            if (snapshotResult.status !== 200) {
                throw new Error(`could not find snapshot ${snapshotKey}`);
            }
            setCurrentSnapshot(snapshotResult.data);
            console.log(`open snapshot ${snapshotResult.data.title} ` +
                `(${snapshotResult.data.snapshotKey})`);
            setIsAppLoading(false);
        } else {
            setCurrentSnapshot(null);
            setBlockItems([]);
        }
    }

    const isBlockItemsCompleted = () => {
        // check so all blocks has audio
        return blockItems.filter(x => x.snapshot?.audioUri !== null).length === 0;
    }
 
    /*
    useBeforeunload(() => {
         if (!isAuthenticated) {
             return;
         } else if (saved) {
             console.log(`everything has been saved.`);
         } else {
             return "You'll lose your data!";
         }
    });
    */

    const openStory = async (alias: string) => {
        if (!alias) throw Error(`alias is empty`);

        setIsAppLoading(true);
        setSelectedBlock(null);
        setAddMode(false);
        setErrorMessage(undefined);

        sessionStorage.setItem('STORY_ALIAS', alias);

        const tenantIdFromSession = sessionStorage.getItem('TENANT_ID')!;

        // Load the metadata, such as title etc.
        storyApi.getStoryContent(alias, tenantIdFromSession).then((res) => {
            if (!isCancelled.current) {
                setStoryTitle(res.data.title!);
                
                const langSetting = res.data.inputs
                    ?.filter(x => x.key === '_lang')[0];
                if (langSetting && langSetting.value) {
                    setLangCode(langSetting.value);
                }

                const cronSetting = res.data.inputs
                    ?.filter(x => x.key === '_podcast-publish-cron')[0];
                if (cronSetting && cronSetting.value) {
                    setPublishCron(cronSetting.value);
                }

                const mixMode = res.data.inputs?.some(x => 
                    x.key === '_mixmode' && 
                    x.value === '1');
                if (!mixMode) {
                    setErrorMessage(`The story is not in mix mode, ` + 
                        `please adjust in Botwriter to enable podcast editing.`);
                    setBlockItems([]);
                }
            }
        }).catch((err: any) => {
            console.error(err.message);
            setErrorMessage(err.message);
        });

        // Load the content (block list)
        storyApi.getLatestSnapshot(alias, false).then(async (res) => {
            if (!isCancelled.current) {
                if (res.status === 200 && res.data !== null) {
                    setCurrentSnapshot(res.data);
                    setIsAppLoading(false);
                } else {
                    setCurrentSnapshot(null);
                    const triggerResult = await storyApi.triggerStory(alias);
                    if (triggerResult.data.success) {
                        // wait and refresh
                    }
                    setIsAppLoading(false);
                }
            }
        }).catch((err: any) => {
            console.error(err.message);
            setCurrentSnapshot(null);
            setIsAppLoading(false);
        });

        // Load voice person list
		const voiceListApiResult = await voicesApi.getListOfAvailableVoices(langCode);
		localStorage.setItem(`voicepersons_${langCode.toLowerCase()}`, 
            JSON.stringify(voiceListApiResult.data));
    }

    const metadataChanged = (data: any) => {
        if (data.saved) {
            const snapshotWithNewTitle = {...currentSnapshot, 
                snapshotKey: currentSnapshot!.snapshotKey!,
                title: data.title
            };
            setCurrentSnapshot(snapshotWithNewTitle);
        }
        setEpisodeMetadataOpen(false);
    }
    
    return (
        <div style={{ marginBottom: 50 }}>
            {props.storyAlias && <div style={{ marginBottom: 35 }}>
                <div style={{ width: 475 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StoryTitle>
                            <div onClick={() => history.push(`/${props.storyAlias}/edit`)}>
                                {storyTitle ?? props.storyAlias}
                            </div>
                        </StoryTitle>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Link onClick={() => setEpisodesModalOpen(true)}>
                            <img alt={t('allEpisodes')}
                                style={{ marginLeft: 13, marginRight: 10, height: 30, color: '#808080' }} 
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDEwaC02Yy0xLjEgMC0yIC45LTIgMnYyNGMwIDEuMS45IDIgMiAyaDZjMS4xIDAgMi0uOSAyLTJ2LTI0YzAtMS4xLS45LTItMi0yem0yOCAwaC02Yy0xLjEgMC0yIC45LTIgMnYyNGMwIDEuMS45IDIgMiAyaDZjMS4xIDAgMi0uOSAyLTJ2LTI0YzAtMS4xLS45LTItMi0yem0tMTQgMGgtNmMtMS4xIDAtMiAuOS0yIDJ2MjRjMCAxLjEuOSAyIDIgMmg2YzEuMSAwIDItLjkgMi0ydi0yNGMwLTEuMS0uOS0yLTItMnoiLz48L3N2Zz4=" />
                            
                        </Link>
                        <SnapshotTitle onClick={() => { setEpisodeMetadataOpen(true) }}>
                            {currentSnapshot?.title ?? '-'}
                        </SnapshotTitle>
                    </div>
                </div>
            </div>}
            {!errorMessage && props.storyAlias && <div style={{ flex: 1, width: '100%', height: '100%', display: 'flex' }}>
                <DragDropContext onDragEnd={onDragEnd} >
                    <LeftPanel>
                        <BlockList
                            loading={isAppLoading}
                            show={!generateMode}
                            items={blockItems}
                            selectedBlock={selectedBlock}
                            save={saveToBackend}
                            onOpenBlock={(block: BlockDto) => openBlock(block)}
                            onAddBlock={() => {
                                setSelectedBlock(null);
                                setAddMode(true);
                            }}
                            onImport={() => {
                                setImportMode(true);
                            }} />
                        {currentSnapshot && <Generate
                            enabled={isReadyForGenerate}
                            storyAlias={props.storyAlias}
                            publishCron={publishCron}
                            currentSnapshot={currentSnapshot}
                            estimatedTime={totalTime}
                            blockItems={blockItems}
                            generate={() => { 
                                setGenerateMode(true);
                                setSelectedBlock(null); 
                                setAddMode(false);
                            }}
                            restart={() => { 
                                setGenerateMode(false); 
                                console.log('restart from generate component');
                            }} />}
                        {markdownPreviewOpen && <MarkupPreview 
                            content={parse(blockItems)}
                            onClose={() => { setMarkdownPreviewOpen(false); }}
                            />}
                        {!generateMode && totalTimeString !== null && 
                            <TotalTime>{totalTimeString}</TotalTime>}
                        {!generateMode && <div onClick={() => setMarkdownPreviewOpen(!markdownPreviewOpen)} 
                            style={{ color: '#eee', fontSize: 12, fontWeight: 600, 
                                marginTop: 10, textAlign: 'center', cursor: 'pointer' }}>
                                Show markup
                        </div>}
                        {!saved && <div style={{ color: '#f7f7f7', fontSize: 12, fontWeight: 600, 
                            marginTop: 10, textAlign: 'center' }}>
                            Not saved.
                        </div>}
                    </LeftPanel>
                    {addMode && selectedBlock === null && <Sidebar 
                        close={() => setAddMode(false)}
                    />}
                    {selectedBlock !== null && selectedBlock.type !== 'placeholder' && <BlockManager 
                        item={selectedBlock} 
                        langCode={langCode}
                        updateSelected={(block: BlockDto, snapshot: SnapshotSummaryDto) => modifyBlock(block, snapshot)} 
                        onRemove={() => removeBlock()} 
                        onClose={() => reset()} />}
                    {selectedBlock !== null && selectedBlock.type === 'placeholder' && <BlockPlaceholder 
                        item={selectedBlock} 
                        langCode={langCode}
                        onReplaceWithBlock={(createdBlock: BlockDto, inputs: InputPropertyDto[]) => importBlock(createdBlock, inputs)} 
                        onRemove={() => removeBlock()} 
                        onClose={() => reset()} />}
                </DragDropContext >
            </div>}

            {errorMessage && <div style={{ fontWeight: 500 }}>
                <p>{errorMessage}</p>
            </div>}

            {importMode && <ImportModal
                isOpen={importMode}
                importBlock={(block: BlockDto, inputs: InputPropertyDto[]) => importBlock(block, inputs)}
                onClose={() => setImportMode(false)}
                langCode={langCode} />}

            {episodeMetadataOpen && <EpisodeMetadataModal 
                storyAlias={props.storyAlias}
                currentSnapshotKey={currentSnapshot?.snapshotKey}
                snapshot={currentSnapshot}
                onChange={metadataChanged} />}

            <Episodes
                storyAlias={props.storyAlias}
                currentSnapshotKey={currentSnapshot?.snapshotKey}
                isOpen={episodesModalOpen}
                onChange={(newSnapshotKey: string) => { openSnapshot(newSnapshotKey); }}
                onClose={() => setEpisodesModalOpen(false)} />

            <Paste enabled={!importMode && !selectedBlock} 
                onUrlPasted={(url: string) => { importUrl(url); }} 
                onFilePasted={(file: File) => { importAudioFile(file); }} />

        </div>
    );
}

export default StationView;