import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    max-width: 700px;
`;

type VoiceItemProps = {
    current?: boolean,
    locked?: boolean
}

const VoiceItem = styled.div`
    padding: 6px 15px;
    font-size: 18px;
    border: solid 1px #e2e2e2;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
        width: 55px;
        height: 55px;
        margin-right: 10px;
    }

    .mic-icon {
        height: 42px;
    }

    ${(props: VoiceItemProps) => props.current && `
        border: solid 2px #5e72e4;
        padding: 5px 14px;
    `}

    ${(props: VoiceItemProps) => props.locked && `
        background-color: #f7f7f7;
        opacity: 0.5;
        border: 0;
        padding: 5px 14px;
    `}
`;

const ShowAllLink = styled.div`
    max-width: 700px;
    text-align: right;
    margin-right: 4px;
    margin-top: 8px;
    font-size: 10px;
    color: #808080;
    cursor: pointer;
    &:hover {
        color: #ccc;
    }
`;

export { Grid, VoiceItem, ShowAllLink };
