import styled from "styled-components";

type SnapshotItemProps = {
    current?: boolean,
    published?: boolean,
}

const SnapshotItem = styled.div`
    padding: 6px 15px;
    margin-bottom: 10px;
    font-size: 18px;
    border: solid 1px #e2e2e2;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;

    ${(props: SnapshotItemProps) => props.current && `
        border: solid 2px #5e72e4;
        padding: 5px 14px;
    `}
`;

const Header = styled.div`
    font-size: 17px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 30px;
    margin-bottom: 15px;
`;

export { SnapshotItem, Header };
