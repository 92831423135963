import React, { useState, useEffect } from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type Props = {
  estimatedTime: number | null
}

const PercentageTimer = (props: Props) => {
  // TODO: use estimatedTime from props
  const [percentage, setPercentage] = useState<number>(0);
  let timeLeft: number = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      count();
    }, 2000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = () => {
    if (timeLeft > 80) {
    } else {
      timeLeft = timeLeft + 10;
      setPercentage(timeLeft);
    }
  }

  return (
    <div style={{ 
        margin: "auto", width: "100px", height: "150px", 
        display: "flex", alignItems: "center"
      }}>
      <CircularProgressbar 
        className="CircularProgressbar-custom"
        value={percentage} 
        strokeWidth={5}
        text={`${percentage}%`} />
    </div>
  );
}

export default PercentageTimer;