import React, { useEffect, useRef, useState } from "react";
import { BlockDto, SnapshotSummaryDto, StoryApi, TasksApi } from "../../generated-sources/openapi";
import { useTranslation } from "react-i18next";
import * as BlockFunctions from "../../utils/blockFunctions";
import { Config } from "../../config";
import { formatRelative } from "date-fns";
import i18next from "i18next";
import { sv } from "date-fns/locale";

import { Button, HStack, Spinner } from "@chakra-ui/react";
import { Container, ReadTextArea, EstimatedTime, ContainsOn } from "./styled";
import Record from "../Record/Record";
import VoiceEditor from "../VoiceEditor";

type Props = {
    lang: string,
    storyAlias?: string,
    onGoToNext: any,
    onReload: any
}

const HumanRecordingStudio = (props: Props) => {
    const { t } = useTranslation();
    // Note: used for cancelling async functions
    const isCancelled = useRef(false);

    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const tasksApi = new TasksApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const [item, setItem] = useState<BlockDto>();
    const [containsOnSnapshots, setContainsOnSnapshots] = useState<SnapshotSummaryDto[]>();

    const [readTimeSecs, setReadTimeSecs] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        if (item) {
            calculateReadingTime(item.text!);
        }

        return () => {
            isCancelled.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.storyAlias) {
            setIsLoading(true);

            storyApi.getLatestSnapshot(props.storyAlias, false).then(async (result) => {
                const snapshot = result.data;
                const blockItem: BlockDto = {
                    type: 'text',
                    id: snapshot.snapshotKey,
                    title: snapshot.title,
                    text: snapshot.markup,
                    storyAlias: props.storyAlias,
                    voicePerson: BlockFunctions.getMicVoicePerson()
                };
                setItem(blockItem);
                setReadTimeSecs(calculateReadingTime(blockItem.text!));

                setIsLoading(false);

                const usesThisOne = await storyApi.getSnapshotsWhichUseThisSnapshot(
                    props.storyAlias!, snapshot.snapshotKey);
                if (usesThisOne.status === 200) {
                    setContainsOnSnapshots(usesThisOne.data);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.storyAlias]);

    const calculateReadingTime = (text: string) => {
        const wordsPerMinute = 250;
        const words = text.trim().split(/\s+/).length;
        const time = words / wordsPerMinute;
        return Math.ceil(time * 60);
    }

    const discard = async () => {
        const result = await tasksApi.discardTask(item!.storyAlias!, item!.id!);
        if (result.data.success) {
            setItem(undefined);
            props.onReload();
        }
    }

    return (
        <Container>

            {isLoading && <Spinner />}

            {!isFinished && !isLoading && item && <>

                <EstimatedTime>{t('estimatedTime')}: {readTimeSecs} {t('secs')}</EstimatedTime>

                {!editMode && <ReadTextArea>{item!.text}</ReadTextArea>}

                {editMode && item.voicePerson && <VoiceEditor 
                    content={item!.text!} 
                    lang={props.lang} voicePerson={item!.voicePerson}
                    saveContinuously={(text: string) => { console.log(text) }} />}

                <Record 
                    selectedItem={item!}
                    hideCloseButton={false}
                    onClose={() => { }}
                    onUploadedAndSelected={() => {
                        setIsFinished(true);
                    }} />

                {containsOnSnapshots && containsOnSnapshots.length > 0 && <ContainsOn>
                    <p>{t('willBeUsedOn')}</p>
                    <ul>
                        {containsOnSnapshots!.map((snapshot) => {
                            return (
                                <li>{snapshot.title} ({t('willBePublishedAt') + ' '} 
                                    {formatRelative(new Date(snapshot.publishDateTime!), new Date(), {
                                        locale: i18next.language === 'sv' ? sv : undefined
                                  })})</li>
                            )
                        })}
                    </ul>
                </ContainsOn>}

                <br/><br/>

                <HStack spacing={5}>
                    {!editMode && <Button size={'sm'} onClick={() => { setEditMode(true) }}>{t('edit')}</Button>}
                    {!editMode && <Button size={'sm'} onClick={discard}>{t('removeTask')}</Button>}
                </HStack>

            </>}

            {isFinished && !isLoading && item && <>
                
                <h1 style={{ fontSize: 30 }}>
                    ✅ &nbsp; Inspelningen är klar
                </h1>

                <p style={{ fontSize: 18, marginTop: 20, marginBottom: 20 }}>
                    Den kommer nu optimeras och därefter kunna nyttjas i sändningar.
                </p>

                <Button colorScheme="brand" onClick={() => {
                    props.onGoToNext();
                }}>Fortsätt med nästa</Button>

            </>}

        </Container>
    );
}

export default HumanRecordingStudio;