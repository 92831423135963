import styled from "styled-components";

const List = styled.div`
    width: 100%;
    height: 450px;
    overflow: auto;
`;

type ImportedBlockItemProps = {
    selected?: boolean,
}

const ImportedBlock = styled.div`
    flex: 1;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    border: solid 2px transparent;
    ${(props: ImportedBlockItemProps) => props.selected && `
        border: solid 2px red;
    `}
`;

const ItemTitle = styled.div`
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const ItemContent = styled.div`
    flex: 1;
    font-size: 14px;
    margin-bottom: 8px;
`;

const ItemImage = styled.div`
    width: 50px;
    height: 50px;
`;

const ItemUrl = styled.div`
    flex: 1;
    font-size: 11px;
    color: #808080;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export { List, ImportedBlock, ItemTitle, ItemContent, ItemImage, ItemUrl };