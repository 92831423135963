import styled from "styled-components";

const Preview = styled.div`
    position: absolute;
    top: 60px;
    left: 60px;
    right: 60px;
    padding: 20px;
    min-height: 400px;
	background-color: #263238;
    border-radius: 8px;

    > div {
        color: #f7f7f7;
        font-size: 14px;
        line-height: 150%;
        font-family: Menlo, Monaco, Consolas, 'Courier New';
        user-select: initial;
    }
`;

const PreviewCloseIcon = styled.div`
    float: right;
    color: #f7f7f7;
    cursor: pointer;
    padding: 10px;
    margin-right: -10px;
    margin-top: -20px;
`;

export { Preview, PreviewCloseIcon };