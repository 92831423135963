import React from "react";
import { useTranslation } from "react-i18next";
import AudioPlayer from 'react-audio-element';
import ReactAudioPlayer from "react-audio-player";

import Lottie from "react-lottie-player";
import lottieRecorder from "../../assets/animations/voicerecorder.json";
import { Player, RecordingNow, RecordingNowInfo, RecordingNowInfoIcon, RecordingNowInfoText } from "./styled";

type Props = {
  audioUri: string,
  duration: number | null,
  recordingNewVersion: boolean,
  recordingEstimatedTime: number | null,
  variant: 'default' | 'old' 
}

const CustomAudioPlayer = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Player>
      {props.audioUri &&
        <div>
          {props.variant === 'default' && 
            <AudioPlayer 
              src={props.audioUri}
              colors = {{ sliderTrack : '#f7f7f7', slider : '#5e6ad2' }}
            />
          }
          {props.variant === 'old' && 
            <ReactAudioPlayer
              src={props.audioUri}
              controls
              controlsList="play timeline nodownload"
            />
          }
        </div>}
      {props.recordingNewVersion && <RecordingNow>
        <RecordingNowInfo>
          <RecordingNowInfoIcon>
            <Lottie
              animationData={lottieRecorder} play loop
              style={{ width: 55, height: 55 }}
            />
          </RecordingNowInfoIcon>
          <RecordingNowInfoText>
            {t('recordingOfNewVersionOngoing')}
            {props.recordingEstimatedTime}
          </RecordingNowInfoText>
        </RecordingNowInfo>
      </RecordingNow>}
    </Player>
  );
};

export default CustomAudioPlayer;
