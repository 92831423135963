import { Mark, mergeAttributes } from '@tiptap/core'

export interface VoiceMarkOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    voicemark: {
      setVoiceMark: () => ReturnType,
      toggleVoiceMark: () => ReturnType,
      unsetVoiceMark: () => ReturnType,
    }
  }
}

export const getEmojiString = (attributes: any) => {
  let str = '💡'; // TODO: 
  if (attributes.lang === 'en') {
    str += '🇬🇧';
  }
  return str;
}

export const VoiceMarkExtension = Mark.create<VoiceMarkOptions>({
  name: 'voicemark',

  group: 'inline',
  inline: false,
  selectable: true,
  atom: true,

  addOptions(): VoiceMarkOptions {
    return (
      {
        HTMLAttributes: { },
      }
    )
  },

  parseHTML() {
    return [
      {
        tag: 'voicemark',
      },
      {
        style: 'text-decoration', // TODO: 
        consuming: false,
        getAttrs: style => ((style as string).includes('voicemark') ? {} : false),
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['voicemark', mergeAttributes(
      this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setVoiceMark: () => ({ commands }) => {
        return commands.setMark('voicemark')
      },
      toggleVoiceMark: () => ({ commands }) => {
        return commands.toggleMark('voicemark')
      },
      unsetVoiceMark: () => ({ chain }) => {
        return chain()
          .extendMarkRange('voicemark')
          .unsetMark('voicemark')
          .run()
      }
    }
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addAttributes() {
    return {
      type: {
        default: 'text',
        parseHTML: element => {
          return element.getAttribute('pronounce-type') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.type) {
            return {}
          }
          return {
            'pronounce-type': attributes.type
          }
        },
      },
      pronounce: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('pronounce-as') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.pronounce) {
            return {}
          }
          return {
            'pronounce-as': attributes.pronounce
          }
        },
      },
      /*
      lang: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('lang') ?? null;
        },
        renderHTML: attributes => {
          if (!attributes.lang) {
            return {}
          }
          return {
            'lang': attributes.lang
          }
        }
      },
      pitch: {
        default: '',
        parseHTML: element => {
          return element.getAttribute('pitch') ?? null;
          }
        },
        renderHTML: attributes => {
          if (!attributes.pitch) {
            return {}
          }
          return {
            'pitch': attributes.pitch
          }
        }
      },
      */
      emotion: {
        default: 'normal',
        parseHTML: element => {
          return {
            emotion: element.getAttribute('emotion') ?? null
          }
        },
        renderHTML: attributes => {
          if (!attributes.emotion) {
            return {}
          }

          return {
            // 'emotion': attributes.emotion,
            // 'emoji': getEmojiString(attributes),
          }
        },
      }
    }
  }
})