import React, { useState } from "react";

import { Wrapper, AddFileInstruction, DropHere, Uploading } from "./styled";

import { Config } from "../../config";
import { BlobsApi, StoryApi } from "../../generated-sources/openapi";
import * as AudioFileFunctions from "../../utils/audioFileFunctions";

import getBlobDuration from "get-blob-duration";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

type Props = {
  storyAlias: string,
  snapshotKey: string,
  currentFileName: string,
  currentFileSize: number,
  onUploaded: any
}

const AudioUploader = (props: Props) => {
  const { t } = useTranslation();
  const blobsApi = new BlobsApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onDrop = (files: any) => {
    upload(files);
  };

  const upload = async (uploadedFiles: any) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setIsUploading(true);

      const file: File = uploadedFiles[0];
      const blobFileName = AudioFileFunctions.generateBlobName(file.name);
      const duration = await getBlobDuration(file);

      // Note: Valid formats are being set as accept 
      // attribute on the Dropzone comp.
      const fileFormat = AudioFileFunctions.getFileFormat(file.name);

      const resp = await blobsApi.uploadAsync(
        blobFileName, fileFormat, false, file);

      if (resp.status === 200 && resp.data.success) {
        const uploadUri: string = resp.data.uri!.toString();
        setIsUploading(false);

        const updateAudioOnSnapshotResult = await storyApi
            .updateAudioOnSnapshot(props.storyAlias, props.snapshotKey, 
              uploadUri, file.size, duration);

        if (updateAudioOnSnapshotResult.data && 
          updateAudioOnSnapshotResult.status === 200) {
            console.log(`audio uri has been saved to snapshot`);
        } else {
            throw new Error(`failed to save to backend`);
        }

        props.onUploaded({
          uri: uploadUri,
          size: file.size,
          duration: duration
        });
      } else if (!resp.data.success) {
        throw new Error(`failed on server: ${resp.data.message}`);
      } else {
        throw new Error(`failed to upload file: ${resp.statusText}`);
      }
    }
  }

  return (
    <Wrapper>
        <Dropzone
            onDropAccepted={onDrop}
            onDropRejected={(files: any) => {
              // TODO: handle this...
              console.warn('rejected files', files);
              // TODO: send file formats to bugsnag to follow-up
            }}
            accept=".mp3,.ogg,.wav,.m4a"
            noClick={false}
            maxFiles={1}
            minSize={10}
            preventDropOnDocument={true}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                return (
                  <div {...getRootProps({ className: 'dropzone-transparent' })} 
                    style={{ position: 'relative', minHeight: 200, padding: 30 }}>

                    {!isDragActive && !isDragActive && <AddFileInstruction>
                      <div>{t('addFileInstruction')}</div>
                    </AddFileInstruction>}

                    {isUploading && <Uploading>
                      <div style={{ flex: 1 }}>{t('uploading')}...</div>
                    </Uploading>}

                    {isDragActive && <DropHere>
                      <div style={{ flex: 1 }}>{t('dropFileHere')}</div>
                    </DropHere>}

                    {!isDragActive && isDragReject && <div style={{ flex: 1 }}>
                      {t('fileInvalidToUpload')}
                    </div>}

                    <input {...getInputProps()} />

                  </div>
                )
              }
            }
          </Dropzone>
    </Wrapper>
  );
};

export default AudioUploader;