import * as React from 'react';
import { useState, useEffect } from 'react';
import { Config } from '../../config';

import { Button, FormControl, InputLabel, Input, FormHelperText, Card } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import ClipLoader from "react-spinners/ClipLoader";

import { toast } from 'react-toastify';
import { TenantSummaryDto, UserApi } from '../../generated-sources/openapi/api';

interface Props {
    open: boolean;
    onClose: any;
    onTenantChange: any;
}

const TenantManagerDialog = (props: Props) => {
    const userApi = new UserApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

    const [tenants, setTenants] = useState<TenantSummaryDto[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [tenantIdToConnect, setTenantIdToConnect] = useState('');

    const getTenants = async () => {
        const userInfo = await userApi.getUserInfo();
        setTenants(userInfo.data.tenants!);
        setIsLoading(false);
    };

    useEffect(() => {
        props.open && getTenants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const getCurrentTenantId = () => {
        return sessionStorage.getItem('TENANT_ID');
    };

    const selectTenantInList = (tenant: TenantSummaryDto) => {
        props.onTenantChange(tenant);
    };

    const handleTenantIdToConnectChange = (event: any) => {
        setTenantIdToConnect(event.target.value);
    };

    const connectToTenant = async () => {
        const result = await userApi.addToTenant(tenantIdToConnect);
        try {
            if (result.data.success) {
                toast.success(`Connected successfully.`, {
                    autoClose: 1000,
                });
                await getTenants();
                setShowAddDialog(false);
            } else {
                toast.error(`Failed to connect tenant: ${result.data.message}`);
            }
        } catch (error) {
            toast.error(error);
            return;
        }
    };

    return (
        <Dialog fullWidth={true} maxWidth="xs" open={props.open} onClose={props.onClose}>
            <DialogContent style={{ marginBottom: 20 }}>
                {!isLoading ? (
                    <div>
                        <Paper style={{ marginBottom: 20 }}>
                            {!isLoading && tenants && (
                                <List component="nav">
                                    {tenants.map((tenant: TenantSummaryDto) => {
                                        let desc = '';
                                        if (tenant.alias) {
                                            desc += `${tenant.alias}.${process.env.REACT_APP_TENANT_DOMAIN}`;
                                        }
                                        return (
                                            <ListItem
                                                button
                                                onClick={() => selectTenantInList(tenant)}
                                                key={tenant.id}
                                                selected={tenant.id === getCurrentTenantId()}
                                            >
                                                {tenant.logoUri && (
                                                    <ListItemIcon>
                                                        <img src={tenant.logoUri} style={{ width: 45, height: 45 }} alt={tenant.name} />
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText primary={tenant.name} secondary={desc} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            )}
                        </Paper>

                        {!showAddDialog && (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{ marginBottom: 20 }}
                                onClick={(): void => {
                                    setShowAddDialog(true);
                                }}
                            >
                                Connect to existing
                            </Button>
                        )}

                        {showAddDialog && (
                            <Card variant="outlined" style={{ marginBottom: 20, padding: 20, textAlign: 'center' }}>
                                <FormControl>
                                    <InputLabel htmlFor="addTenantId">Please enter ID</InputLabel>
                                    <Input
                                        value={tenantIdToConnect}
                                        onChange={handleTenantIdToConnectChange}
                                        id="addTenantId"
                                    />
                                    <FormHelperText id="addTenantId">
                                        Ask the owner of tenant for the ID to get invited.
                                    </FormHelperText>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 10 }}
                                        onClick={(): void => {
                                            connectToTenant();
                                        }}
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={(): void => {
                                            setShowAddDialog(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </FormControl>
                            </Card>
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 20,
                        }}
                    >
                        <ClipLoader color="#eee" size="16px" />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TenantManagerDialog;
