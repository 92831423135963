import { Mark, mergeAttributes } from '@tiptap/core'
// import { Plugin, PluginKey } from 'prosemirror-state'

// https://github.com/ueberdosis/tiptap/blob/main/packages/extension-highlight/src/highlight.ts
// https://github.com/ueberdosis/tiptap/blob/main/packages/suggestion/src/suggestion.ts

export interface GeoMarkOptions {
  // If enabled, geo names will be opened on click.
  openOnClick: boolean,
  // A list of HTML attributes to be rendered.
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    geomark: {
      // Set a mark
      setGeoMark: (attributes: { localixId: string }) => ReturnType,
      // Toggle a mark
      toggleGeoMark: () => ReturnType,
      // Unset a mark
      unsetGeoMark: () => ReturnType,
    }
  }
}

export const GeoMarkExtension = Mark.create<GeoMarkOptions>({
  name: 'geomark',
  priority: 1000,
  inclusive: false,

  addOptions(): GeoMarkOptions {
    return {
      openOnClick: false,
      HTMLAttributes: { },
    }
  },

  parseHTML() {
    return [
      { tag: 'geomark' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['geomark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setGeoMark: attributes => ({ commands }) => {
        return commands.setMark('geomark', attributes)
      },
      unsetGeoMark: () => ({ chain }) => {
        return chain()
          .extendMarkRange('geomark')
          .unsetMark('geomark')
          .run()
      },
    }
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addAttributes() {
    return {
      localixId: {
        default: null,
      }
    }
  },
})