import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    flex: 1;
    padding: 40px;
    width: 770px; // TODO: 
    border-radius: 36px;
    background: #fff;
    box-shadow: 20px 20px 60px #d2d2d2, 
                -20px -20px 60px #fff;
    max-height: 790px;
    overflow-y: auto;
    user-select: none;

    @media only screen and (max-width: 1050px) {
        max-width: 510px;
    }
`;

const Title = styled.h1`
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 300;
    color: #898989;
    cursor: default;
`;

const SubTitle = styled.h2`
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #898989;
    cursor: default;
`;

// TODO: the trash can is not able to be shown in the UI right now
const TrashCan = styled.div`
    position: absolute;
    width: calc(100% - 100px);
    height: calc(100vh - 200px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 3px dashed #f6f6f6;
    border-radius: 11px;

    i {
        font-size: 4em;
        color: #f6f6f6;
    }
`;

const ItemSubtitle = styled.div`
    max-width: 440px;
    margin-top: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`;

const ItemGroup = styled.div`
    font-size: 13px;
`;

export { Wrapper, Title, SubTitle, TrashCan, ItemSubtitle, ItemGroup };
