import React, { useEffect, useState } from "react";
import { BlockDto, InputPropertyDto, VoicePersonDto, VoicesApi } from "../../generated-sources/openapi";
import { ImportedBlock, ItemContent, ItemImage, ItemTitle, ItemUrl, List,  } from "./styled";
import axios from "axios";
import { Config } from "../../config";
import { useTranslation } from "react-i18next";
import { Button, Input, HStack, Modal, ModalCloseButton, 
  ModalContent, ModalOverlay, Skeleton, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import * as BlockFunctions from "../../utils/blockFunctions";
import isUrl from "is-url";

type Props = {
  isOpen: boolean,
  importBlock: any,
  onClose: any,
  langCode: string
}

const ImportModal = (props: Props) => {
  const { t } = useTranslation();
  const voicesApi = new VoicesApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

  useEffect(() => {
    loadVoices();

    const urlInLocalStorage = localStorage.getItem('import-url');
    if (urlInLocalStorage) {
      setUrl(urlInLocalStorage!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [url, setUrl] = useState<string>('');
  const [isLoadingFeed, setIsLoadingFeed] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [fetchedBlocks, setFetchedBlocks] = useState<any[] | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [voices, setVoices] = useState<VoicePersonDto[]>([]);

  useEffect(() => {
    localStorage.setItem('import-url', url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const loadVoices = async () => {
    const voiceListResult = await voicesApi.getListOfAvailableVoices();
    setVoices(voiceListResult.data);
  };

  const fetch = async () => {
    setIsLoadingFeed(true);
    let apiEndpoint: string = `/.netlify/functions/rssimporter?url=${encodeURI(url)}`;

    let result = await axios.get(apiEndpoint);
    let blocks: BlockDto[] = result.data;
    setFetchedBlocks(blocks);

    setIsLoadingFeed(false);
  };

  const selectItem = (item: any) => {
    const id = item.url;
    if (selectedIds.some(x => x === id)) {
      // remove it
      setSelectedIds(selectedIds.filter(x => x !== id));
    }
    else {
      // add it
      setSelectedIds([...selectedIds, id]);
    }
  };

  const addBlocksToStory = () => {
    setIsImporting(true);
    
    const voiceMode = 'randomize';    
    let voiceId: VoicePersonDto | undefined = undefined;

    selectedIds.forEach(id => {
      if (voiceMode === 'randomize') {
        const randomIndex = Math.round(Math.random()*voices.length);
        let randomChosenVoice = voices[randomIndex];
        if (voiceId && voiceId.name === randomChosenVoice.name) {
          // do not set same as the previous one,
          // if so, choose the next voice instead
          randomChosenVoice = voices[randomIndex === voices.length ? 0 : randomIndex+1];
        }
        voiceId = randomChosenVoice;
      }

      const rssItem = fetchedBlocks?.filter(x => x.url === id)[0];
      BlockFunctions.createBlockFromRssItem(rssItem, voiceId, props.langCode).then((block) => {
        let inputs: InputPropertyDto[] = [];
        inputs.push({ 
            key: 'url',
            value: rssItem.url
        });

        props.importBlock(block, inputs);
      });
    });

    setIsImporting(false);
    
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={() => { props.onClose() }} size={'xl'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('importFromRssFeed')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          <HStack>
              <Input 
                variant="outline" 
                value={url}
                size="md"
                isInvalid={url !== '' && !isUrl(url)}
                isDisabled={isLoadingFeed}
                placeholder={t('importUrlPlaceholder')}
                onChange={(e) => { setUrl(e.target.value); }} 
                onPaste={fetch} />
              <Button isLoading={isLoadingFeed} onClick={fetch}>{t('fetch')}</Button>
          </HStack>

          {isLoadingFeed && <Skeleton />}

          {fetchedBlocks && <div>
            <List>
              {fetchedBlocks?.map((item) => (
                <ImportedBlock key={item.url} selected={selectedIds.some(x => x === item.url)}>
                  <div onClick={() => { selectItem(item); }}>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemContent>{item.text}</ItemContent>
                    {item.imageUrl && <ItemImage>{item.imageUrl}</ItemImage>}
                    <ItemUrl><div onClick={() => { window.open(item.url); }}>{item.url}</div></ItemUrl>
                  </div>
                </ImportedBlock>
              ))}
            </List>
          </div>}

        </ModalBody>
        <ModalFooter>
          <Button disabled={selectedIds.length===0} onClick={addBlocksToStory} 
            isLoading={isImporting} loadingText={t('importUrlProcessing')}>
            {t('importSelected')} <span>&nbsp;({selectedIds.length})</span>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportModal;
