import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 2px;
`;

const UploadLink = styled.div`
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 10px;
    color: #808080;
    cursor: pointer;
    &:hover {
        color: #ccc;
    }
`;

export { Grid, UploadLink };