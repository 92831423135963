import styled from "styled-components";

const Container = styled.div`
    width: 700px;
    margin: 30px;
`;

const ReadTextArea = styled.div`
    background-color: #fbfbef;
    margin-bottom: 30px;
    padding: 30px;
    font-size: 20px;
    border-radius: 14px;

    p {
        margin-bottom: 15px;
    }
`;

const EstimatedTime = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    color: #888;
`;

const ContainsOn = styled.div`
    padding: 20px;
    border: solid 1px var(--chakra-colors-gray-200);
    border-radius: 14px;

    ul {
        margin-left: 20px;
    }
    li {
        padding-left: 5px;
    }
`;

export { Container, ReadTextArea, EstimatedTime, ContainsOn };