import React from "react";
import { Modal, ModalCloseButton, 
  ModalContent, ModalOverlay } from "@chakra-ui/react";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

type Props = {
  onSelect: any,
  onClose: any
}

const EmojiModal = (props: Props) => {
  const selectEmoji = (emoji: any) => {
    props.onClose();
    props.onSelect(emoji.native, emoji.colons);
  }

  const cancel = () => {
    props.onClose();
  }

  return (
    <Modal size={'3xl'} isOpen={true} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <Picker 
          set="apple"
          showPreview={true}
          title=""
          emoji=""
          emojiSize={30}
          onSelect={(emoji: any) => selectEmoji(emoji)}
          color={`#c0c0c0`}
          showSkinTones={false}
        />

      </ModalContent>
    </Modal>
  );
};

export default EmojiModal;
