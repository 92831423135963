import styled from "styled-components";
import { BlockDto } from "../generated-sources/openapi";

type ListProps = {
    hidden?: boolean,
    sidebar?: boolean
};

const List = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 108px;

    ${(props: ListProps) => props.hidden && `
        visibility: hidden;
    `}
`;

type ListItemProps = {
    isDragging?: boolean;
    sidebar?: boolean;
    color?: string,
    animate?: boolean,
    dragEnabled?: boolean,
    draggingOver?: string,
    isDropAnimating?: boolean,
    item?: BlockDto,
    selected?: boolean,
    droppable?: boolean
}

const ListItem = styled.div`
    display: flex;
    align-items: center;    
    background-color: ${(props: ListItemProps) => !props.isDragging && props.sidebar ? "#fff" : "#fff"};
    border-radius: 11px;
    border: solid 0.5px transparent;
    padding: 20px 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
    min-height: 88px;
    box-shadow: 0px 1px 4px rgba(97, 97, 97, 0.25);
    ${(props: ListItemProps) => props.isDragging ? "box-shadow: 0px 1px 6px rgba(97, 97, 97, 0.25);" : null}

    .content {
        flex: 1;
        flex-basis: 100%;
        margin-left: 20px;
    }

    .title {
        margin-bottom: 6;
        margin-right: 10px;
        font-size: 16px;
    }

    @media only screen and (max-width: 600px) {
        .title {
            font-size: 13px;
        }
    }

    .voiceperson {
        flex: 1;
        margin-right: 8px;
        img {
            width: 42px;
            height: 42px;
            max-width: 42px;
        }
    }

    .duration {
        flex: 1;
        flex-basis: 60px;
        margin-right: 6px;
        font-size: 15px;
        color: #c0c0c0;
        text-align: center;
    }

    .text {
        margin-bottom: 6;
        font-size: 11;
        display: none;
    }

    .gripper {
        visibility: hidden;
    }
    :hover {
        .gripper {
            visibility: visible;
        }
    }

    ${(props: ListItemProps) => props.item?.type === 'placeholder' && `
        background-color: #f8f8f8;
        box-shadow: none;

        min-height: 80px;
        padding-top: 6px;
        padding-bottom: 6px;

        .status {
            display: none;
        }
        .title {
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-style: italic;
            color: #c0c0c0;
        }
        .gripper {
            display: none;
        }
        .dropToReplace {
            width: 250px;
            font-size: 12px;
            text-align: center;
            color: blue;   
        }
    `}

    ${(props: ListItemProps) => props.item?.type === 'sound' && `
        min-height: 40px;
        padding-top: 6px;
        padding-bottom: 6px;
        .status { display: none; }
        .title {
            text-align: center;
            font-size: 13px;
        }
        .sound-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 26px;
            height: 26px;
        }
        .content {
            margin: 0;
            width: 100%;
        }
    `}

    ${(props: ListItemProps) => props.sidebar ? `
        background-color: #fcfef5;
        padding: 10px;
        min-height: 50px;
        width: 600px;
        
        @media only screen and (max-width: 1050px) {
            width: calc(100% - 20px);
        }` : null}

    cursor: pointer !important;
    ${(props: ListItemProps) => !props.dragEnabled && `
        cursor: pointer;
    `}

    ${(props: ListItemProps) => props.isDropAnimating && props.draggingOver === "sidebar-droppable" && `
        visibility: hidden !important;
    `}

    ${(props: ListItemProps) => props.droppable && `
        border: solid 3px blue;
    `}

    ${(props: ListItemProps) => !props.isDragging && props.sidebar && `
        transform: none !important;
    `}

    ${(props: ListItemProps) => props.animate && `
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fadeIn 1s;
    `}

    ${(props: ListItemProps) => props.selected && `
        border: dotted 0.5px blue;
    `}
`;

type AddableItemProps = {
    isDragging?: boolean;
    animate?: boolean,
    dragEnabled?: boolean,
    draggingOver?: string,
    isDropAnimating?: boolean,
    item?: BlockDto,
    color?: string,
    added?: boolean;
}

const AddableItem = styled.div`
    display: flex;
    align-items: center;
    background-color: ${(props: AddableItemProps) => !props.isDragging ? 
        props.color !== undefined ? 
            props.color : "#fcfef5" : "#fff"};
    color: ${(props: AddableItemProps) => props.isDragging ? "#000" :
        props.color !== undefined ? "#000" : "#fff"}; // TODO: black styling button, set color prop to #303030
    border-radius: 11px;
    padding: 4px 10px;
    font-size: 14px;
    margin: 5px;
    box-shadow: 0px 1px 4px rgba(97, 97, 97, 0.25);
    cursor: pointer !important;

    .sound-icon {
        width: 36px;
        height: 36px;
        margin-right: 16px;
    }

    .story-title {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    ${(props: AddableItemProps) => props.isDragging ? 
        "box-shadow: 0px 1px 6px rgba(97, 97, 97, 0.25);" : null}

   
    ${(props: AddableItemProps) => !props.dragEnabled && `
        cursor: pointer;
    `}

    ${(props: AddableItemProps) => props.isDropAnimating && 
        (props.draggingOver === "sidebar-droppable" ||
        props.draggingOver === "sounds-droppable" ||
        props.draggingOver === "shared-droppable") && `
        visibility: hidden !important;
    `}

    ${(props: AddableItemProps) => !props.isDragging && `
        transform: none !important;
    `}

    ${(props: AddableItemProps) => props.animate && `
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        animation: fadeIn 1s;
    `}
`;

type GripperProps = {
    sidebar?: boolean,
    isDragging?: boolean,
}

const Gripper = styled.div`
    // display: ${(props: GripperProps) => !props.isDragging ? "none" : "block"};
    cursor: move;
    content: '....';
    width: 10px;
    height: 20px;
    display: inline-block;
    margin-top: -8px;
    line-height: 5px;
    vertical-align: middle;
    font-size: 12px;
    font-family: sans-serif;
    letter-spacing: 2px;
    text-shadow: 1px 0 1px #000;
    color: #c0c0c0;
    word-wrap: normal !important; /* To override Chakra UI global style */

    :after {
        color: #c0c0c0;
        content: '.. .. .. ..';
        word-wrap: normal !important; /* To override Chakra UI global style */
    }
`;

type CloneProps = {
    absolute?: boolean;
    notClickable?: boolean;
}

const Clone = styled(ListItem)`
    ${(props: CloneProps) => props.absolute && "position: absolute;"}
    width: calc(100% - 6px);
    background-color: unset;
    box-shadow: unset;
    box-sizing: border-box;
    border: 3px dashed #f6f6f6;
    ${(props: CloneProps) => props.notClickable && "cursor: default !important;"}

    p {
        width: 100%;
        text-align: center;
    }

    ~ div {
        transform: none !important;
    }
`;

const SoundAddableItemClone = styled(AddableItem)`
    ${(props: CloneProps) => props.absolute && "position: absolute;"}
    width: calc(100% - 6px);
    background-color: unset;
    box-shadow: unset;
    box-sizing: border-box;
    border: 3px dashed #f6f6f6;

    ~ div {
        transform: none !important;
    }
`;

const SharedAddableItemClone = styled(AddableItem)`
    ${(props: CloneProps) => props.absolute && "position: absolute;"}
    width: calc(100% - 6px);
    background-color: unset;
    box-shadow: unset;
    box-sizing: border-box;
    border: 3px dashed #f6f6f6;

    ~ div {
        transform: none !important;
    }
`;

type ButtonProps = {
    fullWidth?: boolean;
    small?: boolean;
    dark?: boolean;
    primary?: boolean;
    progress?: boolean;
}

const Button = styled.button`
    border: unset;
    cursor: pointer;
    flex: 1;

    background-color: #fff;
    color: #898989;
	border: solid 1px #e2e2e2;
    padding: 10px 20px;
    border-radius: 11px;
    font-size: 18px;
    color: #565656;

    :first-of-type {
        margin-left: 0;
    }

    &.enabled {
        border: dotted 1px red;
        color: red;
    }

    :active {
        color: #a7a7a7;
    }

    &.processing {
        display: none;
    }

    ${(props: ButtonProps) => props.primary && "font-weight:700;"}
    ${(props: ButtonProps) => props.fullWidth && "width:100%;"}
    ${(props: ButtonProps) => props.small && "font-size:14px; padding:8px 12px;"}
    ${(props: ButtonProps) => props.dark && "background-color:#666; border:0; color:#fff;"}
    ${(props: ButtonProps) => props.progress && "background-color:#999;"}
`;

const LeftPanel = styled.div`
    min-height: 700px; // TODO: 
    min-width: 480px;
    max-width: 480px;
    margin-right: 50px;
    position: relative;
    flex: 1;
    padding: 36px;
    background-color: #fff;
    border-radius: 11px;
    height: 100%;
    user-select: none;
    border-radius: 36px;
    box-shadow: 20px 20px 60px #d2d2d2, 
                -20px -20px 60px #fff;

    @media only screen and (max-width: 1050px) {
        min-height: 580px;
        min-width: 400px;
        max-width: 400px;
    }
    
    @media only screen and (max-width: 600px) {
        min-height: 500px;
        min-width: 320px;
        max-width: 320px;
        padding: 26px;
    }
`;

const StoryTitle = styled.div`
    max-width: 380px;
    font-size: 15px;
    font-weight: 600;
    color: #5a5a5a;
    margin-left: 0;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (min-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const SnapshotTitle = styled.div`
    font-size: 22px;
    color: #5a5a5a;
    margin-left: 0;
    margin-right: 10px;
    cursor: pointer;

    @media only screen and (min-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
        font-size: 27px;
    }
`;

const Link = styled.div`
    font-size: 10px;
    font-weight: 700;
    color: #898989;
    text-decoration: underline;
    cursor: pointer;
    :hover {
        color: #c0c0c0;
    }
`;

const Link2 = styled.a`
    font-size: 14px;
    color: #898989;
    cursor: pointer;
    text-decoration: none;
    :hover {
        color: #c0c0c0;
    }
    :focus { outline:0; }
`;

const AddGroupHeader = styled.div`
    font-size: 18px;
    color: #898989;
    margin-top: 30px;
    margin-left: 5px;
    margin-bottom: 20px;
    cursor: default;
`;

const Loader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    > div {
        flex: 1;
        color: #c0c0c0;
        text-align: center;
        font-size: 28px;
        margin: auto;
        padding-top: 300px;
        cursor: default;
    }
`;

const CloseIcon = styled.div`
    float: right;
    margin-top: -6px;
    background-color: #fff;
	color: #898989;
	border: solid 1px #e2e2e2;
	padding: 7px 20px;
	margin: 5px;
	border-radius: 4px;
	font-size: 18px;
	cursor: pointer;
`;

const TotalTime = styled.div`
    text-align: center;
    font-size: 12px;
    color: #808080;
    margin-top: 5px;
`;

export { List, ListItem, AddableItem, AddGroupHeader, Gripper, Clone, 
    Button, LeftPanel, StoryTitle, SnapshotTitle, Link, Link2, Loader, 
    SoundAddableItemClone, SharedAddableItemClone, CloseIcon, TotalTime };
