import React, { useEffect, useRef, useState } from "react";
import { Config } from "../../config";
import { StoryApi, StorySummaryDto } from "../../generated-sources/openapi";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { FeatureFlags } from "../../utils/featureFlags";

import { Container, EpisodeInfo, Header, LiveBox, 
    PodcastIcon, StatusIcon, StoryItem } from "./styled";
import { Button, StoryTitle } from "../styled";
import { Spinner } from "@chakra-ui/react";

import CreateStationModal from "../CreateStationModal";

const StationList = () => {
    const history = useHistory();

    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const { t } = useTranslation();
    // Note: used for cancelling async functions
    const isCancelled = useRef(false);

    const [tenantName, setTenantName] = useState<string>('');
    const [stories, setStories] = useState<StorySummaryDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const tenantName = sessionStorage.getItem('TENANT_NAME')!;
        setTenantName(tenantName);

        loadStories();

        return () => {
            isCancelled.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadStories = () => {
        setIsLoading(true);

        const tenantId = sessionStorage.getItem('TENANT_ID');
        if (!tenantId) {
            console.warn('tenant-id is null');
            window.location.reload();
        }

        storyApi.getStoriesByTenant(tenantId!, 'podcasts').then((result) => {
            if (!isCancelled.current) {
                setStories(result.data);
                setIsLoading(false);
            }
        });
    }

    const getStatusColor = (item: StorySummaryDto) => {
        if (!item.latestSnapshot || !item.latestSnapshot.destinationAudioDateTime) {
            return '#c53939';
        }
        return item.latestSnapshot?.enabled ? 'green' : 'orange';
    }

    return (
        <Container>
            <div>
                <Header>{t('storiesFor')} {tenantName}</Header>

                {isLoading && <Spinner size="lg" style={{ marginLeft: 20 }} />}

                {stories.map((item) => (
                    <StoryItem key={item.alias} onClick={() =>  history.push(`/${item.alias}`)  }>
                        <PodcastIcon>{item.icon && <img src={item.icon} alt="" />}</PodcastIcon>
                        <StoryTitle>{item.title}</StoryTitle>
                        <EpisodeInfo>
                            {item.latestSnapshot &&
                                <div>
                                    {t('latestEpisode')}:<br/>
                                     {item.latestSnapshot?.title}
                                </div>}
                        </EpisodeInfo>
                        {FeatureFlags.radio && <div style={{ flex: 1, fontSize: 15, display: 'flex', alignItems: 'center' }}>
                            <LiveBox>Live</LiveBox>
                        </div>}
                        <StatusIcon style={{ backgroundColor: getStatusColor(item) }}></StatusIcon>
                    </StoryItem>
                ))}
            </div>

            {!isLoading && stories.length === 0 && 
                <div style={{ fontSize: 13, marginBottom: 20, marginLeft: 15 }}>
                    {t('noStationsYet')}
                </div>}


            {!isLoading && <div style={{ marginTop: 20, marginLeft: 10, marginBottom: 40 }}>
                <Button small onClick={() => { setCreateModalOpen(true); }} 
                    style={{ paddingLeft: 20, paddingRight: 20 }}>{t('createNew')}</Button>
            </div>}

            {createModalOpen && <CreateStationModal 
                onClose={() => { setCreateModalOpen(false); }} />}

        </Container>
    );
}

export default StationList;