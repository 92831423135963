import React from 'react';
import { useTranslation } from "react-i18next";

import { Config } from "../../config";
import { BlobsApi } from "../../generated-sources/openapi/api";
import Resizer from 'react-image-file-resizer';

type Props = {
    storyAlias: string,
    imageUrl: string,
    onChange: any
}

const CoverImage = (props: Props) => {
    const { t } = useTranslation();
    const blobsApi = new BlobsApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

    const minSize: number = 1600;
    const maxSize: number = 5000;
    const quality: number = 100;
    
    const resizeFile = (file: any) => new Promise(resolve => {
        Resizer.imageFileResizer(file, maxSize, maxSize, 'JPEG', quality, 0,
            uri => { resolve(uri); },
            'blob', minSize, minSize
        );
    });

    const onImageChange = async (event: any) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            uploadImage(image);
        } else {
            console.error(`no file selected.`);
        }
    }

    const uploadImage = async (file: any) => {
        const timeStamp = new Date().getMilliseconds();
        const fileName = `${props.storyAlias}-${timeStamp}-coverimage.jpg`;

        const uploadResult = await blobsApi.uploadImageAsync(fileName, file);
        if (uploadResult.data.success) {
            const newUri = uploadResult.data.uri!;
            props.onChange(newUri);
        }
    }

    return (
        <div title="Click to replace">
            <input 
                type="file" 
                accept="image/*"
                id="file-upload" 
                onChange={onImageChange} hidden />
            <label htmlFor="file-upload">
                <img src={props.imageUrl} 
                    alt="Click to replace" 
                    style={{ width: 150, height: 150, border: 'solid 1px #f7f7f7', cursor: 'pointer' }} />
            </label>
            <div style={{ fontSize: 10, color: '#718096' }}>
                {t('coverImageHelpText')}
            </div>
        </div>
    );
}

export default CoverImage;