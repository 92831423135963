import styled from "styled-components";

const Player = styled.div`
    .time-text::first-of-type {
        margin-right: 10px;
    }
    .time-text::last-of-type {
        margin-left: 10px;
    }
`;

const RecordingNow = styled.div`
    width: 100%;
`;

const RecordingNowInfo = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    width: 250px;
`;

const RecordingNowInfoIcon = styled.div`
    width: 60px;
`;

const RecordingNowInfoText = styled.div`
    flex: 1;
    font-size: 14px;
    cursor: default;
`;

export { Player, RecordingNow, RecordingNowInfo,
    RecordingNowInfoIcon, RecordingNowInfoText };
