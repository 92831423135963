import React, { useEffect, useState } from "react";
import { SnapshotSummaryDto, StoryApi } from "../../generated-sources/openapi";
import { Config } from "../../config";
import { formatDistance } from "date-fns";
import { sv } from 'date-fns/locale';
import { Badge, Button, IconButton, Modal, ModalCloseButton, 
  ModalContent, ModalOverlay, Skeleton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdDeleteForever } from "react-icons/md";

import { EpisodeItem } from "./styled";

type Props = {
  storyAlias: string | null,
  currentSnapshotKey: string | null | undefined,
  isOpen: any,
  onChange: any,
  onClose: any,
}

/*
 This component shows all episodes for one podcast.
 In backend terminology, an episode is a snapshot.

 A feature in the episode can also have snapshots, 
 but these are manage in the component SnapshotHistory.
*/
const Episodes = (props: Props) => {
  const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
  const { t } = useTranslation();

  const [episodes, setEpisodes] = useState<SnapshotSummaryDto[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  
  useEffect(() => {
    if (props.isOpen && props.storyAlias) {
      setIsLoading(true);
      loadFromBackend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const loadFromBackend = async () => {
    if (!props.storyAlias) throw new Error(`story alias missing.`);
    const snapshotsFromBackend = await storyApi
      .getStorySnapshots(props.storyAlias!);
    setEpisodes(snapshotsFromBackend.data);
    setIsLoading(false);
  };

  const generateNew = async () => {
    setIsGenerating(true);
    const triggerResult = await storyApi.triggerStory(props.storyAlias!);
    if (triggerResult.data.success) {
      setTimeout(() => { // TODO: set timeout
        loadFromBackend();
        setIsGenerating(false);
      }, 15*1000); // 15 secs
    } else {
      setIsGenerating(false);
      throw Error(triggerResult.data.message!);
    }
  }

  const remove = async (snapshot: SnapshotSummaryDto) => {
    if(episodes?.length === 1) {
      console.warn(`you can't delete the last available episode`);
      return;
    }
    const removeResult = await storyApi
      .deleteSnapshot(props.storyAlias!, snapshot.snapshotKey);
    if (removeResult.status === 200 && removeResult.data) {
      loadFromBackend();
      if (props.currentSnapshotKey === snapshot.snapshotKey) {
        // current episode has been removed,
        // go to the first available one
        const firstAvailableSnapshot = episodes
          ?.filter(x => x.snapshotKey !== snapshot.snapshotKey)[0];

        if (firstAvailableSnapshot) {
          props.onChange(firstAvailableSnapshot.snapshotKey);
        } else {
          throw new Error(`failed to open snapshot.`);
        }
      }
    } else {
      throw new Error(`failed to delete snapshot.`);
    }
  }

  const open = (snapshot: SnapshotSummaryDto) => {
    props.onChange(snapshot.snapshotKey);
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={() => { props.onClose() }} 
      isCentered={true}
      scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <div style={{ minHeight: '40vh', maxHeight: '80vh', overflowX: 'scroll' }}>

          {isLoading && <Skeleton height="20px" my="10px" />}

          {!isLoading && <div style={{ padding: 60 }}>
            {episodes?.map((item: SnapshotSummaryDto) => (
              <EpisodeItem
                key={item.snapshotKey}
                current={item.snapshotKey === props.currentSnapshotKey}>
                <div style={{ width: '60%', paddingTop: 6, paddingBottom: 6 }}
                  onClick={() => open(item)}>
                  <div style={{ fontSize: 14, marginBottom: 4 }}>{item.title}</div>
                  <div style={{ fontSize: 12 }}>
                    <Badge colorScheme={item.enabled && item.destinationAudioSent ? 'green' : 
                      item.destinationAudioDateTime && new Date(item.destinationAudioDateTime) > new Date() ? 'purple' : 'red'}>
                      {item.enabled && item.destinationAudioSent ? t('published') : 
                      item.destinationAudioDateTime && new Date(item.destinationAudioDateTime) > new Date() ? t('scheduled') :
                      t('notPublished')}
                    </Badge>
                  </div>
                </div>
                <div style={{ width: '30%', fontSize: 10, color: '#808080' }}>
                  {item.destinationAudioDateTime && 
                  formatDistance(new Date(item.destinationAudioDateTime), new Date(), { addSuffix: true, locale: sv })}
                </div>
                <div style={{ width: '10%', marginRight: 4 }}>
                  {(!item.destinationAudioSent || !item.enabled) && 
                    <div onClick={() => remove(item)}>
                      <IconButton
                        variant="outline"
                        aria-label={t('remove')}
                        size="sm"
                        isDisabled={episodes.length===1}
                        icon={<MdDeleteForever />}
                      />
                    </div>}
                </div>
              </EpisodeItem>
            ))}

            {!isGenerating && <div style={{ marginTop: 20 }}>
              <Button size="sm" variant="outline" 
                onClick={generateNew}>{t('generateNewEpisode')}</Button>
            </div>}

            {isGenerating && <div style={{ marginTop: 30, display: 'flex' }}>
              <Spinner color="brand" />
              <div style={{ fontSize: 15, marginLeft: 10 }}>{t('generatingFromTemplate')}</div>
            </div>}

          </div>}

        </div>
      </ModalContent>
    </Modal>
  );
};

export default Episodes;
