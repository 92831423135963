import React, { useEffect, useState } from "react";
import { Flex, FormControl, FormHelperText, FormLabel, 
    Input, Stack, Textarea, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Config } from "../../config";
import { CreateOrUpdateStoryDto, InputPropertyDto, StoryApi } from "../../generated-sources/openapi/api";

import CoverImage from './CoverImage';
import DayAndTimeScheduler from './DayAndTimeScheduler';
import { Container } from './styled';
import { BeatLoader } from "react-spinners";

type Props = {
    storyAlias: string
}

const StationMetadataModal = (props: Props) => {
    const history = useHistory();
    const { t } = useTranslation();

    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const tenantId = sessionStorage.getItem('TENANT_ID')!;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [podcastTitle, setPodcastTitle] = useState<string | null>();
    const [podcastAuthor, setPodcastAuthor] = useState<string | null>('');
    const [podcastDescription, setPodcastDescription] = useState<string | null>('');
    const [coverImageUri, setCoverImageUri] = useState<string>();
    const [cron, setCron] = useState<string>('');

    useEffect(() => {
        loadFromBackend(props.storyAlias);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFromBackend = async (storyAlias: string) => {
        const storyResp = await storyApi.getStory(storyAlias, tenantId);
        const story = storyResp.data;

        if (!story.inputs) {
            throw new Error(`inputs is null on the story`);
        }

        setPodcastTitle(story.title);
        // TODO: being able setLanguage(story.inputs!['_lang']);
        
        const descSettings = story.inputs!.filter(x => x.key === '_podcast-description');
        if (descSettings.length > 0) {
            const descriptionValue = descSettings[0].value!;
            setPodcastDescription(descriptionValue);
        }

        const authorSettings = story.inputs!.filter(x => x.key === '_podcast-author');
        if (authorSettings.length > 0) {
            const authorValue = authorSettings[0].value!;
            setPodcastAuthor(authorValue);
        }

        const imageUriSettings = story.inputs!.filter(x => x.key === '_podcast-logouri');
        if(imageUriSettings.length > 0) {
            setCoverImageUri(imageUriSettings[0].value!);
        } else {
            setCoverImageUri('https://bw-coverimages.s3.eu-north-1.amazonaws.com/brief-coverimage.png');
        }

        // CRON expression - when shall episodes be publish on daily basis
        const cronSettings = story.inputs!.filter(x => x.key === '_podcast-publish-cron');
        if (cronSettings.length > 0) {
            const val = cronSettings[0].value!;
            setCron(val);
        }

        setIsLoading(false);
    };

    const save = async () => {
        setIsSaving(true);

        let inputs : InputPropertyDto[] = [];
        inputs.push({ 
            key: '_mixmode',
            value: '1'
        });
        if (podcastDescription) {
            inputs.push({ 
                key: '_podcast-description',
                value: podcastDescription
            });
        }
        if (podcastAuthor) {
            inputs.push({ 
                key: '_podcast-author',
                value: podcastAuthor
            });
        }
        if (coverImageUri) {
            inputs.push({ 
                key: '_podcast-logouri',
                value: coverImageUri
            });
        }
        if (cron) {
            inputs.push({ 
                key: '_podcast-publish-cron',
                value: cron
            });
        }

        const story: CreateOrUpdateStoryDto = {
            tenantId: tenantId,
            storyAlias: props.storyAlias,
            title: podcastTitle,
            inputs: inputs
        };

        const saveStoryResult = await storyApi.createOrUpdateStory(story);
        if (saveStoryResult.data.success) {
            close();
        } else {
            console.error(`failed to save story: ${saveStoryResult.data.message}`);
        }

        setIsSaving(false);
    };

    const openInEditor = () => {
        window.open(`${process.env.REACT_APP_EDITOR_URL}/${props.storyAlias!}`, '_blank');
    };

    const deleteStation = async () => {
        setIsSaving(true);
        const deleteResult = await storyApi.deleteStory(props.storyAlias);
        if (deleteResult.data.success) {
            setIsSaving(false);
            history.push(`/`);
        } else {
            console.error(`Failed to delete station: ${deleteResult.data.message}`);
        }
    };

    const close = () => {
        history.push(`/${props.storyAlias}`);
    }

    return (
        <Container>
            {!isLoading && <div>
                <Stack spacing={3}>

                    <FormControl>
                        <FormLabel htmlFor="title">{t('podcastTitle')}</FormLabel>
                        <Input type="text" id="title" aria-describedby="title-helper-text" 
                            value={podcastTitle!} onChange={(ev => {
                                setPodcastTitle(ev.target.value);
                            })} />
                        <FormHelperText id="title-helper-text">
                            {t('podcastTitleHelpText')}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="title">{t('podcastDescription')}</FormLabel>
                        <Textarea size="lg" resize="vertical"
                            value={podcastDescription!}
                            onChange={(ev => { setPodcastDescription(ev.target.value); })} />
                        <FormHelperText id="title-helper-text">
                            {t('podcastDescriptionHelpText')}
                        </FormHelperText>
                    </FormControl>

                    {coverImageUri && <CoverImage 
                        storyAlias={props.storyAlias}
                        imageUrl={coverImageUri}
                        onChange={(newUri: string) => { setCoverImageUri(newUri); }} />}

                    <DayAndTimeScheduler cron={cron} onChange={(val: string) => { setCron(val); }} />

                    <Flex justify="left" align="left">
                        <Button variant="ghost" size={"xs"} onClick={openInEditor}>{t('editTemplate')}</Button>
                    </Flex>

                    <Flex justify="left" align="left">

                        <Button size="lg" colorScheme="brand" mr={2} onClick={save} 
                            isLoading={isSaving} spinner={<BeatLoader size={8} color="white" />}>{t('save')}</Button>
                        <Button size="lg" variant="ghost" onClick={close}>{t('cancel')}</Button>

                        <Button isLoading={isSaving} style={{ marginLeft: 'auto' }} size="sm" 
                            colorScheme="red" onClick={deleteStation}>{t('delete')}</Button>

                    </Flex>

                </Stack>
            </div>}
        </Container>
    );
}

export default StationMetadataModal;