import React, { useEffect, useRef, useState } from "react";
import { Config } from "../../config";
import { TaskDto, TasksApi } from "../../generated-sources/openapi";
import { useTranslation } from "react-i18next";

import { Container, Header, Item, StoryIcon, Title } from "./styled";
import { Spinner } from "@chakra-ui/react";
import HumanRecordingStudio from "../HumanRecordingStudio/HumanRecordingStudio";

const TaskList = () => {
    const tasksApi = new TasksApi(Config.getApiConfig(), undefined, Config.AxiosInstance);
    const { t } = useTranslation();
    // Note: used for cancelling async functions
    const isCancelled = useRef(false);

    const [tenantName, setTenantName] = useState<string>('');
    const [tasks, setTasks] = useState<TaskDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>();

    useEffect(() => {
        const tenantName = sessionStorage.getItem('TENANT_NAME')!;
        setTenantName(tenantName);

        loadTasks();

        return () => {
            isCancelled.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadTasks = () => {
        setIsLoading(true);

        const tenantId = sessionStorage.getItem('TENANT_ID')!;
        tasksApi.getTasks(tenantId).then((result) => {
            if (!isCancelled.current) {
                setTasks(result.data);
            }
            setIsLoading(false);
        });
    }

    return (
        <Container>
            <div>
                <Header>{t('recordingsToBeMade')} ({tenantName})</Header>

                {isLoading && <Spinner size="lg" style={{ marginLeft: 20 }} />}

                <div style={{ float: 'left', width: 400, height: '67vh', overflow: 'auto' }}>
                    {tasks.map((item) => (
                        <Item key={item.storyAlias} 
                            current={item.storyAlias===selectedId} 
                            onClick={() => { setSelectedId(item.storyAlias!); }}>
                            {item.imageUrl && <StoryIcon><img src={item.imageUrl} alt={item.title!} /></StoryIcon>}
                            <Title>{item.title}</Title>
                        </Item>
                    ))}
                </div>

                {selectedId && <div style={{ float: 'left', width: 400, marginLeft: 40 }}>
                    <HumanRecordingStudio 
                        key={selectedId}
                        storyAlias={selectedId!}
                        lang={Config.LangCode}
                        onGoToNext={() => { loadTasks(); setSelectedId(undefined); }}
                        onReload={() => { loadTasks(); }} />
                </div>}

            </div>

            {!isLoading && tasks.length === 0 && 
                <div style={{ fontSize: 15, marginBottom: 20, marginLeft: 15 }}>
                    {t('noRecordingsToBeMade')}
                </div>}

        </Container>
    );
}

export default TaskList;