import { Extension } from '@tiptap/react'
import { Plugin } from 'prosemirror-state'

// https://githubplus.com/ueberdosis/tiptap/issues/1891

export interface LocalixReplaceOptions { }

export interface Result {
  from: number
  to: number
}

declare module '@tiptap/core' {
  interface Commands {
    lxreplace: {
      getMatchesOfLocationsInText: (term: string) => any,
    }
  }
}

const getFindRegExp = (searchTerm: string, caseSensitive: boolean): RegExp | void => {
  if (!searchTerm) {
    return
  }
  return RegExp(searchTerm, !caseSensitive ? 'gui' : 'gu')
}

const search = (searchTerm: string, editor: any) => {
  let results: Result[] = [];

  const mergedTextNodes: { text: any; pos: any }[] = [];
  let index = 0;

  if (!searchTerm) {
    return;
  }

  editor.state.doc.descendants((node: any, pos: number) => {
    if (node.isText) {
      if (mergedTextNodes[index]) {
        mergedTextNodes[index] = {
          text: mergedTextNodes[index].text + node.text,
          pos: mergedTextNodes[index].pos,
        }
      } else {
        mergedTextNodes[index] = {
          text: node.text,
          pos,
        }
      }
    } else {
      index += 1;
    }
  })

  mergedTextNodes.forEach(({ text, pos }) => {
    const search = getFindRegExp(searchTerm, true)
    if (!search) {
      return;
    }

    let m: RegExpExecArray | null;
    // eslint-disable-next-line no-cond-assign
    while ((m = search.exec(text))) {
      if (m[0] === '') {
        break;
      }

      results.push({
        from: pos + m.index,
        to: pos + m.index + m[0].length
      });
    }
  });

  return results;
}

export const LocalixReplaceExtension = Extension.create<LocalixReplaceOptions>({
  name: 'lxreplace',

  addCommands() {
    return {
      getMatchesOfLocationsInText: (term: string) => ({ editor }) => {
        const items = search(term, editor);

        if (!items) {
          console.warn(`term '${term}' not found in document`);
          return;
        }

        return items;
      }
    }
  },

  addProseMirrorPlugins() {
    const plugins: Plugin[] = [];

    /*
    const geonameMatcherPlugin = new Plugin({
      key: new PluginKey('geonameMatcher'),
      state: {
        init() {
          return DecorationSet.empty
        },
        apply(tr, value, oldState) {
          if (tr.docChanged) {
            
            // return createDeco(tr.doc)
          }
          return oldState
        },
      },
      appendTransaction: (transactions, oldState, newState) => {
        // TODO: use this to apply new search on new contenet
        // const length = newState.doc.content.size
        // return newState.tr.insertText('', options.limit + 1, length)
        return
      }
    })
    plugins.push(geonameMatcherPlugin)
    */

    return plugins;
  },
})