import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { Container } from './styled';
import { Config } from "../../config";
import { CreateOrUpdateStoryDto, InputPropertyDto, StoryApi } from '../../generated-sources/openapi';
import { FormControl, FormHelperText, FormLabel, Input, Stack } from '@chakra-ui/react';

type Props = {
    storyAlias: string,
    onExecuted: any,
}

const UrlTypeForm = (props: Props) => {
    const { t } = useTranslation();
    const storyApi = new StoryApi(Config.getApiConfig(), undefined, Config.AxiosInstance);

    const [isLoading, setIsLoading] = useState(false);
    const [uri, setUri] = useState<string>('');

    // TODO: disable "importing by pasting" while this component is active

    useEffect(() => {
        setIsLoading(true);
        // Note: block manager manages snapshots, 
        // this is used to edit the story/brief/template
        (async function loadStoryContent() {
            const tenantId = sessionStorage.getItem('TENANT_ID')!;
            const contentResult = await storyApi.getStoryContent(props.storyAlias, tenantId);
            if (contentResult.data.content) {
                setUri(contentResult.data.content!);
            } else {
                console.warn(`no story content`);
            }
            setIsLoading(false);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.storyAlias]);

    const saveUriToBackend = async (uri: string) => {
        const tenantId = sessionStorage.getItem('TENANT_ID')!;

        const inputs : InputPropertyDto[] = [];
        inputs.push({ 
            key: 'uri',
            value: uri
        });

        const story: CreateOrUpdateStoryDto = {
            tenantId: tenantId,
            storyAlias: props.storyAlias,
            content: uri,
            title: props.storyAlias,
            inputs: inputs
        };

        const createResult = await storyApi.createOrUpdateStory(story);
        if (createResult.status === 200 && createResult.data.success) {
            console.debug(`saved successfully to backend.`);
            props.onExecuted();
        } else {
            throw new Error(`failed to save to backend: ${createResult.data.message}`);
        }
    };

    return (
        <Container>
            
            {!isLoading && <Stack spacing={3}>

                <FormControl id="uri" isInvalid={!uri || uri.length < 5}>
                    <FormLabel htmlFor="uriField">{t('uriFieldLabel')}</FormLabel>
                    <Input id="uriField" variant="outline" size="lg" value={uri}
                        onChange={(ev: any) => {
                            setUri(ev.target.value);
                            saveUriToBackend(ev.target.value);
                         }} />
                    <FormHelperText>{t('uriFieldHelpText')}</FormHelperText>
                </FormControl>

            </Stack>}

        </Container>
    );
}

export default UrlTypeForm;