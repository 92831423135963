import React, { useCallback, useEffect } from "react";

type Props = {
  enabled: boolean,
  onUrlPasted: any,
  onFilePasted: any
}

const Paste = (props: Props) => {
  const handlePaste = useCallback(event => {
    if (!props.enabled || sessionStorage.getItem('import-disabled') === '1') {
      console.debug(`paste feature not active`);
      return;
    }

    if (event.clipboardData.items.length > 0) {
      // upload the first file in the clipboard
      const item = event.clipboardData.items[0];

      const validMimeTypes = [
        'audio/wav', 'audio/vnd.wave', 'audio/mpeg', 
        'audio/webm', 'audio/ogg'];
      if (validMimeTypes.includes(item.type)) {
        props.onFilePasted(item.getAsFile() as File);
      }

      // cancel paste
      event.preventDefault();

      return;
    }

    // get text representation of clipboard
    const text = (event.originalEvent || event)
      .clipboardData.getData('text/plain');

    const cleanedText = text.replace(/\s/g,'');
    if (cleanedText.startsWith('https://')) {
      // insert URL
      props.onUrlPasted(cleanedText);
    } else {
      // insert text manually
      event.clipboardData.setData('text/plain', cleanedText);
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <></>
  );
};

export default Paste;
